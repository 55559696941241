import * as React from 'react';
import { FC } from 'react';

import BaseEmoji from './BaseEmoji';
import { sentimentColors } from 'theme/colors';

const AnxiousEmoji: FC = () => {
    const mouthPath = (
        <path
            d="M32.5,55.5 C34.3925781,53.5053711 38.6846429,52.3210449 41.0395549,52.3210449 C46.6798516,52.3210449 49.5,55.5 49.5,55.5"
            id="Line"
            stroke="#434343"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
        ></path>
    );
    return (
        <BaseEmoji
            // tearOnHover
            color={sentimentColors.anxious}
            mouth={mouthPath}
        />
    );
};

export default AnxiousEmoji;
