import React from 'react';

/* interfaces */
import { RenderElement } from '../interfaces';

/* component */
import InteractiveQuiz from 'components/interactive-quiz/InteractiveQuiz';

const RenderQuiz: RenderElement = (props): JSX.Element | null => {
    const { data = {} } = props;
    if (!data.quiz) return null;

    return <InteractiveQuiz quizData={data.quiz} />;
};

export default RenderQuiz;
