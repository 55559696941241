import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';

/* material */
import { makeStyles } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

/* components */
import { Eyebrow } from 'components';

/* utils */
import { pxToRem } from 'theme/util';
import colors from 'theme/colors';

interface EyebrowWithBackLinkProps {
    url: string;
    messageId: string;
}

const useStyles = makeStyles((theme) => ({
    icon: {
        color: colors.raspberry,
        fontSize: pxToRem(23),
        marginRight: 13,

        [theme.breakpoints.up('sm')]: {
            fontSize: pxToRem(25),
        },
    },
    linkWrapper: {
        alignItems: 'center',
        display: 'flex',
    },
}));

const EyebrowWithBackLink: FC<EyebrowWithBackLinkProps> = ({
    url,
    messageId,
}) => {
    const classes = useStyles();
    return (
        <div data-qa="eyebrow-back-link" className={classes.linkWrapper}>
            <ArrowBackIcon data-qa="back-arrow-icon" className={classes.icon} />
            <Eyebrow dataAnalyticsPlacement="ArticleHeader" url={url}>
                <FormattedMessage id={messageId} />
            </Eyebrow>
        </div>
    );
};

export default EyebrowWithBackLink;
