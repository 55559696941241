import React, { FC } from 'react';

import Box from '@material-ui/core/Box';

type FixedAspectRatioProps = {
    children: React.ReactNode;
    aspectRatio?: number;
};

const FixedAspectRatio: FC<FixedAspectRatioProps> = ({
    children,
    aspectRatio = 9 / 16,
}) => {
    return (
        <Box height={0} position="relative" pt={`${aspectRatio * 100}%`}>
            <Box
                position="absolute"
                top={0}
                left={0}
                height="100%"
                width="100%"
            >
                {children}
            </Box>
        </Box>
    );
};

export default FixedAspectRatio;
