import React, { Suspense, lazy, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import queryString from 'query-string';
import { pxToRem } from 'theme/util';
import { AVENIR_NEXT_LT_REGULAR } from 'theme/font';
import { Loader } from 'components';
import PodcastPlayer from 'components/podcastPlayer/PodcastPlayer';
import PlaylistItem from './PlaylistItem';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
    root: {
        minHeight: 'calc(100vh - 120px)',
        paddingTop: 40,
        '&& .s7videoplayer': {
            backgroundColor: 'unset',
            filter: 'drop-shadow(5px 5px 10px rgba(0, 0, 0, 0.36))',
        },
        [theme.breakpoints.up('md')]: {
            minHeight: 'calc(100vh - 100px)',
            paddingTop: 40,
            '&& .s7controlbar': {
                borderRadius: 10,
            },
            '&& video': {
                borderRadius: 15,
            },
        },
    },
    watchHeaderContainer: {
        width: '100%',
        textAlign: 'center',
        color: 'white',
        marginBottom: 20,
        [theme.breakpoints.up('md')]: {
            width: 700,
            marginBottom: 30,
            marginLeft: 100,
        },
        '&& h1': {
            fontSize: pxToRem(20),
            fontFamily: AVENIR_NEXT_LT_REGULAR,
            marginBottom: 10,
            [theme.breakpoints.up('md')]: {
                fontSize: pxToRem(45),
                fontWeight: 600,
                lineHeight: '39px',
            },
        },
    },
    desc: {
        fontSize: pxToRem(16),
        fontWeight: 400,
        lineHeight: '19.2px',
        marginTop: 10,
        [theme.breakpoints.up('md')]: {
            fontSize: pxToRem(16),
            fontWeight: 400,
        },
    },
    watchFlexContainer: {
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.up('md')]: {
            flexDirection: 'unset',
            //minWidth: 1024,
        },
    },
    watchContainer: {
        flex: 1,
        // Add styling for the video player
    },
    watchPlaylistContainer: {
        flex: 1,
        padding: '30px 20px',
        [theme.breakpoints.up('md')]: {
            padding: 2,
            marginLeft: 40,
        },
        // Add styling for the video list
    },
}));

interface YoutubeStyleVideosProps {
    isMobile?: boolean;
    rootStyle?: string;
    headerStyle?: string;
    detailStyle?: string;
    videoListData: any;
    fullVideoConfig: any;
    showBio?: boolean;
    id: string;
    dataTestId: string;
    title: string;
    description: string;
    watchFullExperienceLabel?: string;
    fullVideoTitle?: string;

    // Podcast
    showPodcast?: boolean;
    podcast?: any;
    audioName?: string;
    podcastBgColor?: string;
}

const YoutubeStyleVideos: React.FC<YoutubeStyleVideosProps> = ({
    isMobile = false,
    rootStyle,
    headerStyle,
    detailStyle,
    videoListData,
    fullVideoConfig,
    showBio = false,
    id,
    dataTestId,
    title,
    description,
    watchFullExperienceLabel = '',
    fullVideoTitle = '',
    showPodcast = false,
    podcast,
    audioName = '',
    podcastBgColor = '',
}) => {
    const classes = useStyles();
    const [currentVideoId, setCurrentVideoId] = useState(0);
    const [playlist, setPlaylist] = useState(videoListData);
    const qs = queryString.parse(window.location.search);
    const videoDeepLinkId = (qs.video && Number(qs.video)) || null;

    useEffect(() => {
        //Effect used to look for video deeplinking. Logic checks to see if video Id is available before loading the video.
        if (
            videoDeepLinkId &&
            videoDeepLinkId !== currentVideoId &&
            videoListData[videoDeepLinkId - 1]
        ) {
            handleThumbnailClick(videoDeepLinkId - 1);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [videoDeepLinkId]);

    const handleThumbnailClick = (id?: number) => {
        //looking for 0 or it fails
        if (id || id === 0) {
            setCurrentVideoId(id);
        } else {
            //callback.. no id is sent
            setPlaylist((prevPlaylist: any[]) => {
                const videoIndex = prevPlaylist.findIndex(
                    (video) => video.id === currentVideoId,
                );
                if (videoIndex !== -1) {
                    const updatedPlaylist = [...prevPlaylist];
                    const videoToMove = updatedPlaylist.splice(
                        videoIndex,
                        1,
                    )[0];
                    updatedPlaylist.push(videoToMove);
                    setCurrentVideoId(updatedPlaylist[0].id);
                    return updatedPlaylist;
                }
                return prevPlaylist;
            });
        }
    };

    const renderMainVideo = (currentVideoId: number) => {
        const MainVideo = lazy(() => import('./MainVideo'));
        const videoIndex = playlist.findIndex(
            (video: { id: number }) => video.id === currentVideoId,
        );
        return (
            <Suspense fallback={<Loader />}>
                <MainVideo
                    isMobile={isMobile}
                    videoConfig={playlist[videoIndex].videoConfig}
                    fullVideoConfig={fullVideoConfig}
                    title={videoListData[currentVideoId].title}
                    description={videoListData[currentVideoId].desc}
                    handleThumbnailClick={handleThumbnailClick}
                    showBio={showBio}
                    watchFullExperienceLabel={watchFullExperienceLabel}
                    fullVideoTitle={fullVideoTitle}
                    detailStyle={detailStyle}
                />
            </Suspense>
        );
    };

    if (!videoListData) {
        return null;
    }

    return (
        <div
            id={id}
            className={clsx(rootStyle, classes.root)}
            data-testid={dataTestId}
        >
            <div className={clsx(headerStyle, classes.watchHeaderContainer)}>
                <h1>{title}</h1>
                <div className={classes.desc}>{description}</div>
            </div>
            <div className={classes.watchFlexContainer}>
                <div className={classes.watchContainer}>
                    {renderMainVideo(currentVideoId)}
                    {showPodcast && (
                        <PodcastPlayer
                            podcast={podcast}
                            backgroundColor={podcastBgColor}
                            audioName={audioName}
                        />
                    )}
                </div>
                <div className={classes.watchPlaylistContainer}>
                    {playlist.map(
                        (
                            video: {
                                id: number;
                                thumbnail: string;
                                title: string;
                                desc: string;
                                minutes: string;
                            },
                            index: any,
                        ) => {
                            return (
                                <PlaylistItem
                                    isSelected={
                                        currentVideoId === video.id
                                            ? true
                                            : false
                                    }
                                    key={`playlist-item-${index}`}
                                    isMobile={isMobile}
                                    id={video.id}
                                    handleThumbnailClick={handleThumbnailClick}
                                    thumbnail={video.thumbnail}
                                    title={video.title}
                                    description={video.desc}
                                    minutes={video.minutes}
                                    detailStyle={detailStyle}
                                />
                            );
                        },
                    )}
                </div>
            </div>
        </div>
    );
};

export default YoutubeStyleVideos;
