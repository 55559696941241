import { SET_ANALYTIC_PAGE_VIEW_READY } from 'constants/analytics';

import { AnalyticActionTypes } from 'actions/analytics';

export type Analytics = {
    analyticsPageViewReady: boolean;
};

export const initialAnalyticsState: Analytics = {
    analyticsPageViewReady: false,
};

export const analyticsReducer = (
    state = initialAnalyticsState,
    action: AnalyticActionTypes,
) => {
    switch (action.type) {
        case SET_ANALYTIC_PAGE_VIEW_READY:
            return { analyticsPageViewReady: action.isReady };
        default:
            return state;
    }
};
