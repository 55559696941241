import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';

/* material */
import { makeStyles } from '@material-ui/core/styles';

/* utils */
import colors from 'theme/colors';
import { pxToRem } from 'theme/util';

const useStyles = makeStyles((theme) => ({
    bar: {
        backgroundColor: colors.turquoise30,
        borderRadius: '8px',
        height: '8px',
        position: 'relative',
        width: '150px',

        [theme.breakpoints.up('sm')]: {
            borderRadius: '12px',
            height: '12px',
            width: '200px',
        },
    },
    innerBar: {
        backgroundColor: colors.turquoise,
        borderRadius: '8px',
        height: '100%',
        left: 0,
        position: 'absolute',
        width: '0%',

        [theme.breakpoints.up('sm')]: {
            borderRadius: '12px',
        },
    },
    wrapper: {
        alignItems: 'center',
        display: 'flex',
        flexFlow: 'column',
    },
    label: {
        marginBottom: '8px',

        [theme.breakpoints.up('sm')]: {
            fontSize: pxToRem(16),
        },
    },
}));

type ProgressBarProps = {
    index: number;
    steps: number;
};

const ProgressBar: FC<ProgressBarProps> = ({ index, steps }) => {
    const classes = useStyles();
    const questionIdx = index + 1;
    const width = `${Math.floor((questionIdx / steps) * 100)}%`;
    const step = `${questionIdx} / ${steps}`;

    return (
        <div data-qa="progress-bar" className={classes.wrapper}>
            <div data-qa="progress-bar-label" className={classes.label}>
                <FormattedMessage
                    id="quizLanding.question"
                    values={{ step }}
                ></FormattedMessage>
            </div>
            <div data-qa="progress-bar-outer" className={classes.bar}>
                <div
                    data-qa="progress-bar-inner"
                    className={classes.innerBar}
                    style={{ width }}
                ></div>
            </div>
        </div>
    );
};

export default ProgressBar;
