import React, { useState, useRef, useEffect } from 'react';
import dayjs from 'dayjs';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

/* components */
import QuizModal from './QuizModal';
import SentimentModal from './SentimentModal';

/* hooks */
import { useRouteGuard } from 'hooks/routeGuard/useRouteGuard';

/* store */
import { RootState } from 'store';

const QUIZ_MODAL_TIMEOUT_INTERVAL = 300 * 1000; // 5 mins

const QuizSentimentModalWithRouteGuard = () => {
    const history = useHistory();
    const timeoutHandler = useRef<NodeJS.Timeout | null>(null);
    const {
        animal,
        lastQuizModalViewDate,
        lastSentimentModalViewDate,
        isReturnVisit,
        segment,
        uid,
    } = useSelector((state: RootState) => ({
        animal: state.user.animal,
        lastQuizModalViewDate: state.user.lastQuizModalViewDate,
        lastSentimentModalViewDate: state.user.lastSentimentModalViewDate,
        isReturnVisit: state.user.isReturnVisit,
        uid: state.user.uid,
        segment: state.user.segment,
    }));
    const {
        quizModalDisplayThreshold = 1,
        sentimentModalDisplayThreshold = 1,
    } = useFlags();
    const [quizModalVisible, setQuizModalVisible] = useState<boolean>(false);
    const [sentimentModalVisible, setSentimentModalVisible] =
        useState<boolean>(false);
    const isKnownUser = Boolean(uid);
    const quizCompleted = Boolean(animal);
    const [unblock, redirect] = useRouteGuard((redirect: string) => {
        if (!quizModalVisible && !sentimentModalVisible) {
            const currentDate = dayjs().utc();
            const nextQuizModalViewDate = dayjs(lastQuizModalViewDate)
                .utc()
                .add(quizModalDisplayThreshold, 'minute');
            const nextSentimentModalViewDate = dayjs(lastSentimentModalViewDate)
                .utc()
                .add(sentimentModalDisplayThreshold, 'minute');
            const quizModalVisibleValue =
                isKnownUser &&
                !quizCompleted &&
                isReturnVisit &&
                (!lastQuizModalViewDate || currentDate > nextQuizModalViewDate);
            const sentimentModalVisibleValue =
                ((!isReturnVisit && !animal && !lastSentimentModalViewDate) ||
                    (isReturnVisit && !lastSentimentModalViewDate) ||
                    (isReturnVisit &&
                        currentDate > nextSentimentModalViewDate)) &&
                !quizModalVisibleValue;
            if (quizModalVisibleValue || sentimentModalVisibleValue) {
                setQuizModalVisible(quizModalVisibleValue);
                setSentimentModalVisible(sentimentModalVisibleValue);
                return;
            }
        }

        if (unblock) {
            unblock.current();
            history.push(redirect);
        }
    });
    useEffect(() => {
        const shouldShowQuizModalInFiveMins =
            isKnownUser && !quizCompleted && isReturnVisit;
        if (shouldShowQuizModalInFiveMins) {
            timeoutHandler.current = setTimeout(() => {
                setQuizModalVisible(true);
                setSentimentModalVisible(false);
            }, QUIZ_MODAL_TIMEOUT_INTERVAL);
        }
        return () => {
            if (timeoutHandler.current) {
                clearTimeout(timeoutHandler.current);
            }
        };
    }, []); // eslint-disable-line
    const handleModalClose = (): void => {
        if (quizModalVisible) {
            setQuizModalVisible(false);
            if (timeoutHandler.current) {
                clearTimeout(timeoutHandler.current);
                timeoutHandler.current = null;
                return;
            }
        }
        if (sentimentModalVisible) {
            setSentimentModalVisible(false);
        }
        if (!unblock) {
            return;
        }
        unblock.current();
        history.push(redirect);
    };

    if (quizModalVisible) {
        return (
            <QuizModal
                analyticId="quiz-modal"
                visible={quizModalVisible}
                onClose={handleModalClose}
            />
        );
    }

    if (sentimentModalVisible) {
        return (
            <SentimentModal
                isVisible={sentimentModalVisible}
                onClose={handleModalClose}
                segment={segment}
            />
        );
    }

    return null;
};

export default QuizSentimentModalWithRouteGuard;
