import * as React from 'react';
import { FC } from 'react';

import BaseEmoji from './BaseEmoji';
import { sentimentColors } from 'theme/colors';

const HopefulEmoji: FC = () => {
    const mouthPath = (
        <path
            d="M33.0515137,52.2321777 C35.2797038,54.4966634 37.9291992,55.6289063 41,55.6289063 C44.0708008,55.6289063 46.8304443,54.4966634 49.2789307,52.2321777"
            id="Line"
            stroke="#434343"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
        ></path>
    );
    return <BaseEmoji color={sentimentColors.hopeful} mouth={mouthPath} />;
};

export default HopefulEmoji;
