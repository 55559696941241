export const multiStepEvent = (
    data = {
        eventName: '',
        processStepLabel: '',
        processStepValue: '',
    },
) => {
    window.appEventData = window.appEventData || [];

    window.appEventData.push({
        event: data.eventName,
        process: {
            processStepLabel: data.processStepLabel,
            processStepValue: data.processStepValue,
            settingUsed: '',
        },
        selfservice: {
            ActivityName: data.processStepLabel,
        },
    });
};
