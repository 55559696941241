import * as React from 'react';
import { domToReact } from 'html-react-parser';
import { getReduxSessionState } from 'utils/sessionStorage';

/* constants */
import { GENERIC } from 'constants/company';

/* utils */
import { isUrlExternal, isTel, isMailTo } from 'utils/url';

/* interfaces */
import { RenderType, RenderElementProps } from '../interfaces';

const renderAnchor: RenderType = (
    props: RenderElementProps,
): JSX.Element | null => {
    const { domNode, options } = props;
    const { attribs, children } = domNode;
    const invalidAttribs = ['style'];

    // remove invalid attribs before passing as props to react
    const filteredAttribs =
        attribs &&
        Object.keys(attribs).reduce(
            (initalValue: { [key: string]: string }, attrib: string) => {
                !invalidAttribs.includes(attrib) &&
                    (initalValue[attrib] = attribs[attrib]);
                return initalValue;
            },
            {},
        );

    let href = (filteredAttribs && filteredAttribs['href']) || '';
    let target = (filteredAttribs && filteredAttribs['target']) || '';

    const reduxState = getReduxSessionState();
    const companyId =
        reduxState && reduxState.company
            ? reduxState.company.theme.companyId
            : GENERIC;

    href =
        isUrlExternal(href) || isTel(href) || isMailTo(href)
            ? href
            : `/${companyId}/${href}`;
    target = isUrlExternal(href) ? '_blank' : '_self';

    return (
        <a {...{ ...filteredAttribs, href, target }}>
            {children && domToReact(children, options)}
        </a>
    );
};

export default renderAnchor;
