import Url from 'url-parse';

/* constants */
import {
    DOMAINS,
    MY_RETIREMENT_DOMAIN,
    I_CAN_RETIRE_DOMAIN,
} from 'constants/user';

const url = new Url(window.location.href);

export const getDomain = () => {
    const isMyRetirement = url.hostname.indexOf(MY_RETIREMENT_DOMAIN) > -1;
    return DOMAINS[isMyRetirement ? MY_RETIREMENT_DOMAIN : I_CAN_RETIRE_DOMAIN];
};
