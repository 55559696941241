import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';

/* material */
import { makeStyles } from '@material-ui/core/styles';

/* utils */
import { label } from 'theme/font';
import { convertToNumberWithCommas } from 'utils/numberFormat';

const useStyles = makeStyles(() => ({
    label,
}));

export const getLikesPluralFormatting = (count: number) => {
    return count > 1 ? (
        <FormattedMessage id="likes" />
    ) : (
        <FormattedMessage id="like" />
    );
};

type LikesProps = {
    count: number | undefined;
};

const Likes: FC<LikesProps> = ({ count }) => {
    const classes = useStyles();

    if (!count) return null;

    return (
        <span data-qa="article-like-label" className={classes.label}>
            {`${convertToNumberWithCommas(count)} `}
            {getLikesPluralFormatting(count)}
        </span>
    );
};

export default Likes;
