import React, { FC } from 'react';
import clsx from 'clsx';

/* material */
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

/* utils */
import { AVENIR_NEXT_LT_DEMI } from 'theme/font';
import colors from 'theme/colors';
import { pxToRem } from 'theme/util';

/* types */
import { Answer } from './QuizQuestionSet';

/* assets */
import grayArrowShort from 'assets/gray-arrow-short.svg';
import pinkArrow from 'assets/pink-arrow.svg';
import greenArrow from 'assets/green-arrow.svg';
import greenArrowGray from 'assets/gray-arrow.svg';
import greenArrowShort from 'assets/green-arrow-short.svg';

type UseStyleProps = {
    label: Answer;
    isActive?: boolean;
};

const useStyles = makeStyles((theme) => ({
    buttonColumnWrapper: {
        alignItems: 'center',
        display: 'flex',
        flexBasis: 'auto',
        flexDirection: 'column',
        position: 'relative',
    },
    button: {
        borderRadius: 37,
        border: 'solid 2px #7fafce',
        fontFamily: AVENIR_NEXT_LT_DEMI,
        fontSize: pxToRem(13),
        height: 24,
        minHeight: 24,
        marginBottom: 58,
        width: 55,

        '& span': {
            color: colors.darkSapphire,
        },

        [theme.breakpoints.up('sm')]: {
            fontSize: 35,
            height: 66,
            minHeight: 66,
            marginBottom: 130,
            width: 150,
        },
    },
    content: {
        fontFamily: AVENIR_NEXT_LT_DEMI,
        fontStyle: 'italic',
        fontSize: pxToRem(10),
        lineHeight: pxToRem(11),
        textAlign: 'center',
        width: 120,
        visibility: 'hidden',

        '&.visible': {
            visibility: 'visible',
        },

        [theme.breakpoints.up('sm')]: {
            fontSize: pxToRem(25),
            lineHeight: pxToRem(31),
            width: 320,
        },
    },
    contentWrapper: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        height: '100%',
    },
    contentBox: (props: UseStyleProps) => ({
        backgroundColor: !props.isActive
            ? '#ccc'
            : props.label === 'no'
            ? '#FEEBF5'
            : '#ebf7f3',
        borderRadius: 10,
        padding: 10,
        zIndex: 1,

        [theme.breakpoints.up('sm')]: {
            padding: 20,
        },
    }),
    no: {
        '&::before': {
            background: `url('${grayArrowShort}') center center no-repeat `,
            backgroundSize: '20px 100%',
            content: '""',
            height: 42,
            left: 0,
            position: 'absolute',
            top: 32,
            width: '100%',

            [theme.breakpoints.up('sm')]: {
                backgroundSize: 50,
                height: 105,
                top: 80,
            },
        },

        '&.active::before': {
            backgroundImage: `url('${pinkArrow}')`,
        },
    },

    yes: {
        '&::before': {
            backgroundColor: colors.gray60,
            borderRadius: 4,
            content: '""',
            height: '100%',
            left: 'calc(50% - 2px)',
            position: 'absolute',
            top: 32,
            width: 4,

            [theme.breakpoints.up('sm')]: {
                borderRadius: 9,
                left: 'calc(50% - 4px)',
                top: 80,
                width: 9,
            },
        },

        '&::after': {
            background: `url('${greenArrowGray}') center center no-repeat `,
            backgroundSize: 110,
            bottom: -64,
            content: '""',
            height: 36,
            left: -54,
            position: 'absolute',
            width: '100%',

            [theme.breakpoints.up('sm')]: {
                backgroundSize: 275,
                bottom: -160,
                height: 90,
                left: -91,
                width: 275,
            },
        },

        '&.active::before': {
            backgroundColor: '#6ac29f',
        },

        '&.active::after': {
            backgroundImage: `url('${greenArrow}')`,
        },

        '&.left::after': {
            left: 54,
            transform: 'scaleX(-1)',

            [theme.breakpoints.up('sm')]: {
                left: 176,
            },
        },

        '&.last::before': {
            background: `url('${grayArrowShort}') center center no-repeat `,
            backgroundSize: '20px 100%',
            content: '""',
            height: 42,
            left: 0,
            position: 'absolute',
            top: 32,
            width: '100%',

            [theme.breakpoints.up('sm')]: {
                backgroundSize: 50,
                height: 107,
                top: 80,
            },
        },

        '&.last.active::before': {
            backgroundImage: `url('${greenArrowShort}')`,
        },

        '&.last.active::after, &.last::after': {
            background: 'none',
        },
    },
}));

export interface QuizQuestionProps {
    columnLayout?: string;
    copy: string;
    label: Answer;
    isActive?: boolean;
    onClick: () => void;
    hasChildren: boolean;
}
const QuizQuestion: FC<QuizQuestionProps> = ({
    columnLayout = 'left',
    copy,
    label = 'no',
    isActive = false,
    onClick,
    hasChildren = false,
}) => {
    const classes = useStyles({ isActive, label });

    return (
        <div
            className={clsx(
                classes.buttonColumnWrapper,
                classes[label],
                hasChildren ? '' : 'last',
                columnLayout,
                isActive ? 'active' : '',
            )}
        >
            <Button
                data-qa={`quiz-question-${label}-button`}
                className={classes.button}
                onClick={onClick}
            >
                {label}
            </Button>
            <div className={classes.contentWrapper}>
                <div
                    data-qa={`quiz-question-${label}-copy`}
                    className={classes.contentBox}
                >
                    <div
                        className={clsx(
                            classes.content,
                            isActive ? 'visible' : '',
                        )}
                    >
                        {copy}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default QuizQuestion;
