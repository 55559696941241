import muiSwitch from '@material-ui/core/Switch';
import { withStyles } from '@material-ui/core/styles';

const Switch = withStyles((theme) => ({
    root: {
        width: 49,
        height: 24,
        padding: 0,
        display: 'flex',
    },
    switchBase: {
        padding: 4,
        color: theme.colors.white,
        '&$checked': {
            transform: 'translateX(24px)',
            color: theme.palette.common.white,
            '& + $track': {
                opacity: 1,
                backgroundColor: theme.colors.turquoise,
                borderColor: theme.colors.turquoise,
            },
        },

        '& + $track': {
            opacity: 1,
            backgroundColor: theme.colors.turquoise30,
            borderColor: theme.colors.turquoise30,
        },
    },
    thumb: {
        width: 16,
        height: 16,
        boxShadow: 'none',
    },
    track: {
        border: `1px solid ${theme.palette.grey[500]}`,
        borderRadius: 24 / 2,
        opacity: 1,
        backgroundColor: theme.palette.common.white,
    },
    checked: {},
}))(muiSwitch);

export default Switch;
