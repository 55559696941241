import React, { FC } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { ChangeContributionCta } from 'components';
import { useSelector } from 'react-redux';
import { RootState } from 'store';

const useStyles = makeStyles(() => ({
    componentContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: '10px',
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '80%',
        padding: '45px 0',
        borderBottom: '0.5px solid #E5E5E5',
    },
}));

export type ChangeContributionCtaWithSeparatorProps = {
    ctaLabel: string;
    dataAnalyticsPlacement: string;
};

const ChangeContributionCtaWithSeparator: FC<ChangeContributionCtaWithSeparatorProps> =
    ({ ctaLabel, dataAnalyticsPlacement }) => {
        const classes = useStyles();
        const company = useSelector((state: RootState) => state.company.theme);
        const user = useSelector((state: RootState) => state.user);
        return (
            <div className={classes.componentContainer}>
                <div className={classes.buttonContainer}>
                    <ChangeContributionCta
                        company={company}
                        user={user}
                        size="medium"
                        dataAnalyticsPlacement={dataAnalyticsPlacement}
                        ctaLabelOverride={ctaLabel}
                    />
                </div>
            </div>
        );
    };

export default ChangeContributionCtaWithSeparator;
