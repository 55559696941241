import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { AVENIR_NEXT_LT_DEMI, AVENIR_NEXT_LT_REGULAR } from 'theme/font';
import { pxToRem } from 'theme/util';
import { FormattedMessage, useIntl } from 'react-intl';
import colors from 'theme/colors';

interface StyleProps {
    isLatinGrammy: boolean;
}

const useStyles = makeStyles((theme) => ({
    root: {
        position: 'relative',
        minHeight: 'unset',
        [theme.breakpoints.up('md')]: {
            minHeight: 750,
        },
    },
    contentWrapper: {
        position: 'unset',
        backgroundColor: 'unset',
        padding: 0,
        color: colors.white,
        fontFamily: AVENIR_NEXT_LT_DEMI,
        textAlign: 'center',
        [theme.breakpoints.up('md')]: {
            textAlign: 'unset',
            backgroundColor: (props: StyleProps) =>
                props.isLatinGrammy ? 'rgba(255, 255, 255, 0.20)' : '#727796',
            position: 'absolute',
            top: 50,
            left: 50,
            height: 700,
            width: '70%',
            padding: 80,
        },
    },
    title: {
        fontWeight: 400,
        fontSize: pxToRem(22),
        marginBottom: 20,
        textAlign: 'center',
        [theme.breakpoints.up('md')]: {
            marginBottom: 'unset',
            textAlign: 'unset',
        },
    },
    copy: {
        fontSize: pxToRem(16),
        fontFamily: AVENIR_NEXT_LT_REGULAR,
        fontWeight: 400,
        lineHeight: (props: StyleProps) =>
            props.isLatinGrammy ? '26px' : '19.2px',
        letterSpacing: '0.402px',
    },
    featureImage: {
        position: 'unset',
        width: '80%',
        [theme.breakpoints.up('md')]: {
            width: '40%',
            position: 'absolute',
            top: 70,
            right: '-30%',
        },
    },
}));

interface MeetTheArtistProps {
    isMobile?: boolean;
    isLatinGrammy?: boolean;
    artistNum?: number;
}

const MeetTheArtist: React.FC<MeetTheArtistProps> = ({
    isMobile = false,
    isLatinGrammy = false,
    artistNum = 1,
}) => {
    const classes = useStyles({ isLatinGrammy });
    const intl = useIntl();
    const desktopImgPath = intl.formatMessage({
        id: isLatinGrammy
            ? `enLatinGrammyLanding.meetTheArtist.${artistNum}.desktopImg`
            : `enGrammyLanding.meetTheArtist.desktopImg`,
        defaultMessage: isLatinGrammy
            ? `enLatinGrammyLanding.meetTheArtist.${artistNum}.desktopImg`
            : `enGrammyLanding.meetTheArtist.desktopImg`,
    });
    const mobileImgPath = intl.formatMessage({
        id: isLatinGrammy
            ? `enLatinGrammyLanding.meetTheArtist.${artistNum}.mobileImg`
            : `enGrammyLanding.meetTheArtist.mobileImg`,
        defaultMessage: 'enGrammyLanding.meetTheArtist.mobileImg',
    });

    return (
        <div className={classes.root}>
            <div className={classes.contentWrapper}>
                <div className={classes.title}>
                    <FormattedMessage
                        id={
                            isLatinGrammy
                                ? `enLatinGrammyLanding.meetTheArtist.${artistNum}.title`
                                : `enGrammyLanding.meetTheArtist.title`
                        }
                        defaultMessage={
                            isLatinGrammy
                                ? `enLatinGrammyLanding.meetTheArtist.${artistNum}.title`
                                : `enGrammyLanding.meetTheArtist.title`
                        }
                    />
                </div>
                <img
                    alt="MAJOR"
                    className={classes.featureImage}
                    src={isMobile ? mobileImgPath : desktopImgPath}
                />
                <div className={classes.copy}>
                    <FormattedMessage
                        id={
                            isLatinGrammy
                                ? `enLatinGrammyLanding.meetTheArtist.${artistNum}.content`
                                : 'enGrammyLanding.meetTheArtist.content'
                        }
                        defaultMessage={
                            isLatinGrammy
                                ? `enLatinGrammyLanding.meetTheArtist.${artistNum}.content`
                                : 'enGrammyLanding.meetTheArtist.content'
                        }
                        values={{ br: <br /> }}
                    />
                </div>
            </div>
        </div>
    );
};

export default MeetTheArtist;
