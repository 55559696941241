import React, { FC } from 'react';

/* material */
import { Box, Dialog, DialogContent } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';

import { pxToRem } from 'theme/util';

export type SimpleModalProps = {
    isModalOpen: boolean;
    dataQA?: string;
    disableBackdropClick?: boolean;
    disableEscapeKeyDown?: boolean;
    onClose?: () => unknown;
    closeButton?: boolean;
    children: React.ReactNode;
    className?: string;
    width?: number;
};

const useStyles = makeStyles({
    closeButton: {
        position: 'absolute',
        zIndex: 100,
        right: 0,
    },
    paper: {
        borderRadius: '6px',
    },
});

const SimpleModal: FC<SimpleModalProps> = ({
    dataQA = 'simple-modal',
    className = '',
    isModalOpen,
    disableBackdropClick = false,
    disableEscapeKeyDown = false,
    onClose = () => {},
    closeButton = true,
    children,
    width = 631,
}) => {
    const classes = useStyles();
    return (
        <Dialog
            data-qa={dataQA}
            data-testid={'simple-modal'}
            disableBackdropClick={disableBackdropClick}
            disableEscapeKeyDown={disableEscapeKeyDown}
            open={isModalOpen}
            PaperProps={{ className: classes.paper }}
            className={className}
            onClose={onClose}
        >
            {closeButton && (
                <IconButton
                    aria-label="close"
                    data-qa="modal-close-btn"
                    data-testid="modal-close-btn"
                    className={classes.closeButton}
                    onClick={onClose}
                >
                    <CloseIcon />
                </IconButton>
            )}
            <Box width={{ sm: pxToRem(width) }}>
                <DialogContent>{children}</DialogContent>
            </Box>
        </Dialog>
    );
};

export default SimpleModal;
