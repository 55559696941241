export const multiMediaPlayTimeEvent = (
    mediaName: string,
    type: string,
    playtime: number,
) => {
    window.appEventData = window.appEventData || [];
    window.appEventData.push({
        event: 'Multi-Media Play Time',
        MultiMedia: {
            MultiMediaName: mediaName,
            MultiMediaType: type,
            Playtime: playtime,
        },
    });
};
