export const ariaSpeak = (text: string, priority?: string) => {
    const el = document.createElement('div');
    const id = 'speak-' + Date.now();
    el.setAttribute('id', id);
    el.setAttribute('aria-live', priority || 'polite');
    el.classList.add('visually-hidden');
    document.body.appendChild(el);

    window.setTimeout(function () {
        el.innerHTML = text;
    }, 100);

    window.setTimeout(function () {
        document.body.removeChild(el);
    }, 1000);
};
