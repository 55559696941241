import React, { FC, ChangeEvent } from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Radio from '@material-ui/core/Radio';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { pxToRem } from 'theme/util';
import { RecipeOption } from './types';

const useStyles = makeStyles((theme: Theme) => ({
    radio: {
        padding: 0,
        height: pxToRem(39),
    },
    label: {
        fontSize: pxToRem(20),
        color: theme.colors.black,
        lineHeight: pxToRem(39),
    },
}));

export interface OptionProps {
    name: string;
    option: RecipeOption;
    checked: boolean;
    onChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

const Option: FC<OptionProps> = ({
    name,
    option,
    checked,
    onChange,
}: OptionProps) => {
    const classes = useStyles();
    return (
        <Box display="flex" alignItems="flex-start" my={2}>
            <Radio
                name={name}
                checked={checked}
                onChange={onChange}
                value={option.label.title}
                className={classes.radio}
            />
            <Box ml={2} className={classes.label}>
                <Box component="b" mr={1}>
                    {option.label.title}:
                </Box>
                <Typography component="span">
                    {option.label.description}
                </Typography>
            </Box>
        </Box>
    );
};

export default Option;
