import { LOADER_ASYNC_HIDE, LOADER_ASYNC_SHOW } from 'constants/loader';

export type LOADER_ASYNC_HIDE = typeof LOADER_ASYNC_HIDE;
export type LOADER_ASYNC_SHOW = typeof LOADER_ASYNC_SHOW;

export type LoaderAsyncHideAction = {
    type: LOADER_ASYNC_HIDE;
};

export type LoaderAsyncShowAction = {
    type: LOADER_ASYNC_SHOW;
};

export const loaderAsyncHide = (): LoaderAsyncHideAction => ({
    type: LOADER_ASYNC_HIDE,
});

export const loaderAsyncShow = (): LoaderAsyncShowAction => ({
    type: LOADER_ASYNC_SHOW,
});

export type LoaderAsyncActionTypes =
    | LoaderAsyncHideAction
    | LoaderAsyncShowAction;
