import React, { FC } from 'react';

/* material */
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';

/* components */
import { asyncLoad } from 'components';

/* types */
import { Theme } from 'reducers/company';
import { User } from 'reducers/user';

/* util */
import colors from 'theme/colors';
import { siteWidth } from 'utils/siteWidthUtils';

const MobileMenu = asyncLoad.component('menu/MobileMenu');
const DesktopMenu = asyncLoad.component('menu/DesktopMenu');

type MenuProps = {
    company: Theme;
    user: User;
};

const useStyles = makeStyles((theme) => ({
    nav: {
        background: `linear-gradient(to right, ${colors.white}, ${colors.skyBlueLight})`,
        [theme.breakpoints.up('sm')]: {
            boxShadow: '0px 2px 6px 4px rgba(0, 0, 0, 0.1)',
        },

        '& a': {
            textDecoration: 'none',
        },
    },
}));

export const Menu: FC<MenuProps> = ({ company, user }) => {
    const classes = useStyles();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

    if (!company) return null;
    return (
        <>
            <Box
                data-qa="navigation-menu"
                className={classes.nav}
                width="100%"
                display="flex"
                justifyContent="center"
                position="fixed"
                top={0}
                zIndex={22}
            >
                <Box width="100%" maxWidth={siteWidth}>
                    {isMobile ? (
                        <MobileMenu company={company} user={user} />
                    ) : (
                        <DesktopMenu company={company} user={user} />
                    )}
                </Box>
            </Box>
        </>
    );
};

export default Menu;
