import React, { FC } from 'react';

/* material */
import { Box, makeStyles } from '@material-ui/core';

/* components */
import FlipCard from './FlipCard';
import { customClickEvent } from 'analytics';

const useStyles = makeStyles((theme) => ({
    grid: {
        [theme.breakpoints.up('md')]: {
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-between',
        },
    },
    gridThreeColumn: {
        margin: '0 20px 20px',
        [theme.breakpoints.up('md')]: {
            width: `calc(50% - (30px * 2 / 3))`,
            margin: '0 0 25px',
        },
    },
}));

type FlipCard = {
    image: 'string';
    question: 'string';
    answer: 'string';
};

interface FlipCardProps {
    data: {
        cards: FlipCard[];
    };
}

const FlipCardContainer: FC<FlipCardProps> = ({ data }) => {
    const classes = useStyles();
    const cards = data?.cards;
    if (!cards?.length) {
        return null;
    }
    return (
        <Box className={classes.grid}>
            {cards.map((content: FlipCard, idx: number) => {
                const { image, question, answer } = content;

                return (
                    <Box
                        className={classes.gridThreeColumn}
                        key={idx}
                        data-testid="flip-card"
                        data-qa="flip-card"
                        onClick={() => {
                            customClickEvent({
                                customLinkName: `flip-card: ${question}`,
                            });
                        }}
                    >
                        <FlipCard
                            imagePath={image}
                            question={question}
                            answer={answer}
                        />
                    </Box>
                );
            })}
        </Box>
    );
};

export default FlipCardContainer;
