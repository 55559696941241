import React, { FC, useState } from 'react';
import clsx from 'clsx';

/* material */
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import IconButton from '@material-ui/core/IconButton';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Collapse from '@material-ui/core/Collapse';

/* utils */
import { AVENIR_NEXT_LT_DEMI } from 'theme/font';
import { pxToRem } from 'theme/util';
import { customClickEvent } from 'analytics';

export type FlashCardProps = {
    staticImage: string;
    animatedImage: string;
    title: string;
    description: JSX.Element | JSX.Element[] | undefined;
    onExpand?: () => void;
    onCollapse?: () => void;
    forceStop?: boolean;
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
            display: 'inline-block',
            marginBottom: '40px',
            verticalAlign: 'top',
        },
        media: {
            height: 0,
            paddingTop: '56.25%', // 16:9
            position: 'relative',
            '&:hover': {
                '& $animatedImage': {
                    opacity: 1,
                },
            },
        },
        forceStop: {
            '&:hover': {
                '& $animatedImage': {
                    opacity: 0,
                },
            },
        },
        expand: {
            transform: 'rotate(0deg)',
            marginLeft: 'auto',
            transition: theme.transitions.create('transform', {
                duration: theme.transitions.duration.shortest,
            }),
        },
        expandOpen: {
            transform: 'rotate(180deg)',
        },
        cardActions: {
            cursor: 'pointer',
            fontFamily: AVENIR_NEXT_LT_DEMI,
            fontSize: pxToRem(18),
        },
        collapsedContent: {
            [theme.breakpoints.up('sm')]: {
                maxHeight: '200px',
                overflowY: 'auto    ',
            },
        },
        staticImage: {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
        },
        animatedImage: {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            opacity: 0,
            zIndex: 1,
        },
        animatedImageExpanded: {
            opacity: 1,
        },
    }),
);
const FlashCard: FC<FlashCardProps> = ({
    staticImage,
    animatedImage,
    title,
    description,
    forceStop = false,
    onExpand,
    onCollapse,
}) => {
    const classes = useStyles();
    const [expanded, setExpanded] = useState<boolean>(false);

    const handleExpandClick = () => {
        if (!expanded) {
            onExpand && onExpand();
        } else {
            onCollapse && onCollapse();
        }
        setExpanded(!expanded);
    };

    const shouldAnimateOnExpand = !forceStop && expanded;

    return (
        <Card data-qa="flash-card" className={classes.root}>
            <CardMedia
                className={clsx(
                    classes.media,
                    forceStop ? classes.forceStop : null,
                )}
                title={title}
            >
                <img
                    className={classes.staticImage}
                    alt={title}
                    src={staticImage}
                />
                <img
                    className={clsx(classes.animatedImage, {
                        [classes.animatedImageExpanded]: shouldAnimateOnExpand,
                    })}
                    alt={title}
                    src={animatedImage}
                />
            </CardMedia>
            <CardActions
                className={classes.cardActions}
                disableSpacing
                onClick={() => {
                    handleExpandClick();
                    customClickEvent({
                        customLinkName: `12 retirement plan-related terms you should definitely know: Flashcard - ${
                            expanded ? 'hide' : 'show'
                        } - ${title}`,
                    });
                }}
            >
                {title}
                <IconButton
                    className={clsx(classes.expand, {
                        [classes.expandOpen]: expanded,
                    })}
                    aria-expanded={expanded}
                    aria-label="show more"
                >
                    <ExpandMoreIcon />
                </IconButton>
            </CardActions>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
                <CardContent className={classes.collapsedContent}>
                    {description}
                </CardContent>
            </Collapse>
        </Card>
    );
};

export default FlashCard;
