export const FLASHCARDS = [
    {
        animatedImage:
            '/content/dam/my-retirement/flash-cards-images/article29-vid01-traditional-401k.gif',
        staticImage:
            '/content/dam/my-retirement/flash-cards-images/article29-vid01-traditional-401k.png',
        title: 'Traditional 401(k) deferrals',
        description: `<p>A feature of an employer-sponsored plan that allows you to contribute from your paycheck on a pre-tax basis. Since contributions are made before taxes, taxes are paid when the money is withdrawn, including any earnings.&nbsp;<br> </p> <p style="margin-left: 40.0px;"><i>Ex: My <b><u>traditional 401(k</u></b>) makes it really easy to save for retirement AND save on my taxes today.</i></p>`,
    },
    {
        animatedImage:
            '/content/dam/my-retirement/flash-cards-images/article29-vid02-roth-401k.gif',
        staticImage:
            '/content/dam/my-retirement/flash-cards-images/article29-vid02-roth-401k.png',
        title: 'Roth 401(k)',
        description:
            '<p>A feature of an employer-sponsored plan that allows you to contribute money from your paycheck that has already been taxed. While withdrawals of your contributions are always tax-free, earnings are only tax-free when you make a qualified withdrawal.<sup>1</sup></p> <p style="margin-left: 40.0px;"><i>Ex: I’d rather pay for the all-inclusive resort upfront to avoid surprises later, so I kept that same mindset and chose a <u><b>Roth 401(k).</b></u></i></p>',
    },
    {
        animatedImage:
            '/content/dam/my-retirement/flash-cards-images/article29-vid12-traditional-403(b)-Plan.gif',
        staticImage:
            '/content/dam/my-retirement/flash-cards-images/article29-vid12-traditional-403(b)-Plan.png',
        title: '403(b) Plan',
        description: `<p>A retirement account only for employees of public schools and other tax-exempt
        organizations. Just like a 401(k), you may have the option of a traditional (where
        you get taxed when you take money out) or a Roth 403(b) (where you get taxed
        when you put money in).<sup>1</sup></p><p style="margin-left: 40.0px;"><i>
        Ex: Everyone talks about 401(k), but it’s time to give <u><b>403(b)</b></u> the attention it
        deserves!</i></p>`,
    },
    {
        title: 'Traditional IRA contributions',
        staticImage:
            '/content/dam/my-retirement/flash-cards-images/article29-vid03-traditional-ira.png',
        animatedImage:
            '/content/dam/my-retirement/flash-cards-images/article29-vid03-traditional-ira.gif',
        description:
            "<p>An individual retirement account (IRA) that you can open yourself and contribute to at any time. Since it's not with your employer, you'll contribute using your take-home pay. But when it's tax time, you may be able to deduct that amount from your income. Then you’ll pay taxes on the money you withdraw in retirement.<sup>2</sup> Keep in mind that traditional IRA contributions may not be deductible if you're also maxing out your employer-sponsored retirement plan. Depending on your tax-filing status, income and retirement plan participation, you may be eligible to deduct your full contribution, part of it or none of it.<sup>3</sup></p> <p style=\"margin-left: 40.0px;\"><i>Ex: My parents looked at me like I was Albert Einstein when I told them I opened a <b>traditional IRA</b> to put away more money for retirement.</i><br> </p>",
    },
    {
        title: 'Roth IRA',
        staticImage:
            '/content/dam/my-retirement/flash-cards-images/article29-vid04-roth-ira.png',
        animatedImage:
            '/content/dam/my-retirement/flash-cards-images/article29-vid04-roth-ira.gif',
        description: `<p>An IRA that you can only open if you earn an income that’s less than the <a href="https://www.irs.gov/retirement-plans/amount-of-roth-ira-contributions-that-you-can-make-for-2019" target="_blank" data-disable-analytics="true">annual income limit</a>.<sup>2</sup> While withdrawals of your contributions are always tax-free, earnings are only tax-free when you make a qualified withdrawal.</p>
            <p style="margin-left: 40.0px;"><i>Ex: I look forward to the day that I make too much money to contribute to a <b><u>Roth IRA.</u></b></i></p>`,
    },
    {
        title: 'Automatic Contributions',
        staticImage:
            '/content/dam/my-retirement/flash-cards-images/article29-vid05-automatic-contributions.png',
        animatedImage:
            '/content/dam/my-retirement/flash-cards-images/article29-vid05-automatic-contributions.gif',
        description: `<p>An option that sends a portion of every paycheck straight into your retirement account without any work from you. If you contribute to a 401(k), this may already be how your plan works. However, if you have an IRA or another investment, you might want to set up “auto contributions” if it’s an option.</p>
        <p style="margin-left: 40.0px;"><i>Ex: Choosing an afternoon snack can be harder than planning for retirement with <b><u>automatic contributions.</u></b></i></p>`,
    },
    {
        title: 'Automatic Contribution Increases',
        staticImage:
            '/content/dam/my-retirement/flash-cards-images/article29-vid06-automatic-contribution-increases.png',
        animatedImage:
            '/content/dam/my-retirement/flash-cards-images/article29-vid06-automatic-contribution-increases.gif',
        description: `<p>A setting that allows you to automatically increase your contributions each year by an amount of your choosing (up to the annual limit). This helps you save more over time without needing to set a reminder to check in and do it.<sup>4</sup></p>
            <p style="margin-left: 40.0px;"><i>Ex: I set up 1% <b><u>automatic contribution increases</u></b>, and in five years I went from saving 5% to 10% without even noticing — the future is now!</i></p>`,
    },
    {
        title: 'Employer-Sponsored Retirement Plan',
        staticImage:
            '/content/dam/my-retirement/flash-cards-images/article29-vid07-employer-sponsored.png',
        animatedImage:
            '/content/dam/my-retirement/flash-cards-images/article29-vid07-employer-sponsored.gif',
        description: `<p>A retirement program that you get access to through your job. A 401(k) is one example of these plans.</p>
        <p style="margin-left: 40.0px;"><i>Ex: I was excited about the free snacks and coffee, but don’t be fooled — the best benefit of my new job is that </i><b><u><i>employer-sponsored retirement plan</i>.</u></b><u></u></p>`,
    },
    {
        title: '401(k) Employer Match',
        staticImage:
            '/content/dam/my-retirement/flash-cards-images/article29-vid08-401k-employer-match.png',
        animatedImage:
            '/content/dam/my-retirement/flash-cards-images/article29-vid08-401k-employer-match.gif',
        description: `<p>When your employer matches the money you put into your 401(k) up to a certain limit.</p>
        <p style="margin-left: 40.0px;"><i>Ex: The period of time where I was missing out on free money because I wasn’t contributing up to my plan’s <b><u>401(k) employer match</u></b> limit is my own personal Dark Ages.</i></p>`,
    },
    {
        title: 'Compound Growth',
        staticImage:
            '/content/dam/my-retirement/flash-cards-images/article29-vid09-compound-growth.png',
        animatedImage:
            '/content/dam/my-retirement/flash-cards-images/article29-vid09-compound-growth.gif',
        description: `<p>When you contribute to a 401(k), the value of your investment can potentially grow and even generate earnings (also known as dividends). Those earnings are then reinvested in your account, so over time you'll have the chance to earn even more depending on how the market performs.</p>
        <p style="margin-left: 40.0px;"><i>Ex: The happy dance I did when I saw how much the <b><u><a data-disable-analytics="false" data-analytics-label="trackLink:anchorClick" data-analytics-placement="Text" data-analytics-id="compound growth" href="articles/bad048cf-a8d7-4fe1-9c97-b01b4b04dbd1/see-how-compounding-makes-your-money-grow">compound growth</a></u></b> on my 401(k) added to my balance was endzone-worthy.</i></p>`,
    },
    {
        title: 'Vested Balance',
        staticImage:
            '/content/dam/my-retirement/flash-cards-images/article29-vid10-vested-balance.png',
        animatedImage:
            '/content/dam/my-retirement/flash-cards-images/article29-vid10-vested-balance.gif',
        description: `<p>The amount of employer contributions to your 401(k) that are yours to take with you, whether you’re retiring or just switching jobs. This amount increases over time until it's 100%. The money that you've contributed to your plan <a data-disable-analytics="false" data-analytics-label="trackLink:anchorClick" data-analytics-placement="Text" data-analytics-id="from your paycheck " href="articles/bad048cf-a8d7-4fe1-9c97-b01b4b04dbd1/see-how-compounding-makes-your-money-grow">from your paycheck</a> (including any earnings), is always yours to keep.</p>
        <p style="margin-left: 40.0px;"><i>Ex: I can’t wait to celebrate both my five-year work anniversary and being fully <b><u>vested</u> </b>in my 401(k). Yes, there will be streamers.&nbsp;</i></p>`,
    },
    {
        title: 'Dollar-Cost Averaging (DCA)',
        staticImage:
            '/content/dam/my-retirement/flash-cards-images/article29-vid11-dollar-cost-averaging.png',
        animatedImage:
            '/content/dam/my-retirement/flash-cards-images/article29-vid11-dollar-cost-averaging.gif',
        description: `<p>This strategy allows you to buy more shares of an investment when its price is falling and fewer shares of it when its price is rising. Over time, this approach can help you gradually accumulate shares with a lower average price per share.</p>
        <p style="margin-left: 40.0px;"><i>Ex: <b><u>Dollar-cost averaging</u></b> is like making lemonade out of lemons.</i></p>`,
    },
];
