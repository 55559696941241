import React, { useEffect } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useDispatch, useSelector, useStore } from 'react-redux';
import DesktopNav from './components/DesktopNav';
import MobileNav from './components/MobileNav';
import { pageViewCompleteEvent } from 'analytics';
import { safelyParseJson } from 'utils/parseJson';
import useAEMBaseUrl from 'hooks/useAEMBaseUrl';
import { Loader } from 'components';
import { useFetch } from 'hooks/useFetch';
import { setError } from 'actions/error';
import { Helmet } from 'react-helmet';
import Section1 from './sections/Section1';
import Section2 from './sections/Section2';
import Section3 from './sections/Section3';
import Section4 from './sections/Section4';
import Section5 from './sections/Section5';
import Section6 from './sections/Section6';
import Section7 from './sections/Section7';
import Section8 from './sections/Section8';
import Section9 from './sections/Section9';
import { getUrlLang, isUsEn } from 'utils';
import { RootState } from 'store';
import { setLanguage } from 'actions';
import { siteWidth } from 'utils/siteWidthUtils';
import { label } from './types';

const useStyles = makeStyles((theme) => ({
    root: {
        position: 'relative',
        minHeight: 'calc(100vh - 100px)',
        paddingTop: 0,
        background: 'linear-gradient(0.50turn, #327b71, #f6b868)',
        minWidth: 340,
        [theme.breakpoints.up('md')]: {
            //minWidth: siteWidth,
        },
    },
    centerWrapper: {
        display: 'flex',
        flexDirection: 'column',
        maxWidth: siteWidth,
        margin: '0px auto',
        [theme.breakpoints.up('md')]: {
            flexDirection: 'unset',
        },
    },
    content: {
        flex: 2,
        position: 'relative',
        marginLeft: 0,
        // marginTop: 130,
        width: '100%',
        //border: 'solid 2px red',
        [theme.breakpoints.up('md')]: {
            // marginTop: 100,
        },
    },
}));

const ICRHispanic = () => {
    const { uid, user, companyId } = useSelector((state: RootState) => ({
        user: state.user,
        companyId: state.company.theme?.companyId,
        uid: state.user.uid,
    }));
    const classes = useStyles();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down(1024));
    const dispatch = useDispatch();
    const store = useStore();
    const isEn = isUsEn(user.language);

    const {
        data: hispanicContentData,
        loading: isHispanicContentDataLoading,
        error: isHispanicContentDataLoadingError,
    } = useFetch<{ [key: string]: any } | undefined>({
        initialData: undefined,
        processData: (response: { data: string }) =>
            safelyParseJson(response.data),
        url: useAEMBaseUrl(
            `/content/fragments?path=/content/dam/my-retirement/language/hispanic-landing-v2/jcr:content/data/master.json`,
        ),
    });

    useEffect(() => {
        pageViewCompleteEvent();
    }, []);

    useEffect(() => {
        const langFromURL = getUrlLang(user.language);
        if (langFromURL) {
            store.dispatch(setLanguage(langFromURL));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [store]);

    useEffect(() => {
        if (isHispanicContentDataLoadingError) {
            dispatch(setError(new Error(`error fetching hispanic content`)));
        }
    }, [dispatch, isHispanicContentDataLoadingError]);

    if (isHispanicContentDataLoading) {
        return (
            <div className={classes.root}>
                <div className={classes.content}>
                    <Loader />
                </div>
            </div>
        );
    }

    if (isHispanicContentDataLoadingError) {
        return (
            <div className={classes.root}>
                <div className={classes.content}>Error Loading</div>
            </div>
        );
    }

    const dataToUse = isEn ? hispanicContentData?.en : hispanicContentData?.es;
    const navLabels = dataToUse?.navLabels as label[];
    return (
        <>
            <Helmet>
                <title>Hispanic</title>
                <meta name="description" content={'Hispanic'} />
            </Helmet>

            <div className={classes.root}>
                <div
                    className={classes.centerWrapper}
                    data-testid="icr-hispanic-experience"
                >
                    {isMobile ? (
                        <MobileNav
                            companyId={companyId || 'generic'}
                            labels={navLabels}
                        />
                    ) : (
                        <DesktopNav
                            companyId={companyId || 'generic'}
                            labels={navLabels}
                        />
                    )}
                    <div className={classes.content}>
                        <Section1
                            isMobile={isMobile}
                            data={dataToUse.sections.section_1 || null}
                            lang={user.language}
                        />
                        <Section2
                            isMobile={isMobile}
                            data={dataToUse.sections.section_2 || null}
                        />
                        <Section3
                            isMobile={isMobile}
                            data={dataToUse.sections.section_3 || null}
                        />
                        <Section4
                            isMobile={isMobile}
                            data={dataToUse.sections.section_4 || null}
                        />
                        <Section5
                            isMobile={isMobile}
                            data={dataToUse.sections.section_5 || null}
                        />
                        <Section6
                            isMobile={isMobile}
                            data={dataToUse.sections.section_6 || null}
                        />
                        <Section7
                            user={user}
                            data={dataToUse.sections.section_7 || null}
                            companyId={companyId}
                            uid={uid}
                        />
                        {companyId !== 'seiu775srp' && (
                            <Section8
                                isMobile={isMobile}
                                data={dataToUse.sections.section_8 || null}
                            />
                        )}
                        <Section9
                            isMobile={isMobile}
                            data={dataToUse.sections.section_9 || null}
                            companyId={companyId || 'generic'}
                            disclosure={dataToUse.disclosure}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};

export default ICRHispanic;
