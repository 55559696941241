export const SET_VIRTUAL_ARTICLES = 'SET_VIRTUAL_ARTICLES';
export const GET_VIRTUAL_ARTICLES_ERROR = 'GET_VIRTUAL_ARTICLES_ERROR';
export const GET_VIRTUAL_EVENT_ARTICLES_SUCCESS =
    'GET_VIRTUAL_EVENT_ARTICLES_SUCCESS';
export const GET_VIRTUAL_EVENT_ARTICLES = 'GET_VIRTUAL_EVENT_ARTICLES';

// articles in landing page will be displayed in this order
// and in keep watching will follow same order (removing the read ones)
export const VIRTUAL_EVENT_ARTICLES_IDS = {
    ceoArticle: '7950117a-982c-40ef-9de5-4db08b251abd',
    realPeople: '7f9327fa-56fe-40e5-914b-e852ca411889',
    introducingRetirement: '40bb9bef-c0d7-4ebe-8955-f1fe9b854d3c',
    targetDate: '226988f9-c8e3-4a5d-82bd-40ab641ddb06',
    readyChangeFidelity: '90e399be-0512-43bf-b0ed-f00a43420ab2',
    wellnessResource: 'f9d6630f-ce20-497f-a592-04bb35682737',
    bankAmerica: '54871aa8-ac16-498d-b29a-ccab86f7ba65',
};
