import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-scroll';
import { pxToRem } from 'theme/util';
import { AVENIR_NEXT_LT_DEMI, AVENIR_NEXT_LT_REGULAR } from 'theme/font';
import { customClickEvent } from 'analytics';
import { FormattedMessage } from 'react-intl';

interface NavProps {
    isMobile?: boolean;
    isLatinGrammy?: boolean;
}

interface StyleProps {
    isLatinGrammy: boolean;
}

const useStyles = makeStyles((theme) => ({
    nav: {
        flexBasis: '20%',
        position: 'fixed',
        width: '100%',
        top: 80,
        zIndex: 21,
        background: (props: StyleProps) =>
            props.isLatinGrammy
                ? 'linear-gradient(0.50turn, #11999E, #0D7F83)'
                : 'linear-gradient(0.50turn, #3a536f, #5d5180)',
        filter: 'drop-shadow(0px 23px 16px rgba(0, 0, 0, 0.3))',
        [theme.breakpoints.up('md')]: {
            filter: 'unset',
            background: 'unset !important',
            position: 'unset',
        },
    },
    list: {
        position: 'unset',
        top: 'unset',
        width: '100%',
        filter: 'drop-shadow(0px 3px 10px #000)',
        [theme.breakpoints.up('md')]: {
            position: 'sticky',
            top: '35%',
            paddingLeft: '30%',
            background: 'unset',
            filter: 'unset',
        },
        '& > li': {
            display: 'inline-block',
            margin: '0 15px',
            [theme.breakpoints.up('md')]: {
                position: 'relative',
                display: 'block',
                margin: '10px 0px',
            },
        },
    },
    navButtonPosition: {
        position: 'absolute',
        left: -35,
        top: -5,
    },
    inactiveNavItem: {
        color: 'white',
        cursor: 'pointer',
        fontSize: pxToRem(14),
        fontFamily: AVENIR_NEXT_LT_REGULAR,
        '& > div': {
            width: 20,
            height: 20,
            borderRadius: '50%',
            border: '1px solid white',
            margin: 3,
            transitionProperty: 'background-color',
            transitionDuration: '0.3s',
        },
    },
    activeNavItem: {
        fontFamily: AVENIR_NEXT_LT_DEMI,
        color: 'white',
        cursor: 'pointer',
        fontSize: pxToRem(14),
        '& > div': {
            width: 20,
            height: 20,
            borderRadius: '50%',
            border: '1px solid white',
            margin: 3,
            transitionProperty: 'background-color',
            transitionDuration: '0.3s',
            backgroundColor: '#8BAFCF',
        },
    },
}));

const Nav: React.FC<NavProps> = ({
    isMobile = false,
    isLatinGrammy = false,
}) => {
    const classes = useStyles({ isLatinGrammy });
    const [clickedNavItem, setClickedNavItem] = useState<string>('');
    const [isClickedNav, setIsClickedNav] = useState<boolean>(false);
    const getOffset = () => {
        return isMobile ? -130 : -100;
    };

    const handleSetActive = (sectionTitle: string) => {
        if (clickedNavItem === sectionTitle && isClickedNav) {
            customClickEvent({
                customLinkName: `${
                    isLatinGrammy ? 'Latin Grammy Landing' : 'Grammy Landing'
                }_${sectionTitle}`,
            });
            setIsClickedNav(false);
        } else if (!isClickedNav) {
            customClickEvent({
                customLinkName: `${
                    isLatinGrammy ? 'Latin Grammy Landing' : 'Grammy Landing'
                }_${sectionTitle}`,
            });
        }
    };

    return (
        <div className={classes.nav}>
            <ul className={classes.list}>
                <li>
                    <Link
                        onSetActive={handleSetActive}
                        onClick={() => {
                            setClickedNavItem('watch');
                            setIsClickedNav(true);
                        }}
                        className={classes.inactiveNavItem}
                        isDynamic
                        offset={getOffset()}
                        activeClass={classes.activeNavItem}
                        smooth
                        hashSpy
                        spy
                        to="watch"
                    >
                        {!isMobile && (
                            <div className={classes.navButtonPosition} />
                        )}
                        <FormattedMessage
                            id={
                                isLatinGrammy
                                    ? 'enLatinGrammyLanding.nav.watch'
                                    : 'enGrammyLanding.nav.watch'
                            }
                            defaultMessage={
                                isLatinGrammy
                                    ? 'enLatinGrammyLanding.nav.watch'
                                    : 'enGrammyLanding.nav.watch'
                            }
                        />
                    </Link>
                </li>
                <li>
                    <Link
                        onSetActive={handleSetActive}
                        onClick={() => {
                            setClickedNavItem('Listen');
                            setIsClickedNav(true);
                        }}
                        className={classes.inactiveNavItem}
                        isDynamic
                        offset={getOffset()}
                        activeClass={classes.activeNavItem}
                        smooth
                        hashSpy
                        spy
                        to="Listen"
                    >
                        {!isMobile && (
                            <div className={classes.navButtonPosition} />
                        )}
                        <FormattedMessage
                            id={
                                isLatinGrammy
                                    ? 'enLatinGrammyLanding.nav.listen'
                                    : 'enGrammyLanding.nav.listen'
                            }
                            defaultMessage={
                                isLatinGrammy
                                    ? 'enLatinGrammyLanding.nav.listen'
                                    : 'enGrammyLanding.nav.listen'
                            }
                        />
                    </Link>
                </li>
                <li>
                    <Link
                        onSetActive={handleSetActive}
                        onClick={() => {
                            setClickedNavItem('Meet-the-artist');
                            setIsClickedNav(true);
                        }}
                        className={classes.inactiveNavItem}
                        isDynamic
                        offset={getOffset()}
                        activeClass={classes.activeNavItem}
                        smooth
                        hashSpy
                        spy
                        to="Meet-the-artist"
                    >
                        {!isMobile && (
                            <div className={classes.navButtonPosition} />
                        )}
                        <FormattedMessage
                            id={
                                isLatinGrammy
                                    ? 'enLatinGrammyLanding.nav.meetTheArtist'
                                    : 'enGrammyLanding.nav.meetTheArtist'
                            }
                            defaultMessage={
                                isLatinGrammy
                                    ? 'enLatinGrammyLanding.nav.meetTheArtist'
                                    : 'enGrammyLanding.nav.meetTheArtist'
                            }
                        />
                    </Link>
                </li>
            </ul>
        </div>
    );
};

export default Nav;
