export const copyToClipboard = async (
    event: React.MouseEvent<HTMLElement>,
    text: string,
) => {
    try {
        if (navigator.clipboard) {
            await navigator.clipboard.writeText(text);
        } else {
            const currentScrollPosition = window.scrollY; //for IE we need to save to scroll back (because .select causes to scroll to new element)
            const tempTextarea = document.createElement('textarea');
            document.body.appendChild(tempTextarea);
            const clipBoardText = event.currentTarget.getAttribute(
                'data-clipboard-text',
            );
            if (clipBoardText) {
                tempTextarea.value = clipBoardText;
            }
            tempTextarea.select();
            document.execCommand('copy');
            document.body.removeChild(tempTextarea);
            window.scroll(0, currentScrollPosition);
            if (!tempTextarea.value) {
                throw new Error('Missing data attribute');
            } else if (typeof document.execCommand !== 'function') {
                throw new Error('execCommand() is deprecated');
            }
        }
        return true;
    } catch (error) {
        console.log(error);
        return false;
    }
};
