import * as React from 'react';
import { Slider, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    rangeSlider: {
        width: '97%',
        '&& .MuiSlider-rail': {
            height: '12px',
            backgroundColor: '#E2E2E2',
            borderRadius: '10px',
        },
        '&& .MuiSlider-mark': {
            backgroundColor: 'transparent',
        },
        '&& .MuiSlider-thumb': {
            marginTop: '-7px',
            background: '#71C9CE',
            height: '25px',
            width: '25px',
            border: 'none',
            opacity: 'unset',
        },
        '&& .MuiSlider-track': {
            height: '12px',
            background:
                'linear-gradient(90deg, #11999E 0%, #3FB0B5 47.4%, #71C9CE 100%)',
            borderRadius: '10px',
        },
        '&& .MuiSlider-active, .MuiSlider-thumb:hover, .Mui-focusVisible': {
            boxShadow: 'none',
        },
        '&& .MuiSlider-markLabel-active, .MuiSlider-markLabel': {
            color: theme.colors.black,
            fontSize: '18px',
            paddingTop: '13px',
            [theme.breakpoints.down('sm')]: {
                fontSize: '13px',
            },
        },
    },
}));

const tempMarks = [
    {
        value: 3,
        label: 1995,
    },
    {
        value: 19,
        label: 2000,
    },
    {
        value: 35,
        label: 2005,
    },
    {
        value: 51,
        label: 2010,
    },
    {
        value: 67,
        label: 2015,
    },
    {
        value: 83,
        label: 2020,
    },
    {
        value: 100,
        label: 2022,
    },
];

interface MUISliderMarkProps {
    value: number;
    label: number;
}

interface Props {
    selectChange: (cost: number) => void;
    rangeSliderMap: MUISliderMarkProps[];
}

const RangeSlider = ({ rangeSliderMap, selectChange }: Props) => {
    const classes = useStyles();

    return (
        <Slider
            aria-label="Years"
            defaultValue={3}
            onChange={(_, val) => selectChange(val as number)}
            step={0}
            valueLabelDisplay="off"
            marks={rangeSliderMap || tempMarks}
            className={classes.rangeSlider}
        />
    );
};

export default RangeSlider;
