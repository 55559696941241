import React, { FC } from 'react';

/* material */
import { makeStyles, Typography } from '@material-ui/core';

/* utils */
import { pxToRem } from 'theme/util';
import { eyebrowLarge, body1 } from 'theme/font';
import { getAemImagePath } from 'utils/aem';
import colors from 'theme/colors';

/* types */
import { Influencer } from './type';

const useStyles = makeStyles((theme) => ({
    profile: {
        [theme.breakpoints.up('sm')]: {
            display: 'flex',
            flexDirection: 'row-reverse',
            justifyContent: 'space-between',
            margin: '30px 0',
        },
    },
    name: {
        ...eyebrowLarge,
        textTransform: 'uppercase',
    },
    imgWrapper: {
        margin: `20px 0 32px 0`,
        height: 'auto',
        width: '100%',

        [theme.breakpoints.up('sm')]: {
            width: 'auto',
            margin: '0 40px 0 0',
            flexBasis: '40%',
        },
    },
    img: {
        height: 'auto',
        width: '100%',
    },
    content: {
        flexBasis: '60%',
        margin: 0,
    },
    about: {
        body1,
        [theme.breakpoints.up('sm')]: {
            fontSize: pxToRem(22),
            lineHeight: pxToRem(32),
        },
    },
    icon: {
        color: colors.symphonyBlue,
        cursor: 'pointer',
        fontSize: 19,
        marginLeft: 19,
    },
    headerWrapper: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 20,
    },
}));

interface InfluencerCardProps {
    influencer: Influencer & { [key: string]: string | string[] | undefined };
}

const InfluencerCard: FC<InfluencerCardProps> = ({ influencer }) => {
    const classes = useStyles();
    const { name, about, alt, imagePath } = influencer;

    return influencer ? (
        <div data-qa="influencer-card" className={classes.profile}>
            <div className={classes.content}>
                <div className={classes.headerWrapper}>
                    <Typography
                        data-qa="influencer-name"
                        className={classes.name}
                    >
                        {name}
                    </Typography>
                </div>
                <Typography
                    data-qa="influencer-about"
                    component="p"
                    className={classes.about}
                >
                    {about}
                </Typography>
            </div>
            <div className={classes.imgWrapper}>
                <img
                    data-qa="influencer-image"
                    alt={alt}
                    className={classes.img}
                    src={getAemImagePath(imagePath)}
                />
            </div>
        </div>
    ) : null;
};

export default InfluencerCard;
