export const SET_UID = 'SET_UID';
export const SET_LANGUAGE = 'SET_LANGUAGE';
export const SET_IS_ENROLL = 'SET_IS_ENROLL';
export const SET_SEGMENT = 'SET_SEGMENT';
export const SET_ANIMAL = 'SET_ANIMAL';
export const SET_DOMAIN_NAME = 'SET_DOMAIN_NAME';
export const SET_IS_REVISIT = 'SET_IS_REVISIT';
export const SET_LAST_SENTIMENT_MODAL_VIEW_DATE =
    'SET_LAST_SENTIMENT_MODAL_VIEW_DATE';
export const SET_LAST_SENTIMENT_ANSWER_DATE = 'SET_LAST_SENTIMENT_ANSWER_DATE';
export const SET_LAST_QUIZ_MODAL_VIEW_DATE = 'SET_LAST_QUIZ_MODAL_VIEW_DATE';
export const SET_LAST_QUIZ_TAKE_DATE = 'SET_LAST_QUIZ_TAKE_DATE';

export const SEGMENT_GENERIC = 'g';
export const MY_RETIREMENT_DOMAIN = 'myretirement';
export const MY_RETIREMENT_TAG = 'my-retirement';
export const I_CAN_RETIRE_DOMAIN = 'icanretire';
export const I_CAN_RETIRE_TAG = 'i-can-retire';
export const DOMAINS: { [key: string]: string } = {
    [MY_RETIREMENT_DOMAIN]: 'MyRetirement',
    [I_CAN_RETIRE_DOMAIN]: 'ICanRetire',
};
export const ANIMAL_TO_SEGMENT_MAPPING = {
    rabbit: 'o',
    lynx: 'o',
    fox: 'k',
    elk: 's',
    owl: 'j',
};

export const DOLLAR_ON_ROADTRIP_SHERRY = 's';
export const DOLLAR_ON_ROADTRIP_KATHLEEN = 'k';
export const DOLLAR_ON_ROADTRIP_OMAR = 'o';
export const CONTRIBUTION_REMINDER_MODAL_ERROR =
    "We're sorry, an error has occurred. Please try again later.";
