import { call, put } from 'redux-saga/effects';

/* actions */
import {
    getMoneyMinuteArticlesError,
    getMoneyMinuteArticlesSuccess,
} from 'actions';

/* services */
import ArticleService from 'services/article';

/* utils */
import { getAemQueryTags } from 'utils/aem';

/* constants */
import { INFLUENCER_TAG } from 'constants/aem';
import { ArticleSummaries } from 'containers/article-landing/article.types';

export function* getMoneyMinuteArticles() {
    try {
        const queryTags = getAemQueryTags('my-retirement', {
            [INFLUENCER_TAG]: [''],
        });

        const res: Response = yield call(
            ArticleService.fetchArticleSummaries,
            queryTags,
        );

        if (!res.ok)
            throw new Error('Error while fetching money minute articles');
        const json: {
            articles: ArticleSummaries[];
            count: number;
        } = yield call([res, 'json']);
        const { articles } = json;
        yield put(getMoneyMinuteArticlesSuccess(articles));
    } catch (e) {
        yield put(getMoneyMinuteArticlesError(e));
    }
}
