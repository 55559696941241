import React, { FC } from 'react';

/* material */
import { makeStyles } from '@material-ui/core/styles';

/* utils */
import { AVENIR_NEXT_LT_DEMI, body1, contentLink, h4 } from 'theme/font';
import colors from 'theme/colors';
import { pxToRem } from 'theme/util';

const useStyles = makeStyles((theme) => ({
    list: {
        listStyle: 'none',
        margin: 0,
        marginBottom: pxToRem(32),
        padding: 0,
        paddingTop: pxToRem(16),

        '& b:first-of-type': {
            ...h4,
            color: colors.darkSapphire,
            display: 'block',
            fontFamily: AVENIR_NEXT_LT_DEMI,
            margin: `0 0 ${pxToRem(16)} ${pxToRem(46)}`,
            [theme.breakpoints.up('sm')]: {
                fontSize: pxToRem(22),
                lineHeight: pxToRem(29),
                marginBottom: pxToRem(12),
            },
        },

        '& div': {
            alignItems: 'center',
            backgroundColor: colors.darkTurquoise,
            borderRadius: '50%',
            color: colors.white,
            display: 'flex',
            flexShrink: 0,
            fontFamily: AVENIR_NEXT_LT_DEMI,
            height: pxToRem(30),
            justifyContent: 'center',
            left: 0,
            paddingTop: `${pxToRem(1)}`,
            position: 'absolute',
            top: `-${pxToRem(3)}`,
            width: pxToRem(30),
        },

        '& li': {
            ...body1,
            margin: 0,
            marginBottom: pxToRem(48),
            position: 'relative',
            '&:last-child': {
                marginBottom: 0,
            },
            [theme.breakpoints.up('sm')]: {
                fontSize: pxToRem(18),
                lineHeight: pxToRem(28),
                marginBottom: pxToRem(48),
            },
        },

        '& a': { ...contentLink, textDecoration: 'none' },
    },
}));

const ArticleList: FC = ({ children }) => {
    const classes = useStyles();
    return (
        <ol data-qa="article-ordered-list" className={classes.list}>
            {children}
        </ol>
    );
};

export default ArticleList;
