import React, { FC } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useIntl, FormattedMessage } from 'react-intl';

/* material */
import AlertModal from 'components/alert-modal/AlertModal';
import { makeStyles } from '@material-ui/core/styles';

/* store */
import { RootState } from 'store';

/* utils */
import colors from 'theme/colors';
import { AVENIR_NEXT_LT_DEMI } from 'theme/font';

/* actions */
import { clearError } from 'actions/error';

const useStyles = makeStyles(() => ({
    link: {
        color: colors.darkTurquoise,
        fontFamily: AVENIR_NEXT_LT_DEMI,
        textDecoration: 'none',
    },
}));

const ErrorModal: FC = () => {
    const intl = useIntl();
    const classes = useStyles();
    const dispatch = useDispatch();
    const { isError, company } = useSelector((state: RootState) => ({
        isError: state.error.error,
        company: state.company.theme,
    }));

    const handleConfirm = () => {
        dispatch(clearError());
    };

    const renderContactUsLink = () => (
        <a href={`/${company?.companyId}/contact-us`} className={classes.link}>
            <FormattedMessage id="errorModal.contactUs" />
        </a>
    );

    const headerText = intl.formatMessage({ id: 'errorModal.title' });

    return (
        <AlertModal
            data-qa="error-modal"
            bodyText={
                <FormattedMessage
                    id="errorModal.body"
                    values={{ contactUs: renderContactUsLink() }}
                />
            }
            confirmActionLabel="Ok"
            headerText={headerText}
            isModalOpen={Boolean(isError)}
            onConfirm={handleConfirm}
        />
    );
};

export default ErrorModal;
