import { Article } from 'reducers';
import { Store } from 'redux';

export const saveUserProfileToLocalStorage = (reduxStore: Store) => {
    const {
        user,
        company,
        article,
        dollarOnTheRoad,
        transitionToRetirement,
        // personaQuiz, // Persona Quiz will be handled from here (po-web) everything else should be handled from participant-outcomes.
    } = reduxStore.getState();

    const {
        influencerArticlesRead,
        likedArticles,
        readArticles,
    } = article as Article;

    const objToSaveToLocalStorage = {
        user,
        company,
        article: { influencerArticlesRead, likedArticles, readArticles },
        dollarOnTheRoad,
        transitionToRetirement,
        // personaQuiz, // Persona Quiz will be handled from here (po-web) everything else should be handled from participant-outcomes.
    };

    saveToLocalStorage('userProfile', objToSaveToLocalStorage);
};

export const saveToLocalStorage = (
    key: string,
    value: Record<string, unknown>,
): boolean => {
    try {
        localStorage.setItem(key, JSON.stringify(value));
        return true;
    } catch (e) {
        return false;
    }
};

export const getLocalStorage = (key: string): object => {
    try {
        const localState = localStorage.getItem(key);
        if (localState === null) return {};
        return JSON.parse(localState);
    } catch (e) {
        return {};
    }
};
