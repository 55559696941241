import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { AVENIR_NEXT_LT_REGULAR } from 'theme/font';
import { pxToRem } from 'theme/util';
//import { FormattedMessage, useIntl } from 'react-intl';
import colors from 'theme/colors';
import SectionWrapper from '../components/SectionWrapper';
import VideoPlayer, {
    videoParamsType,
} from 'components/dynamic-media-player/VideoPlayer';

interface StyleProps {
    dpRatio: number;
}

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        minHeight: (props: StyleProps) =>
            props.dpRatio === 1.5 ? '400px' : 'calc(100vh - 100px)',
        fontFamily: AVENIR_NEXT_LT_REGULAR,
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.up('md')]: {
            width: '98%',
        },
        [theme.breakpoints.between(800, 1000)]: {
            minHeight: 'calc(100vh - 350px) !important',
        },
    },
    title: {
        fontSize: pxToRem(25),
        lineHeight: '35px',
        textAlign: 'center',
        fontWeight: 600,
        [theme.breakpoints.up(768)]: {
            fontSize: pxToRem(45),
            lineHeight: '55px',
            textAlign: 'unset',
        },
    },
    flexContainer: {
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.up(768)]: {
            flexDirection: 'unset',
            alignItems: 'center',
            justifyContent: 'center',
            //border: 'solid 3px pink',
            //minHeight: 'calc(100vh - 300px)',
        },
    },
    flextItem: {
        flex: 1,
        textAlign: 'center',
    },
    contentWrapper: {
        padding: 20,
        fontSize: pxToRem(18),
        lineHeight: '26px',
        fontWeight: 600,
        fontStyle: 'italic',
    },
    contentQuote: {
        marginBottom: 20,
    },
    name: {
        marginLeft: '-15%',
        marginTop: 20,
    },
    videoWrapper: {
        //height: 400,
    },
    arrowRight: {
        width: '55px',
        backgroundColor: colors.white,
        height: '2px',
        transform: 'rotate(-45deg)',
        position: 'relative',
        top: '19px',
        left: '-9px',
        marginRight: '-18px',
    },
    arrowLeft: {
        width: '2px',
        backgroundColor: colors.white,
        height: '40px',
    },
    line: {
        height: '40px',
        borderTop: '2px solid',
        borderColor: colors.white,
        width: '42%',
    },
    textUnderline: {
        display: 'flex',
        flexDirection: 'row',
        padding: '0 40px',
    },
}));

interface SectionProps {
    isMobile?: boolean;
    data: any;
}

const Section2: React.FC<SectionProps> = ({
    //isMobile = false,
    data,
}) => {
    const classes = useStyles({ dpRatio: window.devicePixelRatio });
    //const intl = useIntl();

    if (!data) {
        return null;
    }

    const TextUnderline = () => {
        const classes = useStyles({ dpRatio: window.devicePixelRatio });
        return (
            <div className={classes.textUnderline}>
                <div className={classes.line} />
                <div className={classes.arrowLeft} />
                <div className={classes.arrowRight} />
                <div className={classes.line} />
            </div>
        );
    };
    return (
        <SectionWrapper sectionId="section_2">
            <div className={classes.root} data-qa="hispanic-section-2">
                <div>
                    <h2 className={classes.title}>{data.title}</h2>
                    <div className={classes.flexContainer}>
                        <div className={classes.flextItem}>
                            <div className={classes.videoWrapper}>
                                <VideoPlayer
                                    params={data.videoConfig as videoParamsType}
                                    id="hispanic-section-2-video"
                                    title="Get started video"
                                />
                            </div>
                        </div>
                        <div
                            className={`${classes.flextItem} ${classes.contentWrapper}`}
                        >
                            <div className={classes.contentQuote}>
                                {data.content}
                            </div>
                            <TextUnderline />
                            <div className={classes.name}>{data.name}</div>
                        </div>
                    </div>
                </div>
            </div>
        </SectionWrapper>
    );
};

export default Section2;
