import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import colors from 'theme/colors';
import Card, { CardProps } from './Card';
import { pxToRem } from 'theme/util';

interface NavigatorProps {
    cards: CardProps[];
    isMobile: boolean;
    note?: string;
    noteColor?: string;
    newWindow?: boolean;
}

const useStyles = makeStyles((theme) => ({
    root: {
        borderTop: '1px solid white',
        marginTop: 20,
    },
    note: {
        padding: 20,
        fontSize: '22px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '29px',
        textAlign: 'center',
        [theme.breakpoints.down('md')]: {
            fontSize: pxToRem(20),
        },
    },
    cardsWrapper: {
        display: 'flex',
        justifyContent: 'center',
        [theme.breakpoints.down('md')]: {
            display: 'block',
        },
    },
}));

const NavigatorCard: React.FC<NavigatorProps> = ({
    cards = [],
    isMobile,
    note = '',
    noteColor = colors.white,
    newWindow = false,
}) => {
    const classes = useStyles();

    if (cards?.length === 0) {
        return null;
    }

    return (
        <div className={classes.root}>
            <p style={{ color: noteColor }} className={classes.note}>
                {note}
            </p>
            <div className={classes.cardsWrapper}>
                {cards?.map(
                    (card: CardProps, index: number) =>
                        card.show === 'yes' && (
                            <Card
                                key={`${card.linkTo}-${index}`}
                                title={card.title}
                                isMobile={isMobile}
                                linkTo={card.linkTo}
                                linkFromPathName={card.linkFromPathName}
                                footerLabel={card.footerLabel}
                                footerSubLabel={card.footerSubLabel}
                                mobileImgPath={card.mobileImgPath}
                                desktopImgPath={card.desktopImgPath}
                                newWindow={newWindow}
                            />
                        ),
                )}
            </div>
        </div>
    );
};

export default NavigatorCard;
