import React from 'react';
import { makeStyles } from '@material-ui/core';
import { SliderProps } from '../WidgetPriceCreep';

const useStyles = makeStyles((theme) => ({
    ticketPricing: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: '40px',
        marginTop: '70px',
        [theme.breakpoints.down('sm')]: {
            marginBottom: '30px',
            marginTop: '40px',
        },
    },
    svg: {
        width: '127.342px',
        height: '127.342px',
        flexShrink: 0,
        [theme.breakpoints.down('sm')]: {
            width: '90px',
        },
    },
    cost: {
        fontSize: '40px',
        fontWeight: 600,
        marginLeft: '30px',
        [theme.breakpoints.down('sm')]: {
            fontSize: '35px',
        },
    },
}));

interface Props {
    ticketPrice: number;
    sliderMap: SliderProps[];
}

const TicketPrice = ({ ticketPrice, sliderMap }: Props) => {
    const classes = useStyles();

    const getValue = sliderMap.filter(
        (item) => item.percentage === ticketPrice,
    );

    return (
        <div className={classes.ticketPricing}>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="128"
                height="128"
                viewBox="0 0 128 128"
                fill="none"
                className={classes.svg}
                aria-label={`${getValue[0].year}: ${getValue[0].cost}`}
            >
                <g clipPath="url(#clip0_493_882)">
                    <path
                        d="M0 81.0385L1.92364 82.9622L12.5378 93.5763L14.4614 95.4999L16.3851 93.5763C21.201 88.7604 28.9365 88.7604 33.7524 93.5763C38.5684 98.3922 38.5684 106.128 33.7524 110.944L31.8288 112.867L33.7524 114.791L44.3666 125.405L46.2902 127.329L48.2139 125.405L125.392 48.2274L127.315 46.3038L125.392 44.3801L114.777 33.766L112.854 31.8423L110.93 33.766C106.114 38.5819 98.3787 38.5819 93.5628 33.766C88.7468 28.9501 88.7468 21.2146 93.5628 16.3986L95.4864 14.475L93.5628 12.5513L82.9486 1.93719L81.025 0.0135498L79.1013 1.93719L1.92364 79.1012L0 81.0385ZM7.72186 81.0385L56.918 31.8287L62.7026 37.6133L66.5635 33.7523L60.7789 27.9678L81.0386 7.70812L87.9692 14.6387C82.9077 21.5693 83.467 31.3648 89.7155 37.6133C95.9639 43.8617 105.732 44.3938 112.663 39.3323L119.621 46.2901L99.361 66.5498L93.5764 60.7652L89.7155 64.6261L95.5 70.4107L46.3039 119.607L39.346 112.649C44.4075 105.718 43.8754 95.9501 37.627 89.7017C31.3786 83.4533 21.583 82.8939 14.6524 87.9554L7.72186 81.0249V81.0385ZM43.4116 56.9179L48.7732 69.5239L39.4824 80.0153L53.2617 78.5419L60.1377 90.3566L63.3029 76.8229L76.8366 73.6577L65.0219 66.7817L66.4953 53.0024L56.0039 62.2932L43.3979 56.9316L43.4116 56.9179ZM66.5635 41.4879L74.2854 49.2097L78.1463 45.3488L70.4245 37.6269L66.5635 41.4879ZM78.1463 53.0706L85.8682 60.7925L89.7291 56.9316L82.0072 49.2097L78.1463 53.0706Z"
                        fill="#364F6B"
                    />
                </g>

                <defs>
                    <clipPath id="clip0_493_882">
                        <rect
                            width="127.342"
                            height="127.342"
                            rx="10"
                            fill="white"
                        />
                    </clipPath>
                </defs>
            </svg>
            <span className={classes.cost}>${getValue[0].cost}</span>
        </div>
    );
};

export default TicketPrice;
