import React, { FC } from 'react';

/* components */
import { HeroLayout } from 'components';

type ArticleImageProps = {
    alt: string | undefined;
    url: string;
};

const ArticleImage: FC<ArticleImageProps> = ({ alt, url }) => {
    if (!url) return null;

    return (
        <HeroLayout fullWidthUntil="sm">
            <img
                data-testid="article-image"
                alt={alt}
                height="100%"
                src={url}
                width="100%"
            />
        </HeroLayout>
    );
};

export default ArticleImage;
