/* material */
import createBreakpoints from '@material-ui/core/styles/createBreakpoints';
import { CSSProperties } from '@material-ui/core/styles/withStyles';

/* utils */
import breakpointsConfig from './breakpoints';
import colors from './colors';
import { pxToRem } from './util';

/* constants */
const FONT_COLOR = colors.black80;

export const AVENIR_NEXT_LT_BOLD = 'AvenirNextLTCom-Bold, Arial, Sans-Serif';
export const AVENIR_NEXT_LT_DEMI = 'AvenirNextLTCom-Demi, Arial, Sans-Serif';
export const AVENIR_NEXT_LT_REGULAR =
    'AvenirNextLTCom-Regular, Arial, Sans-Serif';

/* interfaces */
interface FontConfig {
    color?: string;
    fontFamily?: string;
    letterSpacing?: string;
    lineHeight: number;
    size: number;
}

const breakpoints = createBreakpoints({
    values: breakpointsConfig,
});
const fontResourceUrl = `/fonts`;

export const generateFontSizes = (
    mobileConfig: FontConfig,
    desktopConfig?: FontConfig,
): CSSProperties => ({
    color: mobileConfig.color || FONT_COLOR,
    fontFamily: mobileConfig.fontFamily || AVENIR_NEXT_LT_REGULAR,
    fontSize: pxToRem(mobileConfig.size),
    letterSpacing: mobileConfig.letterSpacing || 'normal',
    lineHeight: pxToRem(mobileConfig.lineHeight),
    [breakpoints.up('sm')]: {
        ...(desktopConfig && {
            fontFamily: desktopConfig.fontFamily,
        }),
        ...(desktopConfig && {
            letterSpacing: desktopConfig.letterSpacing,
        }),
        ...(desktopConfig && { fontSize: pxToRem(desktopConfig.size) }),
        ...(desktopConfig && {
            lineHeight: pxToRem(desktopConfig?.lineHeight),
        }),
    },
});

export const AvenirNextLTComBold = {
    fontColor: FONT_COLOR,
    fontFamily: 'AvenirNextLTCom-Bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    fontWeight: 'normal',
    letterSpacing: 'normal',
    src: `url("${fontResourceUrl}/AvenirNextLTCom-Bold.eot?#iefix") format('embedded-opentype'), url("${fontResourceUrl}/AvenirNextLTCom-Bold.woff") format('woff'), url("${fontResourceUrl}/AvenirNextLTCom-Bold.ttf") format('truetype')`,
    fontDisplay: 'swap',
    crossorigin: 'anonymous',
};

export const AvenirNextLTComDemi = {
    fontColor: FONT_COLOR,
    fontFamily: 'AvenirNextLTCom-Demi',
    fontStretch: 'normal',
    fontStyle: 'normal',
    fontWeight: 'normal',
    letterSpacing: 'normal',
    src: `url("${fontResourceUrl}/AvenirNextLTCom-Demi.eot?#iefix") format('embedded-opentype'), url("${fontResourceUrl}/AvenirNextLTCom-Demi.woff") format('woff'), url("${fontResourceUrl}/AvenirNextLTCom-Demi.ttf") format('truetype')`,
    fontDisplay: 'swap',
    crossorigin: 'anonymous',
};

export const AvenirNextLTComRegular = {
    fontColor: FONT_COLOR,
    fontFamily: 'AvenirNextLTCom-Regular',
    fontStretch: 'normal',
    fontStyle: 'normal',
    fontWeight: 'normal',
    letterSpacing: 'normal',
    src: `url("${fontResourceUrl}/AvenirNextLTCom-Regular.eot?#iefix") format('embedded-opentype'), url("${fontResourceUrl}/AvenirNextLTCom-Regular.woff") format('woff'), url("${fontResourceUrl}/AvenirNextLTCom-Regular.ttf") format('truetype')`,
    fontDisplay: 'swap',
    crossorigin: 'anonymous',
};

export const h1 = generateFontSizes(
    { fontFamily: AVENIR_NEXT_LT_DEMI, lineHeight: 36, size: 30 },
    { size: 50, lineHeight: 65 },
);

export const h2 = generateFontSizes(
    { fontFamily: AVENIR_NEXT_LT_DEMI, lineHeight: 34, size: 25 },
    { size: 35, lineHeight: 45 },
);

export const h3 = generateFontSizes(
    { fontFamily: AVENIR_NEXT_LT_REGULAR, lineHeight: 29, size: 22 },
    { size: 30, lineHeight: 36 },
);

export const h4 = generateFontSizes(
    { fontFamily: AVENIR_NEXT_LT_REGULAR, lineHeight: 24, size: 18 },
    { size: 22, lineHeight: 29 },
);

export const body1 = generateFontSizes(
    { fontFamily: AVENIR_NEXT_LT_REGULAR, lineHeight: 26, size: 16 },
    { lineHeight: 28, size: 18 },
);

export const body2 = generateFontSizes({
    fontFamily: AVENIR_NEXT_LT_REGULAR,
    lineHeight: 20,
    size: 14,
});

export const subtitle1 = generateFontSizes(
    {
        color: colors.symphonyBlue,
        fontFamily: AVENIR_NEXT_LT_REGULAR,
        lineHeight: 24,
        size: 18,
    },
    { lineHeight: 29, size: 22 },
);

export const label = generateFontSizes({
    color: colors.gray60,
    fontFamily: AVENIR_NEXT_LT_DEMI,
    letterSpacing: pxToRem(1.2),
    lineHeight: 16,
    size: 12,
});

export const eyebrowArticle = generateFontSizes(
    {
        color: colors.raspberry,
        fontFamily: AVENIR_NEXT_LT_BOLD,
        letterSpacing: pxToRem(1.5),
        lineHeight: 14,
        size: 11,
    },
    { fontFamily: AVENIR_NEXT_LT_DEMI, lineHeight: 18, size: 14 },
);

export const eyebrowCard = generateFontSizes({
    color: colors.raspberry,
    fontFamily: AVENIR_NEXT_LT_BOLD,
    letterSpacing: pxToRem(1.8),
    lineHeight: 14,
    size: 11,
});

export const eyebrowLarge = generateFontSizes({
    color: colors.raspberry,
    fontFamily: AVENIR_NEXT_LT_BOLD,
    letterSpacing: pxToRem(1.5),
    lineHeight: 18,
    size: 14,
});

export const eyebrowHeading = generateFontSizes(
    {
        color: colors.raspberry,
        fontFamily: AVENIR_NEXT_LT_BOLD,
        letterSpacing: pxToRem(1.18),
        lineHeight: 14,
        size: 11,
    },
    {
        fontFamily: AVENIR_NEXT_LT_BOLD,
        letterSpacing: pxToRem(1.5),
        lineHeight: 18,
        size: 14,
    },
);

export const contentLink = generateFontSizes(
    {
        color: colors.darkTurquoise,
        fontFamily: AVENIR_NEXT_LT_DEMI,
        lineHeight: 26,
        size: 16,
    },
    { lineHeight: 28, size: 18 },
);

export const footnote = generateFontSizes({
    color: colors.black80,
    lineHeight: 16,
    size: 12,
});
