import React, { useEffect, useRef } from 'react';
import VisibilitySensor from 'react-visibility-sensor';
import {
    multiMediaCompletedEvent,
    multiMediaPlayTimeEvent,
    multiMediaReached25Event,
    multiMediaReached50Event,
    multiMediaReached75Event,
    multiMediaStartedEvent,
} from 'analytics';

export type videoParamsType = {
    serverurl: string;
    contenturl: string;
    config: string;
    posterimage: string;
    videoserverurl: string;
    asset: string;
    iconeffect: string;
    autoplay?: string;
    caption?: string;
};
interface Props {
    isMobile?: boolean;
    params: videoParamsType;
    id: string;
    title?: string;
    completedCallBack?: () => void;
    isAutoPlay?: boolean;
}

const VideoPlayer: React.FC<Props> = ({
    //isMobile = false,
    title = '',
    params,
    id,
    completedCallBack,
    isAutoPlay = false,
}) => {
    const s7videoviewer = useRef<any>();
    useEffect(() => {
        if (window.s7viewers && window.s7viewers.VideoViewer) {
            s7videoviewer.current = new window.s7viewers.VideoViewer({
                containerId: id,
                params: params,
                handlers: {
                    trackEvent: function (
                        _objID: string,
                        _compClass: string,
                        _instName: string,
                        _timeStamp: number,
                        eventInfo: string,
                    ) {
                        const eventInfoValues = eventInfo.split(',');
                        const action = eventInfoValues[0];
                        const viewPercentage = eventInfoValues[1];
                        const seconds = (_timeStamp % 60000) / 1000;

                        if (action === 'PLAY') {
                            multiMediaStartedEvent(title, 'video');
                        }

                        if (action === 'MILESTONE') {
                            if (viewPercentage === '25') {
                                multiMediaReached25Event(title, 'video');
                            } else if (viewPercentage === '50') {
                                multiMediaReached50Event(title, 'video');
                            } else if (viewPercentage === '75') {
                                multiMediaReached75Event(title, 'video');
                            }
                        }

                        if (action === 'PLAYBACK_COMPLETE') {
                            multiMediaCompletedEvent(title, 'video');
                            multiMediaPlayTimeEvent(title, 'video', seconds);
                            if (completedCallBack) {
                                completedCallBack();
                            }
                        }
                    },
                },
            }).init();
        }

        return () => {
            s7videoviewer?.current?.dispose();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        s7videoviewer?.current?.setAsset(params.asset, {
            caption: params.caption ? params.caption : '',
            posterimage: `${params.posterimage}`,
        });
    }, [params]);

    return (
        <VisibilitySensor
            delayedCall
            onChange={(isVisible) => {
                if (isVisible && isAutoPlay) {
                    s7videoviewer?.current?.videoplayer?.play();
                } else {
                    s7videoviewer?.current?.videoplayer?.pause();
                }
            }}
        >
            <div id={id} data-qa="dynamic-video-player" />
        </VisibilitySensor>
    );
};

export default VideoPlayer;
