import React, { FC, useState } from 'react';

/* component */
import { QuizQuestionSet } from 'components/interactive-quiz';

/* types */
import { quizItems } from './QuizQuestionSet';

interface InteractiveQuizProps {
    quizData: quizItems;
}

const InteractiveQuiz: FC<InteractiveQuizProps> = ({ quizData }) => {
    const [treeState, setTreeState] = useState(quizData);

    const handleClick = () => {
        // force re-render
        const newState = { ...treeState };
        setTreeState(newState);
    };

    return (
        <div data-testid="interactiveQuiz">
            <QuizQuestionSet onClick={handleClick} questions={treeState} />
        </div>
    );
};

export default InteractiveQuiz;
