import React, { FC, ReactNode, useState } from 'react';

import { parseReplace } from '../../utils/htmlParser';

/* material */
import { makeStyles } from '@material-ui/core';
import { AVENIR_NEXT_LT_DEMI, AVENIR_NEXT_LT_REGULAR } from 'theme/font';
import { pxToRem } from 'theme/util';
import { customClickEvent } from 'analytics';

interface StyleProps {
    isFlipped: boolean;
    hasImagePath: boolean;
}

const useStyles = makeStyles((theme) => ({
    wrapper: {
        backgroundColor: theme.colors.white,
        border: `solid 2px rgba(0,95,158,0.5)`,
        borderRadius: 7,
        height: 340,
        overflow: 'hidden',
        cursor: 'pointer',
        display: 'flex',
        flexDirection: 'column',
        width: (props: StyleProps) =>
            !props.hasImagePath ? '417px' : 'initial',
        [theme.breakpoints.down('sm')]: {
            width: (props: StyleProps) =>
                !props.hasImagePath ? '271px' : 'initial',
            height: (props: StyleProps) =>
                !props.hasImagePath ? 225 : 'initial',
            margin: 'auto',
        },
    },
    answer: {
        fontFamily: AVENIR_NEXT_LT_REGULAR,
        fontSize: pxToRem(18),
        lineHeight: pxToRem(24),
        padding: 25,
        textAlign: 'center',
        width: '100%',

        [theme.breakpoints.up('sm')]: {
            fontSize: pxToRem(20),
            lineHeight: pxToRem(26),
        },
    },
    answerWrapper: {
        overflowY: (props: StyleProps) =>
            props.isFlipped ? 'auto' : 'inherit',
        flex: '1 1 auto',
    },
    topic: {
        alignItems: 'center',
        backgroundColor: (props: StyleProps) =>
            props.isFlipped ? theme.colors.symphonyBlue : theme.colors.gray90,
        color: (props: StyleProps) =>
            props.isFlipped ? theme.colors.white : theme.colors.black,
        display: 'flex',
        fontFamily: AVENIR_NEXT_LT_DEMI,
        fontSize: pxToRem(24),
        lineHeight: pxToRem(30),
        justifyContent: 'center',
        textAlign: 'center',
        letterSpacing: '1.71px',
        paddingTop: pxToRem(20),
        paddingBottom: pxToRem(10),
        flex: '0 1 auto',
        [theme.breakpoints.down('sm')]: {
            fontSize: '18px',
            height: '52px',
        },
    },
    imgBg: {
        backgroundSize: 'contain',
        backgroundPosition: 'bottom',
        backgroundRepeat: 'no-repeat',
        height: '100%',
    },
    questionWrapper: {
        backgroundColor: theme.colors.symphonyBlue,
        color: theme.colors.white,
        height: 'inherit',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: '25px',
        fontWeight: 600,
        [theme.breakpoints.down('sm')]: {
            fontSize: '20px',
        },
    },
    answerContainer: {
        fontSize: '20px',
        lineHeight: '35px',
        padding: '10px',
        color: theme.colors.prussianBlue,
        [theme.breakpoints.down('sm')]: {
            fontSize: pxToRem(16),
            lineHeight: '23px',
        },
    },
}));

interface FlipCardProps {
    imagePath: string;
    question: string;
    answer: string | ReactNode;
    flip?: boolean;
    toggleOnFlip?: (question: string, isFlipped: boolean) => void;
}

const FlipCard: FC<FlipCardProps> = ({
    imagePath,
    answer,
    question,
    flip = false,
    toggleOnFlip,
}) => {
    const [isFlipped, setIsFlipped] = useState(flip);

    const classes = useStyles({
        isFlipped,
        hasImagePath: Boolean(imagePath),
    });

    const handleClick = () => {
        setIsFlipped(!isFlipped);
        toggleOnFlip && toggleOnFlip(question, !isFlipped);
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
        if (e.key === 'Enter') {
            setIsFlipped(!isFlipped);
            toggleOnFlip && toggleOnFlip(question, !isFlipped);
        }
    };

    return (
        <div
            data-qa="flip-card"
            className={classes.wrapper}
            onClick={() => {
                handleClick();
                customClickEvent({
                    customLinkName: `flipcard-${
                        isFlipped ? 'collapsed' : 'expanded'
                    }: ${question}`,
                });
            }}
            tabIndex={0}
            role="button"
            onKeyDown={handleKeyDown}
        >
            {!imagePath ? (
                <>
                    {isFlipped ? (
                        <>
                            <div
                                data-qa="flip-card-topic"
                                className={classes.topic}
                            >
                                {question}
                            </div>
                            <div className={classes.answerContainer}>
                                {answer}
                            </div>
                        </>
                    ) : (
                        <div className={classes.questionWrapper}>
                            {question}
                        </div>
                    )}
                </>
            ) : (
                <>
                    <div data-qa="flip-card-topic" className={classes.topic}>
                        {question}
                    </div>
                    <div className={classes.answerWrapper}>
                        {isFlipped ? (
                            <div
                                data-qa="flip-card-answer"
                                className={classes.answer}
                            >
                                {typeof answer === 'string'
                                    ? parseReplace(answer)
                                    : ''}
                            </div>
                        ) : (
                            <div
                                style={{ backgroundImage: `url(${imagePath})` }}
                                className={classes.imgBg}
                            ></div>
                        )}
                    </div>
                </>
            )}
        </div>
    );
};

export default FlipCard;
