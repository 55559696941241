import * as React from 'react';
import { DomElement, domToReact } from 'html-react-parser';

/* interfaces */
import { RenderType, RenderElementProps } from '../interfaces';

const getLiIndex = (domNode: DomElement) => {
    const { parent } = domNode;

    const liChildren = parent?.children?.filter((child) => {
        return child.name === 'li';
    });

    // generate index number for ordered list
    const idx =
        parent?.name === 'ol'
            ? liChildren && liChildren.indexOf(domNode) + 1
            : '';

    return idx;
};

const renderArticleLi: RenderType = (
    props: RenderElementProps,
): JSX.Element => {
    const { domNode, options } = props;
    const idx = getLiIndex(domNode);
    const { children } = domNode;
    return (
        <li>
            <div>{idx}</div>
            {children && domToReact(children, options)}
        </li>
    );
};

export default renderArticleLi;
