export const customClickEvent = (
    data = {
        customLinkName: '',
    },
) => {
    window.appEventData = window.appEventData || [];
    window.appEventData.push({
        event: 'CTA Clicked',
        linkInfo: {
            customLinkName: data.customLinkName,
            linkId: '',
        },
    });
};
