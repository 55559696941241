import getAEMBaseUrl from 'utils/getAEMBaseUrl';

/* This hooks is intended to be used only with GET methods and json files */
/* do not include /po/content' when using this hook */

const useAEMBaseUrl: (url: string) => string = (url) => {
    return getAEMBaseUrl(url);
};

export default useAEMBaseUrl;
