import React from 'react';
import { domToReact } from 'html-react-parser';

/* interfaces */
import { RenderElement } from '../interfaces';

/* components */
import { ArticleParagraph } from 'containers/article-landing';

const renderArticleP: RenderElement = (props): JSX.Element | null => {
    const { domNode, data: fragmentData, options, HtmlRenderMap } = props;
    const { children } = domNode;

    // parse for custom tags wrapped in P tags
    const data = children && children[0] && children[0].data;
    const customTagRx = /[^[\]]+(?=])/;
    const isCustomTag = typeof data === 'string' && customTagRx.test(data);
    const customTag = isCustomTag ? data.match(customTagRx)[0].split(' ') : [];
    const [tag, ...styles] = customTag;

    if (tag && tag in HtmlRenderMap) {
        const render = HtmlRenderMap[tag] as RenderElement;
        return render({
            domNode,
            options,
            HtmlRenderMap,
            attributes: styles,
            data: fragmentData,
        });
    }
    return (
        <ArticleParagraph>
            {children && domToReact(children, options)}
        </ArticleParagraph>
    );
};

export default renderArticleP;
