import React from 'react';

/* material */
import CircularProgress from '@material-ui/core/CircularProgress';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';

/* store */
import { RootState } from 'store';

const useStyles = makeStyles({
    root: {
        flexGrow: 1,
        textAlign: 'center',
        padding: '50px',
        minHeight: 300,
    },
    loader: {
        marginTop: 25,
        color: '#999',
    },
    title: {
        color: '#666',
    },
});

export const Loader = () => {
    const classes = useStyles();

    const { domainName } = useSelector((state: RootState) => ({
        domainName: state.user.domain.domainName,
    }));

    return (
        <Container data-qa="loader" className={classes.root} maxWidth="sm">
            <Typography className={classes.title} component="h2" variant="h3">
                {domainName}
            </Typography>
            <CircularProgress
                thickness={2}
                size={75}
                className={classes.loader}
            />
        </Container>
    );
};
