import { applyMiddleware, createStore, compose, Store } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { logger } from 'redux-logger';
import { routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';

/* saga */
import rootSaga from './sagas';

/* reducers */
import createRootReducer from 'reducers/rootReducer';

/* middleware */
import trackPageView from 'middleware/trackPageView';

/* utils */
import {
    getLocalStorage,
    saveUserProfileToLocalStorage,
    setReduxSessionState,
} from 'utils';

import { initialArticleState } from 'reducers/article';

const composeEnhancer: typeof compose =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const history = createBrowserHistory();
const rootReducer = createRootReducer(history);
export type RootState = ReturnType<typeof rootReducer>;

type LocalStorageType = Pick<
    RootState,
    | 'user'
    | 'article'
    | 'company'
    | 'dollarOnTheRoad'
>;

const localStorageReduxState = getLocalStorage(
    'userProfile',
) as LocalStorageType;

const preloadedState = Object.assign({}, localStorageReduxState, {
    article: { ...initialArticleState, ...localStorageReduxState.article },
});

export default function configureStore(): Store {
    const sagaMiddleware = createSagaMiddleware();
    const store = createStore(
        rootReducer,
        preloadedState,
        composeEnhancer(
            applyMiddleware(
                routerMiddleware(history),
                sagaMiddleware,
                logger,
                trackPageView,
            ),
        ),
    );

    //Subscribe my store to local storage so Everytime state will change, local storage will updates
    store.subscribe(() => {
        saveUserProfileToLocalStorage(store);
        setReduxSessionState(store);
    });

    sagaMiddleware.run(rootSaga);
    return store;
}
