import React, { FC } from 'react';

/* material */
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import { AVENIR_NEXT_LT_DEMI } from '../../theme/font';
import { pxToRem } from '../../theme/util';
import colors from 'theme/colors';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        marginBottom: 47,
    },
    textInput: {
        width: 416,
        minHeight: 26,
        display: 'block',
        borderTop: 'none',
        borderLeft: 'none',
        borderRight: 'none',
        background: 'none',
        color: colors.black80,
        fontFamily: AVENIR_NEXT_LT_DEMI,
        fontSize: pxToRem(18),
        lineSpacing: pxToRem(22),
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    textBorder: {
        borderBottom: `1px solid ${colors.cobalt}`,
    },
    textBorderError: {
        borderBottom: `1px solid ${colors.deepCerise}`,
    },
    inputErrorLabel: {
        color: colors.deepCerise,
        width: '100%',
        marginTop: 9,
        fontSize: pxToRem(14),
    },
    message: {
        color: colors.black80,
        fontFamily: AVENIR_NEXT_LT_DEMI,
        fontSize: pxToRem(18),
        lineSpacing: pxToRem(22),
        width: 631,
        minHeight: 150,
        border: '1px solid',
        display: 'block',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
        padding: 10,
    },
    messageBorderColor: {
        borderColor: colors.cobalt,
    },
    messageBorderColorError: {
        borderColor: colors.deepCerise,
    },
    messageLabel: {
        fontFamily: AVENIR_NEXT_LT_DEMI,
        fontSize: pxToRem(18),
        lineHeight: pxToRem(22),
        color: colors.gray60,
        marginBottom: 9,
    },
    disabled: {
        backgroundColor: colors.gray97,
        color: colors.gray60,
    },
}));

type Type = 'text' | 'textarea' | 'email';

type ContactUsInputProps = {
    type?: Type;
    label?: string;
    errorMessage?: string | null;
    onChange?: (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => void | undefined;
    isError?: boolean;
    disabled?: boolean;
};

const ContactUsInput: FC<ContactUsInputProps> = ({
    type,
    label,
    errorMessage,
    onChange,
    isError,
    disabled,
}) => {
    const classes = useStyles();

    const renderInput = () => {
        if (!type || !label) {
            return null;
        }

        if (type === 'text' || type === 'email') {
            return (
                <div className={classes.root} data-qa="contact-us-input">
                    <Typography
                        variant="srOnly"
                        htmlFor={label}
                        component="label"
                    >
                        {label}
                    </Typography>
                    <input
                        className={`${classes.textInput} ${
                            isError
                                ? classes.textBorderError
                                : classes.textBorder
                        } ${disabled ? classes.disabled : null}`}
                        type={type}
                        placeholder={isError ? '' : label}
                        onChange={onChange}
                        disabled={disabled}
                        id={label}
                    />
                    {isError && (
                        <Typography className={`${classes.inputErrorLabel}`}>
                            {errorMessage}
                        </Typography>
                    )}
                </div>
            );
        }
        if (type === 'textarea') {
            return (
                <div className={classes.root} data-qa="contact-us-input">
                    <Typography
                        htmlFor={label}
                        component="label"
                        className={classes.messageLabel}
                    >
                        {label}
                    </Typography>

                    <textarea
                        disabled={disabled}
                        className={`${classes.message} ${
                            isError
                                ? classes.messageBorderColorError
                                : classes.messageBorderColor
                        } ${disabled ? classes.disabled : null}`}
                        onChange={onChange}
                        id={label}
                    ></textarea>

                    {isError && (
                        <Typography className={classes.inputErrorLabel}>
                            {errorMessage}
                        </Typography>
                    )}
                </div>
            );
        }

        return null;
    };

    return renderInput();
};

export default ContactUsInput;
