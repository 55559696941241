import { defaultColor, SET_BASIC_DIR_INFO } from 'constants/dollarOnTheRoad';
import { DIRActionTypes } from 'actions/dollar-on-the-road';

export interface DollarOnTheRoad {
    carColor: string;
    name: string;
    under50: boolean | null;
}

export const initialDollarOnTheRoadState: DollarOnTheRoad = {
    carColor: defaultColor,
    name: '',
    under50: null,
};

export const dollarOnTheRoadReducer = (
    state = initialDollarOnTheRoadState,
    action: DIRActionTypes,
): DollarOnTheRoad => {
    switch (action.type) {
        case SET_BASIC_DIR_INFO:
            return {
                ...state,
                carColor: action.carColor,
                name: action.name,
                under50: action.under50,
            };
        default:
            return state;
    }
};
