const defaults = {
    AEM_BASE_URL: 'https://www.dev.capitalgroup.com',
    GOOGLE_RECAPTCHA_V2_KEY_SITE: '',
    LAUNCH_DARKLY_CLIENT_SIDE_ID: '5f400fa713698c0919f585b6',
    DATADOG_CLIENT_TOKEN: '',
    SPRING_PROFILES_ACTIVE: 'dev',
};

export const getGlobalConfig = () => {
    return (window.envConfig && window.envConfig()) || defaults;
};

export const doNotSellLinkConfig: { [key: string]: boolean } = {
    generic: true,
    rwj: false,
};

export interface DomainConfig {
    [key: string]: { [key: string]: boolean };
}
export const domain: DomainConfig = {
    myretirement: {
        rwjbh: true,
        generic: true,
        planpremier: false,
        rkd: false,
        onegas: false,
    },
    icanretire: {
        rwjbh: false,
        generic: true,
        planpremier: true,
        rkd: true,
        onegas: true,
        capgroup: true,
    },
};
