import * as React from 'react';

/* interfaces */
import { RenderElement } from '../interfaces';

/* constants */
import { aemBaseURL } from 'constants/aem';

/* components */
import { ArticleImage } from 'containers/article-landing';

const renderArticleImg: RenderElement = (props): JSX.Element | null => {
    const {
        domNode: { attribs },
    } = props;
    if (attribs) {
        const { src, 'data-assetref': filename, alt } = attribs;
        const url = `${aemBaseURL}${src}`;
        return <ArticleImage alt={alt || filename} url={url} />;
    }

    return null;
};

export default renderArticleImg;
