import React, { FC } from 'react';

/* material */
import { Typography } from '@material-ui/core';

const StaticSubHeader: FC = ({ children }) => {
    return (
        <Typography
            data-qa="article-subheader"
            variant="subtitle1"
            component="h2"
        >
            {children}
        </Typography>
    );
};

export default StaticSubHeader;
