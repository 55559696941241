import { useState, useEffect } from 'react';
import { getHeaders } from '../../services/utils';

interface RequestProps<T> {
    url: RequestInfo;
    processData?: (data: any) => T;
    initialData: T;
    method?: string;
    body?: BodyInit | null;
}

export const useFetch = <T>({
    url,
    initialData,
    processData,
    method = 'GET',
    body,
}: RequestProps<T>) => {
    const [data, setData] = useState<T>(initialData);
    const [error, setError] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true);
    const [httpStatus, setHttpStatus] = useState<number>(200);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const res = await fetch(url, {
                    method,
                    body,
                    headers: getHeaders(),
                });
                setHttpStatus(res.status);
                if (!res.ok) throw new Error('Error fetching');
                const resJson = await res.json();
                if (processData) {
                    setData(processData(resJson));
                } else {
                    setData(resJson);
                }
            } catch (err) {
                setError(true);
            } finally {
                setLoading(false);
            }
        };
        fetchData();
        // TODO add processData to dependency
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [url, method, body]);
    return { loading, data, error, httpStatus };
};
