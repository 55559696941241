/* constants */
import {
    SET_SEGMENT,
    SET_UID,
    SET_ANIMAL,
    SET_DOMAIN_NAME,
    SEGMENT_GENERIC,
    SET_LAST_SENTIMENT_MODAL_VIEW_DATE,
    SET_LAST_SENTIMENT_ANSWER_DATE,
    SET_LAST_QUIZ_MODAL_VIEW_DATE,
    SET_LAST_QUIZ_TAKE_DATE,
    SET_IS_REVISIT,
    SET_IS_ENROLL,
    SET_LANGUAGE,
} from 'constants/user';

/* types */
import { UserActionTypes } from 'actions/user';
import { enUS } from 'utils/locales';

/* store state */

export type SegmentType = 'k' | 'g' | 'o' | 's';
export interface User {
    segment: string;
    uid: string | null;
    animal: string;
    domain: { [key: string]: string };
    lastSentimentModalViewDate: string;
    lastSentimentPollAnsweredDate: string;
    lastQuizModalViewDate: string;
    lastQuizTakeDate: string;
    isReturnVisit: boolean;
    isEnroll: boolean;
    language: string;
}

export const initialUserState: User = {
    segment: SEGMENT_GENERIC,
    uid: null,
    animal: '',
    domain: {},
    lastSentimentModalViewDate: '',
    lastSentimentPollAnsweredDate: '',
    lastQuizModalViewDate: '',
    lastQuizTakeDate: '',
    isReturnVisit: false,
    isEnroll: false,
    language: enUS,
};

export const userReducer = (
    state = initialUserState,
    action: UserActionTypes,
): User => {
    switch (action.type) {
        case SET_SEGMENT:
            return {
                ...state,
                segment: action.segment,
            };
        case SET_UID:
            return { ...state, uid: action.uid };
        case SET_ANIMAL:
            return { ...state, animal: action.animal };
        case SET_DOMAIN_NAME:
            return {
                ...state,
                domain: { ...state.domain, domainName: action.domainName },
            };
        case SET_LAST_SENTIMENT_MODAL_VIEW_DATE:
            return {
                ...state,
                lastSentimentModalViewDate: action.date,
            };
        case SET_LAST_SENTIMENT_ANSWER_DATE:
            return {
                ...state,
                lastSentimentPollAnsweredDate: action.date,
            };
        case SET_LAST_QUIZ_MODAL_VIEW_DATE:
            return {
                ...state,
                lastQuizModalViewDate: action.date,
            };
        case SET_LAST_QUIZ_TAKE_DATE:
            return {
                ...state,
                lastQuizTakeDate: action.date,
            };
        case SET_IS_REVISIT:
            return {
                ...state,
                isReturnVisit: true,
            };
        case SET_IS_ENROLL:
            return {
                ...state,
                isEnroll: action.isEnroll,
            };
        case SET_LANGUAGE:
            return {
                ...state,
                language: action.language,
            };
        default:
            return state;
    }
};
