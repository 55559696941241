import React, { FC, useRef } from 'react';
import clsx from 'clsx';
import { animateScroll as scroll } from 'react-scroll';

/* material */
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

/* components */
import QuizQuestion from './QuizQuestion';

/* utils */
import { AVENIR_NEXT_LT_BOLD } from 'theme/font';
import { pxToRem } from 'theme/util';

/* assets */
import purpleUnderline from 'assets/purple-underline.svg';

const useStyles = makeStyles((theme) => ({
    questionWrapper: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
    },
    question: {
        background: `url('${purpleUnderline}') bottom center no-repeat`,
        backgroundSize: '50%',
        fontFamily: AVENIR_NEXT_LT_BOLD,
        fontSize: pxToRem(18),
        lineHeight: pxToRem(22),
        marginBottom: 18,
        paddingBottom: 18,
        textAlign: 'center',
        width: '100%',

        [theme.breakpoints.up('sm')]: {
            backgroundSize: '70%',
            fontSize: pxToRem(55),
            lineHeight: pxToRem(65),
            marginBottom: 38,
            paddingBottom: 48,
        },
    },
    contentWrapper: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        padding: 10,
        width: 375,

        [theme.breakpoints.up('sm')]: {
            padding: 0,
            width: '100%',
        },
    },
    contentMarginBottom: {
        marginBottom: 78,

        [theme.breakpoints.up('sm')]: {
            marginBottom: 220,
        },
    },

    contentSpacer: {
        marginBottom: 20,

        [theme.breakpoints.up('sm')]: {
            marginBottom: 80,
        },
    },
}));

export type Answer = 'yes' | 'no';

export type quizItem = {
    label: string;
    order: number;
    text: string;
    item?: quizItems;
    selected?: boolean;
};

export type quizItems = {
    items: quizItem[];
    text: string;
};

export interface QuizQuestionSetProps {
    onClick: () => void;
    questions: quizItems;
}

const QuizQuestionSet: FC<QuizQuestionSetProps> = ({ onClick, questions }) => {
    const classes = useStyles();
    const { text, items } = questions;
    const parentRef = useRef<HTMLDivElement | null>(null);

    const clearSelectedState = (answers: quizItem[]) => {
        // reset selection state
        answers.forEach((answer: quizItem) => {
            answer.selected = false;
            if (answer.item) {
                clearSelectedState(answer.item.items);
            }
        });
    };

    const scrollToQuestion = () => {
        if (parentRef.current) {
            const navBarOffset = 100;
            const windowScroll = window.scrollY;
            const yPos = parentRef.current.getBoundingClientRect().y;
            const scrollToY = yPos + windowScroll - navBarOffset;
            windowScroll < scrollToY && scroll.scrollTo(scrollToY);
        }
    };

    const renderQuestions = (answers: quizItem[]) => {
        return answers.map((answer: quizItem, idx: number) => {
            const label = answer.label.toLowerCase() as Answer;
            const copy = answer.text;
            const layout = idx % 2 === 0 ? 'left' : 'right';
            const active = answer['selected'];
            const hasChildren = answer.item ? true : false;
            const handleClick = () => {
                // reset selection state
                clearSelectedState(answers);
                answer.selected = true;
                scrollToQuestion();
                onClick();
            };

            return (
                <QuizQuestion
                    key={idx}
                    onClick={handleClick}
                    label={label}
                    copy={copy}
                    columnLayout={layout}
                    isActive={active}
                    hasChildren={hasChildren}
                />
            );
        });
    };

    return (
        <>
            <div
                ref={parentRef}
                data-qa="quiz-question-wrapper"
                className={classes.questionWrapper}
            >
                <Typography className={classes.question} component="h3">
                    {text}
                </Typography>
                <div
                    className={clsx(
                        items.some((item) => item.item)
                            ? classes.contentMarginBottom
                            : classes.contentSpacer,
                        classes.contentWrapper,
                    )}
                >
                    {renderQuestions(items)}
                </div>
            </div>
            {items.map((item: quizItem, idx: number) => {
                if (item.item && item.selected) {
                    return (
                        <QuizQuestionSet
                            key={idx}
                            questions={item.item}
                            onClick={onClick}
                        />
                    );
                }
                return null;
            })}
        </>
    );
};

export default QuizQuestionSet;
