import React, { FC, ReactNode, useState } from 'react';

/* material */
import { makeStyles } from '@material-ui/core';
import { AVENIR_NEXT_LT_DEMI, AVENIR_NEXT_LT_REGULAR } from 'theme/font';
import { pxToRem } from 'theme/util';

interface StyleProps {
    isFlipped: boolean;
    bgColor: string;
    imageUrl?: string;
}

const useStyles = makeStyles((theme) => ({
    wrapper: {
        backgroundColor: (props: StyleProps) => {
            return props.bgColor;
        },
        backgroundPosition: 'center',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        border: `solid 4px ${theme.colors.symphonyBlue}`,
        borderRadius: 7,
        height: 240,
        overflow: 'hidden',
        cursor: 'pointer',
        // display: 'flex',
        flexDirection: 'column-reverse',
        width: 240,
        [theme.breakpoints.down('sm')]: {
            width: 254,
            height: 235,
            margin: 'auto',
            marginTop: '80px',
        },
    },
    answer: {
        fontFamily: AVENIR_NEXT_LT_REGULAR,
        fontSize: pxToRem(18),
        lineHeight: pxToRem(24),
        padding: 25,
        textAlign: 'center',
        width: '100%',

        [theme.breakpoints.up('sm')]: {
            fontSize: pxToRem(20),
            lineHeight: pxToRem(26),
        },
    },
    answerWrapper: {
        overflowY: (props: StyleProps) =>
            props.isFlipped ? 'auto' : 'inherit',
        flex: '1 1 auto',
    },
    topic: {
        bottom: 0,
        alignItems: 'center',
        backgroundColor: (props: StyleProps) =>
            props.isFlipped ? theme.colors.symphonyBlue : theme.colors.gray90,
        color: (props: StyleProps) =>
            props.isFlipped ? theme.colors.white : theme.colors.black,
        display: 'flex',
        fontFamily: AVENIR_NEXT_LT_DEMI,
        fontSize: pxToRem(24),
        lineHeight: pxToRem(30),
        justifyContent: 'center',
        textAlign: 'center',
        letterSpacing: '1.71px',
        paddingTop: pxToRem(20),
        paddingBottom: pxToRem(10),
        flex: '0 1 auto',
        marginTop: '44px',
        [theme.breakpoints.down('sm')]: {
            fontSize: '18px',
            height: '52px',
            marginTop: '45px',
        },
    },
    questionWrapper: {
        backgroundColor: theme.colors.symphonyBlue,
        color: theme.colors.white,
        height: 'inherit',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: '60px',
        fontWeight: 600,
    },
    image: {
        width: '90px',
        height: '124px',
        position: 'relative',
        top: '25px',
        left: '75px',
        [theme.breakpoints.down('md')]: {},
    },
}));

interface FlipCardProps {
    bgImage: string;
    bgColor: string;
    question: string;
    answer: string | ReactNode;
    flip?: boolean;
    externalFlip?: boolean;
    toggleOnFlip?: (question: string, isFlipped: boolean) => void;
    altText: string;
}

const WidgetFlipCard: FC<FlipCardProps> = ({
    bgImage,
    bgColor,
    answer,
    question,
    flip = false,
    externalFlip = false,
    toggleOnFlip = () => {},
    altText,
}) => {
    let [isFlipped, setIsFlipped] = useState(false);
    if (externalFlip) {
        isFlipped = flip;
        setIsFlipped = () => {};
    }
    const classes = useStyles({
        isFlipped,
        bgColor,
        imageUrl: '',
    });

    const handleClick = () => {
        setIsFlipped(!isFlipped);
        toggleOnFlip && toggleOnFlip(question, !isFlipped);
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
        if (e.key === 'Enter') {
            setIsFlipped(!isFlipped);
            toggleOnFlip && toggleOnFlip(question, !isFlipped);
        }
    };

    return (
        <div
            data-qa="flip-card"
            className={classes.wrapper}
            onClick={handleClick}
            tabIndex={0}
            role="button"
            onKeyDown={handleKeyDown}
        >
            <>
                {isFlipped ? (
                    <>
                        {bgImage && (
                            <img
                                src={bgImage}
                                alt={altText}
                                className={classes.image}
                            />
                        )}
                        <div
                            data-qa="flip-card-topic"
                            className={classes.topic}
                        >
                            <span aria-hidden="true">{answer}</span>
                        </div>
                    </>
                ) : (
                    <div className={classes.questionWrapper}>{question}</div>
                )}
            </>
        </div>
    );
};

export default WidgetFlipCard;
