import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-scroll';
import { AVENIR_NEXT_LT_DEMI } from 'theme/font';
import { customClickEvent } from 'analytics';
import { FormattedMessage } from 'react-intl';
import { KeyboardArrowUp } from '@material-ui/icons';

interface Props {
    isLatinGrammy?: boolean;
}

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'grid',
        color: 'white',
        justifyItems: 'center',
        marginRight: 0,
        padding: '20px 0px',
        [theme.breakpoints.up('md')]: {
            justifyItems: 'end',
            marginRight: 15,
        },
    },
    link: {
        color: 'white',
        cursor: 'pointer',
        fontFamily: AVENIR_NEXT_LT_DEMI,
    },
    icon: {
        display: 'block',
        margin: '0px auto',
    },
}));

const BackToTop = ({ isLatinGrammy = false }: Props) => {
    const classes = useStyles();
    return (
        <div className={classes.root} data-testid="back-to-top">
            <Link
                onClick={() => {
                    customClickEvent({
                        customLinkName: `${
                            isLatinGrammy
                                ? 'latin-grammy-major-landing: back to top'
                                : 'grammy-major-landing: back to top'
                        }`,
                    });
                }}
                className={classes.link}
                offset={-100}
                smooth
                spy
                to="watch"
            >
                <KeyboardArrowUp className={classes.icon} />
                <FormattedMessage
                    id={
                        isLatinGrammy
                            ? 'enLatinGrammyLanding.nav.backToTop'
                            : 'enGrammyLanding.nav.backToTop'
                    }
                    defaultMessage={
                        isLatinGrammy
                            ? 'enLatinGrammyLanding.nav.backToTop'
                            : 'enGrammyLanding.nav.backToTop'
                    }
                />
            </Link>
        </div>
    );
};

export default BackToTop;
