import React, { FC } from 'react';

/* material */
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@material-ui/core';
import { pxToRem } from 'theme/util';
import { customClickEvent } from 'analytics';

type AlertModalProps = {
    bodyText: string | React.ReactNode;
    cancelActionLabel?: string;
    confirmActionLabel: string;
    headerText: string;
    isModalOpen: boolean;
    onCancel?: () => void;
    onConfirm: () => void;
};

const AlertModal: FC<AlertModalProps> = ({
    bodyText,
    cancelActionLabel,
    confirmActionLabel,
    headerText,
    isModalOpen,
    onCancel,
    onConfirm,
}) => {
    return (
        <Dialog
            data-qa="alert-modal"
            data-testid="alert-modal"
            disableBackdropClick
            disableEscapeKeyDown
            open={isModalOpen}
            aria-labelledby="alert-modal-title"
            aria-describedby="alert-modal-description"
        >
            <Box width={{ sm: pxToRem(415) }}>
                <DialogTitle
                    data-qa="alert-modal-title"
                    data-testid="alert-modal-title"
                    id="alert-moda-title"
                >
                    {headerText}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText
                        data-qa="alert-modal-content"
                        data-testid="alert-modal-content"
                        id="alert-modal-description"
                    >
                        {bodyText}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    {cancelActionLabel && (
                        <Button
                            data-qa="alert-modal-cancel"
                            data-testid="alert-modal-cancel"
                            variant="text"
                            size="small"
                            onClick={() => {
                                if (onCancel) onCancel();

                                customClickEvent({
                                    customLinkName: `alert modal: ${cancelActionLabel}`,
                                });
                            }}
                            style={{ textTransform: 'uppercase' }}
                        >
                            {cancelActionLabel}
                        </Button>
                    )}
                    <Button
                        data-qa="alert-modal-confirm"
                        data-testid="alert-modal-confirm"
                        variant="contained"
                        onClick={() => {
                            if (onConfirm) onConfirm();

                            customClickEvent({
                                customLinkName: `alert modal: ${confirmActionLabel}`,
                            });
                        }}
                    >
                        {confirmActionLabel}
                    </Button>
                </DialogActions>
            </Box>
        </Dialog>
    );
};

export default AlertModal;
