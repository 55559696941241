import * as React from 'react';
import { FC } from 'react';
import clsx from 'clsx';

/* material */
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

/* colors */
import colors from 'theme/colors';
import { pollVoteEvent } from 'analytics';

const useStyles = makeStyles((theme) => ({
    square: {
        border: '1px solid #CCC',
        borderRadius: '4px',
        backgroundColor: colors.white,
        boxShadow: '2px 2px 4px 0 rgba(0,0,0,0.1)',
        height: 80,
        padding: 10,
        width: 80,

        '&:hover': {
            backgroundColor: colors.white,
            borderColor: colors.gray60,
            boxShadow: '2px 2px 4px 0 rgba(0,0,0,0.1)',
        },

        '&:active': {
            boxShadow: 'none',
        },

        [theme.breakpoints.up('sm')]: {
            height: 104,
            width: 104,
        },
    },
    selected: {
        boxShadow: 'none',
        borderColor: colors.gray60,
        '&:hover': {
            boxShadow: 'none',
        },
    },
    label: {
        height: '100%',
    },
    disabled: {
        opacity: 0.5,
    },
}));

interface SentimentSquareProps {
    children: JSX.Element;
    label: string;
    onClick: (label: string) => void;
    sentiment: string;
    segment: string;
    analyticComponent: string;
}

const SentimentSquare: FC<SentimentSquareProps> = ({
    children,
    label,
    onClick,
    sentiment,
    segment,
    analyticComponent,
}) => {
    const classes = useStyles();
    const handleClick = () => {
        onClick(label);
        pollVoteEvent({
            pollAnswer: `${analyticComponent}:${label}:${segment}`,
        });
    };
    const isDisabled = sentiment !== '';

    return (
        <Button
            onClick={handleClick}
            classes={{
                label: classes.label,
            }}
            aria-label={label}
            data-qa={label}
            data-selected={sentiment === label}
            disabled={isDisabled}
            className={clsx(classes.square, {
                [classes.selected]: sentiment === label,
                [classes.disabled]: isDisabled && sentiment !== label,
            })}
        >
            {children}
        </Button>
    );
};

export default SentimentSquare;
