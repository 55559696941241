import React, { FC } from 'react';

/* types */
import { ArticleContentFragment } from './article.types';

/* utils */
import { useParseReplace } from 'utils/htmlParser';

type ArticleRichBodyProps = {
    fragment: ArticleContentFragment;
};

const ArticleRichBody: FC<ArticleRichBodyProps> = ({ fragment }) => {
    const { content } = fragment;
    const cont = useParseReplace(content, fragment);
    return <>{cont}</>;
};

export default ArticleRichBody;
