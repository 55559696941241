/* Based on https://github.com/gkaemmer/react-fade-in and https://github.com/belev/react-fade-in/tree/migrate-to-hooks  */
import React, { useEffect, useState, FC } from 'react';

type FadeInProps = {
    children: React.ReactNode;
    className?: string;
    childClassName?: string;
    delay?: number;
    transitionDuration?: number;
};

const FadeIn: FC<FadeInProps> = ({
    children,
    className = '',
    childClassName = '',
    delay = 50,
    transitionDuration = 400,
}) => {
    const [maxIsVisible, setMaxIsVisible] = useState<number>(0);

    useEffect(() => {
        const childrenCount = React.Children.count(children);
        const interval = setInterval(() => {
            setMaxIsVisible((count) => {
                if (count >= childrenCount) clearInterval(interval);

                return count + 1;
            });
        }, delay);

        return () => clearInterval(interval);
    }, [children, delay]);

    return (
        <div className={className}>
            {React.Children.map(children, (child, i) => {
                return (
                    <div
                        className={childClassName}
                        style={{
                            transition: `opacity ${transitionDuration}ms, transform ${transitionDuration}ms`,
                            transform: maxIsVisible
                                ? 'none'
                                : `translateY(${maxIsVisible > i ? 0 : 20}px)`,
                            opacity: maxIsVisible > i ? 1 : 0,
                        }}
                    >
                        {child}
                    </div>
                );
            })}
        </div>
    );
};

export default FadeIn;
