import React, { FC } from 'react';

/* material */
import { makeStyles } from '@material-ui/core';

/* utils */
import { AVENIR_NEXT_LT_REGULAR, AVENIR_NEXT_LT_DEMI } from 'theme/font';
import { pxToRem } from 'theme/util';
import colors from 'theme/colors';
import { getAemImagePath } from 'utils/aem';
import { h4 } from 'theme/font';

const useStyles = makeStyles((theme) => ({
    root: {
        '& div:last-child:after': {
            display: 'none',
        },
    },
    description: {
        fontFamily: AVENIR_NEXT_LT_REGULAR,
        fontSize: pxToRem(18),
        lineHeight: '25px',

        [theme.breakpoints.up('sm')]: {
            fontSize: pxToRem(25),
            lineHeight: '32px',
            marginBottom: 50,
        },
    },
    sectionWrapper: {
        marginBottom: 50,

        '&:after': {
            backgroundColor: colors.cobalt,
            content: '""',
            display: 'block',
            height: 1,
            margin: '50px 30px 0',
            opacity: 0.5,
            width: '100%',
        },

        '& img': {
            width: '100%',
        },
    },
    sectionHeader: {
        color: colors.darkSapphire,
        fontSize: pxToRem(18),
        lineHeight: '25px',

        fontFamily: AVENIR_NEXT_LT_DEMI,
        [theme.breakpoints.up('sm')]: {
            fontSize: pxToRem(25),
            lineHeight: '32px',
            marginBottom: 28,
        },
    },
    glideWrapper: {
        fontFamily: AVENIR_NEXT_LT_REGULAR,

        [theme.breakpoints.up('sm')]: {
            display: 'flex',
            '& > img': {
                flexGrow: 0,
                flexShrink: 0,
                marginRight: 18,
                maxWidth: '58%',
            },
        },
    },
    glidePathContentWrapper: {
        '& > h6': {
            fontSize: pxToRem(16),
            lineHeight: '20px',
            margin: '30px 0 0',

            [theme.breakpoints.up('sm')]: {
                fontSize: pxToRem(20),
                lineHeight: '25px',
                margin: 0,
            },
        },
        '& > ol': {
            fontSize: pxToRem(16),
            lineHeight: '20px',
            marginLeft: 16,
            paddingLeft: 0,

            [theme.breakpoints.up('sm')]: {
                fontSize: pxToRem(18),
                lineHeight: '25px',
                marginLeft: 16,
                maxWidth: 312,
            },
        },
    },
    glidePathDisclosure: {
        fontSize: pxToRem(14),
        lineHeight: '18px',
        marginTop: 30,
    },
    benefitsWrapper: {
        display: 'flex',
        flexFlow: 'column',
        width: '100%',

        [theme.breakpoints.up('sm')]: {
            flexFlow: 'wrap',
            justifyContent: 'space-between',
        },

        '& > div': {
            [theme.breakpoints.up('sm')]: {
                flexGrow: 0,
                flexShrink: 0,
                width: '40%',
            },
        },
    },
    benefit: {
        position: 'relative',
        marginBottom: 50,

        '&:last-child': {
            marginBottom: 0,
        },

        [theme.breakpoints.up('sm')]: {
            marginBottom: 0,
            '&:first-child': {
                marginBottom: 50,
            },
        },

        '& > h6': {
            ...h4,
            fontFamily: AVENIR_NEXT_LT_DEMI,
            marginBottom: 22,
            marginTop: 22,

            '&:before': {
                backgroundColor: colors.raspberry,
                content: '""',
                display: 'block',
                height: 3,
                position: 'absolute',
                width: 30,
                top: 6,
            },
        },
        '& > p': {
            fontSize: pxToRem(16),
            lineHeight: '20px',
            margin: 0,
            [theme.breakpoints.up('sm')]: {
                fontSize: pxToRem(20),
                lineHeight: '25px',
            },
        },
    },
    contributionDisclosure: {
        margin: '30px 0 0',

        [theme.breakpoints.up('sm')]: {
            fontSize: pxToRem(20),
            lineHeight: '25px',
        },
    },
}));

type AftdArticle = {
    benefits: {
        title: string;
        list: [{ copy: string; title: string }];
    };
    contributions: {
        alt: string;
        copy: string;
        disclosure: string;
        image: string;
        title: string;
    };
    description: string;
    glidePath: {
        alt: string;
        copy: string;
        disclosure: string;
        image: string;
        list: [{ copy: string; title: string }];
        title: string;
    };
};

interface ArticleLandingProps {
    data: AftdArticle;
}

const ArticleLanding: FC<ArticleLandingProps> = ({ data }) => {
    const classes = useStyles();
    const { benefits, contributions, description, glidePath } = data;

    const {
        alt: glideImgAlt,
        copy: glidePathCopy,
        disclosure: glidePathDisclosure,
        image: glideImg,
        list: glidePathList,
        title: glidePathTitle,
    } = glidePath;
    const { list: benefitsList, title: benefitsTitle } = benefits;
    const {
        alt: contributionsImageAlt,
        copy: contributionsCopy,
        disclosure: contributionsDisclosure,
        image: contributionsImage,
        title: contributionsTitle,
    } = contributions;
    return (
        <div className={classes.root}>
            <div className={classes.description}>{description}</div>
            <div className={classes.sectionWrapper}>
                <h4 className={classes.sectionHeader}>{glidePathTitle}</h4>
                <div className={classes.glideWrapper}>
                    <img src={getAemImagePath(glideImg)} alt={glideImgAlt} />
                    <div className={classes.glidePathContentWrapper}>
                        <h6>{glidePathCopy}</h6>
                        <ol>
                            {glidePathList.map((item, idx) => (
                                <li key={idx}>{item}</li>
                            ))}
                        </ol>
                    </div>
                </div>
                <p className={classes.glidePathDisclosure}>
                    {glidePathDisclosure}
                </p>
            </div>
            <div className={classes.sectionWrapper}>
                <h4 className={classes.sectionHeader}>{benefitsTitle}</h4>
                <div className={classes.benefitsWrapper}>
                    {benefitsList.map((item, idx) => (
                        <div className={classes.benefit} key={idx}>
                            <h6>{item.title}</h6>
                            <p>{item.copy}</p>
                        </div>
                    ))}
                </div>
            </div>
            <div className={classes.sectionWrapper}>
                <h4 className={classes.sectionHeader}>{contributionsTitle}</h4>
                <p className={classes.description}>{contributionsCopy}</p>
                <img
                    src={getAemImagePath(contributionsImage)}
                    alt={contributionsImageAlt}
                />
                <p className={classes.contributionDisclosure}>
                    {contributionsDisclosure}
                </p>
            </div>
        </div>
    );
};

export default ArticleLanding;
