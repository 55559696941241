import WidgetFlipCard from '../widgetFlashCardGroup/WidgetFlipCard';
import { makeStyles, Theme, useMediaQuery } from '@material-ui/core';
import Carousel from 'components/carousel/Carousel';
import React, { FC, useState } from 'react';
import bubbleBG from 'assets/widgets/flash-card-bubble.png';
import colors from 'theme/colors';
import { customClickEvent } from 'analytics';
// import { parseReplace } from 'utils/htmlParser';

const useStyles = makeStyles((theme) => ({
    root: {
        // marginTop: 75,
        // marginBottom: 25,
        position: 'relative',
    },
    header: {
        color: theme.colors.black,
        textAlign: 'center',
        // font-family: Avenir Next LT Com;
        fontSize: '25px',
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: '28px' /* 112% */,
        marginTop: '50px',
        [theme.breakpoints.down('sm')]: {
            fontSize: '25px',
            lineHeight: '28px',
        },
    },
    subHeader: {
        color: theme.colors.raspberry,
        textAlign: 'center',
        // font-family: Avenir Next LT Com;
        fontSize: '18px',
        fontStyle: 'normal',
        fontWeight: 700,
        lineHeight: '28px' /* 112% */,
        marginTop: '2px',
        [theme.breakpoints.down('sm')]: {
            fontSize: 16,
            lineHeight: '30px' /* 112% */,
            letterSpacing: '0.402px',
        },
    },
    container: {
        display: 'flex',
        justifyContent: 'center',
        gridGap: 30,
    },
    bubbleWrapper: {
        backgroundImage: `url(${bubbleBG})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        position: 'absolute',
        bottom: 310, // -45,
        right: 155, // 145,
        height: 166,
        width: 203,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        [theme.breakpoints.down('xs')]: {
            right: 0,
            bottom: 270,
            zIndex: 1,
        },
        [theme.breakpoints.between(760, 1000)]: {
            bottom: '270px',
            right: '135px',
            zIndex: 1,
        },
    },
    bubbleTextWrapper: {
        width: '78%',
        textAlign: 'center',
        color: colors.white,
        fontSize: '18px',
        fontWeight: 600,
        lineHeight: '24px',
        [theme.breakpoints.down('sm')]: {
            fontSize: 14,
        },
    },
    grid: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        columnGap: 30,
        paddingBottom: 50,
        paddingTop: 30,
        overflow: 'hidden',
        [theme.breakpoints.between(760, 1000)]: {
            paddingTop: '0px',
        },
    },
    mainTitle: {
        color: theme.colors.black,
        // fontFamily: Avenir Next LT Com;
        fontSize: '18px',
        fontStyle: 'normal',
        fontWeight: 700,
        lineHeight: '28px',
    },
    mainContent: {
        color: theme.colors.black,
        // fontFamily: Avenir Next LT Com;
        fontSize: '18px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '28px',
    },
    sourceText: {
        display: 'flex',
        position: 'relative',
        top: '-35px',
        left: '190px',
        color: theme.colors.black,
        '&& a': {
            color: theme.colors.black,
        },
        [theme.breakpoints.down('xs')]: {
            display: 'block',
            left: '0px',
            fontSize: '12px',
        },
        [theme.breakpoints.between(760, 1000)]: {
            display: 'block',
            left: '0px',
        },
    },
    leftArrow: {
        border: `solid ${theme.colors.black}`,
        borderWidth: '0 3px 3px 0',
        [theme.breakpoints.down('xs')]: {
            left: 232,
            top: 220,
        },
        [theme.breakpoints.between(760, 1000)]: {
            left: 215,
            top: 215,
        },
    },
    rightArrow: {
        border: `solid ${theme.colors.black}`,
        borderWidth: '0 3px 3px 0',
        float: 'initial',
        [theme.breakpoints.down('xs')]: {
            right: -507,
            top: -130,
        },
        [theme.breakpoints.between(760, 1000)]: {
            right: -520,
        },
    },
    carouselWrapper: {
        margin: 'auto',
    },
    dotClass: {
        marginTop: 0,
    },
}));

type WidgetCards = {
    bgImage: string;
    bgColor: string;
    question: string;
    answer: string;
    isCardFlipped: boolean;
    key: string;
};

const tempCards = [
    {
        bgImage: 'Not-set',
        question: 'Not Set',
        answer: 'Not Set',
        isCardFlipped: false,
        key: 'card-1',
        bgColor: '#f0f9fe',
    },
    {
        bgImage: 'Not-set',
        question: 'Not Set',
        answer: 'Not Set',
        isCardFlipped: false,
        key: 'card-2',
        bgColor: '#f0f9fe',
    },
    {
        bgImage: 'Not-set',
        question: 'Not Set',
        answer: 'Not Set',
        isCardFlipped: false,
        key: 'card-3',
        bgColor: '#f0f9fe',
    },
];

type WidgetFlashCardGroupProps = {
    title?: string;
    subTitle?: string;
    mobileSubTitle?: string;
    bubbleText?: string;
    cards?: WidgetCards[];
    mainContent1?: string;
    mainContent2?: string;
    mainTitle?: string;
    source?: string;
};

const WidgetFlashCardGroup: FC<WidgetFlashCardGroupProps> = ({
    title = 'Title not set',
    subTitle = 'Sub-title not set',
    mobileSubTitle = 'Sub-title not set',
    bubbleText = 'Bubble text not set',
    cards = tempCards,
    // mainContent1 = 'mainContent1 not set',
    // mainContent2 = 'mainContent2 not set',
    // mainTitle = 'mainTitle not set',
    // source = 'source not set',
}) => {
    const classes = useStyles();
    const isMobile = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down('sm'),
    );
    const [isFlipped, setIsFlipped] = useState(0);
    const handleFlip = (clickedCard: number) => {
        if (isFlipped === clickedCard) {
            setIsFlipped(0);
        } else {
            setIsFlipped(clickedCard);
        }
    };

    const renderFlipcards = () => {
        const cardElements: JSX.Element[] = [];
        cards?.map((card) =>
            cardElements.push(
                <div key={card.key} role="button" tabIndex={0}>
                    <WidgetFlipCard
                        key={card.key}
                        bgColor={card.bgColor}
                        bgImage={card.bgImage}
                        question={card.question}
                        externalFlip
                        answer={card.answer}
                        flip={card.isCardFlipped}
                        toggleOnFlip={(
                            question: string,
                            isFlipped: boolean,
                        ) => {
                            cards.forEach((card) => {
                                card.isCardFlipped = false;
                            });
                            card.isCardFlipped = isFlipped;
                            handleFlip(
                                cards.findIndex(
                                    (elem) => elem.question === question,
                                ) + 1,
                            );
                            customClickEvent({
                                customLinkName: `flipcard-${
                                    isFlipped ? 'expanded' : 'collapsed'
                                }: ${card.question}`,
                            });
                        }}
                        altText={`milk was ${card.answer} a gallon in ${card.question} `}
                    />
                </div>,
            ),
        );
        return cardElements;
    };

    return (
        <div
            className={classes.root}
            data-qa="widget-flash-cards-group"
            //tabIndex={0}
        >
            {/* <div>
                <b className={classes.mainTitle}>{mainTitle}</b>
                <p className={classes.mainContent}>{mainContent1}</p>
                <p className={classes.mainContent}>{mainContent2}</p>
            </div> */}
            <div className={classes.bubbleWrapper}>
                <div className={classes.bubbleTextWrapper}>{bubbleText}</div>
            </div>
            <header className={classes.header}>{title}</header>
            <p className={classes.subHeader}>
                {isMobile ? mobileSubTitle : subTitle}
            </p>
            <div className={classes.grid}>
                {isMobile ? (
                    <div className={classes.carouselWrapper}>
                        <Carousel
                            showArrows
                            leftArrowClassName={classes.leftArrow}
                            rightArrowClassName={classes.rightArrow}
                            dotClassName={classes.dotClass}
                        >
                            {renderFlipcards()}
                        </Carousel>
                    </div>
                ) : (
                    renderFlipcards()
                )}
            </div>
            {/* <span className={classes.sourceText}>{parseReplace(source)}</span> */}
        </div>
    );
};

export default WidgetFlashCardGroup;
