const colors = {
    black: '#000',
    black80: '#333',
    blackLight: '#434343',
    blueGray: '#45788C',
    chardonnay: '#ffc070',
    cobalt: '#005F9E',
    darkSapphire: '#32536e',
    newBlue: '#00294B',
    darkBlue: '#00294e',
    darkSapphire20: '#d7e6f0',
    darkSapphire80: '#99bfd8',
    darkTurquoise: '#007f67',
    darkTurquoise2: '#073B37',
    deepCerise: '#D2272E',
    gray60: '#666666',
    gray65: '#999999',
    gray70: '#b5b9bc',
    gray80: '#ccc',
    gray90: '#ebebeb',
    gray97: '#f9f9fa',
    gray99: '#f9f9f9',
    gray100: '#F7F7F7',
    gray105: '#979797',
    gunmetal: '#263238',
    mint: '#F0F9FA',
    lightningYellow: '#FFA837',
    lightPurple: '#B9B3D0',
    lightGray: '#d8d8d8',
    lightGrayTransparent: '#d8d8d880',
    mediumPurple: '#A19BC5',
    darkPurple: '#412865',
    ocean5: '#f2fafd',
    pacificBlue: '#009CDC',
    paoloGreen: '#008E77',
    greenLight: '#11999E',
    nobelGray: '#979797',
    paper: '#E3E1DA',
    pine: '#007764',
    pine50: '#74B5AA',
    prussianBlue: '#00294B',
    prussianBlue70: '#173C5B',
    softPine: '#3b6f7c',
    raspberry: '#b42573',
    aliceBlue: '#f0f9fd',
    skyBlueLight: '#f0f9fd',
    symphonyBlue: '#364F6B',
    symphonyBlue50: '#9aa7b5',
    turquoise: '#00aea9',
    turquoise5: '#f2fbfa',
    turquoise6: '#f2fafa',
    turquoise7: '#edf9f9',
    turquoise30: '#b2e6e5',
    turquoise50: '#7fd6d4',
    turquoise60: '#71C9CD',
    violetRed: '#FA4B7C',
    violetRed70: '#FB7CA0',
    white: '#fff',
    cyberGrape: '#604A85',
    cyberGrapeDark: '#4F3B71',
    cyberGrapeDarker: '#412865',
    brown: '#E1B569',
    green: '#44726B',
    gry: '#C5C5C5',
    drkGry: '#7A7A7A',
    red: '#D91943',
};

export const sentimentColors = {
    anxious: '#FEAC24',
    anxiousActive: '#955D00',
    confident: '#75D7A1',
    confidentActive: '#008C3F',
    discouraged: '#FF7C7D',
    discouragedActive: '#990001',
    hopeful: '#7EE2DC',
    hopefulActive: '#009087',
    soso: '#FFC900',
    sosoActive: '#9C7B00',
};

export type colorType = typeof colors;
export default colors;
