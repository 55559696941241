import React, { FC, useState } from 'react';
import clsx from 'clsx';

/* material */
import { makeStyles } from '@material-ui/core';
import { AVENIR_NEXT_LT_DEMI, AVENIR_NEXT_LT_REGULAR } from 'theme/font';
import { pxToRem } from 'theme/util';

interface StyleProps {
    isFlipped: boolean;
    disableWrapperScroll: boolean;
    hideFrontTopic: boolean;
}

const useStyles = makeStyles((theme) => ({
    wrapper: {
        cursor: 'pointer',
        backgroundColor: (props: StyleProps) =>
            props.isFlipped ? '#F0F9FD' : theme.colors.white,
        border: `solid 2px rgba(0,95,158,0.5)`,
        borderRadius: 7,
        height: 340,
        overflow: 'hidden',
    },
    avatar: {
        backgroundColor: theme.colors.gray97,
        borderRadius: '50%',
        height: 115,
        marginBottom: 23,
        width: 115,
    },
    content: {
        fontFamily: (props: StyleProps) =>
            props.isFlipped ? AVENIR_NEXT_LT_DEMI : AVENIR_NEXT_LT_REGULAR,
        fontSize: pxToRem(18),
        lineHeight: pxToRem(24),
        padding: 25,
        textAlign: 'center',
        width: '100%',

        '& > p': {
            height: '100%',
            margin: 0,
            maxHeight: 100,
            overflow: 'auto',
        },

        [theme.breakpoints.up('sm')]: {
            fontSize: pxToRem(20),
            lineHeight: pxToRem(26),
        },
    },
    contentWrapper: {
        height: (props: StyleProps) =>
            props.hideFrontTopic ? '100%' : 'calc(340px - 60px)',
        overflowY: (props: StyleProps) =>
            props.isFlipped && !props.disableWrapperScroll
                ? 'scroll'
                : 'inherit',
    },
    topic: {
        alignItems: 'center',
        backgroundColor: (props: StyleProps) =>
            props.isFlipped ? theme.colors.symphonyBlue : theme.colors.gray90,
        color: (props: StyleProps) =>
            props.isFlipped ? theme.colors.white : theme.colors.black,
        display: 'flex',
        justifyContent: 'center',
        height: 60,
        '& > h1': {
            fontFamily: AVENIR_NEXT_LT_DEMI,
            fontSize: pxToRem(16),
            letterSpacing: '1.71px',
        },
    },
}));

interface FlipCardProps {
    avatarImagePath: string;
    quote: string;
    topic: string;
    content: string;
    externalFlip?: boolean;
    flipped?: boolean;
    hideFrontTopic?: boolean;
    disableWrapperScroll?: boolean;
    contentClassName?: string;
    activeClassName?: string;
    topicClassName?: string;
    wrapperClassName?: string;
    avatarClassName?: string;
}

const FlipCard: FC<FlipCardProps> = ({
    avatarImagePath,
    content,
    quote,
    topic,
    externalFlip = false,
    flipped = false,
    hideFrontTopic = false,
    disableWrapperScroll = false,
    contentClassName,
    activeClassName,
    topicClassName,
    wrapperClassName,
    avatarClassName,
}) => {
    let [isFlipped, setIsFlipped] = useState(false);
    if (externalFlip) {
        isFlipped = flipped;
        setIsFlipped = () => {};
    }
    const classes = useStyles({
        isFlipped,
        disableWrapperScroll,
        hideFrontTopic,
    });

    const handleClick = () => {
        setIsFlipped(!isFlipped);
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
        if (e.key === 'Enter') {
            setIsFlipped(!isFlipped);
        }
    };

    return (
        <div
            data-qa="flip-card"
            className={clsx(classes.wrapper, wrapperClassName)}
            onClick={handleClick}
            tabIndex={0}
            role="button"
            onKeyDown={handleKeyDown}
        >
            <div className={classes.contentWrapper}>
                {isFlipped ? (
                    <div
                        data-qa="flip-card-content"
                        className={clsx(classes.content, activeClassName)}
                    >
                        {content}
                    </div>
                ) : (
                    <div className={clsx(classes.content, contentClassName)}>
                        <img
                            data-qa="flip-card-avatar"
                            src={avatarImagePath}
                            alt={topic}
                            className={clsx(classes.avatar, avatarClassName)}
                        />
                        <p data-qa="flip-card-quote">{quote}</p>
                    </div>
                )}
            </div>
            {(!hideFrontTopic || isFlipped) && (
                <div
                    data-qa="flip-card-topic"
                    className={clsx(classes.topic, topicClassName)}
                >
                    <h1>{topic}</h1>
                </div>
            )}
        </div>
    );
};

export default FlipCard;
