import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import SectionWrapper from '../components/SectionWrapper';
import { customClickEvent } from 'analytics/customClickEvent';
import Carousel from 'components/carousel';
import FlipCard from 'components/FlipCard/FlipCard';
import { AVENIR_NEXT_LT_DEMI, AVENIR_NEXT_LT_REGULAR } from 'theme/font';
import { pxToRem } from 'theme/util';
import { Theme, useMediaQuery } from '@material-ui/core';
import colors from 'theme/colors';

interface StyleProps {
    dpRatio: number;
    isMobile?: boolean;
}

const useStyles = makeStyles((theme) => ({
    root: {
        minHeight: (props: StyleProps) =>
            props.dpRatio === 1.5 ? '400px' : 'calc(100vh - 100px)',
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '98%',
            // paddingTop: '100px',
        },
    },
    header: {
        marginBottom: 40,
        [theme.breakpoints.down('sm')]: {
            marginBottom: 0,
        },
    },
    headerText: {
        fontFamily: AVENIR_NEXT_LT_DEMI,
        fontWeight: 600,
        fontSize: pxToRem(25),
        lineHeight: pxToRem(41),
        [theme.breakpoints.up('sm')]: {
            fontSize: pxToRem(45),
            lineHeight: pxToRem(45),
        },
    },
    subHeaderText: {
        fontFamily: AVENIR_NEXT_LT_DEMI,
        fontWeight: 600,
        fontSize: pxToRem(16),
        lineHeight: pxToRem(20),
        padding: '0px 10px',
        [theme.breakpoints.up('sm')]: {
            fontSize: pxToRem(20),
            lineHeight: pxToRem(25),
        },
    },
    ctaText: {
        fontFamily: AVENIR_NEXT_LT_REGULAR,
        fontWeight: 400,
        fontSize: pxToRem(16),
        lineHeight: pxToRem(20),
        margin: 0,
        [theme.breakpoints.up('sm')]: {
            fontSize: pxToRem(20),
            lineHeight: pxToRem(26),
        },
    },
    grid: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        columnGap: 5,
        paddingBottom: 50,
    },
    carouselWrapper: {
        margin: 'auto',
    },
    leftArrow: {
        border: `solid ${theme.colors.white}`,
        borderWidth: '0 3px 3px 0',
        left: -200,
        top: 200,
        [theme.breakpoints.down('xs')]: {
            left: -140,
            top: 140,
        },
    },
    rightArrow: {
        border: `solid ${theme.colors.white}`,
        borderWidth: '0 3px 3px 0',
        float: 'initial',
        right: -200,
        top: -170,
        [theme.breakpoints.down('xs')]: {
            right: -140,
            top: -125,
        },
    },
    card: {
        maxWidth: 340,
        width: '100%',
        margin: 'auto',
        [theme.breakpoints.down('xs')]: {
            maxWidth: 250,
            width: '90%',
        },
    },
    cardFront: {
        backgroundColor: theme.colors.symphonyBlue,
        maxHeight: '100%',
        height: '100%',
        transition: 'color 0.5s ease',
        '& > p': {
            overflow: 'visible',
            fontWeight: 600,
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: pxToRem(16),
            lineHeight: pxToRem(20),
        },
    },
    cardBack: {
        overflow: 'auto',
        color: theme.colors.symphonyBlue,
        fontSize: pxToRem(18),
        lineHeight: pxToRem(22),
        transition: 'color 0.5s ease',
        height: 260,
        minHeight: 260,
        padding: '20px 10px 40px',
        [theme.breakpoints.down('xs')]: {
            height: 180,
            minHeight: 180,
            padding: '20px 10px',
            fontSize: pxToRem(16),
            lineHeight: pxToRem(20),
        },
    },
    cardTopic: {
        height: 80,
        top: -80,
        position: 'relative',
        alignContent: 'center',
        '& > h1': {
            textTransform: 'uppercase',
            fontSize: pxToRem(16),
            paddingTop: 10,
        },
        [theme.breakpoints.down('xs')]: {
            height: 50,
            top: -50,
        },
    },
    cardWrapper: {
        borderColor: theme.colors.symphonyBlue,
        [theme.breakpoints.down('xs')]: {
            maxHeight: 235,
        },
    },
    avatarImg: {
        height: 115,
        marginBottom: 23,
        width: 115,
        [theme.breakpoints.down('xs')]: {
            height: 75,
            marginBottom: 5,
            width: 75,
        },
    },
    dots: {
        marginTop: 0,
    },
}));

interface SectionData {
    header: string;
    subHeader: string;
    cta: string;
    card1: Card;
    card2: Card;
    card3: Card;
}

interface Card {
    quote: string;
    content: string;
    topic: string;
    imagePath: string;
}

interface SectionProps {
    isMobile?: boolean;
    data: SectionData;
}

const Section4: React.FC<SectionProps> = ({ isMobile = false, data }) => {
    const classes = useStyles({ dpRatio: window.devicePixelRatio, isMobile });

    const [isFlipped, setIsFlipped] = useState(0);

    const showCarousel = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down('sm'),
    );

    const handleFlip = (clickedCard: number) => {
        if (isFlipped === clickedCard) {
            setIsFlipped(0);
        } else {
            setIsFlipped(clickedCard);
        }
    };

    const cards = [
        {
            handleFlip: () => {
                handleFlip(1);
                customClickEvent({
                    customLinkName: `Hispanic Landing TD Flip Card: Jocelyn`,
                });
            },
            quote: data.card1.quote,
            content: data.card1.content,
            topic: data.card1.topic,
            avatarImagePath: data.card1.imagePath,
            isFlipped: isFlipped === 1,
            key: 'card-1',
        },
        {
            handleFlip: () => {
                handleFlip(2);
                customClickEvent({
                    customLinkName: `Hispanic Landing TD Flip Card: Omar`,
                });
            },
            quote: data.card2.quote,
            content: data.card2.content,
            topic: data.card2.topic,
            avatarImagePath: data.card2.imagePath,
            isFlipped: isFlipped === 2,
            key: 'card-2',
        },
        {
            handleFlip: () => {
                handleFlip(3);
                customClickEvent({
                    customLinkName: `Hispanic Landing TD Flip Card : Luis`,
                });
            },
            quote: data.card3.quote,
            content: data.card3.content,
            topic: data.card3.topic,
            avatarImagePath: data.card3.imagePath,
            isFlipped: isFlipped === 3,
            key: 'card-3',
        },
    ];

    if (!data) {
        return null;
    }

    const renderFlipcards = () => {
        const cardElements: JSX.Element[] = [];
        cards.map((card) =>
            cardElements.push(
                <div
                    key={card.key}
                    onClick={card.handleFlip}
                    onKeyPress={card.handleFlip}
                    role="button"
                    tabIndex={0}
                    className={classes.card}
                >
                    <FlipCard
                        quote={card.quote}
                        content={card.content}
                        topic={card.topic}
                        hideFrontTopic
                        avatarImagePath={card.avatarImagePath}
                        externalFlip
                        flipped={card.isFlipped}
                        contentClassName={classes.cardFront}
                        activeClassName={classes.cardBack}
                        topicClassName={classes.cardTopic}
                        wrapperClassName={classes.cardWrapper}
                        avatarClassName={classes.avatarImg}
                        disableWrapperScroll
                    />
                </div>,
            ),
        );
        return cardElements;
    };

    return (
        <SectionWrapper sectionId="section_4">
            <div className={classes.root} data-qa="section 4">
                <div className={classes.header}>
                    <h1 className={classes.headerText}>{data.header}</h1>
                    <h2 className={classes.subHeaderText}>{data.subHeader}</h2>
                    <h3 className={classes.ctaText}>{data.cta}</h3>
                </div>
                <div className={classes.grid}>
                    {showCarousel ? (
                        <div className={classes.carouselWrapper}>
                            <Carousel
                                showArrows
                                activeDotColor={colors.lightGray}
                                dotColor={colors.lightGrayTransparent}
                                dotClassName={classes.dots}
                                leftArrowClassName={classes.leftArrow}
                                rightArrowClassName={classes.rightArrow}
                            >
                                {renderFlipcards()}
                            </Carousel>
                        </div>
                    ) : (
                        renderFlipcards()
                    )}
                </div>
            </div>
        </SectionWrapper>
    );
};

export default Section4;
