import { RootState } from 'store';
import { GENERIC } from 'constants/company';

export const showCompanyResourcesLinkSelector = (state: RootState) => {
    const { resources, theme } = state.company;
    return (
        theme?.companyId !== GENERIC &&
        !!resources?.length &&
        theme?.showCompanyResourcesLink
    );
};
