import React, { FC } from 'react';

/* material */
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

/* utils */
import { body2 } from 'theme/font';
import colors from 'theme/colors';
import { pxToRem } from 'theme/util';

const useStyles = makeStyles({
    root: {
        ...body2,
        borderTop: `solid 1px ${colors.gray80}`,
        lineHeight: pxToRem(24),
        overflowWrap: 'break-word',
        paddingTop: pxToRem(10),
        marginBottom: pxToRem(32),
    },
});

const ArticleReferences: FC = ({ children }) => {
    const classes = useStyles();
    return (
        <Typography
            data-qa="article-references"
            className={classes.root}
            variant="body2"
        >
            {children}
        </Typography>
    );
};

export default ArticleReferences;
