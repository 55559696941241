import React from 'react';
import Badge from './Badge';

export const getBadgeTags = (badgeTags: string[]) => {
    return badgeTags.reduce((previous: string[], current: string) => {
        const tagSegments = current.split('/');
        /* 
           my-retirement:badges/new
        */
        if (tagSegments.length === 2) {
            previous.push(tagSegments.pop() as string);
        }

        return previous;
    }, []);
};

export const hasBadgeTag = (badgeTags: string[] = [], tag: string) => {
    const badges = getBadgeTags(badgeTags);
    return badges.includes(tag);
};

export const renderBadge = (badgeTags: string[] = []) => {
    const badges = getBadgeTags(badgeTags);

    if (!badges.length) return null;

    return badges.map((badge, idx) => <Badge key={idx} status={badge} />);
};
