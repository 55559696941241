import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MeetTheArtist from '../components/MeetTheArtist';
import BackToTop from '../components/BackToTop';
import { FormattedMessage, useIntl } from 'react-intl';
import colors from 'theme/colors';
import Carousel from 'components/carousel/Carousel';
import NavigatorCards from '../../../components/navigatorCards/NavigatorCards';
import { CardProps } from 'components/navigatorCards/Card';

const useStyles = makeStyles((theme) => ({
    root: {
        minHeight: 'calc(100vh - 120px)',
        padding: 20,
        [theme.breakpoints.up('md')]: {
            minHeight: 'calc(100vh - 100px)',
            padding: 'unset',
        },
    },
    disclosure: {
        color: colors.white,
        textAlign: 'center',
        padding: '50px 165px 50px 125px',
        [theme.breakpoints.down('md')]: {
            padding: 20,
        },
    },
    leftArrow: {
        border: 'solid white',
        borderWidth: '0 3px 3px 0',
        top: '153px',
        left: '15px',
        [theme.breakpoints.up(800)]: {
            top: '260px',
        },
    },
    rightArrow: {
        border: 'solid white',
        borderWidth: '0 3px 3px 0',
        bottom: '950px',
        [theme.breakpoints.between(376, 391)]: {
            bottom: '910px',
        },
        [theme.breakpoints.between(392, 450)]: {
            bottom: '850px',
        },
        [theme.breakpoints.up(800)]: {
            right: '10px',
            bottom: '700px',
        },
    },
}));

interface MeetTheArtistSectionProps {
    isMobile?: boolean;
    isLatinGrammy?: boolean;
    cards?: CardProps[];
}

const MeetTheArtistSection: React.FC<MeetTheArtistSectionProps> = ({
    isMobile = false,
    isLatinGrammy = false,
    cards = [],
}) => {
    const classes = useStyles();
    const intl = useIntl();
    return isLatinGrammy ? (
        <div id="Meet-the-artist" className={classes.root}>
            <Carousel
                showArrows={isMobile}
                leftArrowClassName={classes.leftArrow}
                rightArrowClassName={classes.rightArrow}
                invertDotColors
            >
                <div>
                    <MeetTheArtist
                        isMobile={isMobile}
                        isLatinGrammy={isLatinGrammy}
                        artistNum={1}
                    />
                </div>
                <div>
                    <MeetTheArtist
                        isMobile={isMobile}
                        isLatinGrammy={isLatinGrammy}
                        artistNum={2}
                    />
                </div>
            </Carousel>
            {cards.length !== 0 && (
                <NavigatorCards
                    cards={cards}
                    note={intl.formatMessage({
                        id: 'enLatinGrammyLanding.navigatorCardNote',
                        defaultMessage:
                            'enLatinGrammyLanding.navigatorCardNote',
                    })}
                    isMobile={isMobile}
                />
            )}
            <BackToTop isLatinGrammy={isLatinGrammy} />
            <div className={classes.disclosure}>
                <FormattedMessage
                    id="enLatinGrammyLanding.grammyDisclosure"
                    defaultMessage="enLatinGrammyLanding.grammyDisclosure"
                />
            </div>
        </div>
    ) : (
        <>
            <div id="Meet-the-artist" className={classes.root}>
                <MeetTheArtist
                    isMobile={isMobile}
                    isLatinGrammy={isLatinGrammy}
                />
                <NavigatorCards
                    cards={cards}
                    note={intl.formatMessage({
                        id: 'enGrammyLanding.navigatorCardNote',
                        defaultMessage: 'enGrammyLanding.navigatorCardNote',
                    })}
                    isMobile={isMobile}
                />
                <BackToTop isLatinGrammy={isLatinGrammy} />
                <div className={classes.disclosure}>
                    <FormattedMessage
                        id="enGrammyLanding.grammyDisclosure"
                        defaultMessage="enGrammyLanding.grammyDisclosure"
                    />
                </div>
            </div>
        </>
    );
};

export default MeetTheArtistSection;
