import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { pxToRem } from 'theme/util';
import SectionWrapper from '../components/SectionWrapper';
import colors from 'theme/colors';
import { AVENIR_NEXT_LT_DEMI } from 'theme/font';
import FadeIn from 'components/FadeIn/FadeIn';
import { parseReplace } from 'utils/htmlParser';
import { scroller } from 'react-scroll';
import ActionCta from '../components/ActionCta';
import { User } from 'reducers';
import {
    multiStepEvent,
    pageViewCompleteEvent,
    pageViewEvent,
} from 'analytics';
import { getPageViewLang } from 'utils';
import { Option, Quiz } from '../models/Quiz';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        overflow: 'auto',
        minHeight: 'calc(100vh - 100px)',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '10px',
        background: colors.symphonyBlue,
        marginBottom: '50px',
        [theme.breakpoints.up('md')]: {
            width: '98%',
        },
    },
    heading: {
        color: colors.white,
        textAlign: 'center',
        fontSize: '25px',
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: '41px',
        //width: '293px',
        [theme.breakpoints.up('md')]: {
            fontSize: '45px',
            lineHeight: '39px',
        },
    },
    mainPara: {
        color: colors.white,
        textAlign: 'center',
        fontSize: pxToRem(18),
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: pxToRem(26),
        width: '335px',
        fontFamily: AVENIR_NEXT_LT_DEMI,
        [theme.breakpoints.up('md')]: {
            fontSize: pxToRem(21),
            lineHeight: pxToRem(26),
            width: '592px',
        },
    },
    button: {
        fontFamily: AVENIR_NEXT_LT_DEMI,
        margin: '0 30px 0 30px',
        width: 120,
        borderColor: theme.colors.white,
        '&:hover': {
            borderColor: theme.colors.white,
        },
        [theme.breakpoints.up('sm')]: {
            margin: '0 40px 0 40px',
        },
    },
    answerButtonWrapper: {
        display: 'flex',
        gridGap: '15px',
        marginBottom: 35,
        marginTop: 10,
        [theme.breakpoints.up('sm')]: {
            marginBottom: 40,
            marginTop: 20,
        },
    },
    ansButtons: {
        backgroundColor: 'transparent',
        color: colors.white,
        boxShadow: 'none',
        fontFamily: AVENIR_NEXT_LT_DEMI,
        margin: '0 30px 0 30px',
        width: 120,
        borderColor: theme.colors.white,
        [theme.breakpoints.up('md')]: {
            margin: '0 40px 0 40px',
            '&:hover': {
                borderColor: theme.colors.white,
            },
        },
    },
    answerContainer: {
        padding: '5px 5px 5px 5px',
        border: '1px white solid',
        borderRadius: 8,
        textAlign: 'center',
        maxWidth: '600px',
        overflow: 'auto',
        [theme.breakpoints.up('sm')]: {
            padding: '30px 15px 30px 15px',
        },
        [theme.breakpoints.down('md')]: {
            maxWidth: '375px',
        },
    },
    answerMessage: {
        fontSize: pxToRem(16),
        lineHeight: pxToRem(34),
        marginBottom: 10,
        fontFamily: AVENIR_NEXT_LT_DEMI,
        [theme.breakpoints.up('sm')]: {
            marginBottom: 20,
            fontSize: pxToRem(25),
            lineHeight: pxToRem(34),
        },
    },
    answerCopy: {
        fontFamily: AVENIR_NEXT_LT_DEMI,
        fontSize: pxToRem(16),
        lineHeight: pxToRem(26),
        marginBottom: 10,
        fontStyle: 'italic',
        height: 200,
        overflow: 'auto',
        touchAction: 'pan-y',
        '& a': {
            color: theme.colors.white,
        },
        [theme.breakpoints.up('sm')]: {
            fontSize: pxToRem(20),
            lineHeight: pxToRem(26),
            marginBottom: 20,
            height: 'unset',
        },
    },
    navButtonWrapper: {
        display: 'flex',
        flexDirection: 'row-reverse',
        justifyContent: 'space-between',
        paddingTop: 10,
        // [theme.breakpoints.up('sm')]: {
        //     padding: '0px 20px',
        // },
    },
}));

enum Stages {
    INTRO,
    DEBT,
    INVEST,
    SPONSOR,
    TIME,
    RETIREMENT,
}

interface SectionProps {
    user: User;
    data: Quiz;
    companyId?: string;
    uid?: string | null;
}

const Section7: React.FC<SectionProps> = ({ user, data, companyId, uid }) => {
    const [currentStage, setCurrentStage] = useState(Stages.INTRO);
    const [selectedOption, setSelectedOption] = useState<Option | null>(null);
    const [selectedAns, setSelectedAns] = useState('');
    const [opposite, setOpposite] = useState('');
    const classes = useStyles();
    const quizToolName = 'Hispanic - Fact or Myth';

    const runAnalytics = (ans?: string) => {
        const ansText = ans
            ? selectedOption?.correctAnswer.toLowerCase() === ans?.toLowerCase()
                ? 'correct'
                : 'wrong'
            : 'next';
        const currentStep = Object.values(Stages).indexOf(Stages[currentStage]);
        const processStepValue =
            currentStage === Stages.INTRO
                ? `Step1: ${quizToolName} – Start`
                : currentStage !== Stages.RETIREMENT
                ? `Step${currentStep + 1}: ${quizToolName} – Question ${
                      selectedOption?.question
                  } - ${ansText}`
                : `Complete: ${quizToolName} - Confirmation`;

        pageViewEvent({
            path: window.location.pathname,
            uid: uid || '',
            personaSegment: user.segment,
            language: getPageViewLang(user.language),
        });
        multiStepEvent({
            eventName: `Self Service ${
                currentStage === Stages.INTRO
                    ? 'Started'
                    : currentStage !== Stages.RETIREMENT
                    ? 'Step'
                    : 'Completed'
            }`,
            processStepLabel: quizToolName,
            processStepValue,
        });
        pageViewCompleteEvent();
    };

    const getButtonTheme = (currentCta = '') => {
        if (currentStage === Stages.INTRO) {
            return 'quiz';
        }
        if (!selectedAns.length) {
            return 'button';
        }
        if (
            selectedAns.length > 0 &&
            selectedOption?.correctAnswer.toLowerCase() ===
                selectedAns.toLowerCase() &&
            currentCta.toLowerCase() !== opposite.toLowerCase()
        ) {
            return 'correct';
        }
        if (
            selectedAns.length > 0 &&
            opposite.toLowerCase() === currentCta.toLowerCase()
        ) {
            return 'opposite';
        }
        if (
            selectedAns.length > 0 &&
            selectedOption?.correctAnswer.toLowerCase() !==
                selectedAns.toLowerCase() &&
            currentCta.toLowerCase() !== opposite.toLowerCase()
        ) {
            return 'wrong';
        }
    };

    const nextHandler = () => {
        switch (currentStage) {
            case Stages.DEBT: {
                setCurrentStage(Stages.INVEST);
                setSelectedOption(data.options[1]);
                setSelectedAns('');
                runAnalytics('');
                break;
            }
            case Stages.INVEST: {
                setCurrentStage(Stages.SPONSOR);
                setSelectedOption(data.options[2]);
                setSelectedAns('');
                runAnalytics('');
                break;
            }
            case Stages.SPONSOR: {
                setCurrentStage(Stages.TIME);
                setSelectedOption(data.options[3]);
                setSelectedAns('');
                runAnalytics('');
                break;
            }
            case Stages.TIME: {
                setCurrentStage(Stages.RETIREMENT);
                setSelectedOption(data.options[4]);
                setSelectedAns('');
                runAnalytics('');
                break;
            }
            case Stages.RETIREMENT: {
                runAnalytics();
                scroller.scrollTo(
                    companyId === 'seiu775srp' ? 'section_9' : 'section_8',
                    { smooth: true },
                );
            }
        }
    };

    const backHandler = () => {
        switch (currentStage) {
            case Stages.INVEST: {
                setCurrentStage(Stages.DEBT);
                setSelectedOption(data.options[0]);
                setSelectedAns('');
                break;
            }
            case Stages.SPONSOR: {
                setCurrentStage(Stages.INVEST);
                setSelectedOption(data.options[1]);
                setSelectedAns('');
                break;
            }
            case Stages.TIME: {
                setCurrentStage(Stages.SPONSOR);
                setSelectedOption(data.options[2]);
                setSelectedAns('');
                break;
            }
            case Stages.RETIREMENT: {
                setCurrentStage(Stages.TIME);
                setSelectedOption(data.options[3]);
                setSelectedAns('');
                break;
            }
        }
    };

    const renderNavigationCtas = () => {
        switch (currentStage) {
            case Stages.DEBT: {
                return (
                    <ActionCta
                        dataQa="next-cta"
                        label={data.nextQuestion}
                        buttonTheme="next"
                        varient="outlined"
                        onClick={(e) => {
                            e.stopPropagation();
                            nextHandler();
                        }}
                    />
                );
            }
            case Stages.INVEST:
            case Stages.SPONSOR:
            case Stages.TIME:
            case Stages.RETIREMENT: {
                return (
                    <>
                        <ActionCta
                            dataQa="next-cta"
                            label={
                                currentStage === Stages.RETIREMENT
                                    ? data.nextSection
                                    : data.nextQuestion
                            }
                            buttonTheme="next"
                            varient="outlined"
                            onClick={(e) => {
                                e.stopPropagation();
                                nextHandler();
                            }}
                        />
                        <ActionCta
                            dataQa="back-cta"
                            label={data.back}
                            buttonTheme="back"
                            varient="outlined"
                            onClick={(e) => {
                                e.stopPropagation();
                                backHandler();
                            }}
                        />
                    </>
                );
            }
        }
    };

    const getAnswerCtas = (ctas: string[]) => {
        return ctas.map((cta) => (
            <ActionCta
                key={`${cta}-ans`}
                dataQa={`${cta}-ans`}
                label={cta}
                varient={selectedAns.length > 0 ? 'contained' : 'outlined'}
                className={
                    currentStage !== Stages.INTRO ? classes.ansButtons : ''
                }
                buttonTheme={getButtonTheme(cta)}
                onClick={(e) => {
                    e.stopPropagation();
                    switch (cta) {
                        case data.startQuiz: {
                            runAnalytics();
                            setCurrentStage(Stages.DEBT);
                            setSelectedOption(data.options[0]);
                            break;
                        }
                        case data.myth: {
                            setSelectedAns(data.myth.toLowerCase());
                            setOpposite(data.truth.toLowerCase());
                            runAnalytics(data.myth.toLowerCase());
                            break;
                        }
                        case data.truth: {
                            setSelectedAns(data.truth.toLowerCase());
                            setOpposite(data.myth.toLowerCase());
                            runAnalytics(data.truth.toLowerCase());
                            break;
                        }
                    }
                }}
            />
        ));
    };

    const renderAnswer = () => {
        if (selectedOption && selectedAns) {
            const ans =
                selectedOption.correctAnswer.toLowerCase() ===
                selectedAns.toLowerCase()
                    ? selectedOption.answers.filter(
                          (ans) =>
                              ans.key.toLowerCase() ===
                              selectedOption.correctAnswer.toLowerCase(),
                      )[0]
                    : selectedOption.answers.filter(
                          (ans) =>
                              ans.key.toLowerCase() !==
                              selectedOption.correctAnswer.toLowerCase(),
                      )[0];
            return (
                <div
                    className={classes.answerContainer}
                    data-qa="ans-container"
                >
                    <FadeIn transitionDuration={400}>
                        <div className={classes.answerMessage}>{ans.title}</div>
                    </FadeIn>
                    <FadeIn transitionDuration={1000}>
                        <div className={classes.answerCopy}>
                            {parseReplace(ans.description)}
                        </div>
                    </FadeIn>
                    <div className={classes.navButtonWrapper}>
                        {renderNavigationCtas()}
                    </div>
                </div>
            );
        }
    };

    const getQuizContent = () => {
        switch (currentStage) {
            case Stages.INTRO: {
                return (
                    <>
                        <h2
                            className={classes.heading}
                            data-qa="start-quiz-intro"
                        >
                            {data.navTitle}
                        </h2>
                        <p className={classes.mainPara}>{data.intro}</p>
                        {getAnswerCtas([data.startQuiz])}
                    </>
                );
            }
            case Stages.DEBT:
            case Stages.INVEST:
            case Stages.SPONSOR:
            case Stages.TIME:
            case Stages.RETIREMENT: {
                return (
                    <>
                        <FadeIn transitionDuration={400}>
                            <h2 className={classes.mainPara}>
                                {selectedOption?.question}
                            </h2>
                        </FadeIn>

                        <div className={classes.answerButtonWrapper}>
                            {getAnswerCtas([data.myth, data.truth])}
                        </div>
                        {renderAnswer()}
                    </>
                );
            }
        }
    };

    if (!data) {
        return null;
    }

    return (
        <SectionWrapper sectionId="section_7">
            <div className={classes.root} data-qa="section 7">
                {getQuizContent()}
            </div>
        </SectionWrapper>
    );
};

export default Section7;
