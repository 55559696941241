import React, { FC, useState } from 'react';
import FadeIn from '../../components/FadeIn/FadeIn';

import { FormattedMessage } from 'react-intl';

/* material */
import { makeStyles } from '@material-ui/core';
import { Button } from '@material-ui/core';
import { scroller } from 'react-scroll';
import {
    AVENIR_NEXT_LT_DEMI,
    AVENIR_NEXT_LT_REGULAR,
    AVENIR_NEXT_LT_BOLD,
} from 'theme/font';
import { pxToRem } from 'theme/util';
import colors from 'theme/colors';
import { parseReplace } from 'utils/htmlParser';
import { customClickEvent } from 'analytics';

export interface MythQuizProps {
    data: {
        config: {
            answerBg: string;
            title: string;
            description: string;
            content: string;
            startImage: string;
            endImage: string;
            quizColors: {
                correct: string;
                wrong: string;
                depends: string;
            };
            ctas: {
                start: string;
                next: string;
                end: string;
            };
            endTitle: string;
            endContent: string;
            endButtonLabel: string;
            endButtonURL: string;
        };
        options: [MythOption];
    };
    companyId: string;
}

export interface MythOption {
    myth: string;
    answers: {
        true: string;
        false: string;
        depends: string;
    };
    content: string;
    correctAnswer: string;
    image: string;
}

const useStyles = makeStyles((theme) => ({
    root: {
        textAlign: 'center',
        width: '100%',
        margin: '0 auto 40px auto',
        [theme.breakpoints.up('sm')]: {
            width: 500,
        },
    },
    title: {
        fontSize: pxToRem(25),
        lineHeight: pxToRem(41),
        marginBottom: 30,
        color: theme.colors.darkSapphire,
        fontFamily: AVENIR_NEXT_LT_DEMI,
    },
    content: {
        color: theme.colors.black,
        marginBottom: 50,
        fontFamily: AVENIR_NEXT_LT_REGULAR,
        fontSize: pxToRem(18),
        lineHeight: pxToRem(26),
    },
    image: {
        maxWidth: 400,
        marginTop: 30,
    },
    stepperBackWrapper: {
        marginBottom: 30,
    },
    stepperWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    progressBarWrapper: {
        backgroundColor: '#F2F2F2',
        borderRadius: 5.5,
    },
    progressBar: {
        backgroundColor: theme.colors.turquoise60,
        height: 11,
        borderRadius: 5.5,
    },
    stepperLabel: {
        fontFamily: AVENIR_NEXT_LT_REGULAR,
        fontSize: pxToRem(14),
        lineHeight: pxToRem(35),
    },
    answerContainer: {
        boxShadow: '0px 0px 15px 1px rgba(0,0,0,0.31)',
        padding: '40px 40px 175px 40px',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'bottom',
        backgroundSize: 'contain',
    },
    answerCopy: {
        fontFamily: AVENIR_NEXT_LT_REGULAR,
        fontSize: pxToRem(16),
        lineHeight: pxToRem(26),
        marginBottom: 20,
    },
    answerMessage: {
        fontSize: pxToRem(25),
        lineHeight: pxToRem(34),
        marginBottom: 40,
        fontFamily: AVENIR_NEXT_LT_BOLD,
    },
    myth: {
        color: theme.colors.darkSapphire,
        fontFamily: AVENIR_NEXT_LT_REGULAR,
        fontSize: pxToRem(22),
        lineHeight: pxToRem(35),
    },
    buttonWrapper: {
        marginBottom: 40,
    },
    button: {
        margin: '0 10px 0 10px',
        width: 55,
    },
    backButton: {
        fontFamily: AVENIR_NEXT_LT_BOLD,
        fontSize: pxToRem(12),
        color: theme.colors.softPine,
    },
    endContainer: {
        boxShadow: '0px 0px 15px 1px rgba(0,0,0,0.31)',
        padding: 40,
    },
}));

const MythQuiz: FC<MythQuizProps> = ({ companyId, data }) => {
    const {
        title,
        content,
        ctas,
        quizColors,
        startImage,
        endImage,
        answerBg,
        endTitle,
        endContent,
        endButtonLabel,
        endButtonURL,
    } = data.config;
    const id = window.btoa(title);
    const { options } = data;
    const [isStart, setIsStart] = useState<boolean>(true);
    const [step, setStep] = useState<number>(0);
    const [showImage, setShowImage] = useState<boolean>(true);
    const [showAnswer, setShowAnswer] = useState<boolean>(false);
    const [answerColor, setAnswerColor] = useState<string>(colors.white);
    const [answerMessage, setAnswerMessage] = useState<string>('');
    const [trueButtonStyle, setTrueButtonStyle] = useState({
        backgroundColor: colors.white,
        color: colors.paoloGreen,
        border: '',
    });
    const [falseButtonStyle, setFalseButtonStyle] = useState({
        backgroundColor: colors.white,
        color: colors.paoloGreen,
        border: '',
    });

    const classes = useStyles();
    const startHandler = () => {
        scroller.scrollTo(`quiz-wrapper-${id}`, {
            duration: 1000,
            delay: 10,
            offset: -125,
            smooth: 'easeInOutQuint',
        });
        setIsStart(false);
    };
    const resetButtonColors = () => {
        setTrueButtonStyle({
            backgroundColor: colors.white,
            color: colors.paoloGreen,
            border: `2px solid ${colors.paoloGreen}`,
        });
        setFalseButtonStyle({
            backgroundColor: colors.white,
            color: colors.paoloGreen,
            border: `2px solid ${colors.paoloGreen}`,
        });
    };

    const renderStepper = () => {
        if (isStart || step > options.length - 1) {
            return;
        }
        const stepperPercent = (100 / options.length) * (step + 1);
        return (
            <div id={`top-${id}`} className={classes.stepperBackWrapper}>
                <div className={classes.stepperWrapper}>
                    <div className={classes.stepperLabel}>
                        Question {step + 1}/{options.length}
                    </div>
                    {step !== 0 && (
                        <Button
                            className={classes.backButton}
                            onClick={() => {
                                prevHandler();
                            }}
                        >
                            &lt; Back
                        </Button>
                    )}
                </div>
                <div className={classes.progressBarWrapper}>
                    <div
                        className={classes.progressBar}
                        style={{
                            width: `${stepperPercent}%`,
                        }}
                    ></div>
                </div>
            </div>
        );
    };

    const answerHandler = (value: boolean, option: any) => {
        resetButtonColors();
        if (option) {
            scroller.scrollTo(`myth-${id}`, {
                duration: 1000,
                delay: 10,
                offset: 40,
                smooth: 'easeInOutQuint',
            });
            setShowAnswer(true);
            setShowImage(false);
            if (option.correctAnswer === 'depends') {
                setTrueButtonStyle({
                    backgroundColor: quizColors.depends,
                    color: colors.white,
                    border: `2px solid ${quizColors.depends}`,
                });
                setFalseButtonStyle({
                    backgroundColor: quizColors.depends,
                    color: colors.white,
                    border: `2px solid ${quizColors.depends}`,
                });

                setAnswerColor(quizColors.depends);
                setAnswerMessage(option.answers.depends);
                return;
            }
            if (value.toString() === option.correctAnswer.toString()) {
                if (value === true) {
                    setTrueButtonStyle({
                        backgroundColor: quizColors.correct,
                        color: colors.white,
                        border: `2px solid ${quizColors.correct}`,
                    });
                    setFalseButtonStyle({
                        backgroundColor: colors.white,
                        color: colors.gray80,
                        border: `2px solid ${colors.gray80}`,
                    });
                } else {
                    setFalseButtonStyle({
                        backgroundColor: quizColors.correct,
                        color: colors.white,
                        border: `2px solid ${quizColors.correct}`,
                    });
                    setTrueButtonStyle({
                        backgroundColor: colors.white,
                        color: colors.gray80,
                        border: `2px solid ${colors.gray80}`,
                    });
                }
                setAnswerColor(quizColors.correct);
                setAnswerMessage(
                    option.answers[option.correctAnswer.toString()],
                );
            } else {
                if (value === true) {
                    setTrueButtonStyle({
                        backgroundColor: quizColors.wrong,
                        color: colors.white,
                        border: `2px solid ${quizColors.wrong}`,
                    });
                    setFalseButtonStyle({
                        backgroundColor: colors.white,
                        color: colors.gray80,
                        border: `2px solid ${colors.gray80}`,
                    });
                } else {
                    setFalseButtonStyle({
                        backgroundColor: quizColors.wrong,
                        color: colors.white,
                        border: `2px solid ${quizColors.wrong}`,
                    });
                    setTrueButtonStyle({
                        backgroundColor: colors.white,
                        color: colors.gray80,
                        border: `2px solid ${colors.gray80}`,
                    });
                }
                setAnswerColor(quizColors.wrong);
                setAnswerMessage(option.answers[value.toString()]);
            }
        }
    };

    const nextHandler = () => {
        resetButtonColors();
        setShowAnswer(false);
        setShowImage(true);
        setStep(step + 1);
        scroller.scrollTo(`top-${id}`, {
            duration: 1000,
            delay: 10,
            offset: -125,
            smooth: 'easeInOutQuint',
        });
    };
    const prevHandler = () => {
        resetButtonColors();
        setStep(step - 1);
        setShowAnswer(false);
        setShowImage(true);
    };

    const renderAnswer = () => {
        const option = options[step];
        if (showAnswer) {
            return (
                <div
                    className={classes.answerContainer}
                    style={{ backgroundImage: `url(${answerBg})` }}
                >
                    <FadeIn transitionDuration={400}>
                        <div
                            className={classes.answerMessage}
                            style={{ color: answerColor }}
                        >
                            {answerMessage}
                        </div>
                    </FadeIn>
                    <FadeIn transitionDuration={1000}>
                        <div className={classes.answerCopy}>
                            {parseReplace(option.content)}
                        </div>
                    </FadeIn>
                    {showAnswer && step < options.length - 1 && (
                        <Button variant="outlined" onClick={nextHandler}>
                            {ctas.next}
                        </Button>
                    )}
                    {showAnswer && step === options.length - 1 && (
                        <Button variant="outlined" onClick={nextHandler}>
                            {ctas.end}
                        </Button>
                    )}
                </div>
            );
        }
        return null;
    };

    const renderStep = () => {
        if (isStart) {
            return (
                <div>
                    <h2 className={classes.title}>{title}</h2>
                    <div className={classes.content}>
                        {parseReplace(content)}
                    </div>
                    <Button onClick={startHandler} variant="outlined">
                        {ctas.start}
                    </Button>
                    <img
                        className={classes.image}
                        src={startImage}
                        alt={title}
                    />
                </div>
            );
        }
        if (step !== options.length) {
            return (
                <div>
                    <h2 className={classes.title}>{title}</h2>
                    <FadeIn transitionDuration={400}>
                        <h2 id={`myth-${id}`} className={classes.myth}>
                            {parseReplace(options[step].myth)}
                        </h2>
                    </FadeIn>

                    <div className={classes.buttonWrapper}>
                        <Button
                            style={trueButtonStyle}
                            className={classes.button}
                            variant="outlined"
                            onClick={() => {
                                answerHandler(true, options[step]);
                            }}
                        >
                            <FormattedMessage id="true" />
                        </Button>
                        <Button
                            style={falseButtonStyle}
                            className={classes.button}
                            variant="outlined"
                            onClick={() => {
                                answerHandler(false, options[step]);
                            }}
                        >
                            <FormattedMessage id="false" />
                        </Button>
                        {showImage && (
                            <img
                                className={classes.image}
                                src={options[step].image}
                                alt={title}
                            />
                        )}
                    </div>

                    {renderAnswer()}
                </div>
            );
        } else {
            return (
                <div className={classes.endContainer}>
                    <h2 className={classes.title}>{endTitle}</h2>
                    <div className={classes.answerCopy}>
                        {parseReplace(endContent)}
                    </div>
                    <Button
                        variant="outlined"
                        onClick={() => {
                            customClickEvent({
                                customLinkName: `${title} - quiz: ${endButtonLabel}`,
                            });
                            window.location.href = `/${companyId}${endButtonURL}`;
                        }}
                    >
                        {endButtonLabel}
                    </Button>
                    <img className={classes.image} src={endImage} alt={title} />
                </div>
            );
        }
    };

    return (
        <div id={`quiz-wrapper-${id}`} className={classes.root}>
            {renderStepper()}
            {renderStep()}
        </div>
    );
};

export default MythQuiz;
