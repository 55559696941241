import React, { FC } from 'react';

import { AccordionProps } from '@material-ui/core/Accordion';
import { AccordionSummaryProps } from '@material-ui/core/AccordionSummary';
import { AccordionDetailsProps } from '@material-ui/core/AccordionDetails';

import {
    Accordion as MuiAccordion,
    AccordionSummary as MuiAccordionSummary,
    AccordionDetails as MuiAccordionDetails,
} from '@material-ui/core';

import { withStyles } from '@material-ui/core/styles';

/* utils */
import { AVENIR_NEXT_LT_DEMI } from 'theme/font';
import colors from 'theme/colors';

const StyledAccordion = withStyles(() => ({
    root: {
        backgroundColor: 'transparent',
        boxShadow: 'none',
        '&$expanded': {
            margin: 0,
        },

        '&:before': {
            background: 'none',
        },
        '& .MuiButtonBase-root': {
            outline: '0',
        },
    },
    expanded: {},
}))(MuiAccordion);

const StyledAccordionSummary = withStyles((theme) => ({
    root: {
        minHeight: 'auto',
        padding: '19px 0 12px',
        '&$expanded': {
            minHeight: 'auto',
        },

        [theme.breakpoints.up('sm')]: {
            padding: '26px 0 19px',
        },
    },
    content: {
        alignItems: 'center',
        justifyContent: 'space-between',
        margin: 0,
        '&$expanded': {
            margin: 0,
        },
    },
    expanded: {},
}))(MuiAccordionSummary);

const StyledAccordionDetails = withStyles((theme) => ({
    root: {
        flexFlow: 'column',
        padding: '0 0 19px 0',

        [theme.breakpoints.up('sm')]: {
            padding: '0 0 26px 0',
        },

        '& a': {
            color: colors.darkTurquoise,
            fontFamily: AVENIR_NEXT_LT_DEMI,
        },
    },
}))(MuiAccordionDetails);

export const Accordion: FC<AccordionProps> = (props) => {
    return <StyledAccordion data-testid={'accordionContainer'} {...props} />;
};

export const AccordionSummary: FC<AccordionSummaryProps> = (props) => {
    return (
        <StyledAccordionSummary data-testid={'accordionSummary'} {...props} />
    );
};

export const AccordionDetails: FC<AccordionDetailsProps> = (props) => {
    return (
        <StyledAccordionDetails data-testid={'accordionDetails'} {...props} />
    );
};
