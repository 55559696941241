import { makeStyles } from '@material-ui/core';
import { customClickEvent } from 'analytics';
import React, { FC, useState } from 'react';
import { assignStringValue } from 'utils/assignStringValue';
import Card, { CardSideProps } from './Card';
export interface StyledProps {
    isFlipped: boolean;
    flipDirection: 'horizontal' | 'vertical';
    frontRotateX: string;
    frontRotateY: string;
    backRotateX: string;
    backRotateY: string;
}
const useStyles = makeStyles((theme) => ({
    cardContainer: {
        display: 'flex',
        justifyContent: 'center',
    },
    flipCardContainer: {
        cursor: 'pointer',
        height: '506px',
        width: '900px',
        marginBottom: '30px',
        perspective: '1000px',
        zIndex: 'auto',
        [theme.breakpoints.down('sm')]: {
            height: '302px',
            width: '335px',
            marginBottom: '15px',
        },
    },
    flipper: {
        height: '100%',
        position: 'relative',
        width: '100%',
    },
    front: {
        WebkitBackfaceVisibility: 'hidden',
        backfaceVisibility: 'hidden',
        height: '100%',
        left: '0',
        position: ({ isFlipped }: StyledProps) =>
            isFlipped ? 'absolute' : 'relative',
        top: '0',
        transform: ({
            flipDirection,
            frontRotateX,
            frontRotateY,
        }: StyledProps) =>
            flipDirection === 'horizontal' ? frontRotateY : frontRotateX,
        transformStyle: 'preserve-3d',
        transition: `0.6s`,
        width: '100%',
        zIndex: 2,
    },
    back: {
        WebkitBackfaceVisibility: 'hidden',
        backfaceVisibility: 'hidden',
        height: '100%',
        left: '0',
        position: ({ isFlipped }: StyledProps) =>
            isFlipped ? 'relative' : 'absolute',
        top: '0',
        transform: ({ flipDirection, isFlipped }: StyledProps) =>
            flipDirection === 'horizontal'
                ? `rotateY(${isFlipped ? 0 : -180}deg)`
                : `rotateX(${isFlipped ? 0 : -180}deg)`,
        transformStyle: 'preserve-3d',
        transition: `0.6s`,
        width: '100%',
    },
}));

export type AuthorableFlipCardProps = {
    frontOfCard: CardSideProps;
    backOfCard: CardSideProps;
};

const AuthorableFlipCard: FC<AuthorableFlipCardProps> = ({
    frontOfCard,
    backOfCard,
}) => {
    const [isFlipped, setIsFlipped] = useState(false);
    const [flipKey, setFlipKey] = useState(0);

    const handleFlip = () => {
        setIsFlipped(!isFlipped);
        setFlipKey(flipKey + 1);
        customClickEvent({
            customLinkName: `AuthorableFlipCard_${!isFlipped ? assignStringValue(frontOfCard.title, 'front', true) : assignStringValue(frontOfCard.title, 'back', true)}`,
        });
    };

    const handleKeyDown = (event: React.KeyboardEvent) => {
        if (event.key === 'Enter') {
            handleFlip();
        }
    };
    const frontRotateY = `rotateY(${isFlipped ? 180 : 0}deg)`;
    const backRotateY = `rotateY(${isFlipped ? 0 : -180}deg)`;
    const frontRotateX = `rotateX(${isFlipped ? 180 : 0}deg)`;
    const backRotateX = `rotateX(${isFlipped ? 0 : -180}deg)`;
    const classes = useStyles({
        isFlipped,
        flipDirection: 'horizontal',
        frontRotateX,
        frontRotateY,
        backRotateX,
        backRotateY,
    });
    return (
        <div className={classes.cardContainer}>
            <div
                data-qa={isFlipped ? 'flipped' : 'not-flipped'}
                className={classes.flipCardContainer}
                role="button"
                onClick={handleFlip}
                onKeyDown={handleKeyDown}
                tabIndex={0}
            >
                <div className={classes.flipper}>
                    <div className={classes.front}>
                        <Card
                            key={flipKey}
                            isFrontSide={true}
                            cardSideProps={frontOfCard}
                        />
                    </div>
                    <div className={classes.back}>
                        <Card
                            key={flipKey}
                            isFrontSide={false}
                            cardSideProps={backOfCard}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AuthorableFlipCard;
