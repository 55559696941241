const calculator = {
    fetchRetirementCalculations: (calculatorValues: string) =>
        fetch(`/po/calculator/retirement/goal?${calculatorValues}`, {
            method: 'GET',
        }),
    fetchCalculatorConstants: () =>
        fetch('/po/calculator/retirement/constants', { method: 'GET' }),
};

export default calculator;
