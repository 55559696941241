import { LoaderAsyncActionTypes } from 'actions/loader';
import { LOADER_ASYNC_HIDE, LOADER_ASYNC_SHOW } from 'constants/loader';

type LoaderAsync = {
    isVisible: boolean;
};

export const initialLoaderAsyncState: LoaderAsync = {
    isVisible: false,
};

export const loaderAsyncReducer = (
    state = initialLoaderAsyncState,
    action: LoaderAsyncActionTypes,
): LoaderAsync => {
    switch (action.type) {
        case LOADER_ASYNC_HIDE:
            return {
                isVisible: false,
            };
        case LOADER_ASYNC_SHOW:
            return {
                isVisible: true,
            };
        default:
            return state;
    }
};
