import React, { useState } from 'react';
import clsx from 'clsx';
import Sherry from 'assets/Sherry.svg';
import Omar from 'assets/Omar.svg';
import PopOver from 'components/powerOfTime/PopOver';
import { AVENIR_NEXT_LT_DEMI, AVENIR_NEXT_LT_REGULAR } from 'theme/font';
/* material */
import { makeStyles } from '@material-ui/core/styles';
import { pxToRem } from 'theme/util';
import { FormattedMessage, useIntl } from 'react-intl';

import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';

const useStyles = makeStyles((theme) => ({
    wrapper: {
        padding: 15,
        fontFamily: AVENIR_NEXT_LT_REGULAR,
        marginBottom: 170,
    },
    headerWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 30,
    },
    powerOfTimeLabel: {
        fontSize: pxToRem(12),
        fontFamily: AVENIR_NEXT_LT_DEMI,
        [theme.breakpoints.up('sm')]: {
            fontSize: pxToRem(18),
        },
    },
    averageAgeLabel: {
        fontSize: pxToRem(12),
        [theme.breakpoints.up('sm')]: {
            fontSize: pxToRem(20),
        },
    },
    bold: {
        fontWeight: 'bold',
    },
    chartWrapper: {
        height: 332,
        display: 'flex',
    },
    leftColumn: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '100%',
    },
    rightColumn: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        borderBottom: `2px ${theme.colors.gray65} solid`,
        width: '100%',
    },
    barWidth: {
        width: 175,
        transition: 'height 1250ms',
        [theme.breakpoints.up('sm')]: {
            width: 200,
        },
    },
    firstBalanceWrapper: {
        backgroundColor: '#55697f',
    },
    firstTotalWrapper: {
        backgroundColor: theme.colors.newBlue,
    },
    secondBalanceWrapper: {
        backgroundColor: '#77ada0',
    },
    secondTotalWrapper: {
        backgroundColor: '#008E77',
    },
    barWrapper: {
        display: 'flex',
        justifyContent: 'flex-end',
        flexDirection: 'column',
        height: '100%',
    },
    cellContent: {
        marginTop: 10,
        color: theme.colors.white,
        textAlign: 'center',
        width: '100%',
        fontSize: pxToRem(12),
        '& div': {
            fontFamily: AVENIR_NEXT_LT_DEMI,
        },
        [theme.breakpoints.up('sm')]: {
            fontSize: pxToRem(18),
        },
    },
    avatarWrapper: {
        marginBottom: -155,
        height: 145,
        marginTop: 10,
        textAlign: 'center',
    },
    nameLabel: {
        fontSize: pxToRem(16),
        color: theme.colors.black80,
        fontFamily: AVENIR_NEXT_LT_DEMI,
    },
    startedLabel: {
        fontSize: pxToRem(16),
    },
}));

const StartingLate = () => {
    const classes = useStyles();
    const intl = useIntl();
    const [isCompVisible, setIsCompVisible] = useState<boolean>(false);
    return (
        <VisibilitySensor
            onChange={(isVisible) => {
                setIsCompVisible(isVisible ? true : false);
            }}
        >
            <div
                className={classes.wrapper}
                role="presentation"
                // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
                tabIndex={0}
                aria-label={intl.formatMessage({
                    id: 'startingLate.screenReaderText',
                })}
            >
                <div className={classes.headerWrapper}>
                    <div className={classes.powerOfTimeLabel}>
                        <FormattedMessage id="startingLate.title" />
                    </div>
                    <div className={classes.averageAgeLabel}>
                        <FormattedMessage id="powerOfTime.averageAgeStart" />
                        <span className={classes.bold}>65</span>{' '}
                        <FormattedMessage id="powerOfTime.averageAgeEnd" />
                    </div>
                </div>
                <div className={classes.chartWrapper}>
                    <div className={classes.leftColumn}>
                        <div>$500,000</div>
                        <div>$400,000</div>
                        <div>$300,000</div>
                        <div>$200,000</div>
                        <div>$100,000</div>
                        <div>$0</div>
                    </div>
                    <div className={classes.rightColumn}>
                        <div></div>
                        <div>
                            <div className={classes.barWrapper}>
                                <div
                                    style={
                                        isCompVisible
                                            ? { height: 104 }
                                            : { height: 0 }
                                    }
                                    className={clsx(
                                        classes.firstBalanceWrapper,
                                        classes.barWidth,
                                    )}
                                >
                                    <div className={classes.cellContent}>
                                        <div>
                                            $
                                            <CountUp
                                                decimals={3}
                                                decimal=","
                                                end={252.061}
                                                redraw={
                                                    isCompVisible ? true : false
                                                }
                                                duration={1}
                                            />
                                        </div>
                                        <FormattedMessage id="powerOfTime.balanceAtRetirement" />
                                    </div>
                                </div>
                                <div
                                    style={
                                        isCompVisible
                                            ? { height: 60 }
                                            : { height: 0 }
                                    }
                                    className={clsx(
                                        classes.firstTotalWrapper,
                                        classes.barWidth,
                                    )}
                                >
                                    <div className={classes.cellContent}>
                                        <div>
                                            $
                                            <CountUp
                                                decimals={3}
                                                decimal=","
                                                end={90.481}
                                                redraw={
                                                    isCompVisible ? true : false
                                                }
                                                duration={1}
                                            />
                                        </div>
                                        <FormattedMessage id="powerOfTime.totalContribution" />
                                    </div>
                                </div>
                                <div
                                    className={clsx(
                                        classes.avatarWrapper,
                                        classes.barWidth,
                                    )}
                                >
                                    <img src={Sherry} alt="Sherry" />
                                    <div className={classes.nameLabel}>
                                        Sherry
                                        <PopOver
                                            text={intl.formatMessage({
                                                id: 'startingLate.sherryContributed',
                                            })}
                                            color="#000000"
                                        />
                                    </div>
                                    <div className={classes.startedLabel}>
                                        <FormattedMessage id="powerOfTime.started25" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className={classes.barWrapper}>
                                <div
                                    style={
                                        isCompVisible
                                            ? { height: 111 }
                                            : { height: 0 }
                                    }
                                    className={clsx(
                                        classes.secondBalanceWrapper,
                                        classes.barWidth,
                                    )}
                                >
                                    <div className={classes.cellContent}>
                                        <div>
                                            $
                                            <CountUp
                                                decimals={3}
                                                decimal=","
                                                end={329.853}
                                                redraw={
                                                    isCompVisible ? true : false
                                                }
                                                duration={1}
                                            />
                                        </div>
                                        <FormattedMessage id="powerOfTime.balanceAtRetirement" />
                                    </div>
                                </div>
                                <div
                                    style={
                                        isCompVisible
                                            ? { height: 104 }
                                            : { height: 0 }
                                    }
                                    className={clsx(
                                        classes.secondTotalWrapper,
                                        classes.barWidth,
                                    )}
                                >
                                    <div className={classes.cellContent}>
                                        <div>
                                            $
                                            <CountUp
                                                decimals={3}
                                                decimal=","
                                                end={153.449}
                                                redraw={
                                                    isCompVisible ? true : false
                                                }
                                                duration={1}
                                            />
                                        </div>
                                        <FormattedMessage id="powerOfTime.totalContribution" />
                                    </div>
                                </div>
                                <div className={classes.avatarWrapper}>
                                    <img src={Omar} alt="Omar" />
                                    <div className={classes.nameLabel}>
                                        Omar
                                        <PopOver
                                            text={intl.formatMessage({
                                                id: 'startingLate.omarContributed',
                                            })}
                                            color="#000000"
                                        />
                                    </div>
                                    <div className={classes.startedLabel}>
                                        <FormattedMessage id="powerOfTime.started35" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div></div>
                    </div>
                </div>
            </div>
        </VisibilitySensor>
    );
};

export default StartingLate;
