import { getGlobalConfig } from 'utils/config';

export const aemBaseURL = getGlobalConfig().AEM_BASE_URL;
export const AEM_CONTENT_PATH = '/content/dam/my-retirement/content/';

export const TOPICS_TAG = 'topics';
export const COMPANIES_TAG = 'companies';
export const SEGMENTS_TAG = 'segments';
export const CONTENT_ZONE_TAG = 'contentZone';
export const EXCLUSION_TAG = 'exclusion';
export const INFLUENCER_TAG = 'influencer';

export const TYPE_ARTICLE_CONTENT_FRAGMENT = 'article';
export const TYPE_VIDEO_CONTENT_FRAGMENT = 'video';
export const TYPE_INFOGRAPHIC_CONTENT_FRAGMENT = 'infographic';
export const TYPE_RICH_ARTICLE_FRAGMENT = 'rich';
