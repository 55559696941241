import { SET_ANALYTIC_PAGE_VIEW_READY } from 'constants/analytics';

export type SET_ANALYTIC_PAGE_VIEW_READY = typeof SET_ANALYTIC_PAGE_VIEW_READY;

/* types */
export type SetAnalyticPageViewReadyAction = {
    type: SET_ANALYTIC_PAGE_VIEW_READY;
    isReady: boolean;
};

/* action creator */
export const setAnalyticPageViewReady = (
    isReady: boolean,
): SetAnalyticPageViewReadyAction => ({
    type: SET_ANALYTIC_PAGE_VIEW_READY,
    isReady,
});

export type AnalyticActionTypes = SetAnalyticPageViewReadyAction;
