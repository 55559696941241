export const UPDATE_ARTICLE_LIKES_SUCCESS = 'UPDATE_ARTICLE_LIKES_SUCCESS';
export const GET_ARTICLE_SUCCESS = 'GET_ARTICLE_SUCCESS';
export const CLEAR_READ_ARTICLES = 'CLEAR_READ_ARTICLES';
export const GET_ARTICLE_ERROR = 'GET_ARTICLE_ERROR';
export const GET_ARTICLE_LIKES_SUCCESS = 'GET_ARTICLE_LIKES_SUCCESS';
export const GET_ARTICLE_SUMMARIES_ERROR = 'GET_ARTICLE_SUMMARIES_ERROR';
export const GET_MONEY_MINUTE_ARTICLES = 'GET_MONEY_MINUTE_ARTICLES';
export const GET_MONEY_MINUTE_ARTICLES_ERROR =
    'GET_MONEY_MINUTE_ARTICLES_ERROR';
export const GET_MONEY_MINUTE_ARTICLES_SUCCESS =
    'GET_MONEY_MINUTE_ARTICLES_SUCCESS';
export const UPDATE_READ_INFLUENCER_ARTICLES =
    'UPDATE_READ_INFLUENCER_ARTICLES';
export const CLEAR_READ_INFLUENCER_ARTICLES = 'CLEAR_READ_INFLUENCER_ARTICLES';

export const GENERIC_ARTICLE_PATHNAME = '/generic/articles/';
export const DOLLAR_ROAD_TRIP_ARTICLE_IDS = [
    'a74ad192-ce9c-4a26-ad4c-8d0992bc44eb',
    '8a7a549f-eb8b-4432-abcd-8582b28100f3',
    '2df3fcc8-aeb8-4589-a01a-7b75be3bedd3',
];
