import React from 'react';
import loadable from '@loadable/component';

/* interfaces */
import { RenderElement } from '../interfaces';
import { CardWithDropDownsProps } from 'components/card-with-dropdowns/interfaces';
import AuthoredQuiz from 'components/authoredQuiz/AuthoredQuiz';
import ArticleLanding from 'containers/TargetDate/ArticleLanding';
import {
    FlipCardContainer,
    RecipeCard,
    SpotifyPlaylist,
    TransitionToRetirementTool,
} from 'components';
import WidgetFlashCardGroup from 'components/widgetFlashCardGroup/WidgetFlashCardGroup';
import WidgetInflationCart from 'components/WidgetInflationCart/WidgetInflationCart';
import PriceCreep from 'components/WidgetPriceCreep/WidgetPriceCreep';
import AuthorableSplitSelection from 'components/atomic/widgets/authorableSplitSelection/AuthorableSplitSelection';
import AuthorableFlipCard from 'components/atomic/widgets/authorable-flip-card/AuthorableFlipCard';
import ChangeContributionCtaWithSeparator from 'components/atomic/widgets/change-contribution-cta-with-separator/ChangeContributionCtaWithSeparator';

// custom components
const CardWithDropDowns = loadable(
    () => import('components/card-with-dropdowns'),
);
const DynamicMediaPlayerWithTranscript = loadable(
    () =>
        import(
            'components/dynamic-media-player/DynamicMediaPlayerWithTranscript'
        ),
);

const renderCustomComponent: RenderElement = (props) => {
    const { data = {}, index } = props;
    const { multiMediaJson } = data;

    try {
        if (typeof index !== 'number') throw new Error('index missing');
        const json = multiMediaJson[index];
        if (!json) throw new Error('missing data');
        //validate that we have all of the required fields
        if (
            !json.hasOwnProperty('component') ||
            typeof json.component !== 'string'
        )
            throw new Error('missing component prop');
        if (
            !json.hasOwnProperty('data') ||
            typeof json.data !== 'object' ||
            json.data === null
        )
            throw new Error('missing data prop');

        // render specific component
        switch (json.component) {
            case 'dropdown-cards':
                const { image, dropdowns } =
                    json.data as CardWithDropDownsProps;
                //individual validation
                if (!image) throw new Error('missing image');
                if (!dropdowns) throw new Error('missing dropdowns');
                return (
                    <div>
                        <CardWithDropDowns
                            image={image}
                            dropdowns={dropdowns}
                        />
                    </div>
                );
            case 's7Viewer':
                const { title: videoTitle, transcript } = data;
                return (
                    <DynamicMediaPlayerWithTranscript
                        params={json.data}
                        title={videoTitle}
                        transcript={transcript}
                    />
                );
            case 'aftd':
                return <ArticleLanding data={json.data} />;
            case 'flipcards':
                return <FlipCardContainer data={json.data} />;
            case 'recipe-card':
                return (
                    <RecipeCard id={json.data.id} options={json.data.options} />
                );
            case 'quiz':
                return <AuthoredQuiz type={json.type} data={json.data} />;
            case 'spotify-playlist':
                return <SpotifyPlaylist id={json.data.id} />;
            case 'flashcard-group':
                return (
                    <WidgetFlashCardGroup
                        title={json.data.title}
                        subTitle={json.data.subTitle}
                        mobileSubTitle={json.data.mobileSubTitle}
                        bubbleText={json.data.bubbleText}
                        cards={json.data.cards}
                        // mainContent1={json.data.mainContent1}
                        // mainContent2={json.data.mainContent2}
                        // mainTitle={json.data.mainTitle}
                        // source={json.data.source}
                    />
                );
            case 'inflation-cart':
                return (
                    <WidgetInflationCart
                        // mainTitle={json.data.mainTitle}
                        // mainDescription={json.data.mainDescription}
                        title={json.data.title}
                        description={json.data.description}
                        cartPieces={json.data.cartPieces}
                        // source={json.data.source}
                        // disclaimer={json.data.disclaimer}
                    />
                );

            case 'authorable-split-selection':
                return (
                    <AuthorableSplitSelection
                        leftOptions={json.data.leftOptions}
                        rightOptions={json.data.rightOptions}
                    />
                );
            case 'price-creep':
                return (
                    <PriceCreep
                        // mainTitle={json.data.mainTitle}
                        // description={json.data.description}
                        subTitle={json.data.subTitle}
                        imagineCostText={json.data.imagineCostText}
                        header={json.data.header}
                        sliderMap={json.data.sliderMap}
                        // source={json.data.source}
                        // disclaimer={json.data.disclaimer}
                    />
                );
            case 'authorable-flip-card':
                return (
                    <AuthorableFlipCard
                        frontOfCard={json.data.frontOfCard}
                        backOfCard={json.data.backOfCard}
                    />
                );
            case 'change-contribution-cta-with-separator':
                return (
                    <ChangeContributionCtaWithSeparator
                        ctaLabel={json.data.ctaLabel}
                        dataAnalyticsPlacement={
                            json.data.dataAnalyticsPlacement
                        }
                    />
                );
            case 'ttr-widget':
                return <TransitionToRetirementTool data={json.data} />;
            // TODO: move renderFlashCards to mm-json component so text would be editable from AEM
        }
    } catch (e) {
        console.error({ e });
    }
    // return empty object if any error happened
    return <></>;
};

export default renderCustomComponent;

/*
flashcard-group AEM snippet
AEM test page: https://na.author.dev.capitalgroup.com/editor.html/content/dam/my-retirement/content/should-you-retire-early
[mm-json]{ "component": "flashcard-group", "data": { "title": "The cost of milk is MOOving higher!", "subTitle": "Flip the card to find out.", "bubbleText": "Imagine what milk might cost during your retirement!", "cards": [{ "bgImage": "/content/dam/my-retirement/images/milk-bg.jpg", "question": "2017", "answer": "$2.50", "isCardFlipped": false, "key": "card-1", "bgColor": "#f0f9fe" }, { "bgImage": "/content/dam/my-retirement/images/milk-bg.jpg", "question": "2019", "answer": "$2.50", "isCardFlipped": false, "key": "card-2", "bgColor": "#f0f9fe" }, { "bgImage": "/content/dam/my-retirement/images/milk-bg.jpg", "question": "2023", "answer": "$2.50", "isCardFlipped": false, "key": "card-3", "bgColor": "#f0f9fe" }] } }[/mm-json]

*/
