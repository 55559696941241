/* constants */
import { CLEAR_ERROR } from 'constants/error';

/* actions */
import { ErrorActionTypes } from 'actions/error';

/* utils */
import { ErrorMap, errorMap } from 'utils/error';

export interface Err {
    error: ErrorMap | null;
}

export const initialErrorState: Err = {
    error: null,
};

export const errorReducer = (
    state = initialErrorState,
    action: ErrorActionTypes,
): Err => {
    const { error } = action;

    if (error) {
        return { error: errorMap(error) };
    }

    if (action.type === CLEAR_ERROR) {
        return { error: null };
    }

    return state;
};
