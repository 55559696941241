import React, { useState, FC } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import InfoOutlined from '@material-ui/icons/InfoOutlined';
import CloseIcon from '@material-ui/icons/Close';
import { Button, IconButton } from '@material-ui/core';
import Popover from '@material-ui/core/Popover';
import { AVENIR_NEXT_LT_REGULAR } from 'theme/font';
import { customClickEvent } from 'analytics';
const useStyles = makeStyles((theme) => ({
    wrapper: {
        display: 'inline',
        '& .MuiIconButton-root': {
            padding: 0,
            margin: 0,
        },
    },
    popOverContent: {
        padding: '35px 20px 20px 20px',
        width: 200,
        fontSize: 12,
        fontFamily: AVENIR_NEXT_LT_REGULAR,
        position: 'relative',
        [theme.breakpoints.up('sm')]: {
            width: 400,
            fontSize: 18,
        },
    },
    infoIcon: {
        fontSize: 'small',
        margin: 1,
    },
    closeIcon: {
        color: '#000000',
    },
    closeButton: {
        position: 'absolute',
        right: -5,
        top: 5,
    },
}));

interface PopOverProps {
    text: string;
    color: string;
}

const PopOver: FC<PopOverProps> = ({ text, color }) => {
    const classes = useStyles();

    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    return (
        <div className={classes.wrapper}>
            <IconButton
                aria-describedby={id}
                aria-label="info"
                data-testid="popover-open-button"
                onClick={(e) => {
                    handleClick(e);
                    customClickEvent({
                        customLinkName: `Power of Time iBall: ${text}`,
                    });
                }}
            >
                <InfoOutlined
                    style={{ color: color }}
                    className={classes.infoIcon}
                />
            </IconButton>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <div className={classes.popOverContent}>
                    <Button
                        className={classes.closeButton}
                        onClick={handleClose}
                    >
                        <CloseIcon className={classes.closeIcon} />
                    </Button>
                    {text}
                </div>
            </Popover>
        </div>
    );
};

export default PopOver;
