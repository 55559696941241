import Url from 'url-parse';

/* types */
import { Theme } from 'reducers/company';
import { User } from 'reducers/user';

/* utils */
import { getReduxSessionState } from 'utils';

/* constants */
import { GENERIC } from 'constants/company';
const NON_COMPANY_PAGES = ['privacy'];

export const isUrlExternal = (url: string): boolean => {
    const urlRgx = /https?:\/\/(?:[\w\d-])/;
    return urlRgx.test(url);
};

export const isTel = (url: string): boolean => {
    const urlRgx = /tel:/;
    return urlRgx.test(url);
};

export const isMailTo = (url: string): boolean => {
    const urlRgx = /mailto:/;
    return urlRgx.test(url);
};

export const getLinkToQuiz = (user: User, company: Theme, path?: string) => {
    const animal = user?.animal;
    const companyId = company.companyId;

    // if (window.location.hostname.includes('localhost')) {
    //     return animal && companyId
    //     ? `http://localhost:3001/${companyId}/quiz-result/${animal}`
    //     : `http://localhost:3001/${companyId}/quiz${path ? `/${path}` : ''}`;
    //  }

    return animal && companyId
        ? `/retirement/${companyId}/quiz-result/${animal}`
        : `/retirement/${companyId}/quiz${path ? `/${path}` : '/'}`;
};

/**
 * getFullCtaLink - Returns a full path for the CTA
 * @param ctaLink string
 * @param companyId string | undefined
 * @returns string
 */
export const getFullCtaLink = (
    ctaLink: string,
    companyId: string | undefined,
    urlParam?: string,
): string => {
    const host =
        process.env.NODE_ENV === 'development'
            ? 'localhost:3001'
            : window.location.host;
    const link = companyId
        ? `${urlParam}${companyId}${ctaLink}`
        : `${urlParam}${ctaLink}`;
    return `${window.location.protocol}//${host}${link}`;
};

export const getLinkToMoneyMinute = (company: Theme) => {
    const { companyId } = company;
    return `/${companyId}/get-the-facts/money-minute`;
};

export const getCompanyUrl = () => {
    const windowLocation = window.location;
    const url = new Url(windowLocation.href);
    const urlPathArray = url.pathname.split('/');
    let companyName: string = GENERIC;
    if (urlPathArray.length >= 2 && urlPathArray[1].length > 0) {
        if (!NON_COMPANY_PAGES.includes(urlPathArray[1])) {
            companyName = urlPathArray[1];
        } else {
            const reduxState = getReduxSessionState();
            if (reduxState && reduxState.company.theme) {
                companyName = reduxState.company.theme.companyId;
            }
        }
    }
    return companyName.toLowerCase();
};
