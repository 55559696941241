import React, { FC, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-scroll';
import { pxToRem } from 'theme/util';
import { AVENIR_NEXT_LT_DEMI, AVENIR_NEXT_LT_REGULAR } from 'theme/font';
import { customClickEvent } from 'analytics';
import { label } from '../types';

type Props = {
    companyId: string;
    labels: label[];
};

const useStyles = makeStyles((theme) => ({
    nav: {
        [theme.breakpoints.up('sm')]: {
            filter: 'unset',
            background: 'unset !important',
            position: 'unset',
            minWidth: 300,
        },
    },
    list: {
        [theme.breakpoints.up('sm')]: {
            position: 'sticky',
            top: '30%',
            left: 175,
            width: 210,
            background: 'unset',
            filter: 'unset',
        },
        '& > li': {
            [theme.breakpoints.up('sm')]: {
                position: 'relative',
                display: 'block',
                margin: '10px 0px',
            },
        },
    },
    navButtonPosition: {
        position: 'absolute',
        left: -35,
        top: -5,
    },
    inactiveNavItem: {
        color: 'white',
        cursor: 'pointer',
        fontSize: pxToRem(14),
        fontFamily: AVENIR_NEXT_LT_REGULAR,
        transitionProperty: 'color',
        transitionDuration: '0.3s',
        '& > div': {
            width: 20,
            height: 20,
            borderRadius: '50%',
            border: '1px solid white',
            margin: 3,
            transitionProperty: 'background-color border',
            transitionDuration: '0.3s',
        },
    },
    activeNavItem: {
        fontFamily: AVENIR_NEXT_LT_DEMI,
        color: 'white',
        cursor: 'pointer',
        fontSize: pxToRem(14),
        transitionProperty: 'color',
        transitionDuration: '0.3s',
        '& > div': {
            width: 20,
            height: 20,
            borderRadius: '50%',
            border: '1px solid white',
            margin: 3,
            transitionProperty: 'background-color border',
            transitionDuration: '0.3s',
            backgroundColor: '#D8D8D8',
        },
    },
    dropdownMenu: {
        marginTop: 90,
    },
}));

const DesktopNav: FC<Props> = ({ labels, companyId }) => {
    const classes = useStyles();
    const [clickedNavItem, setClickedNavItem] = useState<string>('');
    const [isClickedNav, setiIsClickedNav] = useState<boolean>(false);
    const [navColor, setNavColor] = useState<string>('white');

    const handleSetActive = (sectionTitle: string, navColor: string) => {
        setNavColor(navColor);
        if (clickedNavItem === sectionTitle && isClickedNav) {
            customClickEvent({
                customLinkName: `Hispanic Landing_${sectionTitle}`,
            });
            setiIsClickedNav(false);
        } else {
            if (!isClickedNav) {
                customClickEvent({
                    customLinkName: `Hispanic Landing_${sectionTitle}`,
                });
            }
        }
    };

    if (!labels) {
        return null;
    }

    return (
        <div className={classes.nav} data-qa="hispanic-desktop-nav">
            <ul className={classes.list}>
                {labels.map((option, index) => {
                    if (companyId === 'seiu775srp' && index === 7) {
                        return null;
                    }
                    return (
                        <li key={`navItem_${index}`}>
                            <Link
                                style={{ color: navColor }}
                                onSetActive={() => {
                                    handleSetActive(
                                        `section_${index + 1}`,
                                        option.color,
                                    );
                                }}
                                onClick={() => {
                                    setClickedNavItem(`section_${index + 1}`);
                                    setiIsClickedNav(true);
                                }}
                                className={classes.inactiveNavItem}
                                isDynamic
                                offset={-100}
                                activeClass={classes.activeNavItem}
                                smooth
                                hashSpy
                                spy
                                to={`section_${index + 1}`}
                            >
                                <div
                                    style={{ border: `1px solid ${navColor}` }}
                                    className={classes.navButtonPosition}
                                />
                                {option.label}
                            </Link>
                        </li>
                    );
                })}
            </ul>
        </div>
    );
};

export default DesktopNav;
