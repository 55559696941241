import React, { FC } from 'react';

/* material */
import { Link } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

/* utils */
import { eyebrowArticle, eyebrowCard } from 'theme/font';
import { customClickEvent } from 'analytics';

const useStyles = makeStyles(() => ({
    article: {
        ...eyebrowArticle,
        textTransform: 'uppercase',
    },
    card: {
        ...eyebrowCard,
        textTransform: 'uppercase',
    },
}));

type Display = 'article' | 'card';

type EyebrowProps = {
    display?: Display;
    title?: string;
    url?: string | null;
    dataAnalyticsPlacement: string;
};

const Eyebrow: FC<EyebrowProps> = ({
    children,
    display,
    title,
    url,
    dataAnalyticsPlacement,
}) => {
    const classes = useStyles();

    let fontStyle: Display = 'article';

    if (display === 'card') {
        fontStyle = 'card';
    }

    return url ? (
        <Link
            onClick={() => {
                customClickEvent({
                    customLinkName: `${dataAnalyticsPlacement}: ${title}`,
                });
            }}
            data-qa="eyebrow-link"
            href={url}
            className={classes[fontStyle]}
        >
            {title || children}
        </Link>
    ) : (
        <span className={classes[fontStyle]}>{title || children}</span>
    );
};

export default Eyebrow;
