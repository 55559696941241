import React, { FC } from 'react';
import { useIntl } from 'react-intl';

/* material */
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import clsx from 'clsx';

/* utils */
import colors from 'theme/colors';
import { pxToRem } from 'theme/util';
import { siteWidth } from 'utils/siteWidthUtils';

const useStyle = makeStyles((theme) => ({
    bold: {
        fontWeight: 'bold',
        '& a': {
            textDecoration: 'none',
            color: colors.darkTurquoise,
        },
    },
    disclosure: {
        color: colors.black80,
        fontSize: pxToRem(14),
        lineHeight: pxToRem(20),
        margin: '8px 0',

        [theme.breakpoints.up('sm')]: {
            fontSize: pxToRem(16),
            lineHeight: pxToRem(26),
            padding: '0',
        },

        '&:first-child': {
            paddingTop: '30px',
            margin: 0,
        },
    },
}));

export const Disclosures: FC = () => {
    const classes = useStyle();
    const intl = useIntl();

    const renderDisclosure = () => {
        const isTargetDate =
            window.location.pathname.indexOf('target-date') > -1 ? true : false;
        if (isTargetDate) {
            return (
                <>
                    <p className={clsx(classes.disclosure, classes.bold)}>
                        {intl.formatMessage({
                            id: 'disclosures.targetDate1',
                        })}
                    </p>
                    <p className={clsx(classes.disclosure, classes.bold)}>
                        {intl.formatMessage(
                            {
                                id: 'disclosures.targetDate2',
                            },
                            {
                                a: function render([text]) {
                                    return (
                                        <a
                                            href="https://www.capitalgroup.com/individual/investments/prospectuses-and-reports.htm"
                                            target="_Blank"
                                            rel="noopener noreferrer"
                                        >
                                            {text}
                                        </a>
                                    );
                                },
                            },
                        )}
                    </p>
                    <p className={classes.disclosure}>
                        {intl.formatMessage({
                            id: 'disclosures.targetDate3',
                        })}
                    </p>
                    <p className={classes.disclosure}>
                        {intl.formatMessage({
                            id: 'disclosures.targetDate4',
                        })}
                    </p>
                    <p className={classes.disclosure}>
                        {intl.formatMessage({
                            id: 'disclosures.targetDate5',
                        })}
                    </p>
                    <p className={classes.disclosure}>
                        {intl.formatMessage({
                            id: 'disclosures.targetDate6',
                        })}
                    </p>
                </>
            );
        } else {
            return (
                <>
                    <p className={classes.disclosure}>
                        <strong>
                            {intl.formatMessage({
                                id: 'disclosures.notFDIC',
                            })}
                        </strong>
                    </p>
                    <p className={classes.disclosure}>
                        {intl.formatMessage({
                            id: 'disclosures.americanFunds',
                        })}
                    </p>
                </>
            );
        }
    };

    return (
        <Box width="100%" bgcolor={colors.gray97} px={'20px'}>
            <Box
                color={colors.gray70}
                pb={4}
                mx="auto"
                style={{ maxWidth: siteWidth }}
                //width={{ xs: '100%', lg: siteWidth }}
                data-qa="footer-disclosures"
            >
                <Box ml={{ xs: 2.5, lg: 0 }} mr={{ xs: 2.5, lg: 0 }}>
                    {renderDisclosure()}
                </Box>
            </Box>
        </Box>
    );
};
