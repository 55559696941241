import React, { FC } from 'react';

/* material */
import { Button } from '@material-ui/core';
import SmileIcon from '@material-ui/icons/SentimentSatisfiedOutlined';
import { makeStyles } from '@material-ui/core/styles';

/* utils */
import { getLikesPluralFormatting } from './Likes';
import { convertToNumberWithCommas } from 'utils/numberFormat';
import colors from 'theme/colors';
import { AVENIR_NEXT_LT_DEMI } from 'theme/font';
import { pxToRem, pxToSpacing } from 'theme/util';
import { customClickEvent } from 'analytics';

const useStyles = makeStyles((theme) => ({
    root: (props: Pick<LikeButtonProps, 'isArticleLiked'>) => {
        const { isArticleLiked } = props;
        return {
            backgroundColor: isArticleLiked ? colors.turquoise6 : colors.white,
            fontFamily: AVENIR_NEXT_LT_DEMI,
            letterSpacing: pxToRem(0.75),
            padding: theme.spacing(
                pxToSpacing(4),
                pxToSpacing(10),
                pxToSpacing(2),
            ),

            '&:hover': {
                backgroundColor: colors.turquoise6,
            },

            [theme.breakpoints.up('sm')]: {
                letterSpacing: pxToRem(1),
                padding: theme.spacing(
                    pxToSpacing(6),
                    pxToSpacing(10),
                    pxToSpacing(4),
                ),
            },
        };
    },
    icon: {
        '&:first-child': {
            fontSize: pxToRem(21),
            marginTop: '-1px',
            [theme.breakpoints.up('sm')]: {
                fontSize: pxToRem(29),
            },
        },
    },
}));

type LikeButtonProps = {
    count: number | undefined;
    isArticleLiked: boolean;
    onLikeClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
};

const LikeButton: FC<LikeButtonProps> = ({
    count = 0,
    onLikeClick,
    isArticleLiked,
}) => {
    const classes = useStyles({ isArticleLiked });

    return (
        <Button
            data-qa="article-like-button"
            aria-label={`${isArticleLiked ? 'Undo Like' : 'Like'} article`}
            className={classes.root}
            variant="outlined"
            size="large"
            onClick={(e) => {
                if (onLikeClick) onLikeClick(e);

                customClickEvent({
                    customLinkName: `Article Content: Article Like Button`,
                });
            }}
            startIcon={<SmileIcon className={classes.icon} />}
        >
            {`${convertToNumberWithCommas(count)} `}
            {getLikesPluralFormatting(count)}
        </Button>
    );
};

export default LikeButton;
