import { Store } from 'redux';

export const clearReduxSessionState = () => {
    window.sessionStorage.clear();
};

export const getReduxSessionState = () => {
    try {
        const reduxStore = window.sessionStorage.getItem('redux-store');
        return typeof reduxStore === 'string'
            ? { ...JSON.parse(reduxStore), error: { error: null } }
            : {};
    } catch (e) {
        return {};
    }
};

export const setReduxSessionState = (reduxStore: Store) => {
    const state = reduxStore.getState();
    window.sessionStorage.setItem('redux-store', JSON.stringify(state));
};
