import React, { FC } from 'react';
import theme from 'theme/theme';
import DesktopView from './DesktopView';
import MobileView from './MobileView';
import { useMediaQuery } from '@material-ui/core';

export type CenterSelectionOption = {
    number: string;
    imageOff: string;
    imageOn: string;
    title: string;
    content: string;
    centerBGColor: string;
    centerTxtColor: string;
    footer?: string;
};

export interface CenterSelectionProps {
    leftOptions?: [CenterSelectionOption];
    rightOptions?: [CenterSelectionOption];
}

const AuthorableSplitSelection: FC<CenterSelectionProps> = ({
    leftOptions,
    rightOptions,
}) => {
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    if (isMobile) {
        return (
            <MobileView leftOptions={leftOptions} rightOptions={rightOptions} />
        );
    }
    return (
        <DesktopView leftOptions={leftOptions} rightOptions={rightOptions} />
    );
};

export default AuthorableSplitSelection;
