import VideoCamIcon from 'components/icons/VideoCamIcon';
import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

/* material */
import {
    Box,
    Card,
    CardContent,
    CardMedia,
    Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Breakpoint } from '@material-ui/core/styles/createBreakpoints';

/* components */
import { ArticleMinutes, Eyebrow } from 'components';

/* utils */
import { pxToRem, pxToSpacing } from 'theme/util';

/* constants */
import { customClickEvent } from 'analytics';
import colors from 'theme/colors';
import { AVENIR_NEXT_LT_DEMI } from 'theme/font';

const useStyles = makeStyles((theme) => ({
    card: (props: Pick<StaticArticleCardProps, 'fullWidthUntil'>) => {
        const { fullWidthUntil } = props;
        return {
            borderTop: `solid 1px ${colors.gray80}`,
            borderBottom: `solid 1px ${colors.gray80}`,
            display: 'block',
            left: '50%',
            marginLeft: '-50vw',
            marginRight: '-50vw',
            position: 'relative',
            right: '50%',
            width: '100vw',

            [theme.breakpoints.up(fullWidthUntil as Breakpoint)]: {
                border: `solid 1px ${colors.gray80}`,
                left: 'inherit',
                marginLeft: 'inherit',
                marginRight: 'inherit',
                right: 'inherit',
                maxWidth: '100%',
            },
        };
    },
    cardMedia: {
        position: 'absolute',
        top: 0,
        left: 0,
        height: '100%',
        width: '100%',
        minHeight: 170,
        [theme.breakpoints.up('sm')]: {
            position: 'relative',
        },
    },
    cardContent: {
        '&:last-child': {
            padding: `${pxToRem(16)} ${pxToRem(16)}`,
        },
    },
    cardAction: {
        position: 'absolute',
        display: 'block',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,

        '&:focus': {
            outline: '',
        },
    },
    videoIcon: {
        position: 'absolute',
        bottom: '-10px',
        left: '20px',

        [theme.breakpoints.up('sm')]: {
            bottom: '10px',
        },
    },
}));

type StaticArticleCardProps = {
    contentMinutes?: string;
    description?: string;
    fullWidthUntil?: Breakpoint;
    title: string;
    imagePath: string;
    topicTitle?: string;
    topicUrl?: string;
    isVideo?: boolean;
    url: string;
};

export const StaticArticleCard: FC<StaticArticleCardProps> = ({
    contentMinutes,
    description = '',
    fullWidthUntil = 'sm',
    imagePath,
    title,
    topicTitle = '',
    topicUrl = '',
    isVideo = false,
    url,
}) => {
    const classes = useStyles({ fullWidthUntil });

    return (
        <Card data-qa="article-card" className={classes.card}>
            <Box
                display="flex"
                flexDirection={{ xs: 'column', sm: 'row' }}
                height="100%"
            >
                <Box
                    position="relative"
                    pt={{ xs: `${(9 / 16) * 100}%`, sm: 'inherit' }}
                    flex={{ xs: '1 1 auto', sm: '0 0 36%' }}
                    height={{ xs: 0, sm: 'auto' }}
                >
                    <CardMedia
                        data-qa="article-card-image"
                        className={classes.cardMedia}
                        component="div"
                        image={imagePath}
                    />
                    {isVideo && (
                        <div className={classes.videoIcon}>
                            <VideoCamIcon />
                        </div>
                    )}
                </Box>
                <CardContent className={classes.cardContent}>
                    <Box mb={pxToSpacing(12)} position="relative" zIndex={3}>
                        <Eyebrow
                            dataAnalyticsPlacement="Article Header"
                            url={topicUrl}
                            display="card"
                        >
                            {topicTitle}
                        </Eyebrow>
                    </Box>
                    <Typography data-qa="article-card-header" variant="h4">
                        <Box
                            fontFamily={AVENIR_NEXT_LT_DEMI}
                            mb={pxToSpacing(8)}
                        >
                            {title}
                        </Box>
                    </Typography>
                    <Typography
                        data-qa="article-card-subheader"
                        variant="body1"
                    >
                        <Box
                            component="span"
                            display="block"
                            fontSize={pxToRem(16)}
                            lineHeight={pxToRem(22)}
                            pb={pxToSpacing(16)}
                        >
                            {description}
                        </Box>
                    </Typography>
                    {contentMinutes && (
                        <ArticleMinutes
                            length={contentMinutes}
                            isVideo={isVideo}
                        />
                    )}
                </CardContent>
            </Box>
            <Link 
                className={classes.cardAction} 
                to={url} 
                onClick={() => {
                        customClickEvent({
                            customLinkName: `bottom article: ${title}_clicked`,
                        });
                    }}
                >
                <Typography variant="srOnly">
                    <FormattedMessage
                        id="articleLanding.nextArticle.linkToArticle"
                        values={{ title }}
                    />
                </Typography>
            </Link>
        </Card>
    );
};
