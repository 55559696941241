import React, { useState } from 'react';

import Sherry from 'assets/Sherry.svg';
import Omar from 'assets/Omar.svg';

import PopOver from 'components/powerOfTime/PopOver';
import { AVENIR_NEXT_LT_DEMI, AVENIR_NEXT_LT_REGULAR } from 'theme/font';
/* material */
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';
import { FormattedMessage, useIntl } from 'react-intl';
import { pxToRem } from 'theme/util';

const useStyles = makeStyles((theme) => ({
    wrapper: { padding: 15 },
    headerWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 30,
    },
    powerOfTimeLabel: {
        fontSize: pxToRem(12),
        fontFamily: AVENIR_NEXT_LT_DEMI,
        [theme.breakpoints.up('sm')]: {
            fontSize: pxToRem(18),
        },
    },
    averageAgeLabel: {
        fontSize: pxToRem(12),
        fontFamily: AVENIR_NEXT_LT_REGULAR,
        [theme.breakpoints.up('sm')]: {
            fontSize: pxToRem(20),
        },
    },
    profileWrapper: {
        display: 'flex',
    },
    leftColumn: {
        textAlign: 'center',
        marginRight: 25,
        '& img': {
            height: 60,
            [theme.breakpoints.up('sm')]: {
                height: 70,
            },
        },
    },
    rightColumn: {
        textAlign: 'left',
        display: 'flex',
        width: '100%',
    },
    name: {
        fontSize: pxToRem(10),
        fontWeight: 'bold',
        [theme.breakpoints.up('sm')]: {
            fontSize: pxToRem(16),
        },
    },
    started: {
        fontSize: pxToRem(10),
        width: 80,
        [theme.breakpoints.up('sm')]: {
            fontSize: pxToRem(14),
        },
    },
    centerRightContent: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
    },
    sherryTotal: {
        backgroundColor: theme.colors.newBlue,
        height: 94,
        transition: 'width 1250ms',
        overflow: 'hidden',
    },
    sherryBalance: {
        backgroundColor: '#55697f',
        height: 94,
        transition: 'width 1250ms',
        overflow: 'hidden',
    },
    omarTotal: {
        backgroundColor: theme.colors.paoloGreen,
        height: 94,
        transition: 'width 1500ms',
        overflow: 'hidden',
    },
    omarBalance: {
        backgroundColor: '#77ada0',
        height: 94,
        transition: 'width 1500ms',
        overflow: 'hidden',
    },
    cellContent: {
        color: theme.colors.white,
        marginRight: 5,
        textAlign: 'right',
    },
    amount: {
        fontSize: pxToRem(10),
        fontWeight: 'bold',
        [theme.breakpoints.up('sm')]: {
            fontSize: pxToRem(18),
        },
    },
    amountLabel: {
        fontSize: pxToRem(10),
        lineHeight: 1,
        [theme.breakpoints.up('sm')]: {
            fontSize: pxToRem(16),
        },
    },
    bottom: {
        display: 'flex',
        justifyContent: 'space-between',
        borderTop: '2px solid #979797',
        marginLeft: 100,
        '& div': {
            marginTop: 15,
            fontSize: pxToRem(8),
            [theme.breakpoints.up('sm')]: {
                fontSize: pxToRem(12),
            },
        },
    },
    bold: {
        fontWeight: 'bold',
    },
}));

const PowerOfTimeStatic = () => {
    const classes = useStyles();
    const [isCompVisible, setIsCompVisible] = useState<boolean>(false);
    const intl = useIntl();

    return (
        <VisibilitySensor
            onChange={(isVisible) => {
                setIsCompVisible(isVisible ? true : false);
            }}
        >
            <div
                className={classes.wrapper}
                role="presentation"
                // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
                tabIndex={0}
                aria-label={intl.formatMessage({
                    id: 'powerOfTime.screenReaderText',
                })}
            >
                <div className={classes.headerWrapper}>
                    <div className={classes.powerOfTimeLabel}>
                        <FormattedMessage id="powerOfTime.title" />
                    </div>
                    <div className={classes.averageAgeLabel}>
                        <FormattedMessage id="powerOfTime.averageAgeStart" />
                        <span className={classes.bold}>65</span>{' '}
                        <FormattedMessage id="powerOfTime.averageAgeEnd" />
                    </div>
                </div>
                <div
                    style={{ marginBottom: 30 }}
                    className={classes.profileWrapper}
                >
                    <div className={classes.leftColumn}>
                        <img src={Sherry} alt="Sherry" />
                        <div className={classes.name}>
                            Sherry
                            <PopOver
                                text={intl.formatMessage({
                                    id: 'powerOfTime.sherryContributed',
                                })}
                                color="#000000"
                            />
                        </div>
                        <div className={classes.started}>
                            <FormattedMessage id="powerOfTime.started25" />
                        </div>
                    </div>
                    <div className={classes.rightColumn}>
                        <div
                            style={
                                isCompVisible
                                    ? {
                                          width: '30%',
                                      }
                                    : { width: '0px' }
                            }
                            className={clsx(
                                classes.sherryTotal,
                                classes.centerRightContent,
                            )}
                        >
                            <div className={classes.cellContent}>
                                <div className={classes.amount}>
                                    $
                                    <CountUp
                                        decimals={3}
                                        decimal=","
                                        end={90.481}
                                        redraw={isCompVisible ? true : false}
                                        duration={1}
                                    />
                                </div>
                                <div className={classes.amountLabel}>
                                    <FormattedMessage id="powerOfTime.totalContribution" />
                                </div>
                            </div>
                        </div>
                        <div
                            style={
                                isCompVisible
                                    ? { width: '52%' }
                                    : { width: '0px' }
                            }
                            className={clsx(
                                classes.sherryBalance,
                                classes.centerRightContent,
                            )}
                        >
                            <div className={classes.cellContent}>
                                <div className={classes.amount}>
                                    $
                                    <CountUp
                                        decimals={3}
                                        decimal=","
                                        end={252.061}
                                        redraw={isCompVisible ? true : false}
                                        duration={1}
                                    />
                                </div>
                                <div className={classes.amountLabel}>
                                    <PopOver
                                        text={intl.formatMessage({
                                            id: 'powerOfTime.sherryOver40',
                                        })}
                                        color="#FFFFFF"
                                    />{' '}
                                    <FormattedMessage id="powerOfTime.balanceAtRetirement" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={classes.profileWrapper}>
                    <div className={classes.leftColumn}>
                        <img src={Omar} alt="Omar" />
                        <div className={classes.name}>
                            Omar
                            <PopOver
                                text={intl.formatMessage({
                                    id: 'powerOfTime.omarContributed',
                                })}
                                color="#000000"
                            />
                        </div>
                        <div className={classes.started}>
                            <FormattedMessage id="powerOfTime.started35" />
                        </div>
                    </div>
                    <div className={classes.rightColumn}>
                        <div
                            style={
                                isCompVisible
                                    ? { width: '20%' }
                                    : { width: '0px' }
                            }
                            className={clsx(
                                classes.omarTotal,
                                classes.centerRightContent,
                            )}
                        >
                            <div className={classes.cellContent}>
                                <div className={classes.amount}>
                                    $
                                    <CountUp
                                        decimals={3}
                                        decimal=","
                                        end={76.724}
                                        redraw={isCompVisible ? true : false}
                                        duration={1}
                                    />
                                </div>
                                <div className={classes.amountLabel}>
                                    <FormattedMessage id="powerOfTime.totalContribution" />
                                </div>
                            </div>
                        </div>
                        <div
                            style={
                                isCompVisible
                                    ? { width: '30%' }
                                    : { width: '0px' }
                            }
                            className={clsx(
                                classes.omarBalance,
                                classes.centerRightContent,
                            )}
                        >
                            <div className={classes.cellContent}>
                                <div className={classes.amount}>
                                    $
                                    <CountUp
                                        decimals={3}
                                        decimal=","
                                        end={164.926}
                                        redraw={isCompVisible ? true : false}
                                        duration={1}
                                    />
                                </div>
                                <div className={classes.amountLabel}>
                                    <PopOver
                                        text={intl.formatMessage({
                                            id: 'powerOfTime.omarOver30',
                                        })}
                                        color="#FFFFFF"
                                    />{' '}
                                    <FormattedMessage id="powerOfTime.balanceAtRetirement" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={classes.bottom}>
                    <div>$0</div>
                    <div>$50,000</div>
                    <div>$100,000</div>
                    <div>$150,000</div>
                    <div>$200,000</div>
                    <div>$250,000</div>
                    <div>$300,000</div>
                </div>
            </div>
        </VisibilitySensor>
    );
};

export default PowerOfTimeStatic;
