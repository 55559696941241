import React, { FC, ChangeEvent } from 'react';
import { Theme, makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { pxToRem } from 'theme/util';
import { IteratorContent, RecipeOption } from './types';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        backgroundColor: theme.colors.gray99,
        border: 0,
        padding: `${pxToRem(23)} ${pxToRem(40)}`,
        '&.Mui-expanded': {
            margin: 0,
        },
        '&::before': {
            display: 'none',
        },
    },
    summary: {
        '& > .MuiAccordionSummary-content': {
            margin: 0,
        },
    },
    greenHeading: {
        color: theme.colors.greenLight,
        fontWeight: 700,
    },
    description: {
        fontSize: pxToRem(16),
    },
}));

export interface AccordionProps {
    option: RecipeOption;
    expanded: boolean;
    onChange: (event: ChangeEvent<{}>, expanded: boolean) => void;
}

const Accordion: FC<AccordionProps> = ({
    option,
    expanded,
    onChange,
}: AccordionProps) => {
    const classes = useStyles();

    return (
        <MuiAccordion
            expanded={expanded}
            onChange={onChange}
            variant="outlined"
            className={classes.root}
        >
            <MuiAccordionSummary
                className={classes.summary}
                expandIcon={<ExpandMoreIcon />}
            >
                <Typography variant="h2" className={classes.greenHeading}>
                    {option.accordionTitle}
                </Typography>
            </MuiAccordionSummary>
            <MuiAccordionDetails>
                <Box>
                    <Box mb={3}>
                        <Typography className={classes.description}>
                            {option.accordionDescription}
                        </Typography>
                    </Box>
                    <Box>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={5}>
                                <img
                                    src={option.imageUrl}
                                    width="100%"
                                    alt="Recipe"
                                />
                            </Grid>
                            <Grid item xs={12} sm={7}>
                                <Box mb={2}>
                                    <Box
                                        component="span"
                                        className={classes.greenHeading}
                                        mr={1}
                                        fontSize={pxToRem(22)}
                                    >
                                        Ingredients:
                                    </Box>
                                    <Typography
                                        component="span"
                                        className={classes.description}
                                    >
                                        {option.ingredients.join(', ')}
                                    </Typography>
                                </Box>
                                <Box mb={2}>
                                    <Box
                                        className={classes.greenHeading}
                                        mb={1}
                                        fontSize={pxToRem(22)}
                                    >
                                        Steps
                                    </Box>
                                    {option.steps.map(
                                        (
                                            step: IteratorContent,
                                            index: number,
                                        ) => (
                                            <Box key={step.title}>
                                                <Typography
                                                    component="span"
                                                    className={
                                                        classes.description
                                                    }
                                                >
                                                    {index + 1}.
                                                </Typography>
                                                <Box
                                                    component="b"
                                                    mx={1}
                                                    className={
                                                        classes.description
                                                    }
                                                >
                                                    {step.title}:
                                                </Box>
                                                <Typography
                                                    component="span"
                                                    className={
                                                        classes.description
                                                    }
                                                >
                                                    {step.description}
                                                </Typography>
                                            </Box>
                                        ),
                                    )}
                                </Box>
                                <Typography variant="caption">
                                    {option.helperText}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </MuiAccordionDetails>
        </MuiAccordion>
    );
};

export default Accordion;
