import * as React from 'react';
import { domToReact } from 'html-react-parser';

/* interfaces */
import { RenderType, RenderElementProps } from '../interfaces';

/* components */
import { ArticleUnorderedList } from 'containers/article-landing';

const renderArticleUl: RenderType = (
    props: RenderElementProps,
): JSX.Element => {
    const { domNode, options } = props;
    const { children } = domNode;

    return (
        <ArticleUnorderedList>
            {children && domToReact(children, options)}
        </ArticleUnorderedList>
    );
};

export default renderArticleUl;
