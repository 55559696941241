import parse from 'html-react-parser';
import { useMemo } from 'react';

import {
    renderAnchor,
    renderArticleImg,
    renderArticleLi,
    renderArticleOl,
    renderArticleP,
    renderArticleQuote,
    renderArticleReference,
    renderArticleUl,
    renderArticleVideo,
    renderDynamicMedia,
    renderFlashCards,
    renderStyle,
    renderQuiz,
    renderCustomComponent,
    renderPowerOfTime,
    renderStartingLate,
} from './functions';

/* interfaces */
import {
    FragmentDataType,
    HtmlRenderDictionary,
    RenderElement,
} from './interfaces';
import generateOptions from './options';

let currentIndex: number;

//helper function to increment the index
const renderJson: RenderElement = (props) => {
    props.index = currentIndex++;
    return renderCustomComponent(props);
};

const HtmlRenderMap: HtmlRenderDictionary = {
    a: renderAnchor,
    dynamicMediaPlayer: renderDynamicMedia,
    flashcards: renderFlashCards,
    img: renderArticleImg,
    li: renderArticleLi,
    ol: renderArticleOl,
    p: renderArticleP,
    quote: renderArticleQuote,
    references: renderArticleReference,
    style: renderStyle,
    ul: renderArticleUl,
    video: renderArticleVideo,
    'mm-quiz': renderQuiz,
    'mm-json': renderJson,
    'power-of-time': renderPowerOfTime,
    'starting-late': renderStartingLate,
};

export const parseReplace = (
    html: string | undefined,
    data?: FragmentDataType,
) => {
    if (!html) return;
    return parse(html, generateOptions({ data, HtmlRenderMap }));
};

// if we don't use memo generateOptions will get executed multiple times, hence hard to know the current index for  renderJson
export const useParseReplace = (
    html: string | undefined,
    data?: FragmentDataType,
) => {
    currentIndex = 0;
    return useMemo(
        () => parse(html ? html : '', generateOptions({ data, HtmlRenderMap })),
        [data, html],
    );
};
