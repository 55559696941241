import React, { FC, useEffect, useRef } from 'react';
import VisibilitySensor from 'react-visibility-sensor';

/* material */
import { Breakpoint } from '@material-ui/core/styles/createBreakpoints';

/* components */
import HeroLayout from 'components/hero-layout/HeroLayout';

import {
    multiMediaStartedEvent,
    multiMediaCompletedEvent,
    multiMediaPlayTimeEvent,
    multiMediaReached25Event,
    multiMediaReached50Event,
    multiMediaReached75Event,
} from '../../analytics';

/* utils */
import { Typography, Box } from '@material-ui/core';
import { pxToRem } from 'theme/util';

type DynamicMediaPlayerProps = {
    params: { [key: string]: string } | undefined;
    title?: string;
    playOnVisible?: boolean;
    fullWidthUntil?: Breakpoint;
    alt?: string;
    noConstraints?: boolean;
    completedCallBack?: () => void;
};

const setAriaAttribute = (videoContainerId: string, videoInfoId: string) => {
    document
        .querySelector(`#${videoContainerId}_videoPlayer > video`)
        ?.setAttribute('aria-describedby', videoInfoId);
};

const DynamicMediaPlayer: FC<DynamicMediaPlayerProps> = React.memo(
    ({
        title = '',
        alt,
        params,
        playOnVisible = false,
        fullWidthUntil = 'sm',
        noConstraints = false,
        completedCallBack,
    }) => {
        const randNum = new Date().getTime();
        let playedOnVisible = false;
        const videoContainerId = `video-container-${randNum}`;
        const videoInfoId = `video-info-${randNum}`;
        const s7videoviewer = useRef<any>();

        useEffect(() => {
            s7videoviewer.current && s7videoviewer.current.dispose();
            if (params) {
                //in order to autoplay to work, the video should be muted
                if (playOnVisible) {
                    const muteValue = '0';
                    params.mutevolume = muteValue;
                }

                if (window.s7viewers) {
                    s7videoviewer.current = new window.s7viewers.VideoViewer({
                        containerId: videoContainerId,
                        params,
                        handlers: {
                            initComplete: () => {
                                setAriaAttribute(videoContainerId, videoInfoId);
                            },
                            trackEvent: function (
                                _objID: string,
                                _compClass: string,
                                _instName: string,
                                _timeStamp: number,
                                eventInfo: string,
                            ) {
                                const eventInfoValues = eventInfo.split(',');
                                const action = eventInfoValues[0];
                                const viewPercentage = eventInfoValues[1];
                                const seconds = (_timeStamp % 60000) / 1000;

                                if (action === 'PLAY') {
                                    multiMediaStartedEvent(title, 'video');
                                }

                                if (action === 'MILESTONE') {
                                    if (viewPercentage === '25') {
                                        multiMediaReached25Event(
                                            title,
                                            'video',
                                        );
                                    } else if (viewPercentage === '50') {
                                        multiMediaReached50Event(
                                            title,
                                            'video',
                                        );
                                    } else if (viewPercentage === '75') {
                                        multiMediaReached75Event(
                                            title,
                                            'video',
                                        );
                                    }
                                }

                                if (action === 'PLAYBACK_COMPLETE') {
                                    multiMediaCompletedEvent(title, 'video');
                                    multiMediaPlayTimeEvent(
                                        title,
                                        'video',
                                        seconds,
                                    );
                                    if (completedCallBack) {
                                        completedCallBack();
                                    }
                                }
                            },
                        },
                    });
                    s7videoviewer.current.init();
                }
            }

            return () => {
                s7videoviewer.current && s7videoviewer.current.dispose();
                s7videoviewer.current = null;
            };
        }, [
            params,
            videoContainerId,
            videoInfoId,
            playOnVisible,
            title,
            completedCallBack,
        ]);

        const onVisibilityChange = (isVisible: boolean) => {
            try {
                if (isVisible && !playedOnVisible) {
                    s7videoviewer.current.videoplayer.play();
                    playedOnVisible = true;
                } else {
                    if (s7videoviewer?.current?.videoplayer) {
                        s7videoviewer.current.videoplayer.pause();
                    }
                }
            } catch (e) {
                console.log('oh well');
            }
        };

        //video already is added to the dom
        if (document.getElementById(videoContainerId)) {
            return null;
        }

        if (noConstraints) {
            return playOnVisible ? (
                <VisibilitySensor
                    onChange={onVisibilityChange}
                    partialVisibility
                    resizeDelay={500}
                >
                    <div data-qa="dynamic-media-player" id={videoContainerId} />
                </VisibilitySensor>
            ) : (
                <div data-qa="dynamic-media-player" id={videoContainerId} />
            );
        }

        return (
            <Box
                data-qa="dynamic-media-player"
                minHeight={{ xs: pxToRem(200), sm: pxToRem(415) }}
            >
                <HeroLayout fullWidthUntil={fullWidthUntil}>
                    {playOnVisible ? (
                        <VisibilitySensor
                            onChange={onVisibilityChange}
                            partialVisibility
                        >
                            <div id={videoContainerId} />
                        </VisibilitySensor>
                    ) : (
                        <div id={videoContainerId} />
                    )}
                </HeroLayout>
                <Typography id={videoInfoId} variant="srOnly">
                    {alt}
                </Typography>
            </Box>
        );
    },
    function (prevProps, nextProps) {
        if (!prevProps.params || !nextProps.params) return false;
        return prevProps.params.asset === nextProps.params.asset;
    },
);

DynamicMediaPlayer.displayName = 'DynamicMediaPlayer';
export default DynamicMediaPlayer;
