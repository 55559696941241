import React, { FC, useState } from 'react';

/* material */
import { makeStyles, Typography } from '@material-ui/core';

/* components */
import { SimpleModal } from 'components';
import { AVENIR_NEXT_LT_DEMI } from 'theme/font';
import { pxToRem } from 'theme/util';
import Button from '@material-ui/core/Button';
import { FormattedMessage } from 'react-intl';

type TransitionToRetirementConfig = {
    title: string;
    desc: string;
    imagePath: string;
};

type TransitionToRetirementProps = {
    data: {
        toolConfig: TransitionToRetirementConfig;
    };
};

const useStyles = makeStyles((theme) => ({
    root: {
        textAlign: 'center',
        padding: `${pxToRem(60)} ${pxToRem(20)} 0`,
        width: '100%',
        marginBottom: pxToRem(30),

        [theme.breakpoints.up('sm')]: {
            boxShadow: '0 15px 30px -1px rgba(0, 0, 0, 0.27)',
            padding: `${pxToRem(30)} ${pxToRem(88)} ${pxToRem(46)}`,
        },
    },
    surveyRoot: {
        textAlign: 'center',
        padding: `${pxToRem(20)} ${pxToRem(0)}`,
        width: '100%',

        [theme.breakpoints.up('sm')]: {
            padding: `${pxToRem(40)} ${pxToRem(20)} ${pxToRem(20)}`,
        },
    },
    title: {
        color: theme.colors.newBlue,
        fontFamily: AVENIR_NEXT_LT_DEMI,
        textAlign: 'center',
        fontSize: pxToRem(25),
        [theme.breakpoints.up('sm')]: {
            fontSize: pxToRem(30),
        },
    },
    description: {
        fontSize: pxToRem(16),
        padding: `${pxToRem(20)} ${pxToRem(16)}`,
        lineHeight: 2,

        [theme.breakpoints.up('sm')]: {
            fontSize: pxToRem(22),
            lineHeight: 1.3,
            padding: `${pxToRem(30)} 0`,
        },
    },
    image: {
        width: '100%',
        maxWidth: 400,
        marginBottom: pxToRem(40),
    },
}));

const TransitionToRetirementTool: FC<TransitionToRetirementProps> = () => {
    const [modalVisible, setModalVisible] = useState<boolean>(false);
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <Typography className={classes.title} variant="h3" component="h1">
                <FormattedMessage id="transitioningToRetirementTool.title" />
            </Typography>
            <Typography
                className={classes.description}
                component="p"
                variant="body1"
            >
                <FormattedMessage id="transitioningToRetirementTool.description" />
            </Typography>
            <div>
                <img
                    className={classes.image}
                    alt="alt text"
                    src="https://survey.co1.qualtrics.com/CP/Graphic.php?IM=IM_9FTJ7RSIu3FoyIm"
                />
            </div>
            <Button
                data-analytics-label="trackLink : buttonClick"
                data-analytics-id="Start survey"
                data-analytics-placement="TTR Survey"
                onClick={() => setModalVisible(true)}
                variant="contained"
            >
                <FormattedMessage id="transitioningToRetirementTool.startButton" />
            </Button>
            <SimpleModal
                dataQA="ttr-modal"
                closeButton
                onClose={() => setModalVisible(false)}
                isModalOpen={modalVisible}
            >
                <div
                    className={classes.surveyRoot}
                    dangerouslySetInnerHTML={{
                        __html: " <iframe width='100%' height='700px' frameBorder='0' src='https://survey.co1.qualtrics.com/jfe/form/SV_2ghVUZHWtoAx82y'/>",
                    }}
                />
            </SimpleModal>
        </div>
    );
};

export default TransitionToRetirementTool;
