import { SET_ERROR, CLEAR_ERROR } from 'constants/error';

export type SET_ERROR = typeof SET_ERROR;
export type CLEAR_ERROR = typeof CLEAR_ERROR;

/* actions */
export type SetErrorAction = {
    type: SET_ERROR;
    error: Error;
};

export type ClearErrorAction = {
    type: CLEAR_ERROR;
    error: null;
};

/* action creator */
export const setError = (error: Error): SetErrorAction => ({
    type: SET_ERROR,
    error,
});

export const clearError = (): ClearErrorAction => ({
    type: CLEAR_ERROR,
    error: null,
});

export type ErrorActionTypes = SetErrorAction | ClearErrorAction;
