import React from 'react';

/* components */
import { AspectRatio, HeroLayout } from 'components';
import ReactPlayer from 'react-player';

/* interfaces */
import { RenderElement } from '../interfaces';

/* utils */
import { getCustomTagTextChildren } from '../utils';

const renderArticleVideo: RenderElement = (props): JSX.Element => {
    const {
        domNode: { children },
    } = props;
    const textElements = children && getCustomTagTextChildren(children);
    const url = textElements && textElements[1].data;

    const handleError = () => {
        // TODO handle video error
        console.log('video error');
    };

    return (
        <HeroLayout fullWidthUntil="sm">
            <AspectRatio>
                <ReactPlayer
                    config={{
                        file: {
                            attributes: { controlsList: 'nodownload' },
                        },
                    }}
                    data-qa="article-video"
                    onError={handleError}
                    height="100%"
                    width="100%"
                    controls
                    url={url}
                />
            </AspectRatio>
        </HeroLayout>
    );
};

export default renderArticleVideo;
