import React from 'react';

/* material */
import { makeStyles } from '@material-ui/core/styles';
import VideoIcon from '@material-ui/icons/Videocam';

/* utils */
import colors from 'theme/colors';

const useStyles = makeStyles({
    videoIcon: {
        background: colors.white,
        color: colors.black,
        width: 45,
        height: 45,
        borderRadius: '50%',
        padding: 10,
        boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.25)',
    },
});

const VideoCamIcon = () => {
    const classes = useStyles();
    return <VideoIcon data-qa="VideoIcon" className={classes.videoIcon} />;
};

export default VideoCamIcon;
