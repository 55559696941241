import React from 'react';
import { makeStyles } from '@material-ui/core';

import { AVENIR_NEXT_LT_REGULAR, AVENIR_NEXT_LT_BOLD } from 'theme/font';
import { pxToRem } from 'theme/util';
//import { FormattedMessage, useIntl } from 'react-intl';
import SectionWrapper from '../components/SectionWrapper';
import colors from 'theme/colors';
import { parseReplace } from 'utils/htmlParser';
import TopPersonImage from '../../../assets/thumb-jocelyn.png';
import BottomPersonImage from '../../../assets/thumb-carlos.png';
//import colors from 'theme/colors';

interface StyleProps {
    dpRatio: number;
}

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: '#364F6B',
        borderRadius: 10,
        minHeight: (props: StyleProps) =>
            props.dpRatio === 1.5 ? '800px' : 'calc(100vh - 100px)',
        width: '100%',
        fontFamily: AVENIR_NEXT_LT_REGULAR,
        color: colors.white,
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.up('md')]: {
            width: '98%',
        },
    },
    wrapper: {
        padding: 50,
        width: '100%',
        //height: 500,
    },
    title: {
        fontSize: pxToRem(25),
        lineHeight: '41px',
        [theme.breakpoints.up(768)]: {
            fontSize: pxToRem(45),
            lineHeight: '39px',
        },
    },
    content: {
        fontSize: pxToRem(16),
        lineHeight: '20px',
        fontWeight: 600,
        [theme.breakpoints.up(768)]: {
            fontSize: pxToRem(20),
            lineHeight: '26px',
            paddingRight: 100,
        },
        '&& strong': {
            fontFamily: AVENIR_NEXT_LT_BOLD,
        },
    },
    flexWrapper: {
        display: 'flex',
        alignItems: 'center',
        marginTop: 25,
    },
    leftItem: {
        textAlign: 'center',
        width: '10%',
        maxWidth: 100,
        fontSize: pxToRem(12),
        fontWeight: 600,
        '&& img': {
            width: '90%',
        },
        [theme.breakpoints.up(768)]: {
            fontSize: pxToRem(16),
        },
    },
    centerTopItem: {
        width: '80%',
        marginLeft: 20,
    },
    centerBottomItem: {
        width: '80%',
        marginLeft: 20,
    },
    chartTitle: {
        marginTop: 25,
        marginLeft: 'unset',
        fontSize: pxToRem(16),
        lineHeight: '26px',
        [theme.breakpoints.up(768)]: {
            fontSize: pxToRem(20),
            lineHeight: '26px',
            marginTop: 28,
            marginLeft: '12%',
        },
    },
    rightTopItem: {
        width: '10%',
        marginLeft: 20,
        fontSize: pxToRem(18),
        fontWeight: 600,
        [theme.breakpoints.up(768)]: {
            fontSize: pxToRem(20),
        },
    },
    rightBottomItem: {
        width: '10%',
        marginLeft: '-30%',
        fontSize: pxToRem(18),
        fontWeight: 600,
        [theme.breakpoints.up(768)]: {
            fontSize: pxToRem(20),
            marginLeft: '-40%',
        },
    },
    topBar: {
        backgroundColor: '#B42573',
        width: '100%',
        height: 94,
        display: 'flex',
        alignItems: 'center',
        paddingLeft: 10,
        fontSize: pxToRem(14),
        fontWeight: 400,
        lineHeight: '26px',
        filter: 'drop-shadow(5px 5px 10px rgba(0, 0, 0, 0.36))',
        [theme.breakpoints.up(768)]: {
            fontSize: pxToRem(20),
            lineHeight: '26px',
            paddingLeft: 20,
        },
    },
    bottomBar: {
        backgroundColor: '#61AB8B',
        width: '55%',
        height: 94,
        display: 'flex',
        alignItems: 'center',
        paddingLeft: 10,
        fontSize: pxToRem(14),
        fontWeight: 400,
        lineHeight: '26px',
        filter: 'drop-shadow(5px 5px 10px rgba(0, 0, 0, 0.36))',
        [theme.breakpoints.up(768)]: {
            width: '46%',
            fontSize: pxToRem(20),
            paddingLeft: 20,
        },
    },
}));

interface SectionProps {
    isMobile?: boolean;
    data: any;
}

const Section3: React.FC<SectionProps> = ({
    //isMobile = false,
    data,
}) => {
    const classes = useStyles({ dpRatio: window.devicePixelRatio });

    if (!data) {
        return null;
    }

    return (
        <SectionWrapper sectionId="section_3">
            <div className={classes.root} data-qa="section_3">
                <div className={classes.wrapper}>
                    <h2 className={classes.title}>{data.title}</h2>

                    <div className={classes.content}>
                        {parseReplace(data.content)}
                    </div>

                    <div className={classes.chartTitle}>
                        {parseReplace(data.subTitle)}
                    </div>

                    <div className={classes.flexWrapper}>
                        <div className={classes.leftItem}>
                            <img
                                alt={data.persontopName}
                                src={TopPersonImage}
                            />
                            <br />
                            {data.persontopName}
                        </div>
                        <div className={classes.centerTopItem}>
                            <div className={classes.topBar}>
                                {data.persontopNote}
                            </div>
                        </div>
                        <div className={classes.rightTopItem}>
                            <div>{data.persontopValue}</div>
                        </div>
                    </div>

                    <div className={classes.flexWrapper}>
                        <div className={classes.leftItem}>
                            <img
                                alt={data.personBottomName}
                                src={BottomPersonImage}
                            />
                            <br />
                            {data.personBottomName}
                        </div>
                        <div className={classes.centerBottomItem}>
                            <div className={classes.bottomBar}>
                                {data.personBottomNote}
                            </div>
                        </div>
                        <div className={classes.rightBottomItem}>
                            <div>{data.personBottomValue}</div>
                        </div>
                    </div>
                </div>
            </div>
        </SectionWrapper>
    );
};

export default Section3;
