import React, { FC, useState } from 'react';

import { useIntl } from 'react-intl';

import { Tooltip as MuiTooltip, IconButton } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { body2 } from 'theme/font';
import colors from 'theme/colors';

const LightTooltip = withStyles((theme) => ({
    tooltip: {
        ...body2,
        backgroundColor: colors.white,
        border: `solid 1px ${colors.symphonyBlue50}`,
        borderRadius: '2px',
        boxShadow: theme.shadows[3],
        margin: '8px 0',
        maxWidth: '300px',
        padding: '14px 16px',
        textAlign: 'left',
        whiteSpace: 'normal',
        width: '100%',
    },
    arrow: {
        color: colors.symphonyBlue50,
        fontSize: '16px',

        '&::after': {
            content: '""',
            display: 'block',
            borderRight: '8px solid transparent',
            borderLeft: '8px solid transparent',
            borderBottom: `8px solid #fff`,
            height: '0',
            left: '0',
            position: 'absolute',
            top: '5px',
            width: '0',
        },
    },
}))(MuiTooltip);

const useStyles = makeStyles(() => ({
    icon: {
        padding: '0 6px',
        '&:hover': {
            backgroundColor: 'transparent',
        },
        '& .MuiSvgIcon-root': {
            fontSize: '22px',
        },
    },
}));

type TooltipProps = {
    content: string;
    icon: React.ReactElement;
    'data-qa'?: string;
};

const Tooltip: FC<TooltipProps> = ({ content, icon, 'data-qa': dataQa }) => {
    const intl = useIntl();
    const [open, setOpen] = useState(false);

    const handleTooltipClose = () => {
        setOpen(false);
    };

    const handleTooltipOpen = () => {
        setOpen(true);
    };

    const classes = useStyles();

    return (
        <ClickAwayListener onClickAway={handleTooltipClose}>
            <LightTooltip
                arrow
                PopperProps={{
                    disablePortal: false,
                }}
                onClose={handleTooltipClose}
                open={open}
                disableFocusListener
                disableHoverListener
                disableTouchListener
                title={content}
            >
                <IconButton
                    data-qa={dataQa}
                    className={classes.icon}
                    onClick={handleTooltipOpen}
                    aria-label={intl.formatMessage({ id: 'moreInfoTooltip' })}
                >
                    {icon}
                </IconButton>
            </LightTooltip>
        </ClickAwayListener>
    );
};

export default Tooltip;
