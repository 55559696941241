import React, { FC } from 'react';
import { Link } from 'react-router-dom';

/* material */
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

/* components */
import { AspectRatio } from 'components';

/* utils */
import { pxToRem } from 'theme/util';
import colors from 'theme/colors';
import { AVENIR_NEXT_LT_DEMI } from 'theme/font';
import { customClickEvent } from 'analytics';

type StaticContentCardProps = {
    image: '*.svg' | '*.png' | '*.jpg' | '*.jpeg';
    description: string | React.ReactElement;
    url: string;
    contentMinutes?: string | React.ReactElement;
};

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: colors.white,
        border: 'solid 1px #F5F5F5',
        boxShadow: '0 2px 4px 0 rgba(209,209,209,0.5)',
        maxWidth: '200px',
        minWidth: '200px',
        paddingBottom: '20px',
        position: 'relative',
        textDecoration: 'none',

        '&:hover': {
            border: 'solid 1px #DCDCDC',
        },
    },
    media: {
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        height: '100%',
    },
    title: {
        color: colors.black80,
        fontSize: pxToRem(14),
        lineHeight: pxToRem(20),

        [theme.breakpoints.up('sm')]: {
            fontSize: pxToRem(18),
            lineHeight: pxToRem(26),
        },
    },
    contentWrapper: {
        margin: '16px 15px',
        textAlign: 'left',
    },
    minuteView: {
        bottom: '13px',
        color: colors.gray60,
        fontFamily: AVENIR_NEXT_LT_DEMI,
        fontSize: pxToRem(10),
        left: '15px',
        letterSpacing: pxToRem(1.2),
        lineHeight: pxToRem(16),
        position: 'absolute',
        [theme.breakpoints.up('sm')]: {
            fontSize: pxToRem(12),
        },
    },
}));

const StaticContentCard: FC<StaticContentCardProps> = ({
    image,
    description,
    url,
    contentMinutes,
}) => {
    const classes = useStyles();

    return (
        <Link
            onClick={() => {
                customClickEvent({
                    customLinkName: `quiz result: ${description}`,
                });
            }}
            className={classes.root}
            to={url}
            data-qa="static-content-card"
        >
            <AspectRatio>
                <div
                    className={classes.media}
                    style={{ backgroundImage: `url('${image}')` }}
                    data-qa="static-content-card-image"
                />
            </AspectRatio>
            <div className={classes.contentWrapper}>
                <div>
                    <Typography
                        className={classes.title}
                        variant="h5"
                        component="h2"
                        data-qa="content-card-title"
                    >
                        {description}
                    </Typography>
                </div>
            </div>
            {contentMinutes && (
                <div className={classes.minuteView}>{contentMinutes}</div>
            )}
        </Link>
    );
};

export default StaticContentCard;
