import { put, call } from 'redux-saga/effects';
import queryString from 'query-string';
import { push } from 'connected-react-router';

/* actions */
import { loaderAsyncHide, loaderAsyncShow } from 'actions/loader';
import {
    CalculateRetirementProjectionUpdateAction,
    calculateRetirementProjectionUpdateSuccess,
    calculateRetirementProjectionUpdateError,
    CalculateRetirementProjectionsAction,
    calculateRetirementProjectionsSuccess,
    calculateRetirementProjectionsError,
    getRetirementContributionConstantsError,
    getRetirementContributionConstantsSuccess,
} from 'actions/calculator';

import CalculatorService from 'services/calculator';
import {
    CONTRIBUTION_PERCENTAGE_KEY,
    AUTO_INCREASE_KEY,
} from 'constants/calculator';
import { RetirementProjectionValues } from 'containers/calculator/calculator.type';

const DEFAULT_CALC_PARAMS = 'calc_page=7';

export function* calculateRetirementProjectionUpdate(
    params: CalculateRetirementProjectionUpdateAction,
) {
    try {
        yield put(loaderAsyncShow());

        const { payload, contributionPercentage, autoIncreasePercentage } =
            params;
        const payloadWithIncreasedContribution = {
            ...payload,
            [CONTRIBUTION_PERCENTAGE_KEY]: contributionPercentage,
            [AUTO_INCREASE_KEY]: autoIncreasePercentage,
        };

        const res: Response = yield call(
            CalculatorService.fetchRetirementCalculations,
            `${queryString.stringify(
                payloadWithIncreasedContribution,
            )}&${DEFAULT_CALC_PARAMS}`,
        );

        if (!res.ok)
            throw new Error('Error while fetching retirment calculations');

        const json: RetirementProjectionValues = yield call([res, 'json']);
        const projectionPayload = {
            contributionPercentage,
            projection: json,
        };

        yield put(
            calculateRetirementProjectionUpdateSuccess(projectionPayload),
        );
    } catch (e) {
        yield put(calculateRetirementProjectionUpdateError(e));
    } finally {
        yield put(loaderAsyncHide());
    }
}

export function* calculateRetirementProjections(
    params: CalculateRetirementProjectionsAction,
) {
    try {
        yield put(loaderAsyncShow());

        const {
            payload,
            redirectToResults,
            companyId,
            autoIncreasePercentage,
        } = params;
        const increasedContributionPercentageByOne =
            (payload[CONTRIBUTION_PERCENTAGE_KEY] as number) + 1;
        const payloadWithIncreasedContribution = {
            ...payload,
            [CONTRIBUTION_PERCENTAGE_KEY]: increasedContributionPercentageByOne,
            [AUTO_INCREASE_KEY]: autoIncreasePercentage,
        };

        /**
         * Make calls in series as Dinkytown's api does not support
         * parallel calls due to sessions.
         */
        const res: Response = yield call(
            CalculatorService.fetchRetirementCalculations,
            `${queryString.stringify(payload)}&${DEFAULT_CALC_PARAMS}`,
        );

        if (!res.ok)
            throw new Error('Error while fetching retirment calculations');

        const resWithIncreasedContribution: Response = yield call(
            CalculatorService.fetchRetirementCalculations,
            `${queryString.stringify(
                payloadWithIncreasedContribution,
            )}&${DEFAULT_CALC_PARAMS}`,
        );

        if (!resWithIncreasedContribution.ok)
            throw new Error('Error while fetching retirment calculations');

        const json: RetirementProjectionValues = yield call([res, 'json']);
        const jsonWithIncreasedContribution: RetirementProjectionValues =
            yield call([resWithIncreasedContribution, 'json']);

        const projectionPayload = {
            contributionPercentageIncrease:
                increasedContributionPercentageByOne,
            projection: json,
            projectionWithContributionPercentageIncrease:
                jsonWithIncreasedContribution,
            values: payload,
        };

        yield put(calculateRetirementProjectionsSuccess(projectionPayload));

        redirectToResults &&
            (yield put(push(`/${companyId}/calculator/projections`)));
    } catch (e) {
        yield put(calculateRetirementProjectionsError(e));
    } finally {
        yield put(loaderAsyncHide());
    }
}

export function* getRetirementContributionConstants() {
    try {
        const res: Response = yield call(
            CalculatorService.fetchCalculatorConstants,
        );

        if (!res.ok)
            throw new Error(
                'Error while fetching retirment contribution constants',
            );

        const json = yield call([res, 'json']);
        const {
            maxEmployeeContributionsWhenMeetingMinAgeForAdditionalContributionLimits,
            maxEmployeeContributionsWhenNotMeetingMinAgeForAdditionalContributionLimits,
            minAgeForAdditionalContributionLimits,
        } = json;

        yield put(
            getRetirementContributionConstantsSuccess({
                maxEmployeeContributionsWhenMeetingMinAgeForAdditionalContributionLimits,
                maxEmployeeContributionsWhenNotMeetingMinAgeForAdditionalContributionLimits,
                minAgeForAdditionalContributionLimits,
            }),
        );
    } catch (e) {
        yield put(getRetirementContributionConstantsError(e));
    }
}
