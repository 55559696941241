export const CONTRIBUTING_TO_401K = 'contributingTo401k';
export const HOW_FAR_RETIREMENT = 'howFarRetirement';
export const PLANNING_FOR_RETIREMENT = 'planningForRetirement';
export const RETIREMENT_ROADBLOCK = 'retirementRoadblock';
export const RETIREMENT_VACATION = 'retirementVacation';
export const FINANCIAL_FUTURE_SENTIMENT = 'financialFutureSentiment';

export const QUIZ_OPTION_BEACH = 'quizOptionBeach';
export const QUIZ_OPTION_CITY = 'quizOptionCity';
export const QUIZ_OPTION_MOUNTAINS = 'quizOptionMountains';
export const QUIZ_OPTION_PARK = 'quizOptionPark';
export const QUIZ_MAN_PIGGY_BANK = 'quizManPiggyBank';
export const QUIZ_WOMAN_BASKET = 'quizWomanBasket';
export const QUIZ_WOMAN_MUG = 'quizWomanMug';
export const QUIZ_WOMAN_PHONE = 'quizWomanPhone';
export const QUIZ_WOMAN_LAPTOP = 'quizWomanLaptop';
export const QUIZ_OPTION_NEW_BEACH = 'quizOptionNewBeach';
export const QUIZ_OPTION_DINNER = 'quizOptionDinner';
export const QUIZ_OPTION_ROAD_TRIP = 'quizOptionRoadTrip';
export const QUIZ_OPTION_HIKE = 'quizOptionHike';

export const SET_PERSONA_QUIZ_SELECTION = 'SET_PERSONA_QUIZ_SELECTION';
export const RESET_PERSONA_QUIZ = 'RESET_PERSONA_QUIZ';

// Maps user's response to Quiz Roadblock question to article
export const QUIZ_ROADBLOCK_TO_ARTICLE_MAPPING: { [key: string]: string } = {
    debt: 'quiz-action-debt',
    upsAndDowns: 'quiz-action-econ',
    dayToDay: 'quiz-action-expenses',
};
