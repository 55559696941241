/* constants */
import {
    SET_IS_ENROLL,
    SET_SEGMENT,
    SET_UID,
    SET_LANGUAGE,
    SET_ANIMAL,
    SET_DOMAIN_NAME,
    SET_LAST_SENTIMENT_MODAL_VIEW_DATE,
    SET_LAST_SENTIMENT_ANSWER_DATE,
    SET_LAST_QUIZ_MODAL_VIEW_DATE,
    SET_LAST_QUIZ_TAKE_DATE,
    SET_IS_REVISIT,
} from 'constants/user';

export type SET_IS_ENROLL = typeof SET_IS_ENROLL;
export type SET_SEGMENT = typeof SET_SEGMENT;
export type SET_UID = typeof SET_UID;
export type SET_LANGUAGE = typeof SET_LANGUAGE;
export type SET_ANIMAL = typeof SET_ANIMAL;
export type SET_DOMAIN_NAME = typeof SET_DOMAIN_NAME;
export type SET_LAST_SENTIMENT_MODAL_VIEW_DATE =
    typeof SET_LAST_SENTIMENT_MODAL_VIEW_DATE;
export type SET_LAST_SENTIMENT_ANSWER_DATE =
    typeof SET_LAST_SENTIMENT_ANSWER_DATE;
export type SET_LAST_QUIZ_MODAL_VIEW_DATE =
    typeof SET_LAST_QUIZ_MODAL_VIEW_DATE;
export type SET_LAST_QUIZ_TAKE_DATE = typeof SET_LAST_QUIZ_TAKE_DATE;
export type SET_IS_REVISIT = typeof SET_IS_REVISIT;

/* actions */
export type SetUIDAction = {
    type: SET_UID;
    uid: string | null;
};

export type SetLanguageAction = {
    type: SET_LANGUAGE;
    language: string;
};

export type SetIsEnrollAction = {
    type: SET_IS_ENROLL;
    isEnroll: boolean;
};

export type SetSegmentAction = {
    type: SET_SEGMENT;
    segment: string;
};

export type SetAnimalAction = {
    type: SET_ANIMAL;
    animal: string;
};

export type SetDomainNameAction = {
    type: SET_DOMAIN_NAME;
    domainName: string;
};

export type SetLastSentimentModalViewDateAction = {
    type: SET_LAST_SENTIMENT_MODAL_VIEW_DATE;
    date: string;
};

export type SetLastSentimentAnswerDate = {
    type: SET_LAST_SENTIMENT_ANSWER_DATE;
    date: string;
};

export type SetLastQuizModalViewDateAction = {
    type: SET_LAST_QUIZ_MODAL_VIEW_DATE;
    date: string;
};

export type SetLastQuizTakeDateAction = {
    type: SET_LAST_QUIZ_TAKE_DATE;
    date: string;
};

export type SetIsReturnVisitAction = {
    type: SET_IS_REVISIT;
    isReturnVisit: boolean;
};

/* action creators */
export const setUID = (uid: string | null = null): SetUIDAction => ({
    type: SET_UID,
    uid,
});

export const setLanguage = (language: string): SetLanguageAction => ({
    type: SET_LANGUAGE,
    language,
});

export const setSegment = (segment: string): SetSegmentAction => ({
    type: SET_SEGMENT,
    segment,
});

export const setIsEnroll = (isEnroll: boolean): SetIsEnrollAction => ({
    type: SET_IS_ENROLL,
    isEnroll,
});

export const setAnimal = (animal: string): SetAnimalAction => ({
    type: SET_ANIMAL,
    animal,
});

export const setDomainName = (domainName: string): SetDomainNameAction => ({
    type: SET_DOMAIN_NAME,
    domainName,
});

export const setLastSentimentModalViewDate = (
    date: string,
): SetLastSentimentModalViewDateAction => ({
    type: SET_LAST_SENTIMENT_MODAL_VIEW_DATE,
    date,
});

export const setLastSentimentAnswerDate = (
    date: string,
): SetLastSentimentAnswerDate => ({
    type: SET_LAST_SENTIMENT_ANSWER_DATE,
    date,
});

export const setLastQuizModalViewDate = (
    date: string,
): SetLastQuizModalViewDateAction => ({
    type: SET_LAST_QUIZ_MODAL_VIEW_DATE,
    date,
});

export const setLastQuizTakeDate = (
    date: string,
): SetLastQuizTakeDateAction => ({
    type: SET_LAST_QUIZ_TAKE_DATE,
    date,
});

export const setIsReturnVisit = (
    isReturnVisit: boolean,
): SetIsReturnVisitAction => ({
    type: SET_IS_REVISIT,
    isReturnVisit,
});

export type UserActionTypes =
    | SetIsEnrollAction
    | SetUIDAction
    | SetSegmentAction
    | SetAnimalAction
    | SetDomainNameAction
    | SetLastSentimentModalViewDateAction
    | SetLastSentimentAnswerDate
    | SetLastQuizModalViewDateAction
    | SetLastQuizTakeDateAction
    | SetIsReturnVisitAction
    | SetLanguageAction;
