import * as React from 'react';
import { FC } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';

const EMOJI_HEIGHT = 75;
const EMOJI_WIDTH = 82;

const calcRelativeX = (x: number) => `${(x / EMOJI_WIDTH) * 100}%`;
const calcRelativeY = (y: number) => `${(y / EMOJI_HEIGHT) * 100}%`;

interface BaseEmojiProps {
    color: string;
    mouth: JSX.Element;
    tearOnHover?: boolean;
}
const useStyles = makeStyles(() => ({
    face: {
        borderRadius: '50%',
        height: '100%',
        position: 'relative',
        width: '100%',
        '&:hover': {
            '& $eye': {
                animation: '$blink 10s infinite',
            },
            '& $tear': {
                display: 'block',
            },
        },
    },
    eye: {
        backgroundColor: '#434343',
        position: 'absolute',
        height: calcRelativeY(8),
        borderRadius: '50%',
        top: calcRelativeY(28),
        width: calcRelativeX(8),
    },
    leftEye: {
        left: calcRelativeX(20),
    },
    rightEye: {
        left: calcRelativeX(54),
    },
    tear: {
        animation: '$tearDrop 2s ease-in infinite',
        background: '#548dff',
        borderRadius: '0 100% 40% 50%/0 50% 40% 100%',
        display: 'none',
        height: calcRelativeY(12),
        marginLeft: calcRelativeX(6),
        position: 'absolute',
        right: calcRelativeX(12),
        width: calcRelativeX(12),
    },
    '@keyframes blink': {
        '0%': {
            transform: 'scale(1, 1)',
        },
        '10%': {
            transform: 'scale(1, 1)',
        },
        '11%': {
            transform: 'scale(1, 0.1)',
        },
        '12%': {
            transform: 'scale(1, 1)',
        },
        '30%': {
            transform: 'scale(1, 1)',
        },
        '31%': {
            transform: 'scale(1, 0.1)',
        },
        '32%': {
            transform: 'scale(1, 1)',
        },
        '60%': {
            transform: 'scale(1, 1)',
        },
        '61%': {
            transform: 'scale(1, 0.1)',
        },
        '62%': {
            transform: 'scale(1, 1)',
        },
        '100%': {
            transform: 'scale(1, 1)',
        },
    },
    '@keyframes tearDrop': {
        '0%, 100%': {
            display: 'block',
            top: calcRelativeY(40),
            transform: 'rotate(45deg) scale(0)',
        },
        '25%': {
            display: 'block',
            transform: 'rotate(45deg) scale(1.5)',
        },
        '49.9%': {
            display: 'block',
            top: calcRelativeY(65),
            transform: 'rotate(45deg) scale(0)',
        },
        '50%': {
            display: 'block',
            top: calcRelativeY(40),
            transform: 'rotate(45deg) scale(0)',
        },
        '75%': {
            display: 'block',
            transform: 'rotate(45deg) scale(1.5)',
        },
        '99.9%': {
            display: 'block',
            top: calcRelativeY(65),
            transform: 'rotate(45deg) scale(0)',
        },
    },
}));

const BaseEmoji: FC<BaseEmojiProps> = ({ color, mouth, tearOnHover }) => {
    const classes = useStyles();
    //svg only used for the mouth which is hard to replicate using css only
    return (
        <div className={classes.face} style={{ backgroundColor: color }}>
            <div className={clsx(classes.eye, classes.leftEye)}></div>
            <div className={clsx(classes.eye, classes.rightEye)}></div>
            {tearOnHover && <span className={classes.tear} />}

            <svg
                preserveAspectRatio="xMidYMid meet"
                viewBox="0 0 82 75"
                width="100%"
                height="100%"
            >
                <g
                    id="Designed-Screens"
                    stroke="none"
                    strokeWidth="1"
                    fill="none"
                    fillRule="evenodd"
                >
                    {mouth}
                </g>
            </svg>
        </div>
    );
};

export default BaseEmoji;
