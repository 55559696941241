import React, { FC } from 'react';
import MythQuiz from '../authoredQuiz/MythQuiz';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { GENERIC } from 'constants/company';
//kept as any so that we can have multiple quiz types here
interface AuthoredQuizProps {
    type: string;
    data: any;
}

const AuthoredQuiz: FC<AuthoredQuizProps> = ({ type, data }) => {
    const { company } = useSelector((state: RootState) => ({
        company: state.company.theme,
    }));
    const companyId = company?.companyId || GENERIC;

    switch (type) {
        case 'myth':
            return <MythQuiz data={data} companyId={companyId} />;
        default:
            return null;
    }
};

export default AuthoredQuiz;
