import React from 'react';
import YoutubeStyleVideos from 'components/youtubeStyleVideos';

interface WatchSectionProps {
    isMobile?: boolean;
    videoListData: any;
    fullVideoConfig: any;
    isLatinGrammy: boolean;
    watch: any;
}

const WatchSection: React.FC<WatchSectionProps> = ({
    isMobile = false,
    videoListData,
    fullVideoConfig,
    isLatinGrammy,
    watch,
}) => {
    if (!videoListData) {
        return null;
    }

    return (
        <span data-testid="watch-section-wrapper">
            <YoutubeStyleVideos
                isMobile={isMobile}
                videoListData={videoListData}
                fullVideoConfig={fullVideoConfig}
                id="watch"
                dataTestId="watch-section"
                showBio={isLatinGrammy}
                title={watch.title}
                description={watch.desc}
                watchFullExperienceLabel={watch.watchFullExperienceLabel}
                showPodcast={Boolean(watch?.podcast)}
                podcast={watch.podcast}
                podcastBgColor={isLatinGrammy ? 'darkslategray' : '#41405b'}
                audioName={isLatinGrammy ? 'grammy latin' : 'grammy major'}
                fullVideoTitle={
                    isLatinGrammy
                        ? 'Latin Grammy full video'
                        : 'Grammy full video'
                }
            />
        </span>
    );
};

export default WatchSection;
