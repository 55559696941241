import { createMuiTheme } from '@material-ui/core';
import breakpoints from './breakpoints';
import button from './button';
import dialog from './dialog';
import colors from './colors';
import paper from './paper';
import typography from './typography';
import {
    AvenirNextLTComBold,
    AvenirNextLTComDemi,
    AvenirNextLTComRegular,
    body1,
    body2,
    h1,
    h2,
    h3,
    h4,
    subtitle1,
} from './font';
import { HTML_FONT_SIZE, pxToRem, THEME_SPACING } from './util';

// TODO Remove casting as any
const theme = createMuiTheme({
    breakpoints: {
        values: breakpoints,
    },
    badges: {
        textPaddingTop: '3px',
        styles: {
            new: {
                backgroundColor: colors.raspberry,
                color: colors.white,
            },
            star: {
                backgroundColor: colors.pacificBlue,
                color: colors.white,
            },
            featured: {
                backgroundColor: colors.turquoise,
                color: colors.white,
            },
            important: {
                backgroundColor: colors.turquoise,
                color: colors.white,
            },
        },
    },
    colors: colors,
    palette: {
        primary: {
            main: colors.turquoise5,
        },
        secondary: {
            main: colors.turquoise,
        },
        text: {
            primary: colors.black80,
        },
    },
    props: {
        MuiButton: {
            disableElevation: true,
        },
        MuiButtonBase: {
            disableRipple: true,
        },
    },
    shape: {
        borderRadius: 0,
    },
    spacing: THEME_SPACING,
    typography: {
        body1,
        body2,
        fontFamily: 'AvenirNextLTCom-Regular, Arial, Sans-Serif',
        htmlFontSize: HTML_FONT_SIZE,
        h1,
        h2,
        h3,
        h4,
        subtitle1,
        pxToRem,
    },
    overrides: {
        MuiCssBaseline: {
            '@global': {
                body: {
                    backgroundColor: colors.white,
                },
                '@font-face': [
                    AvenirNextLTComBold,
                    AvenirNextLTComDemi,
                    AvenirNextLTComRegular,
                ],
                button: {
                    backgroundColor: 'transparent',
                    border: 'none',
                    cursor: 'pointer',
                },
            },
        },
        MuiButtonBase: {
            root: {
                '&.Mui-focusVisible': {
                    outline: `solid 2px #86A8DF`,
                },
            },
        },
        MuiButton: button,
        MuiDialogActions: dialog.dialogActions,
        MuiDialogContent: dialog.dialogContent,
        MuiDialogContentText: dialog.dialogContentText,
        MuiDialogTitle: dialog.dialogTitle,
        MuiLink: {
            root: {
                color: colors.darkTurquoise,
            },
        },
        MuiPaper: paper,
        MuiTypography: typography,
    },
} as any);

export default theme;
