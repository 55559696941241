import createBreakpoints from '@material-ui/core/styles/createBreakpoints';

import { pxToRem } from './util';
import { AVENIR_NEXT_LT_DEMI } from './font';
import colors from './colors';
import breakpointsSettings from './breakpoints';

const breakpoints = createBreakpoints({
    values: breakpointsSettings,
});

const dialog = {
    dialogActions: {
        root: {
            padding: pxToRem(24),
            paddingTop: 0,
        },
    },
    dialogContent: {
        root: {
            paddingBottom: 0,
            paddingTop: 0,
        },
    },
    dialogContentText: {
        root: {
            fontSize: pxToRem(16),
            lineHeight: pxToRem(22),
            marginBottom: pxToRem(24),
            '&:last-child': {
                paddingBottom: 0,
            },

            [breakpoints.up('sm')]: {
                fontSize: pxToRem(18),
                lineHeight: pxToRem(28),
            },
        },
    },
    dialogTitle: {
        root: {
            paddingBottom: pxToRem(16),
            paddingTop: pxToRem(24),
            '& h2': {
                color: colors.symphonyBlue,
                fontFamily: AVENIR_NEXT_LT_DEMI,
                fontSize: pxToRem(18),
                [breakpoints.up('sm')]: {
                    fontSize: pxToRem(22),
                },
            },
        },
    },
};

export default dialog;
