import {
    RESET_PERSONA_QUIZ,
    SET_PERSONA_QUIZ_SELECTION,
} from 'constants/persona-quiz';

import { PersonaQuizActionTypes } from 'actions/persona-quiz';

export type PersonaQuizState = { [key: string]: string };

export const initialPersonaQuizState: PersonaQuizState = {};

export const personaQuizReducer = (
    state = initialPersonaQuizState,
    action: PersonaQuizActionTypes,
) => {
    switch (action.type) {
        case RESET_PERSONA_QUIZ:
            return initialPersonaQuizState;
        case SET_PERSONA_QUIZ_SELECTION:
            return {
                ...state,
                [action.questionKey]: action.value,
            };
        default:
            return state;
    }
};
