import React, { FC } from 'react';

/* material */
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

/* utils */
import { AVENIR_NEXT_LT_DEMI } from 'theme/font';
import { pxToRem } from 'theme/util';

const useStyles = makeStyles((theme) => ({
    root: {
        fontFamily: AVENIR_NEXT_LT_DEMI,
        lineHeight: pxToRem(31),
        [theme.breakpoints.up('sm')]: {
            lineHeight: pxToRem(39),
        },
    },
}));

const StaticHeader: FC = ({ children }) => {
    const classes = useStyles();
    return (
        <Typography
            data-qa="article-header"
            className={classes.root}
            variant="h3"
            component="h1"
        >
            {children}
        </Typography>
    );
};

export default StaticHeader;
