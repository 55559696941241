/* eslint-disable jsx-a11y/media-has-caption */
import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import PlayArrow from '@material-ui/icons/PlayArrow';
import PauseIcon from '@material-ui/icons/Pause';
import { AVENIR_NEXT_LT_REGULAR } from 'theme/font';
import { pxToRem } from 'theme/util';
import colors from 'theme/colors';

import {
    multiMediaStartedEvent,
    multiMediaCompletedEvent,
    multiMediaPlayTimeEvent,
    multiMediaReached25Event,
    multiMediaReached50Event,
    multiMediaReached75Event,
} from '../../analytics';

interface PodcastPlayerProps {
    podcast: any;
    audioName: string;
    backgroundColor?: string;
}

interface StyleProps {
    backgroundColor?: string;
}

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: 20,
        marginBottom: 0,
        padding: 20,
        color: colors.white,
        [theme.breakpoints.up('md')]: {
            padding: 'unset',
            marginTop: 50,
            marginBottom: 50,
        },
        fontFamily: AVENIR_NEXT_LT_REGULAR,
    },
    titleWrapper: {
        display: 'flex',
        alignItems: 'center',
    },
    title: {
        marginLeft: 10,
        fontWeight: 600,
        lineHeight: '24px',
        fontSize: pxToRem(20),
    },
    subTitle: {
        marginTop: 10,
        fontWeight: 400,
        lineHeight: '16.8px',
        fontSize: pxToRem(14),
        textAlign: 'center',
    },
    player: {
        width: '100%',
        marginTop: 25,
        display: 'flex',
        '&& .MuiLinearProgress-root': {
            height: 8,
            borderRadius: 0,
            backgroundColor: '#d9d9d9',
        },
        '&& .MuiLinearProgress-bar': {
            backgroundColor: '#b02d73',
        },
    },
    leftContainer: {
        width: 53,
        textAlign: 'right',
        marginRight: 10,
    },
    centerContainer: {
        flexGrow: 10,
        marginTop: 10,
    },
    rightContainer: {
        width: 125,
        fontSize: pxToRem(11),
        fontWeight: 600,
        lineHeight: '12px',
        paddingLeft: 10,
        marginTop: 10,
        [theme.breakpoints.up('md')]: {
            fontSize: pxToRem(12),
        },
    },
    circleWrapper: {
        marginTop: -10,
        height: 53,
        width: 53,
        backgroundColor: (props: StyleProps) =>
            props.backgroundColor ? props.backgroundColor : '#41405b',
        borderRadius: '50%',
        display: 'inline-block',
    },
    pauseButton: {
        marginTop: 1,
        marginLeft: -1,
        height: 35,
        width: 35,
        color: '#FFF',
    },
    playButton: {
        marginTop: 1,
        marginLeft: -1,
        height: 35,
        width: 35,
        color: '#FFF',
    },
    audioIcon: {
        dispaly: 'block',
    },
}));

const PodcastPlayer: React.FC<PodcastPlayerProps> = ({
    podcast,
    audioName,
    backgroundColor,
}) => {
    const [currentTime, setCurrentTime] = useState(0);
    const [totalTime, setTotalTime] = useState(0);
    const [isPlaying, setIsPlaying] = useState(false);
    const [percentHeard, setPercentHeard] = useState(0);
    const audioElementRef = useRef<HTMLAudioElement>(null);
    const classes = useStyles({ backgroundColor });

    useEffect(() => {
        const percentlistenedTo = Math.floor((currentTime / totalTime) * 100);
        if (percentlistenedTo > 25 && percentlistenedTo < 49) {
            setPercentHeard(25);
        }
        if (percentlistenedTo > 50 && percentlistenedTo < 74) {
            setPercentHeard(50);
        }
        if (percentlistenedTo > 75 && percentlistenedTo < 99) {
            setPercentHeard(75);
        }
        if (percentlistenedTo === 100) {
            setPercentHeard(100);
        }
    }, [currentTime, totalTime]);

    useEffect(() => {
        if (percentHeard > 0) {
            if (percentHeard === 25) {
                multiMediaReached25Event(audioName, 'audio');
            }
            if (percentHeard === 50) {
                multiMediaReached50Event(audioName, 'audio');
            }
            if (percentHeard === 75) {
                multiMediaReached75Event(audioName, 'audio');
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [percentHeard]);

    useEffect(() => {
        const audioElement = audioElementRef.current;
        if (audioElement) {
            const handleTimeUpdate = () => {
                setCurrentTime(audioElement.currentTime);
                setTotalTime(audioElement.duration);
            };

            const handleLoadedMetadata = () => {
                setTotalTime(audioElement.duration);
            };

            audioElement.addEventListener('timeupdate', handleTimeUpdate);
            audioElement.addEventListener(
                'loadedmetadata',
                handleLoadedMetadata,
            );

            return () => {
                audioElement.removeEventListener(
                    'timeupdate',
                    handleTimeUpdate,
                );
                audioElement.removeEventListener(
                    'loadedmetadata',
                    handleLoadedMetadata,
                );
            };
        }
    }, []);

    const handlePlayPause = () => {
        const audioElement = audioElementRef.current;
        if (audioElement) {
            if (isPlaying) {
                audioElement.pause();
            } else {
                multiMediaStartedEvent(audioName, 'audio');
                audioElement.play();
            }
            setIsPlaying(!isPlaying);
        }
    };

    const handleProgressBarClick = (
        event: React.MouseEvent<HTMLDivElement>,
    ) => {
        const progressBar = event.currentTarget;
        const rect = progressBar.getBoundingClientRect();
        const offsetX = event.clientX - rect.left;
        const clickedTime = (offsetX / progressBar.offsetWidth) * totalTime;
        setCurrentTime(clickedTime);
        const audioElement = audioElementRef.current;
        if (audioElement) {
            audioElement.currentTime = clickedTime;
            audioElement.play();
            if (!isPlaying) {
                multiMediaStartedEvent(audioName, 'audio');
            }
            setIsPlaying(true);
        }
    };

    const handleProgressBarKeyDown = () => {
        const audioElement = audioElementRef.current;
        if (audioElement) {
            audioElement.play();
        }
    };

    const handlePause = () => {
        const seconds = Math.floor(currentTime);

        setIsPlaying(false);
        if (percentHeard === 100) {
            multiMediaCompletedEvent(audioName, 'audio');
            multiMediaPlayTimeEvent(audioName, 'audio', seconds);
            setCurrentTime(0);
        }
    };

    const calculateProgressValue = () => {
        if (totalTime === 0) {
            return 0;
        }
        return (currentTime / totalTime) * 100;
    };

    const formatTime = (time: number) => {
        const minutes = Math.floor(time / 60);
        const seconds = Math.floor(time % 60);
        return `${minutes}:${seconds.toString().padStart(2, '0')}`;
    };

    return (
        <div className={classes.root} data-testid="podcast-player">
            <div className={classes.titleWrapper}>
                <img
                    alt="Listen to this experience"
                    className={classes.audioIcon}
                    src={podcast.titleIconPath}
                />
                <div className={classes.title}>{podcast.title}</div>
            </div>
            <div className={classes.player}>
                <div className={classes.leftContainer}>
                    <button
                        onClick={handlePlayPause}
                        className={classes.circleWrapper}
                    >
                        {isPlaying ? (
                            <PauseIcon className={classes.pauseButton} />
                        ) : (
                            <PlayArrow className={classes.playButton} />
                        )}
                    </button>
                </div>
                <div className={classes.centerContainer}>
                    <div
                        role="button"
                        tabIndex={0}
                        onKeyDown={handleProgressBarKeyDown}
                        onClick={handleProgressBarClick}
                        style={{ cursor: 'pointer' }}
                    >
                        <LinearProgress
                            color="primary"
                            variant="determinate"
                            value={calculateProgressValue()}
                        />
                    </div>
                    <div className={classes.subTitle}>{podcast.subTitle}</div>
                </div>
                <div className={classes.rightContainer}>
                    <span>{formatTime(currentTime)}</span> /{' '}
                    <span>{formatTime(totalTime)}</span>
                </div>

                <div></div>
                <audio
                    onPause={handlePause}
                    ref={audioElementRef}
                    src={podcast.audioUrl}
                />
            </div>
        </div>
    );
};

export default PodcastPlayer;
