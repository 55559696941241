import React, { useEffect } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useDispatch } from 'react-redux';
import Nav from './components/Nav';
import WatchSection from './sections/WatchSection';
import ListenSection from './sections/ListenSection';
import MeetTheArtistSection from './sections/MeetTheArtistSection';
import { pageViewCompleteEvent } from 'analytics';
import { safelyParseJson } from 'utils/parseJson';
import useAEMBaseUrl from 'hooks/useAEMBaseUrl';
import { Loader } from 'components';
import { useFetch } from 'hooks/useFetch';
import { setError } from 'actions/error';
import flat from 'flat';
import { IntlProvider } from 'react-intl';
import { Helmet } from 'react-helmet';

interface StyleProps {
    isLatinGrammy: boolean;
}

const useStyles = makeStyles((theme) => ({
    root: {
        minHeight: 'calc(100vh - 100px)',
        position: 'relative',
        paddingTop: 0,
        background: (props: StyleProps) =>
            props.isLatinGrammy
                ? 'linear-gradient(0.50turn, #11999E, #0D7F83)'
                : 'linear-gradient(0.50turn, #3a536f, #5d5180)',
        [theme.breakpoints.up('md')]: {
            background: (props: StyleProps) =>
                props.isLatinGrammy
                    ? 'linear-gradient(0.25turn, #11999E, #0D7F83)'
                    : 'linear-gradient(0.50turn, #3a536f, #5d5180)',
            //minWidth: 1465,
        },
        '&& .s7container': {
            backgroundColor: 'unset !important',
        },
    },
    centerWrapper: {
        display: 'flex',
        flexDirection: 'column',
        maxWidth: 1600,
        margin: '0px auto',
        [theme.breakpoints.up('md')]: {
            flexDirection: 'unset',
        },
    },
    content: {
        flex: 2,
        position: 'relative',
        marginLeft: 0,
        paddingTop: 100,
        width: '100%',
    },
}));

const ICRGrammy = () => {
    const isLatinGrammy = window.location.href
        .toLowerCase()
        .includes('latin-grammy');
    const classes = useStyles({ isLatinGrammy });
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down(960));
    const dispatch = useDispatch();

    const {
        data: grammyContentData,
        loading: isGrammyContentDataLoading,
        error: isGrammyContentDataLoadingError,
    } = useFetch<{ [key: string]: any } | undefined>({
        initialData: undefined,
        processData: (response: { data: string }) =>
            safelyParseJson(response.data),
        url: useAEMBaseUrl(
            `/content/fragments?path=/content/dam/my-retirement/language/${
                isLatinGrammy ? 'latin-grammy-landing' : 'grammy-landing'
            }/jcr:content/data/master.json`,
        ),
    });

    useEffect(() => {
        pageViewCompleteEvent();
    }, []);

    useEffect(() => {
        if (isGrammyContentDataLoadingError) {
            dispatch(
                setError(
                    new Error(
                        `error fetching ${
                            isLatinGrammy ? 'Latin Grammy' : 'Grammy AEM'
                        } content`,
                    ),
                ),
            );
        }
    }, [dispatch, isGrammyContentDataLoadingError, isLatinGrammy]);

    if (isGrammyContentDataLoading) {
        return (
            <div className={classes.root}>
                <div className={classes.content}>
                    <Loader />
                </div>
            </div>
        );
    }

    if (isGrammyContentDataLoadingError) {
        return (
            <div className={classes.root}>
                <div className={classes.content}>Error Loading</div>
            </div>
        );
    }

    const aemContent = grammyContentData;
    const messages: Record<string, string> = flat(aemContent);
    const videoArray = isLatinGrammy
        ? grammyContentData?.enLatinGrammyLanding?.watch?.videoList || null
        : grammyContentData?.enGrammyLanding?.watch?.videoList || null;
    const fullVideoConfig = isLatinGrammy
        ? grammyContentData?.enLatinGrammyLanding?.watch?.fullVideoConfig ||
          null
        : grammyContentData?.enGrammyLanding?.watch?.fullVideoConfig || null;

    const latinGrammyCards =
        grammyContentData?.enLatinGrammyLanding?.navigatorCards || null;
    const grammyCards =
        grammyContentData?.enGrammyLanding?.navigatorCards || null;

    return (
        <IntlProvider locale={'en-US'} messages={messages}>
            <Helmet>
                <title>Grammy Event</title>
                <meta
                    name="description"
                    content={
                        isLatinGrammy ? 'Latin Grammy Event' : 'Grammy Event'
                    }
                />
            </Helmet>

            <div className={classes.root}>
                <div
                    className={classes.centerWrapper}
                    data-testid="icr-Grammy-Experience"
                >
                    <Nav isMobile={isMobile} isLatinGrammy={isLatinGrammy} />
                    <div className={classes.content}>
                        <WatchSection
                            isMobile={isMobile}
                            videoListData={videoArray}
                            fullVideoConfig={fullVideoConfig}
                            isLatinGrammy={isLatinGrammy}
                            watch={
                                isLatinGrammy
                                    ? grammyContentData?.enLatinGrammyLanding
                                          ?.watch
                                    : grammyContentData?.enGrammyLanding?.watch
                            }
                        />
                        <ListenSection
                            isMobile={isMobile}
                            isLatinGrammy={isLatinGrammy}
                        />
                        <MeetTheArtistSection
                            isMobile={isMobile}
                            isLatinGrammy={isLatinGrammy}
                            cards={
                                isLatinGrammy ? latinGrammyCards : grammyCards
                            }
                        />
                    </div>
                </div>
            </div>
        </IntlProvider>
    );
};

export default ICRGrammy;
