import { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { UnregisterCallback } from 'history';

export type UseRouteGuardHook = {
    (cb: (redirect: string) => void): [
        React.MutableRefObject<UnregisterCallback>,
        string,
    ];
};

export const useRouteGuard: UseRouteGuardHook = (
    cb: (redirect: string) => void,
) => {
    const history = useHistory();
    const unblock = useRef<UnregisterCallback>(() => {});
    const [redirect, setRedirect] = useState<string>('');

    useEffect(() => {
        unblock.current = history.block((location) => {
            setRedirect(location.pathname);
            cb(location.pathname);
            return false;
        });

        return () => {
            unblock.current();
        };
    }, [history, cb]);

    return [unblock, redirect];
};
