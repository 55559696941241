import * as React from 'react';
import clsx from 'clsx';
import { FC, useState, useEffect, useRef, useCallback } from 'react';
import SwipeableViews from 'react-swipeable-views';

/* material */
import { Theme, makeStyles } from '@material-ui/core/styles';

/* utils */
import colors from 'theme/colors';
import { customClickEvent } from 'analytics';
import ChevronRight from '../../assets/Chevron_Right.svg';
import ChevronLeft from '../../assets/Chevron_Left.svg';

interface StyleProps {
    invertDotColors: boolean;
    dotColor?: string;
    activeDotColor?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
    carousel: {
        '&:focus': {
            outline: 'none',
        },
    },
    dots: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        marginTop: '20px',
        width: '100%',
    },
    dot: {
        backgroundColor: (props: StyleProps) =>
            props.dotColor
                ? props.dotColor
                : props.invertDotColors
                ? theme.colors.gry
                : theme.colors.drkGry,
        borderRadius: '50%',
        height: 8,
        margin: '0 2px',
        padding: 0,
        width: 8,
    },
    dotActive: {
        backgroundColor: (props: StyleProps) =>
            props.activeDotColor
                ? props.activeDotColor
                : props.invertDotColors
                ? theme.colors.drkGry
                : theme.colors.gry,
    },
    arrow: {
        border: `solid ${colors.nobelGray}`,
        borderWidth: '0 3px 3px 0',
        display: 'inline-block',
        padding: '10px',
    },
    forwardArrow: {
        transform: 'rotate(-45deg)',
        [theme.breakpoints.down('sm')]: {
            position: 'relative',
            float: 'right',
            right: '15px',
            bottom: '130px',
        },
        [theme.breakpoints.up('sm')]: {
            right: '200px',
        },
    },

    backwardArrow: {
        transform: 'rotate(135deg)',
        [theme.breakpoints.down('sm')]: {
            position: 'relative',
            bottom: '130px',
            top: '130px',
            left: '18px',
            zIndex: 1,
        },
        [theme.breakpoints.up('sm')]: {
            left: '200px',
        },
    },
}));

interface CarouselProps {
    /**
     * class name to be added  to the root element
     */
    className?: string;
    /**
     * class name to be added  to the dots element
     */
    dotClassName?: string;
    /**
     * color to be added  to the dot element
     */
    dotColor?: string;
    /**
     * color to be added  to the active dot element
     */
    activeDotColor?: string;
    /**
     * start automatically transitions. default: false
     */
    autoPlay?: boolean;
    /**
     * use with autoPlay time in ms to transition between slides, default: 400
     */
    interval?: number;
    /**
     * show bottom dots, default: true
     */
    indicators?: boolean;
    /**
     * controls whether slides should repeat or not, default: true
     */
    cycleNavigation?: boolean;
    /**
     * hovering on element should stop auto-animation, default: true
     */
    stopAutoPlayOnHover?: boolean;
    /**
     * show left and right arrows, default: false
     */
    showArrows?: boolean;
    leftArrowClassName?: string;
    rightArrowClassName?: string;
    invertDotColors?: boolean;
    children: JSX.Element[];
    arrowWithBackground?: boolean;
    arrowWithBackgroundRightClass?: string;
    arrowWithBackgroundLeftClass?: string;
}

const Carousel: FC<CarouselProps> = ({
    className,
    dotClassName,
    dotColor,
    activeDotColor,
    autoPlay = false,
    interval = 4000,
    indicators = true,
    cycleNavigation = true,
    stopAutoPlayOnHover = true,
    showArrows = false,
    leftArrowClassName,
    rightArrowClassName,
    invertDotColors = false,
    children,
    arrowWithBackground = false,
    arrowWithBackgroundRightClass,
    arrowWithBackgroundLeftClass,
}) => {
    const [currentStep, setCurrentStep] = useState<number>(0);
    const loop = useRef<NodeJS.Timeout>();
    const classes = useStyles({ invertDotColors, dotColor, activeDotColor });

    const handleNext = useCallback(() => {
        if (currentStep === children.length - 1) {
            if (cycleNavigation) {
                setCurrentStep(0);
            }
        } else {
            setCurrentStep(currentStep + 1);
        }
    }, [children.length, currentStep, cycleNavigation]);

    const handlePrevious = useCallback(() => {
        if (currentStep === 0) {
            if (cycleNavigation) {
                setCurrentStep(children.length - 1);
            }
        } else {
            setCurrentStep(currentStep - 1);
        }
    }, [children.length, currentStep, cycleNavigation]);

    const stop = useCallback(() => {
        if (loop.current) {
            clearInterval(loop.current);
            loop.current = undefined;
        }
    }, []);

    const start = useCallback(() => {
        if (!loop.current) {
            loop.current = setInterval(() => {
                handleNext();
            }, interval);
        }
    }, [handleNext, interval]);

    const reset = useCallback(() => {
        if (autoPlay) {
            start();
        }
    }, [autoPlay, start]);

    useEffect(() => {
        if (autoPlay) {
            start();
        }

        return function cleanup() {
            if (loop.current) {
                clearInterval(loop.current);
                loop.current = undefined;
            }
        };
    }, [autoPlay, start]);

    const handleDotClick = (step: number) => {
        setCurrentStep(step);
    };

    const onKeyPress = (event: React.KeyboardEvent) => {
        if (event.keyCode === 37) {
            handlePrevious();
        }
        if (event.keyCode === 39) {
            handleNext();
        }
    };

    return (
        <div
            role="listbox"
            tabIndex={0}
            aria-roledescription="carousel"
            onKeyPress={onKeyPress}
            className={clsx(classes.carousel, className ? className : null)}
            onFocus={() => {
                stopAutoPlayOnHover && stop();
            }}
            onBlur={() => {
                stopAutoPlayOnHover && reset();
            }}
            onMouseOver={() => {
                stopAutoPlayOnHover && stop();
            }}
            onMouseOut={() => {
                stopAutoPlayOnHover && reset();
            }}
        >
            {showArrows && (
                <span
                    data-qa="back-arrow-icon"
                    className={clsx(
                        leftArrowClassName,
                        classes.arrow,
                        classes.backwardArrow,
                    )}
                    onClick={handlePrevious}
                    role="button"
                    onKeyPress={onKeyPress}
                    tabIndex={0}
                />
            )}
            {arrowWithBackground && (
                <span
                    data-qa="back-arrow-icon"
                    className={arrowWithBackgroundLeftClass}
                    onClick={handlePrevious}
                    role="button"
                    onKeyPress={onKeyPress}
                    tabIndex={0}
                >
                    <img
                        alt="ChevronLeft"
                        height="32px"
                        width="32px"
                        src={ChevronLeft}
                    />
                </span>
            )}
            <SwipeableViews
                index={currentStep}
                onChangeIndex={(index) => setCurrentStep(index)}
                enableMouseEvents
            >
                {children}
            </SwipeableViews>
            {showArrows && (
                <span
                    data-qa="forward-arrow-icon"
                    className={clsx(
                        rightArrowClassName,
                        classes.arrow,
                        classes.forwardArrow,
                    )}
                    onClick={handleNext}
                    role="button"
                    onKeyPress={onKeyPress}
                    tabIndex={0}
                />
            )}
            {arrowWithBackground && (
                <span
                    className={arrowWithBackgroundRightClass}
                    data-qa="forward-arrow-icon"
                    onClick={handleNext}
                    role="button"
                    onKeyPress={onKeyPress}
                    tabIndex={0}
                >
                    <img
                        alt="ChevronRight"
                        height="32px"
                        width="32px"
                        src={ChevronRight}
                    />
                </span>
            )}
            {indicators && children.length > 1 && (
                <div
                    className={clsx(
                        classes.dots,
                        dotClassName ? dotClassName : null,
                    )}
                >
                    {[...new Array(children.length)].map((_, index) => (
                        <button
                            key={index}
                            data-qa={`carousel-dot-${index}`}
                            onClick={() => {
                                customClickEvent({
                                    customLinkName: `carousel button: Carousel Button ${index}`,
                                });
                                handleDotClick(index);
                            }}
                            className={clsx(classes.dot, {
                                [classes.dotActive]: index === currentStep,
                            })}
                        ></button>
                    ))}
                </div>
            )}
        </div>
    );
};

export default Carousel;
