import React, { FC, useEffect, useState } from 'react';

/* material */
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { parseReplace } from 'utils/htmlParser';
import { pxToRem } from 'theme/util';
import { customClickEvent } from 'analytics';

import { AVENIR_NEXT_LT_REGULAR } from 'theme/font';
import { CenterSelectionOption } from './AuthorableSplitSelection';

const minHeight = 800;
const useStyles = makeStyles((theme) => ({
    root: {
        fontFamily: AVENIR_NEXT_LT_REGULAR,
        flexGrow: 1,
        marginBottom: 50,
        marginTop: 50,
    },
    gridContainer: {
        justifyContent: 'center',
    },
    leftSection: {
        padding: theme.spacing(2),
        cursor: 'pointer',
        minHeight: minHeight,
    },
    middleSection: {
        padding: theme.spacing(3),
        color: '#FFFFFF',
        minHeight: minHeight,
        borderRadius: 10,
    },
    middleSectionTitle: {
        fontSize: pxToRem(128),
        lineHeight: 1,
    },
    middleSectionTitleFirstDigit: {
        fontWeight: 400,
    },
    middleSectionTitleSecondDigit: {
        fontWeight: 800,
    },
    middleSectionContent: {
        marginTop: 30,
        fontSize: pxToRem(24),
        lineHeight: '32px',
    },
    middleSectionFooter: {
        marginTop: 20,
        fontSize: pxToRem(24),
        lineHeight: '32px',
    },
    rightSection: {
        padding: theme.spacing(2),
        cursor: 'pointer',
        minHeight: minHeight,
        //border: 'solid 1px red',
    },
    option: {
        padding: 15,
        width: 263,
        height: 207,
        textAlign: 'center',
        fontSize: pxToRem(18),
    },
    optionTitleWrapper: {
        marginTop: 10,
    },
    optionSelected: {
        boxShadow: '0px 8px 10px -6px #0000001A',
        borderRadius: 10,
        fontWeight: 600,
    },
}));

interface DesktopViewProps {
    leftOptions?: [CenterSelectionOption];
    rightOptions?: [CenterSelectionOption];
}

const DesktopView: FC<DesktopViewProps> = ({ leftOptions, rightOptions }) => {
    const classes = useStyles();
    const [centerContentOption, setCenterContentOption] =
        useState<CenterSelectionOption | null>(null);
    const [selectedOption, setSelectedOption] = useState({
        index: 0,
        side: 'left',
    });

    const handleOptionClick = (
        index: number,
        optionsArray: [CenterSelectionOption],
        side: string,
    ) => {
        const option = optionsArray[index];
        setCenterContentOption(option);
        setSelectedOption({ index, side });

        customClickEvent({
            customLinkName: `selection-widget: ${optionsArray[index].title}`,
        });
    };

    const handleKeyDown = (
        event: React.KeyboardEvent,
        index: number,
        options: [CenterSelectionOption],
        side: string,
    ) => {
        if (event.key === 'Enter' || event.key === ' ') {
            handleOptionClick(index, options, side);
        }
    };

    const renderOption = (
        options: [CenterSelectionOption],
        option: CenterSelectionOption,
        index: number,
        side: string,
    ) => {
        return (
            <div
                key={`split-selection-option-${index}`}
                role="button"
                tabIndex={0}
                className={`${classes.option} ${
                    index === selectedOption.index &&
                    side === selectedOption.side
                        ? classes.optionSelected
                        : ''
                }`}
                onClick={() => handleOptionClick(index, options, side)}
                onKeyDown={(event) =>
                    handleKeyDown(event, index, options, side)
                }
            >
                <img
                    src={
                        index === selectedOption.index &&
                        side === selectedOption.side
                            ? option.imageOn
                            : option.imageOff
                    }
                    alt={option.title}
                />
                <div className={classes.optionTitleWrapper}>{option.title}</div>
            </div>
        );
    };

    useEffect(() => {
        if (leftOptions && leftOptions.length > 0)
            handleOptionClick(0, leftOptions, 'left');
    }, [leftOptions]);

    return (
        <div className={classes.root} data-testid="split-selection-desktop">
            <Grid className={classes.gridContainer} container>
                <Grid item xs={3} className={classes.leftSection}>
                    {/* left side */}
                    {leftOptions?.map((option, index) => {
                        return renderOption(leftOptions, option, index, 'left');
                    })}
                </Grid>
                <Grid item xs={3}>
                    {centerContentOption && (
                        <div
                            className={classes.middleSection}
                            style={{
                                backgroundColor: `${centerContentOption.centerBGColor}`,
                                boxShadow: `10px 10px 0px 0px ${centerContentOption.centerBGColor}40`,
                                color: `${centerContentOption.centerTxtColor}`,
                            }}
                        >
                            <div className={classes.middleSectionTitle}>
                                <span
                                    className={
                                        classes.middleSectionTitleFirstDigit
                                    }
                                >
                                    {centerContentOption.number[0]}
                                </span>
                                <span
                                    className={
                                        classes.middleSectionTitleSecondDigit
                                    }
                                >
                                    {centerContentOption.number[1]}
                                </span>
                            </div>
                            <div className={classes.middleSectionContent}>
                                {parseReplace(centerContentOption.content)}
                                <div className={classes.middleSectionFooter}>
                                    {parseReplace(centerContentOption.footer)}
                                </div>
                            </div>
                        </div>
                    )}
                </Grid>
                <Grid item xs={3} className={classes.rightSection}>
                    {/* right side */}
                    {rightOptions?.map((option, index) => {
                        return renderOption(
                            rightOptions,
                            option,
                            index,
                            'right',
                        );
                    })}
                </Grid>
            </Grid>
        </div>
    );
};

export default DesktopView;
