/* types */
import { ArticleSummaries } from 'containers/article-landing/article.types';

/* constants */
import {
    GET_VIRTUAL_ARTICLES_ERROR,
    GET_VIRTUAL_EVENT_ARTICLES_SUCCESS,
    GET_VIRTUAL_EVENT_ARTICLES,
} from 'constants/virtual-articles';

export interface VirtualEventArticles extends ArticleSummaries {
    likes?: number;
}

export type GET_VIRTUAL_EVENT_ARTICLES = typeof GET_VIRTUAL_EVENT_ARTICLES;
export type GET_VIRTUAL_EVENT_ARTICLES_SUCCESS = typeof GET_VIRTUAL_EVENT_ARTICLES_SUCCESS;
export type GET_VIRTUAL_ARTICLES_ERROR = typeof GET_VIRTUAL_ARTICLES_ERROR;

export type GetVirtualEventArticlesAction = {
    type: GET_VIRTUAL_EVENT_ARTICLES;
};
export type GetVirtualEventArticlesSuccessAction = {
    type: GET_VIRTUAL_EVENT_ARTICLES_SUCCESS;
    payload: VirtualEventArticles[];
};
export type GetVirtualEventArticlesErrorAction = {
    type: GET_VIRTUAL_ARTICLES_ERROR;
    error: Error;
};

export const getVirtualEventArticles = (): GetVirtualEventArticlesAction => ({
    type: GET_VIRTUAL_EVENT_ARTICLES,
});

export const getVirtualEventArticlesSuccess = (
    payload: VirtualEventArticles[],
): GetVirtualEventArticlesSuccessAction => ({
    type: GET_VIRTUAL_EVENT_ARTICLES_SUCCESS,
    payload,
});

export const getVirtualEventArticlesError = (
    error: Error,
): GetVirtualEventArticlesErrorAction => ({
    type: GET_VIRTUAL_ARTICLES_ERROR,
    error,
});
