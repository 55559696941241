import React from 'react';
import { RenderType } from '../interfaces';

/* components */
import { PowerOfTime } from 'components';

const renderPowerOfTime: RenderType = (): JSX.Element | null => {
    return <PowerOfTime />;
};

export default renderPowerOfTime;
