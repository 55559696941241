import React, { ReactChildren, ReactElement, ReactNode } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { AVENIR_NEXT_LT_REGULAR } from 'theme/font';
import colors from 'theme/colors';

const useStyles = makeStyles((theme) => ({
    root: {
        //border: 'dashed 2px blue',
        // minHeight: 'calc(100vh - 130px)',
        // padding: 0,
        color: colors.white,
        fontFamily: AVENIR_NEXT_LT_REGULAR,
        [theme.breakpoints.up(768)]: {
            // minHeight: 'calc(100vh - 100px)',
            // padding: 0,
        },
    },
}));

interface SectionProps {
    sectionId: string;
    children: ReactChildren | ReactNode | ReactElement;
}

const SectionWrapper: React.FC<SectionProps> = ({ sectionId, children }) => {
    const classes = useStyles();
    return (
        <div
            id={sectionId}
            className={classes.root}
            data-qa="hispanic-section-wrapper"
        >
            {children}
        </div>
    );
};

export default SectionWrapper;
