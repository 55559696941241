import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { AVENIR_NEXT_LT_REGULAR } from 'theme/font';
import { pxToRem } from 'theme/util';
import SectionWrapper from '../components/SectionWrapper';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        height: '695px',
        position: 'relative',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '460px auto',
        backgroundPosition: 'top center',
        fontFamily: AVENIR_NEXT_LT_REGULAR,
        [theme.breakpoints.up(460)]: {
            backgroundSize: 'cover',
            height: '150vw',
        },
        [theme.breakpoints.up(1024)]: {
            height: '907px',
            backgroundPosition: 'top left',
            // marginTop: '100px',
        },
    },
    content: {
        position: 'relative',
        margin: '0 auto',
        top: '120px',
        left: 'unset',
        padding: '10px 20px',
        width: 'unset',
        textAlign: 'center',
        fontWeight: 600,
        '&& h2': {
            fontSize: pxToRem(25),
            lineHeight: '30px',
        },
        '&& p': {
            fontSize: pxToRem(16),
            lineHeight: '20px',
            maxWidth: 300,
            margin: '0 auto',
            paddingBottom: 30,
        },
        [theme.breakpoints.between(460, 600)]: {
            top: '30vw',
            '&& h2': {
                paddingBottom: '20px',
                fontSize: pxToRem(32),
                lineHeight: '35px',
            },
            '&& p': {
                maxWidth: 400,
            },
        },
        [theme.breakpoints.between(600, 1024)]: {
            top: '32vw',
            maxWidth: 800,
            '&& h2': {
                paddingBottom: '20px',
                fontSize: pxToRem(45),
                lineHeight: '50px',
            },
            '&& p': {
                fontSize: pxToRem(20),
                lineHeight: '24px',
                maxWidth: 500,
                paddingBottom: '10vw',
            },
        },
        [theme.breakpoints.up(1024)]: {
            top: '170px',
            left: '12%',
            maxWidth: 590,
            textAlign: 'left',
            margin: 'unset',
            '&& h2': {
                fontSize: pxToRem(45),
                lineHeight: '50px',
            },
            '&& p': {
                fontSize: pxToRem(20),
                lineHeight: '26px',
                maxWidth: 'unset',
            },
        },
    },
}));
interface SectionData {
    desktopImg: string;
    mobileImg: string;
    title: string;
    content: string[];
}

interface SectionProps {
    isMobile?: boolean;
    data: SectionData;
}

const Section5: React.FC<SectionProps> = ({ isMobile = false, data }) => {
    const classes = useStyles();

    if (!data) {
        return null;
    }

    return (
        <SectionWrapper sectionId="section_5">
            <div
                className={classes.root}
                data-qa="section 5"
                style={
                    isMobile
                        ? {
                              backgroundImage: `url(${data.mobileImg})`,
                          }
                        : {
                              backgroundImage: `url(${data.desktopImg})`,
                          }
                }
            >
                <div className={classes.content}>
                    <h2>{data.title}</h2>
                    {data.content.map((item, index) => (
                        <p key={index}>{item}</p>
                    ))}
                </div>
            </div>
        </SectionWrapper>
    );
};

export default Section5;
