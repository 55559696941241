import { put, call, select } from 'redux-saga/effects';

/* actions */
import {
    getVirtualEventArticlesError,
    getVirtualEventArticlesSuccess,
} from 'actions/virtual-event-articles';

/* services */
import ArticleService from 'services/article';

/* utils */
import { getAemQueryTags } from 'utils/aem';

/* constants */
import { COMPANIES_TAG } from 'constants/aem';
import { VIRTUAL_EVENT_ARTICLES_IDS } from 'constants/virtual-articles';

/* store */
import { RootState } from 'store';

/* types */
import { VirtualEventArticles } from 'actions/virtual-event-articles';

export function* getVirtualEventArticles() {
    try {
        const getCompanyId = (state: RootState) =>
            state.company.theme?.companyId;
        const company = yield select(getCompanyId);
        // TODO we should have an additional tag for the virtual events, so mapping and filter will be fast
        const queryTags = getAemQueryTags('my-retirement', {
            [COMPANIES_TAG]: [company],
        });
        const res = yield call(ArticleService.fetchArticleSummaries, queryTags);

        if (!res.ok)
            throw new Error('Error while fetching virtual event articles');
        const json = yield call([res, 'json']);
        const { articles } = json;
        const sortedArticles: VirtualEventArticles[] = Object.values(
            VIRTUAL_EVENT_ARTICLES_IDS,
        ).reduce((accumulator: VirtualEventArticles[], articleID) => {
            const article = articles.find(
                (x: VirtualEventArticles) => x.id === articleID,
            );
            return article ? accumulator.concat([article]) : accumulator;
        }, []);
        yield put(getVirtualEventArticlesSuccess(sortedArticles));
    } catch (e) {
        yield put(getVirtualEventArticlesError(e));
    }
}

export function* getNextArticle() {
    try {
        const getReadArticles = (state: RootState) =>
            state.article.readArticles;
        const getVirtualEventArticles = (state: RootState) =>
            state.article.virtualEventArticles;
        const readArticles: string[] = yield select(getReadArticles);
        const allVirtualEventArticles: VirtualEventArticles[] = yield select(
            getVirtualEventArticles,
        );
        const unreadArticles = allVirtualEventArticles.filter(
            (v) => !readArticles.includes(v.id),
        );
        return unreadArticles[0];
    } catch (e) {
        //todo change for get next article error
        yield put(getVirtualEventArticlesError(e));
    }
}
