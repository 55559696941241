import React, { FC } from 'react';

/* material */
import Box from '@material-ui/core/Box';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

/* types */
import { ArticleContentFragment } from './article.types';

/* components */
import DynamicMediaPlayer from 'components/dynamic-media-player/DynamicMediaPlayer';
import ArticleImage from './ArticleImage';

/* utils */
import { aemBaseURL } from 'constants/aem';
import { isImageFileExtension } from 'utils/fileTypes';
import { parseReplace } from 'utils/htmlParser';
import { pxToSpacing } from 'theme/util';
import { safelyParseJson } from 'utils/parseJson';

// append '-mobile' suffix to infographic image to support mobile layout
// for each infographic image, authors will be required to upload in AEM
// a mobile image with the same name and a '-mobile' suffix appended to it
// Example:
// desktop: my-image.jpg
// mobile: 'my-image-mobile.jpg
const getFilename = (filename: string, matches: boolean) => {
    if (matches) return filename;

    const idx = filename?.lastIndexOf('.');
    const name = filename?.substring(0, idx);
    const ext = filename?.substring(idx + 1);

    return `${name}-mobile.${ext}`;
};

// update dynamic params for mobile layout
// for each infographic video, authors will be required to upload in AEM
// a mobile version with the same name and a '-mobile' suffix appended to it
// Example:
// desktop: my-video.mp4
// mobile: 'my-video-mobile.mp4
const getDynamicMediaParams = (params: string, matches: boolean) => {
    const paramsToJSON = safelyParseJson(params);

    if (!paramsToJSON) return;

    if (matches) return paramsToJSON;

    // update params to reference mobile version
    const asset = paramsToJSON.asset.replace('-AVS', '-mobile-AVS');

    return {
        ...paramsToJSON,
        asset,
        posterimage: asset,
    };
};

type ArticleInfographicBodyProps = {
    fragment: ArticleContentFragment;
};

const ArticleInfographicBody: FC<ArticleInfographicBodyProps> = ({
    fragment,
}) => {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('sm'));
    const { closing, dynamicMediaParams, infographicPath, intro, alt, title } =
        fragment;
    const isImage = infographicPath && isImageFileExtension(infographicPath);
    const filename = infographicPath && getFilename(infographicPath, matches);
    const infographicUrl = `${aemBaseURL}${filename}`;
    const params =
        dynamicMediaParams &&
        getDynamicMediaParams(dynamicMediaParams, matches);

    return (
        <>
            <Box data-qa="intro">{parseReplace(intro)}</Box>
            {isImage && (
                <Box mb={pxToSpacing(32)}>
                    <ArticleImage url={infographicUrl} alt={alt} />
                </Box>
            )}
            {params && (
                <Box data-qa="article-infographic" mb={pxToSpacing(32)}>
                    <DynamicMediaPlayer
                        playOnVisible
                        params={params}
                        alt={alt}
                        title={title}
                    />
                </Box>
            )}
            <Box data-qa="closing">{parseReplace(closing)}</Box>
        </>
    );
};

export default ArticleInfographicBody;
