import { enUS } from './locales';

const SI_SYMBOL = ['', 'k', 'M', 'G', 'T', 'P', 'E'];
/**
 * Converts a given number to read friendly format
 * 5 -> 5
 * 1000 -> 1k
 * 23456 -> 23.4k
 * @param number - number
 * @return string - number formatted in friendly reading format
 */
export const convertToNumberFriendlyFormat = (
    number: number,
    toUpperCase = false,
): string => {
    const tier = (Math.log10(number) / 3) | 0;

    if (tier === 0) return number.toFixed(0);

    const suffix = SI_SYMBOL[tier];
    const scale = Math.pow(10, tier * 3);

    let scaled = (number / scale).toFixed(1);

    if (/\.0$/.test(scaled)) scaled = scaled.substr(0, scaled.length - 2);

    return `${scaled}${toUpperCase ? suffix.toUpperCase() : suffix}`;
};

/**
 * Add commas to a given number
 * @param number - number
 * @return string - number formatted with commas
 */
export const convertToNumberWithCommas = (number: number): string => {
    return new Intl.NumberFormat().format(number);
};

/**
 * Convert to whole currency number
 * @param number - number
 * @return string - whole number in usd currency
 */
export const convertToWholeCurrency = (number: number): string => {
    return new Intl.NumberFormat(enUS, {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
    }).format(number);
};
