import React, { FC } from 'react';

/* material */
import { makeStyles } from '@material-ui/core/styles';
import StarIcon from '@material-ui/icons/Star';
import BarChartIcon from '@material-ui/icons/BarChart';

/* utils */
import { AVENIR_NEXT_LT_DEMI } from 'theme/font';
import { pxToRem } from 'theme/util';

const iconMap: { [key: string]: JSX.Element } = {
    new: <StarIcon />,
    resource: <BarChartIcon />,
    featured: <StarIcon />,
    star: <StarIcon />,
    important: <StarIcon />,
};

export type Badges = {
    textPaddingTop: string;
    styles: {
        [key: string]: {
            backgroundColor: string;
            color: string;
        };
    };
};

interface BadgeStyleProps {
    backgroundColor: string | undefined;
    color: string | undefined;
    status: string;
}

const useStyles = makeStyles((theme) => ({
    root: {
        alignItems: 'center',
        backgroundColor: (props: BadgeStyleProps) => {
            const { backgroundColor, status } = props;
            return (
                backgroundColor || theme.badges?.styles[status].backgroundColor
            );
        },
        borderRadius: '0 0 10px 0',
        display: 'inline-flex',
        marginBottom: 8,
        marginRight: 8,
        padding: `${pxToRem(7)} ${pxToRem(15)}`,
        textTransform: 'uppercase',
    },
    icon: {
        alignItems: 'center',
        display: 'flex',
        marginRight: 4,
        '& svg': {
            fill: theme.colors.chardonnay,
            fontSize: 20,
        },
    },
    label: {
        color: (props: BadgeStyleProps) => {
            const { color, status } = props;
            return color || theme.badges?.styles[status].color;
        },
        fontSize: pxToRem(14),
        fontFamily: AVENIR_NEXT_LT_DEMI,
        paddingTop: theme.badges?.textPaddingTop,
    },
}));

interface BadgeProps {
    backgroundColor?: string;
    color?: string;
    status: string;
    icon?: JSX.Element;
}

const Badge: FC<BadgeProps> = ({ backgroundColor, status, color, icon }) => {
    const classes = useStyles({ backgroundColor, color, status });
    const mappedIcon = iconMap[status] || null;
    const badgeIcon = icon ? icon : mappedIcon;
    return (
        <div className={classes.root}>
            {badgeIcon ? <div className={classes.icon}>{badgeIcon}</div> : null}
            <div className={classes.label}>{status}</div>
        </div>
    );
};

export default Badge;
