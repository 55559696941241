import getAEMBaseUrl from 'utils/getAEMBaseUrl';
import { LikeAction } from 'hooks/articles/useArticleApi.types';
import { getHeaders } from './utils';

const articles = {
    fetchNextArticle: (
        readAemFragmentPaths: string[],
        numberOfArticles: number,
        tagsQueryString: string,
    ) =>
        fetch('/po/content/articles/next', {
            method: 'POST',
            headers: getHeaders(),
            body: JSON.stringify({
                numberOfArticles,
                articleRatingType: 'LIKES',
                tagsQueryString,
                exclusionArticles: readAemFragmentPaths,
            }),
        }),
    fetchArticleSummaries: (tags: string) =>
        fetch(getAEMBaseUrl(`/content/articles`), {
            method: 'POST',
            headers: getHeaders(),
            body: JSON.stringify({
                tagsQueryString: tags,
                multiMediaFetch: false,
                influencersFetch: true,
            }),
        }),
    updateLikes: (articleId: string, type: LikeAction) =>
        fetch(
            `/po/content/articles/${articleId}/rating?articleRatingType=LIKES&articleRatingActionType=${type}`,
            {
                method: 'POST',
            },
        ),
};

export default articles;
