import * as React from 'react';
/* interfaces */
import { RenderType, RenderElementProps } from '../interfaces';
/* material */
import Box from '@material-ui/core/Box';

/* utils */
import { pxToSpacing } from 'theme/util';
import { getCustomTagTextChildren } from '../utils';
import { safelyParseJson } from 'utils/parseJson';

import DynamicMediaPlayer from 'components/dynamic-media-player/DynamicMediaPlayer';

const renderDynamicMedia: RenderType = (
    props: RenderElementProps,
): JSX.Element | null => {
    const { domNode } = props;
    const { children } = domNode;
    const textElements = children && getCustomTagTextChildren(children);
    const params = textElements && safelyParseJson(textElements[1].data);

    if (!params) return null;

    return (
        <Box data-qa="article-infographic" mb={pxToSpacing(32)}>
            <DynamicMediaPlayer params={params} />
        </Box>
    );
};

export default renderDynamicMedia;
