import {
    aemBaseURL,
    CONTENT_ZONE_TAG,
    EXCLUSION_TAG,
    TYPE_ARTICLE_CONTENT_FRAGMENT,
    TYPE_INFOGRAPHIC_CONTENT_FRAGMENT,
    TYPE_VIDEO_CONTENT_FRAGMENT,
    TYPE_RICH_ARTICLE_FRAGMENT,
    INFLUENCER_TAG,
} from 'constants/aem';

/* types */
import { Theme } from 'reducers';

export const getAemImagePath = function (path: string) {
    if (!path) {
        return '';
    }
    return `${aemBaseURL}${path}`;
};

export type ArticleCategories =
    | 'virtualEvent'
    | 'moneyMinute'
    | 'basic'
    | 'AFDP';

const ArticleCategoriesMap = {
    virtualEvent: 'virtual-event',
    moneyMinute: 'money-minute',
    basic: 'basic',
    AFDP: 'target-date',
};

export const getAemArticlePath = (
    company: Theme | null,
    articleId: string,
    aemContentPath: string,
    category: ArticleCategories,
) => {
    const companyId = company?.companyId;
    if (!aemContentPath || !companyId) {
        return '';
    }

    const contentFragmentName =
        getArticleNameFromAemArticlePath(aemContentPath);

    if (!category || category === 'basic') {
        return `/${companyId}/articles/${articleId}/${contentFragmentName}`;
    }

    const directory = ArticleCategoriesMap[category];
    return `/${companyId}/${directory}/articles/${articleId}/${contentFragmentName}`;
};

export const getArticleNameFromAemArticlePath = (aemContentPath: string) => {
    const contentFragmentName = aemContentPath.substring(
        aemContentPath.lastIndexOf('/') + 1,
    );
    return contentFragmentName
        .replace(/ /g, '-')
        .replace(/['?]/g, '')
        .toLowerCase();
};

/**
 * Map cfModel path to article type
 * @param path {string} - content fragment path
 *
 * @returns {string} - article type
 */
export const getFragmentTypeFromModelPath = (path: string) => {
    const fragmentType = path.substring(path.lastIndexOf('/') + 1);
    const fragmentTypeMap: { [key: string]: string } = {
        'article-fragment': TYPE_ARTICLE_CONTENT_FRAGMENT,
        'infographic-model': TYPE_INFOGRAPHIC_CONTENT_FRAGMENT,
        'video-transcript-model': TYPE_VIDEO_CONTENT_FRAGMENT,
        'rich-article-model': TYPE_RICH_ARTICLE_FRAGMENT,
    };

    return fragmentTypeMap[fragmentType];
};

/**
 * Constructs a querystring of tags to query AEM
 * @param domain {string} - website domain
 * @param tags {string[]} - tags to filter by
 *
 * @returns {string}
 */
export const getAemQueryTags = (
    domain: string,
    tags: { [key: string]: (string | undefined | null)[] },
) => {
    // normalize tag title to tag name
    const tagMap: { [key: string]: string } = {
        [CONTENT_ZONE_TAG]: 'content-zone',
        [INFLUENCER_TAG]: 'influencers',
    };

    return Object.keys(tags).reduce((queryTags: string, tag: string) => {
        return (queryTags +=
            (queryTags ? '&' : '') +
            tags[tag]
                .map((tagValue) => {
                    const normalizeTag = tagMap[tag] || tag;
                    // make an exception for exclusion tag pattern
                    // exclusion=tag
                    if (tag === EXCLUSION_TAG) {
                        return `${tag}=${tagValue}`;
                    }
                    return `${tag}=${domain}:${normalizeTag}/${tagValue}`;
                })
                .join('&'));
    }, '');
};
