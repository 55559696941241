import React, { FC } from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
    root: { borderRadius: 12 },
}));

export interface SpotifyPlaylistProps {
    id: string;
    width?: string;
    height?: string;
}

const SpotifyPlaylist: FC<SpotifyPlaylistProps> = ({
    id,
    width = '100%',
    height = '100%',
}) => {
    const classes = useStyles();

    return (
        <iframe
            title="Spotify Player"
            className={classes.root}
            src={`https://open.spotify.com/embed/playlist/${id}?utm_source=generator`}
            width={width}
            height={height}
            frameBorder="0"
            allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
            loading="lazy"
        ></iframe>
    );
};

export default SpotifyPlaylist;
