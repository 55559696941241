import React, { FC } from 'react';

/* material */
import { Box } from '@material-ui/core';

/* components */
import { DynamicMediaPlayerWithTranscript } from 'components';

/* utils */
import { parseReplace } from 'utils/htmlParser';
import { safelyParseJson } from 'utils/parseJson';

/* types */
import { ArticleContentFragment } from './article.types';
import { pxToSpacing } from 'theme/util';

type ArticleVideoBodyProps = {
    fragment: ArticleContentFragment;
};

const ArticleVideoBody: FC<ArticleVideoBodyProps> = ({ fragment }) => {
    const { dynamicMediaParams, summary, transcript, title } = fragment;
    const params = dynamicMediaParams && safelyParseJson(dynamicMediaParams);

    return (
        <Box data-testid="article-video-body">
            <DynamicMediaPlayerWithTranscript
                params={params}
                title={title}
                transcript={transcript}
            />
            <Box mb={pxToSpacing(32)} />
            {parseReplace(summary)}
        </Box>
    );
};

export default ArticleVideoBody;
