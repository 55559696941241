import React, { FC } from 'react';

/* material */

import { Breakpoint } from '@material-ui/core/styles/createBreakpoints';

/* components */

import { StaticArticleCard } from './StaticArticleCard';

/* interface */
import { Theme } from 'reducers/company';
import { ArticleSummaries } from 'containers/article-landing/article.types';

/* utils */
import { elipsis } from 'utils/text';
import { getTopicUrl, getTopicTitle } from 'utils/topics';
import {
    getAemImagePath,
    getAemArticlePath,
    getFragmentTypeFromModelPath,
    ArticleCategories,
} from 'utils/aem';

/* constants */
import { TYPE_VIDEO_CONTENT_FRAGMENT } from 'constants/aem';

type ArticleCardProps = {
    article: ArticleSummaries | undefined;
    company: Theme | null;
    fullWidthUntil?: Breakpoint;
    category?: ArticleCategories;
};

export const ArticleCard: FC<ArticleCardProps> = ({
    article,
    company,
    fullWidthUntil,
    category = 'basic',
}) => {
    if (!article || !company) return null;

    const {
        cfModel,
        description,
        aemId,
        imagePath,
        length,
        path,
        title,
        topics,
        id,
    } = article;
    const articleDescription = elipsis(description, 130);
    const articleImage = getAemImagePath(imagePath);
    const articlePath = getAemArticlePath(
        company,
        category === 'basic' ? aemId : id,
        path,
        category,
    );
    const articleTitle = elipsis(title, 50);
    const topicUrl = company && getTopicUrl(company, topics);
    const topicTitle = getTopicTitle(topics);
    const isVideo =
        getFragmentTypeFromModelPath(cfModel) === TYPE_VIDEO_CONTENT_FRAGMENT;

    return (
        <>
            <StaticArticleCard
                data-qa="article-card"
                contentMinutes={length}
                description={articleDescription}
                fullWidthUntil={fullWidthUntil}
                imagePath={articleImage}
                title={articleTitle}
                topicTitle={topicTitle}
                topicUrl={topicUrl}
                isVideo={isVideo}
                url={articlePath}
            />
        </>
    );
};
