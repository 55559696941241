import {
    RetirementCalculatorValues,
    RetirementProjectionValues,
} from 'containers/calculator/calculator.type';
import { CalculateRetirmentActionTypes } from 'actions/calculator';

/* constants */
import {
    CALCULATE_RETIREMENT_PROJECTIONS_SUCCESS,
    CALCULATE_RETIREMENT_PROJECTION_UPDATE_SUCCESS,
    CURRENT_AGE_KEY,
    PRETAX_SALARY_KEY,
    CURRENT_SAVINGS_KEY,
    CONTRIBUTION_PERCENTAGE_KEY,
    EMPLOYER_MATCHING_CONTRIBUTION_PERCENTAGE_KEY,
    EMPLOYER_MATCHING_UP_TO_CONTRIBUTION_PERCENTAGE_OF_SALARY_KEY,
    GOAL_KEY,
    ANNUAL_NEED_AT_RETIREMENT_KEY,
    RETIREMENT_AGE_KEY,
    RETIREMENT_BALANCE_KEY,
    PREV_RETIREMENT_BALANCE_KEY,
    CLEAR_RETIREMENT_PROJECTIONS,
    GET_RETIREMENT_CONTRIBUTION_CONSTANTS_SUCCESS,
} from 'constants/calculator';

export type RetirementProjection = {
    contributionPercentageIncrease: number;
    maxEmployeeContributionsWhenMeetingMinAgeForAdditionalContributionLimits: number;
    maxEmployeeContributionsWhenNotMeetingMinAgeForAdditionalContributionLimits: number;
    minAgeForAdditionalContributionLimits: number;
    projection: RetirementProjectionValues;
    projectionWithContributionPercentageIncrease: RetirementProjectionValues;
    values: RetirementCalculatorValues;
};

export const defaultValues = {
    [CURRENT_AGE_KEY]: 0,
    [CONTRIBUTION_PERCENTAGE_KEY]: 0,
    [CURRENT_SAVINGS_KEY]: 0,
    [EMPLOYER_MATCHING_CONTRIBUTION_PERCENTAGE_KEY]: 0,
    [EMPLOYER_MATCHING_UP_TO_CONTRIBUTION_PERCENTAGE_OF_SALARY_KEY]: 0,
    [PRETAX_SALARY_KEY]: 0,
    [RETIREMENT_AGE_KEY]: 67,
};

export const defaultProjections = {
    [GOAL_KEY]: 0,
    [ANNUAL_NEED_AT_RETIREMENT_KEY]: 0,
    [RETIREMENT_AGE_KEY]: 0,
    [RETIREMENT_BALANCE_KEY]: 0,
    [PREV_RETIREMENT_BALANCE_KEY]: 0,
};

export const initialRetirementCalculatorState: RetirementProjection = {
    contributionPercentageIncrease: 1,
    maxEmployeeContributionsWhenMeetingMinAgeForAdditionalContributionLimits: 0,
    maxEmployeeContributionsWhenNotMeetingMinAgeForAdditionalContributionLimits: 0,
    minAgeForAdditionalContributionLimits: 0,
    projection: defaultProjections,
    projectionWithContributionPercentageIncrease: defaultProjections,
    values: defaultValues,
};

export const calculatorReducer = (
    state = initialRetirementCalculatorState,
    action: CalculateRetirmentActionTypes,
): RetirementProjection => {
    switch (action.type) {
        case CALCULATE_RETIREMENT_PROJECTIONS_SUCCESS:
            return {
                ...state,
                ...action.payload,
                projection: {
                    ...action.payload.projection,
                    [PREV_RETIREMENT_BALANCE_KEY]: 0,
                },
                projectionWithContributionPercentageIncrease: {
                    ...action.payload
                        .projectionWithContributionPercentageIncrease,
                    [PREV_RETIREMENT_BALANCE_KEY]: 0,
                },
            };
        case CALCULATE_RETIREMENT_PROJECTION_UPDATE_SUCCESS:
            return {
                ...state,
                contributionPercentageIncrease:
                    action.payload.contributionPercentage,
                projectionWithContributionPercentageIncrease: {
                    ...action.payload.projection,
                    [PREV_RETIREMENT_BALANCE_KEY]:
                        state.projectionWithContributionPercentageIncrease
                            .retirementBalance,
                },
            };
        case CLEAR_RETIREMENT_PROJECTIONS:
            return {
                ...initialRetirementCalculatorState,
                maxEmployeeContributionsWhenMeetingMinAgeForAdditionalContributionLimits:
                    state.maxEmployeeContributionsWhenMeetingMinAgeForAdditionalContributionLimits,
                maxEmployeeContributionsWhenNotMeetingMinAgeForAdditionalContributionLimits:
                    state.maxEmployeeContributionsWhenNotMeetingMinAgeForAdditionalContributionLimits,
                minAgeForAdditionalContributionLimits:
                    state.minAgeForAdditionalContributionLimits,
            };
        case GET_RETIREMENT_CONTRIBUTION_CONSTANTS_SUCCESS:
            return {
                ...state,
                ...action.payload,
            };
        default:
            return state;
    }
};
