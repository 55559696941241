import React, { FC } from 'react';
import { Link } from 'react-router-dom';
// import { useFlags } from 'launchdarkly-react-client-sdk';

/* material */
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import VideoCamIcon from 'components/icons/VideoCamIcon';

/* components */
import { ArticleMinutes, AspectRatio } from 'components';
import { renderBadge } from 'components/badge/utils';

/* types */
import { ArticleSummaries } from 'containers/article-landing/article.types';
import { Theme } from 'reducers';

/* utils */
import { pxToRem } from 'theme/util';
import colors from 'theme/colors';
import {
    getAemImagePath,
    getAemArticlePath,
    getFragmentTypeFromModelPath,
    ArticleCategories,
} from 'utils/aem';
import { elipsis } from 'utils/text';

/* constants */
import { TYPE_VIDEO_CONTENT_FRAGMENT } from 'constants/aem';
import { AVENIR_NEXT_LT_DEMI } from 'theme/font';
import { isUrlExternal } from 'utils/url';
import { customClickEvent } from 'analytics';

// import { DOLLAR_ROAD_TRIP_ARTICLE_IDS } from 'constants/article';

type ContentCardProps = {
    article: ArticleSummaries;
    fullWidth?: boolean;
    company: Theme | null;
    variant?: 'contained';
    category?: ArticleCategories;
};

const useStyles = makeStyles((theme) => ({
    root: (props: Pick<ContentCardProps, 'variant' | 'fullWidth'>) => {
        const { variant, fullWidth } = props;
        return {
            backgroundColor: colors.white,
            display: 'flex',
            flexDirection: 'column',
            flex: '1 1 auto',
            margin: variant === 'contained' ? '0 0 20px 0' : '0 12px 3px 0',
            minWidth: fullWidth ? '100%' : '200px',
            position: 'relative',
            '& a': {
                textDecoration: 'none',
            },
            [theme.breakpoints.up('sm')]: {
                marginRight: 16,
                width: 'calc(33.3% - 10.66px)',
                maxWidth: 'calc(33.3% - 10.66px)',
                '&:nth-of-type(3n+3)': {
                    marginRight: 0,
                },
                minWidth: 'auto',
            },
        };
    },
    media: {
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        height: '100%',
    },
    title: () => {
        return {
            color: colors.black80,
            fontSize: pxToRem(14),
            lineHeight: pxToRem(20),

            [theme.breakpoints.up('sm')]: {
                fontSize: pxToRem(18),
                lineHeight: pxToRem(26),
            },
        };
    },
    contentWrapper: {
        margin: '16px 15px',
        textAlign: 'left',
    },
    minuteView: (props: Pick<ContentCardProps, 'variant' | 'fullWidth'>) => {
        const { variant, fullWidth } = props;
        return {
            bottom: '13px',
            left: '15px',
            lineHeight: pxToRem(16),
            position: 'absolute',

            [theme.breakpoints.up('sm')]: {
                bottom: variant === 'contained' && !fullWidth ? '13px' : '20px',
                left: variant === 'contained' ? '15px' : '20px',
            },

            '& > div': {
                fontSize: pxToRem(10),
                [theme.breakpoints.up('sm')]: {
                    fontSize: pxToRem(12),
                },
            },
        };
    },
    videoIcon: {
        position: 'absolute',
        bottom: -10,
        left: 20,
    },
    card: (
        props: Pick<ContentCardProps, 'category' | 'variant' | 'fullWidth'>,
    ) => {
        const { category } = props;

        return {
            border: 'solid 1px #F5F5F5',
            boxShadow: '0 2px 4px 0 rgba(209,209,209,0.5)',
            display: 'block',
            height: category === 'virtualEvent' ? 'auto' : '100%',
            '&:hover': {
                border: 'solid 1px #DCDCDC',
            },
            paddingBottom: '20px',
            position: 'relative',

            [theme.breakpoints.up('sm')]: {
                paddingBottom: '38px',
            },
        };
    },
    cardUpperTitle: {
        color: colors.darkSapphire,
        fontFamily: AVENIR_NEXT_LT_DEMI,
        fontSize: pxToRem(18),
        overflow: 'hidden',
        paddingBottom: '20px',
        textAlign: 'left',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
    badgeWrapper: {
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: 1,
    },
}));

const ContentCard: FC<ContentCardProps> = ({
    article,
    company,
    variant,
    fullWidth,
    category = 'basic',
}) => {
    const classes = useStyles({ variant, fullWidth });
    const {
        aemId,
        length,
        id,
        imagePath,
        path,
        cfModel,
        redirectUrl,
        contentType,
        badges,
    } = article;
    const isVideo =
        contentType === 'video' ||
        getFragmentTypeFromModelPath(cfModel) === TYPE_VIDEO_CONTENT_FRAGMENT;
    // const isDollarRoadTripArticle = DOLLAR_ROAD_TRIP_ARTICLE_IDS.includes(
    //     aemId || id,
    // );
    const contentMinutes = <ArticleMinutes length={length} isVideo={isVideo} />;
    const imgPath = getAemImagePath(imagePath);
    const articleTitle =
        variant === 'contained'
            ? elipsis(article.title, fullWidth ? 80 : 40)
            : article.title;
    const isVirtualEventVideo = category === 'virtualEvent';
    let articlePath = getAemArticlePath(company, aemId || id, path, category);
    let linkTarget = '_self';

    if (redirectUrl) {
        if (!isUrlExternal(redirectUrl)) {
            articlePath = `/${company?.companyId}/${redirectUrl}`;
        } else {
            articlePath = redirectUrl;
            linkTarget = '_blank';
        }
    }

    return (
        <div data-type="card" className={classes.root}>
            {isVirtualEventVideo && (
                <div className={classes.cardUpperTitle}>{article.title}</div>
            )}
            <Link
                className={classes.card}
                onClick={() => {
                    customClickEvent({
                        customLinkName: `contentCard: ${articleTitle}s`,
                    });
                }}
                to={{ pathname: articlePath }}
                target={linkTarget}
                data-qa="content-card"
            >
                {badges && (
                    <div className={classes.badgeWrapper}>
                        {renderBadge(badges.slice(0, 1))}
                    </div>
                )}

                <AspectRatio>
                    <div
                        className={classes.media}
                        style={{ backgroundImage: `url('${imgPath}')` }}
                        data-qa="content-card-image"
                    />
                    {isVideo && (
                        <div className={classes.videoIcon}>
                            <VideoCamIcon />
                        </div>
                    )}
                </AspectRatio>

                <div className={classes.contentWrapper}>
                    <div>
                        <Typography
                            className={classes.title}
                            variant="h5"
                            component="h2"
                            data-qa="content-card-title"
                        >
                            {isVirtualEventVideo
                                ? article.description
                                : articleTitle}
                        </Typography>
                    </div>
                </div>
                <div className={classes.minuteView}>{contentMinutes}</div>
            </Link>
        </div>
    );
};

export default ContentCard;
