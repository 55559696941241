import React, { FC } from 'react';

/* material */
import { Box } from '@material-ui/core';

/* component */
import ArticleImage from './ArticleImage';

/* utils */
import { aemBaseURL } from 'constants/aem';
import { pxToSpacing } from 'theme/util';

/* types */
import { customClickEvent } from 'analytics';
import useHtmlParser from 'hooks/useHtmlParser';
import { parseReplace } from 'utils/htmlParser';
import { ArticleContentFragment } from './article.types';

type ArticleContentBodyProps = {
    fragment: ArticleContentFragment;
};

const ArticleContentBody: FC<ArticleContentBodyProps> = ({ fragment }) => {
	const { content, articleImagePath, alt } = fragment;
	const src = `${aemBaseURL}${articleImagePath}`;

	const handleLinkClick = (event: MouseEvent) => {
		const target = event.target as HTMLElement;
		if (target.tagName === 'A') {
			const name = (target as HTMLAnchorElement).href.split('/');
			customClickEvent({
				customLinkName: `${name[name.length - 1]}`,
			});
		}
	};

  const containerRef = useHtmlParser(content ?? '', handleLinkClick);

	return (
		<>
			{articleImagePath && (
				<Box mb={pxToSpacing(32)}>
					<ArticleImage url={src} alt={alt} />
				</Box>
			)}
			<Box data-testid="article-content-body" ref={containerRef}>
				{parseReplace(content)}
			</Box>
		</>
	);
};

export default ArticleContentBody;
