import React, { FC, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { parseReplace } from 'utils/htmlParser';

/* material */
import Button from '@material-ui/core/Button';

/* components */
import { AlertModal } from 'components';
// import { asyncLoad } from 'components';

/* types */
import { Theme } from 'reducers/company';
import { User } from 'reducers/user';

/* constants */
import { GENERIC } from 'constants/company';
import { customClickEvent } from 'analytics';

interface ChangeContributionProps {
  company: Theme | null;
  user: User | null;
  size?: 'small' | 'medium' | 'large';
  dataAnalyticsPlacement: string;
  isAllocationUrl?: boolean;
  buttonTextId?: string;
  fromActionPlan?: boolean;
  ctaLabelOverride?: string;
}

// const AlertModal = asyncLoad.component('alert-modal/AlertModal');

const ChangeContribution: FC<ChangeContributionProps> = ({
  company,
  user,
  size = 'medium',
  dataAnalyticsPlacement,
  isAllocationUrl,
  buttonTextId,
  fromActionPlan,
  ctaLabelOverride,
}) => {
  const intl = useIntl();

  const domainName = user?.domain.domainName;
  const isEnroll = user?.isEnroll;

  const { companyId, recordKeeperUrl, enrollUrl, recordKeeperAllocationUrl, changeContributionCtaTextOverride } = company as Theme;
  const isRecordKeeperUrlAFile = recordKeeperUrl?.endsWith('.pdf');
  const recordKeeperName = company?.recordKeeperName;
  const isGenericSite = companyId === GENERIC;
  const modalTextOverride = company?.leavingIcrModalTextOverride;

  const handleRedirect = () => {
    let url = recordKeeperUrl;
    let target = isRecordKeeperUrlAFile ? '_blank' : '_self';
    if (recordKeeperAllocationUrl && isAllocationUrl) {
      url = recordKeeperAllocationUrl;
    } else if (isEnroll && enrollUrl) {
      url = enrollUrl;
      target = '_self';
    }

    window.open(url, target);
    setIsContributionModalVisible(false);
  };

  const getAlertBodyText = () => {
    if (isEnroll) {
      return 'changeContribution.leavingEnroll';
    }

    if (isRecordKeeperUrlAFile) {
      return 'changeContribution.leavingMyRetirementFile';
    }

    return 'changeContribution.leavingMyRetirement';
  };

  const getActionLabel = () => {
    if (isEnroll) {
      return 'register';
    }
    if (isRecordKeeperUrlAFile) {
      return 'next';
    }

    return intl.formatMessage({
      id: 'leave',
    });
  };

  const [isContributionModalVisible, setIsContributionModalVisible] = useState(false);

  const handleChangeContributionClose = () => {
    setIsContributionModalVisible(false);
  };

  const renderAlertModal = () => {
    return isGenericSite ? (
      <AlertModal
        bodyText={intl.formatMessage(
          {
            id: 'changeContribution.partnerWithMyRetirement',
          },
          { domainName }
        )}
        confirmActionLabel={intl.formatMessage({
          id: 'ok',
        })}
        headerText={intl.formatMessage({
          id: 'changeContribution.headsUp',
        })}
        isModalOpen={isContributionModalVisible}
        onConfirm={handleChangeContributionClose}
      />
    ) : (
      <AlertModal
        bodyText={
          !modalTextOverride ? (
            <FormattedMessage
              id={getAlertBodyText()}
              values={{
                recordKeeperName: recordKeeperName,
                domainName,
              }}
            />
          ) : (
            parseReplace(modalTextOverride)
          )
        }
        cancelActionLabel={intl.formatMessage({
          id: 'stay',
        })}
        confirmActionLabel={getActionLabel()}
        headerText={intl.formatMessage({
          id: 'changeContribution.headsUp',
        })}
        isModalOpen={isContributionModalVisible}
        onConfirm={handleRedirect}
        onCancel={handleChangeContributionClose}
      />
    );
  };

  const ctaTextId = buttonTextId ?? (isEnroll ? 'enrollNow' : 'changeMyContribution');

  return (
    <>
      <Button
        data-qa="make-plan-changes"
        variant="contained"
        size={size}
        onClick={() => {
          customClickEvent({
            customLinkName: `${dataAnalyticsPlacement}: ${intl.formatMessage({
              id: ctaTextId,
            })}`,
          });

          setIsContributionModalVisible(true);
        }}
      >
        {ctaLabelOverride
          ? ctaLabelOverride
          : fromActionPlan
          ? intl.formatMessage({
              id: 'actionPlan.makeAChangeCta',
            })
          : changeContributionCtaTextOverride || <FormattedMessage id={ctaTextId} />}
      </Button>
      {isContributionModalVisible && renderAlertModal()}
    </>
  );
};

export default ChangeContribution;