import { pageViewEvent } from 'analytics';
import { Dispatch, Action } from 'redux';
import { targetView } from 'utils/targetView';
import { setEvidonLang } from 'utils/setEvidonLang';
import { getPageViewLang, isUsEn } from 'utils';
import { setLanguage } from 'actions/user';
import { enUS } from 'utils/locales';

const LOCATION_CHANGE = '@@router/LOCATION_CHANGE';

const getSlashPositionByIndex = (
    string: string,
    subString: string,
    index: number,
) => {
    return string.split(subString, index).join(subString).length;
};

export const trackPageView =
    (store: any) => (next: Dispatch) => (action: Action) => {
        next(action);
        if (action.type === LOCATION_CHANGE) {
            const { payload } = action as any;
            const routePath = payload.location.pathname;

            //Check for spanish paths and set to English if the path is does not support Spanish
            const spanishAllowedPathsArray = [
                '/hispanic',
                '/calculator',
                '/calculator/projections',
            ];
            const pathWithoutCompany = routePath.substring(
                getSlashPositionByIndex(routePath, '/', 2),
                routePath.length,
            );

            if (!spanishAllowedPathsArray.includes(pathWithoutCompany)) {
                store.dispatch(setLanguage(enUS));
                setEvidonLang('en');
            }

            setTimeout(() => {
                const { user } = store.getState();
                pageViewEvent({
                    path: routePath,
                    uid: user.uid,
                    personaSegment: user.segment,
                    language: getPageViewLang(user.language),
                });

                setEvidonLang(isUsEn(user.language) ? 'en' : 'es');
            }, 250);

            targetView(routePath);
        }
    };
export default trackPageView;
