import { makeStyles } from '@material-ui/core';
import { CartPiece } from 'components/WidgetInflationCart/WidgetInflationCart';
import React from 'react';

interface HousingCardProps {
    card: CartPiece;
}

const useStyles = makeStyles((theme) => ({
    housingCard: {
        backgroundColor: '#11999E',
        width: '298px',
        height: '363px',
        flexShrink: 0,
        borderRadius: '10px',
        padding: '20px',
        [theme.breakpoints.down('sm')]: {
            height: '300px',
        },
    },
    svg: {
        width: '66px',
        height: '66px',
        flexShrink: 0,
    },
    h3: {
        color: theme.colors.white,
        textAlign: 'center',
        fontSize: '18px',
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: 'normal',
        width: '220px',
        height: '13px',
        flexShrink: 0,
        margin: '0px auto 18px',
    },
    li: {
        color: theme.colors.white,
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 400,
        // lineHeight: 'normal',
        lineHeight: '25px' /* 178.571% */,
        flexShrink: 0,
    },
    svgIcon: {
        textAlign: 'center',
    },
}));

const Housing = ({ card }: HousingCardProps) => {
    const classes = useStyles();
    return (
        <div className={classes.housingCard}>
            <div className={classes.svgIcon}>
                <svg
                    className={classes.svg}
                    xmlns="http://www.w3.org/2000/svg"
                    width="66"
                    height="66"
                    viewBox="0 0 66 66"
                    fill="none"
                    data-card={card.card}
                >
                    <path
                        d="M22.5999 17.4706H17.0537C16.8239 17.4706 16.6377 17.2843 16.6377 17.0546V11.5084C16.6377 11.2786 16.8239 11.0924 17.0537 11.0924H22.5999C22.8296 11.0924 23.0158 11.2786 23.0158 11.5084V17.0546C23.0158 17.2843 22.8296 17.4706 22.5999 17.4706ZM17.4696 16.6386H22.1839V11.9243H17.4696V16.6386Z"
                        fill="white"
                    />

                    <path
                        d="M28.1468 17.4706H22.6005C22.3708 17.4706 22.1846 17.2844 22.1846 17.0547V11.5084C22.1846 11.2787 22.3708 11.0925 22.6005 11.0925H28.1468C28.3765 11.0925 28.5627 11.2787 28.5627 11.5084V17.0547C28.5627 17.2844 28.3765 17.4706 28.1468 17.4706ZM23.0165 16.6387H27.7308V11.9244H23.0165V16.6387Z"
                        fill="white"
                    />

                    <path
                        d="M33.6927 17.4706H28.1464C27.9167 17.4706 27.7305 17.2844 27.7305 17.0547V11.5084C27.7305 11.2787 27.9167 11.0925 28.1464 11.0925H33.6927C33.9224 11.0925 34.1086 11.2787 34.1086 11.5084V17.0547C34.1086 17.2844 33.9224 17.4706 33.6927 17.4706ZM28.5624 16.6387H33.2767V11.9244H28.5624V16.6387Z"
                        fill="white"
                    />

                    <path
                        d="M39.2395 17.4706H33.6933C33.4636 17.4706 33.2773 17.2843 33.2773 17.0546V11.5084C33.2773 11.2786 33.4636 11.0924 33.6933 11.0924H39.2395C39.4693 11.0924 39.6555 11.2786 39.6555 11.5084V17.0546C39.6555 17.2843 39.4693 17.4706 39.2395 17.4706ZM34.1093 16.6386H38.8236V11.9243H34.1093V16.6386Z"
                        fill="white"
                    />

                    <path
                        d="M22.5999 28.5631H17.0537C16.8239 28.5631 16.6377 28.3769 16.6377 28.1471V22.6009C16.6377 22.3712 16.8239 22.1849 17.0537 22.1849H22.5999C22.8296 22.1849 23.0158 22.3712 23.0158 22.6009V28.1471C23.0158 28.3769 22.8296 28.5631 22.5999 28.5631ZM17.4696 27.7312H22.1839V23.0169H17.4696V27.7312Z"
                        fill="white"
                    />

                    <path
                        d="M28.1468 28.563H22.6005C22.3708 28.563 22.1846 28.3768 22.1846 28.1471V22.6008C22.1846 22.3711 22.3708 22.1849 22.6005 22.1849H28.1468C28.3765 22.1849 28.5627 22.3711 28.5627 22.6008V28.1471C28.5627 28.3768 28.3765 28.563 28.1468 28.563ZM23.0165 27.7311H27.7308V23.0168H23.0165V27.7311Z"
                        fill="white"
                    />

                    <path
                        d="M33.6927 28.563H28.1464C27.9167 28.563 27.7305 28.3768 27.7305 28.1471V22.6008C27.7305 22.3711 27.9167 22.1849 28.1464 22.1849H33.6927C33.9224 22.1849 34.1086 22.3711 34.1086 22.6008V28.1471C34.1086 28.3768 33.9224 28.563 33.6927 28.563ZM28.5624 27.7311H33.2767V23.0168H28.5624V27.7311Z"
                        fill="white"
                    />

                    <path
                        d="M39.2395 28.5631H33.6933C33.4636 28.5631 33.2773 28.3769 33.2773 28.1471V22.6009C33.2773 22.3712 33.4636 22.1849 33.6933 22.1849H39.2395C39.4693 22.1849 39.6555 22.3712 39.6555 22.6009V28.1471C39.6555 28.3769 39.4693 28.5631 39.2395 28.5631ZM34.1093 27.7312H38.8236V23.0169H34.1093V27.7312Z"
                        fill="white"
                    />

                    <path
                        d="M22.5999 23.0168H17.0537C16.8239 23.0168 16.6377 22.8306 16.6377 22.6009V17.0546C16.6377 16.8249 16.8239 16.6387 17.0537 16.6387H22.5999C22.8296 16.6387 23.0158 16.8249 23.0158 17.0546V22.6009C23.0158 22.8306 22.8296 23.0168 22.5999 23.0168ZM17.4696 22.1849H22.1839V17.4706H17.4696V22.1849Z"
                        fill="white"
                    />

                    <path
                        d="M28.1468 23.0168H22.6005C22.3708 23.0168 22.1846 22.8306 22.1846 22.6009V17.0546C22.1846 16.8249 22.3708 16.6387 22.6005 16.6387H28.1468C28.3765 16.6387 28.5627 16.8249 28.5627 17.0546V22.6009C28.5627 22.8306 28.3765 23.0168 28.1468 23.0168ZM23.0165 22.1849H27.7308V17.4706H23.0165V22.1849Z"
                        fill="white"
                    />

                    <path
                        d="M33.6927 23.0168H28.1464C27.9167 23.0168 27.7305 22.8306 27.7305 22.6009V17.0546C27.7305 16.8249 27.9167 16.6387 28.1464 16.6387H33.6927C33.9224 16.6387 34.1086 16.8249 34.1086 17.0546V22.6009C34.1086 22.8306 33.9224 23.0168 33.6927 23.0168ZM28.5624 22.1849H33.2767V17.4706H28.5624V22.1849Z"
                        fill="white"
                    />

                    <path
                        d="M39.2395 23.0168H33.6933C33.4636 23.0168 33.2773 22.8306 33.2773 22.6009V17.0546C33.2773 16.8249 33.4636 16.6387 33.6933 16.6387H39.2395C39.4693 16.6387 39.6555 16.8249 39.6555 17.0546V22.6009C39.6555 22.8306 39.4693 23.0168 39.2395 23.0168ZM34.1093 22.1849H38.8236V17.4706H34.1093V22.1849Z"
                        fill="white"
                    />

                    <path
                        d="M47.5588 6.37815H8.7353C8.50555 6.37815 8.31934 6.19194 8.31934 5.96219V0.415966C8.31934 0.186214 8.50555 0 8.7353 0H47.5588C47.7886 0 47.9748 0.186214 47.9748 0.415966V5.96219C47.9748 6.19194 47.7886 6.37815 47.5588 6.37815ZM9.15127 5.54622H47.1429V0.831933H9.15127V5.54622Z"
                        fill="white"
                    />

                    <path
                        d="M44.0919 34.1093H12.2011C11.9714 34.1093 11.7852 33.9231 11.7852 33.6933V5.96223C11.7852 5.73248 11.9714 5.54626 12.2011 5.54626H44.0919C44.3216 5.54626 44.5078 5.73248 44.5078 5.96223V33.6933C44.5078 33.9231 44.3216 34.1093 44.0919 34.1093ZM12.6171 33.2774H43.6759V6.3782H12.6171V33.2774Z"
                        fill="white"
                    />

                    <path
                        d="M44.0919 66.0001H12.2011C11.9714 66.0001 11.7852 65.8139 11.7852 65.5841V39.2396C11.7852 39.0098 11.9714 38.8236 12.2011 38.8236H44.0919C44.3216 38.8236 44.5078 39.0098 44.5078 39.2396V65.5841C44.5078 65.8139 44.3216 66.0001 44.0919 66.0001ZM12.6171 65.1681H43.6759V39.6555H12.6171V65.1681Z"
                        fill="white"
                    />

                    <path
                        d="M44.0919 39.6555H12.2011C11.9714 39.6555 11.7852 39.4693 11.7852 39.2395V33.6933C11.7852 33.4636 11.9714 33.2773 12.2011 33.2773H44.0919C44.3216 33.2773 44.5078 33.4636 44.5078 33.6933V39.2395C44.5078 39.4693 44.3216 39.6555 44.0919 39.6555ZM12.6171 38.8236H43.6759V34.1093H12.6171V38.8236Z"
                        fill="white"
                    />

                    <path
                        d="M33.693 66H22.6005C22.3708 66 22.1846 65.8138 22.1846 65.584V49.6386C22.1846 49.4089 22.3708 49.2227 22.6005 49.2227H33.693C33.9227 49.2227 34.1089 49.4089 34.1089 49.6386V65.584C34.1089 65.8138 33.9227 66 33.693 66ZM23.0165 65.168H33.277V50.0546H23.0165V65.168Z"
                        fill="white"
                    />

                    <path
                        d="M44.0925 61.1471H33.6933C33.4636 61.1471 33.2773 60.9609 33.2773 60.7312C33.2773 60.5014 33.4636 60.3152 33.6933 60.3152H44.0925C44.3222 60.3152 44.5084 60.5014 44.5084 60.7312C44.5084 60.9609 44.3222 61.1471 44.0925 61.1471Z"
                        fill="white"
                    />

                    <path
                        d="M22.6003 61.1471H12.2011C11.9714 61.1471 11.7852 60.9609 11.7852 60.7312C11.7852 60.5014 11.9714 60.3152 12.2011 60.3152H22.6003C22.83 60.3152 23.0162 60.5014 23.0162 60.7312C23.0162 60.9609 22.83 61.1471 22.6003 61.1471Z"
                        fill="white"
                    />

                    <path
                        d="M28.1464 66C27.9167 66 27.7305 65.8138 27.7305 65.584V49.6386C27.7305 49.4089 27.9167 49.2227 28.1464 49.2227C28.3762 49.2227 28.5624 49.4089 28.5624 49.6386V65.584C28.5624 65.8138 28.3762 66 28.1464 66Z"
                        fill="white"
                    />

                    <path
                        d="M62.8122 66H44.0937C43.8639 66 43.6777 65.8138 43.6777 65.584V16.3613C43.6777 16.1316 43.8639 15.9454 44.0937 15.9454H62.8122C63.0419 15.9454 63.2282 16.1316 63.2282 16.3613V65.584C63.2282 65.8138 63.0419 66 62.8122 66ZM44.5097 65.1681H62.3962V16.7773H44.5097V65.1681Z"
                        fill="white"
                    />

                    <path
                        d="M48.2529 24.4033C48.0231 24.4033 47.8369 24.2171 47.8369 23.9874V19.8277C47.8369 19.598 48.0231 19.4117 48.2529 19.4117C48.4826 19.4117 48.6688 19.598 48.6688 19.8277V23.9874C48.6688 24.2171 48.4826 24.4033 48.2529 24.4033Z"
                        fill="white"
                    />

                    <path
                        d="M48.2529 28.563C48.0231 28.563 47.8369 28.3768 47.8369 28.147V26.7604C47.8369 26.5307 48.0231 26.3445 48.2529 26.3445C48.4826 26.3445 48.6688 26.5307 48.6688 26.7604V28.147C48.6688 28.3768 48.4826 28.563 48.2529 28.563Z"
                        fill="white"
                    />

                    <path
                        d="M51.7187 24.4033C51.4889 24.4033 51.3027 24.2171 51.3027 23.9874V19.8277C51.3027 19.598 51.4889 19.4117 51.7187 19.4117C51.9485 19.4117 52.1347 19.598 52.1347 19.8277V23.9874C52.1347 24.2171 51.9485 24.4033 51.7187 24.4033Z"
                        fill="white"
                    />

                    <path
                        d="M51.7187 28.563C51.4889 28.563 51.3027 28.3768 51.3027 28.147V26.7604C51.3027 26.5307 51.4889 26.3445 51.7187 26.3445C51.9485 26.3445 52.1347 26.5307 52.1347 26.7604V28.147C52.1347 28.3768 51.9485 28.563 51.7187 28.563Z"
                        fill="white"
                    />

                    <path
                        d="M55.1855 24.4033C54.9557 24.4033 54.7695 24.2171 54.7695 23.9874V19.8277C54.7695 19.598 54.9557 19.4117 55.1855 19.4117C55.4152 19.4117 55.6015 19.598 55.6015 19.8277V23.9874C55.6015 24.2171 55.4152 24.4033 55.1855 24.4033Z"
                        fill="white"
                    />

                    <path
                        d="M55.1855 28.563C54.9557 28.563 54.7695 28.3768 54.7695 28.147V26.7604C54.7695 26.5307 54.9557 26.3445 55.1855 26.3445C55.4152 26.3445 55.6015 26.5307 55.6015 26.7604V28.147C55.6015 28.3768 55.4152 28.563 55.1855 28.563Z"
                        fill="white"
                    />

                    <path
                        d="M58.6513 24.4033C58.4216 24.4033 58.2354 24.2171 58.2354 23.9874V19.8277C58.2354 19.598 58.4216 19.4117 58.6513 19.4117C58.8811 19.4117 59.0673 19.598 59.0673 19.8277V23.9874C59.0673 24.2171 58.8811 24.4033 58.6513 24.4033Z"
                        fill="white"
                    />

                    <path
                        d="M58.6513 28.563C58.4216 28.563 58.2354 28.3768 58.2354 28.147V26.7604C58.2354 26.5307 58.4216 26.3445 58.6513 26.3445C58.8811 26.3445 59.0673 26.5307 59.0673 26.7604V28.147C59.0673 28.3768 58.8811 28.563 58.6513 28.563Z"
                        fill="white"
                    />

                    <path
                        d="M48.2529 35.4957C48.0231 35.4957 47.8369 35.3095 47.8369 35.0798V30.9201C47.8369 30.6904 48.0231 30.5042 48.2529 30.5042C48.4826 30.5042 48.6688 30.6904 48.6688 30.9201V35.0798C48.6688 35.3095 48.4826 35.4957 48.2529 35.4957Z"
                        fill="white"
                    />

                    <path
                        d="M48.2529 39.6554C48.0231 39.6554 47.8369 39.4692 47.8369 39.2395V37.8529C47.8369 37.6232 48.0231 37.437 48.2529 37.437C48.4826 37.437 48.6688 37.6232 48.6688 37.8529V39.2395C48.6688 39.4692 48.4826 39.6554 48.2529 39.6554Z"
                        fill="white"
                    />

                    <path
                        d="M51.7187 35.4957C51.4889 35.4957 51.3027 35.3095 51.3027 35.0798V30.9201C51.3027 30.6904 51.4889 30.5042 51.7187 30.5042C51.9485 30.5042 52.1347 30.6904 52.1347 30.9201V35.0798C52.1347 35.3095 51.9485 35.4957 51.7187 35.4957Z"
                        fill="white"
                    />

                    <path
                        d="M51.7187 39.6554C51.4889 39.6554 51.3027 39.4692 51.3027 39.2395V37.8529C51.3027 37.6232 51.4889 37.437 51.7187 37.437C51.9485 37.437 52.1347 37.6232 52.1347 37.8529V39.2395C52.1347 39.4692 51.9485 39.6554 51.7187 39.6554Z"
                        fill="white"
                    />

                    <path
                        d="M55.1855 35.4958C54.9557 35.4958 54.7695 35.3096 54.7695 35.0798V30.9202C54.7695 30.6904 54.9557 30.5042 55.1855 30.5042C55.4152 30.5042 55.6015 30.6904 55.6015 30.9202V35.0798C55.6015 35.3096 55.4152 35.4958 55.1855 35.4958Z"
                        fill="white"
                    />

                    <path
                        d="M55.1855 39.6554C54.9557 39.6554 54.7695 39.4692 54.7695 39.2395V37.8529C54.7695 37.6232 54.9557 37.437 55.1855 37.437C55.4152 37.437 55.6015 37.6232 55.6015 37.8529V39.2395C55.6015 39.4692 55.4152 39.6554 55.1855 39.6554Z"
                        fill="white"
                    />

                    <path
                        d="M58.6513 35.4958C58.4216 35.4958 58.2354 35.3096 58.2354 35.0798V30.9202C58.2354 30.6904 58.4216 30.5042 58.6513 30.5042C58.8811 30.5042 59.0673 30.6904 59.0673 30.9202V35.0798C59.0673 35.3096 58.8811 35.4958 58.6513 35.4958Z"
                        fill="white"
                    />

                    <path
                        d="M58.6513 39.6554C58.4216 39.6554 58.2354 39.4692 58.2354 39.2395V37.8529C58.2354 37.6232 58.4216 37.437 58.6513 37.437C58.8811 37.437 59.0673 37.6232 59.0673 37.8529V39.2395C59.0673 39.4692 58.8811 39.6554 58.6513 39.6554Z"
                        fill="white"
                    />

                    <path
                        d="M48.2529 46.5883C48.0231 46.5883 47.8369 46.4021 47.8369 46.1723V42.0126C47.8369 41.7829 48.0231 41.5967 48.2529 41.5967C48.4826 41.5967 48.6688 41.7829 48.6688 42.0126V46.1723C48.6688 46.4021 48.4826 46.5883 48.2529 46.5883Z"
                        fill="white"
                    />

                    <path
                        d="M48.2529 50.7478C48.0231 50.7478 47.8369 50.5616 47.8369 50.3319V48.9453C47.8369 48.7156 48.0231 48.5294 48.2529 48.5294C48.4826 48.5294 48.6688 48.7156 48.6688 48.9453V50.3319C48.6688 50.5616 48.4826 50.7478 48.2529 50.7478Z"
                        fill="white"
                    />

                    <path
                        d="M51.7187 46.5883C51.4889 46.5883 51.3027 46.4021 51.3027 46.1723V42.0126C51.3027 41.7829 51.4889 41.5967 51.7187 41.5967C51.9485 41.5967 52.1347 41.7829 52.1347 42.0126V46.1723C52.1347 46.4021 51.9485 46.5883 51.7187 46.5883Z"
                        fill="white"
                    />

                    <path
                        d="M51.7187 50.7478C51.4889 50.7478 51.3027 50.5616 51.3027 50.3319V48.9453C51.3027 48.7156 51.4889 48.5294 51.7187 48.5294C51.9485 48.5294 52.1347 48.7156 52.1347 48.9453V50.3319C52.1347 50.5616 51.9485 50.7478 51.7187 50.7478Z"
                        fill="white"
                    />

                    <path
                        d="M55.1855 46.5882C54.9557 46.5882 54.7695 46.402 54.7695 46.1722V42.0126C54.7695 41.7828 54.9557 41.5966 55.1855 41.5966C55.4152 41.5966 55.6015 41.7828 55.6015 42.0126V46.1722C55.6015 46.402 55.4152 46.5882 55.1855 46.5882Z"
                        fill="white"
                    />

                    <path
                        d="M55.1855 50.7479C54.9557 50.7479 54.7695 50.5617 54.7695 50.3319V48.9454C54.7695 48.7156 54.9557 48.5294 55.1855 48.5294C55.4152 48.5294 55.6015 48.7156 55.6015 48.9454V50.3319C55.6015 50.5617 55.4152 50.7479 55.1855 50.7479Z"
                        fill="white"
                    />

                    <path
                        d="M58.6513 46.5882C58.4216 46.5882 58.2354 46.402 58.2354 46.1722V42.0126C58.2354 41.7828 58.4216 41.5966 58.6513 41.5966C58.8811 41.5966 59.0673 41.7828 59.0673 42.0126V46.1722C59.0673 46.402 58.8811 46.5882 58.6513 46.5882Z"
                        fill="white"
                    />

                    <path
                        d="M58.6513 50.7479C58.4216 50.7479 58.2354 50.5617 58.2354 50.3319V48.9454C58.2354 48.7156 58.4216 48.5294 58.6513 48.5294C58.8811 48.5294 59.0673 48.7156 59.0673 48.9454V50.3319C59.0673 50.5617 58.8811 50.7479 58.6513 50.7479Z"
                        fill="white"
                    />

                    <path
                        d="M48.2529 57.6807C48.0231 57.6807 47.8369 57.4945 47.8369 57.2647V53.1051C47.8369 52.8753 48.0231 52.6891 48.2529 52.6891C48.4826 52.6891 48.6688 52.8753 48.6688 53.1051V57.2647C48.6688 57.4945 48.4826 57.6807 48.2529 57.6807Z"
                        fill="white"
                    />

                    <path
                        d="M48.2529 61.8403C48.0231 61.8403 47.8369 61.6541 47.8369 61.4243V60.0378C47.8369 59.808 48.0231 59.6218 48.2529 59.6218C48.4826 59.6218 48.6688 59.808 48.6688 60.0378V61.4243C48.6688 61.6541 48.4826 61.8403 48.2529 61.8403Z"
                        fill="white"
                    />

                    <path
                        d="M51.7187 57.6807C51.4889 57.6807 51.3027 57.4945 51.3027 57.2647V53.1051C51.3027 52.8753 51.4889 52.6891 51.7187 52.6891C51.9485 52.6891 52.1347 52.8753 52.1347 53.1051V57.2647C52.1347 57.4945 51.9485 57.6807 51.7187 57.6807Z"
                        fill="white"
                    />

                    <path
                        d="M51.7187 61.8403C51.4889 61.8403 51.3027 61.6541 51.3027 61.4243V60.0378C51.3027 59.808 51.4889 59.6218 51.7187 59.6218C51.9485 59.6218 52.1347 59.808 52.1347 60.0378V61.4243C52.1347 61.6541 51.9485 61.8403 51.7187 61.8403Z"
                        fill="white"
                    />

                    <path
                        d="M55.1855 57.6807C54.9557 57.6807 54.7695 57.4945 54.7695 57.2647V53.1051C54.7695 52.8753 54.9557 52.6891 55.1855 52.6891C55.4152 52.6891 55.6015 52.8753 55.6015 53.1051V57.2647C55.6015 57.4945 55.4152 57.6807 55.1855 57.6807Z"
                        fill="white"
                    />

                    <path
                        d="M55.1855 61.8403C54.9557 61.8403 54.7695 61.6541 54.7695 61.4243V60.0378C54.7695 59.808 54.9557 59.6218 55.1855 59.6218C55.4152 59.6218 55.6015 59.808 55.6015 60.0378V61.4243C55.6015 61.6541 55.4152 61.8403 55.1855 61.8403Z"
                        fill="white"
                    />

                    <path
                        d="M58.6513 57.6807C58.4216 57.6807 58.2354 57.4945 58.2354 57.2647V53.1051C58.2354 52.8753 58.4216 52.6891 58.6513 52.6891C58.8811 52.6891 59.0673 52.8753 59.0673 53.1051V57.2647C59.0673 57.4945 58.8811 57.6807 58.6513 57.6807Z"
                        fill="white"
                    />

                    <path
                        d="M58.6513 61.8403C58.4216 61.8403 58.2354 61.6541 58.2354 61.4243V60.0378C58.2354 59.808 58.4216 59.6218 58.6513 59.6218C58.8811 59.6218 59.0673 59.808 59.0673 60.0378V61.4243C59.0673 61.6541 58.8811 61.8403 58.6513 61.8403Z"
                        fill="white"
                    />

                    <path
                        d="M65.5853 16.7773H44.0937C43.8639 16.7773 43.6777 16.5911 43.6777 16.3613V12.2017C43.6777 11.9719 43.8639 11.7857 44.0937 11.7857H65.5853C65.8151 11.7857 66.0013 11.9719 66.0013 12.2017V16.3613C66.0013 16.5911 65.8151 16.7773 65.5853 16.7773ZM44.5097 15.9454H65.1693V12.6176H44.5097V15.9454Z"
                        fill="white"
                    />

                    <path
                        d="M5.26907 51.4412C3.05571 51.4412 1.38672 47.2387 1.38672 41.666C1.38672 36.0933 3.05571 31.8907 5.26907 31.8907C7.48243 31.8907 9.15142 36.0933 9.15142 41.666C9.15142 47.2387 7.48243 51.4412 5.26907 51.4412ZM5.26907 32.7227C3.82636 32.7227 2.21865 36.3955 2.21865 41.666C2.21865 46.9364 3.82636 50.6092 5.26907 50.6092C6.71178 50.6092 8.31949 46.9364 8.31949 41.666C8.31949 36.3955 6.71178 32.7227 5.26907 32.7227Z"
                        fill="white"
                    />

                    <path
                        d="M5.26851 66C5.03875 66 4.85254 65.8138 4.85254 65.584V42.7059C4.85254 42.4761 5.03875 42.2899 5.26851 42.2899C5.49826 42.2899 5.68447 42.4761 5.68447 42.7059V65.584C5.68447 65.8138 5.49826 66 5.26851 66Z"
                        fill="white"
                    />

                    <path
                        d="M65.584 66H0.415966C0.186214 66 0 65.8138 0 65.5841C0 65.3543 0.186214 65.1681 0.415966 65.1681H65.584C65.8138 65.1681 66 65.3543 66 65.5841C66 65.8138 65.8138 66 65.584 66Z"
                        fill="white"
                    />
                </svg>
            </div>
            <hr />
            <h3 className={classes.h3}>{card.header}</h3>
            <ul>
                {card.list.map((item) => (
                    <li
                        className={classes.li}
                        key={`${card.card}-${Math.random()}`}
                    >
                        {item.desc}
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default Housing;
