import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { AVENIR_NEXT_LT_REGULAR } from 'theme/font';
import { pxToRem } from 'theme/util';
//import { FormattedMessage, useIntl } from 'react-intl';
//import colors from 'theme/colors';
import SectionWrapper from '../components/SectionWrapper';
import VideoPlayer, {
    videoParamsType,
} from 'components/dynamic-media-player/VideoPlayer';
import colors from 'theme/colors';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        minHeight: 'calc(100vh - 100px)',
        fontFamily: AVENIR_NEXT_LT_REGULAR,
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.up('md')]: {
            width: '98%',
            // paddingBottom: 150,
            // paddingTop: 50,
        },
        [theme.breakpoints.between(800, 1000)]: {
            minHeight: 'calc(100vh - 350px)',
        },
    },
    title: {
        color: colors.black,
        fontSize: pxToRem(25),
        lineHeight: '35px',
        textAlign: 'center',
        fontWeight: 600,
        [theme.breakpoints.up(768)]: {
            fontSize: pxToRem(45),
            lineHeight: '39px',
            textAlign: 'unset',
        },
    },
    flexContainer: {
        display: 'flex',
        flexDirection: 'column',
        //border: 'solid 3px blue',
        [theme.breakpoints.up(768)]: {
            flexDirection: 'unset',
            alignItems: 'center',
            justifyContent: 'center',
        },
    },
    flextItem: {
        flex: 1,
        textAlign: 'left',
    },
    contentWrapper: {
        color: colors.black,
        padding: 20,
        fontSize: pxToRem(20),
        lineHeight: '26px',
        fontWeight: 600,
        marginTop: 'unset',
        [theme.breakpoints.up(768)]: {
            marginTop: -125,
        },
    },
    videoWrapper: {
        //height: 400,
    },
}));

interface SectionProps {
    isMobile?: boolean;
    data: any;
}

const Section6: React.FC<SectionProps> = ({
    //isMobile = false,
    data,
}) => {
    const classes = useStyles();
    //const intl = useIntl();

    if (!data) {
        return <div>NO Data</div>;
    }

    return (
        <SectionWrapper sectionId="section_6">
            <div className={classes.root} data-qa="hispanic-section-6">
                <div>
                    <h2 className={classes.title}>{data.title}</h2>
                    <div className={classes.flexContainer}>
                        <div className={classes.flextItem}>
                            <div className={classes.videoWrapper}>
                                <VideoPlayer
                                    params={data.videoConfig as videoParamsType}
                                    id="hispanic-section-6-video"
                                    title="Accessing your money video"
                                />
                            </div>
                        </div>
                        <div
                            className={`${classes.flextItem} ${classes.contentWrapper}`}
                        >
                            {data.content}
                        </div>
                    </div>
                </div>
            </div>
        </SectionWrapper>
    );
};

export default Section6;
