import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { AVENIR_NEXT_LT_REGULAR } from 'theme/font';
import { pxToRem } from 'theme/util';
//import { FormattedMessage, useIntl } from 'react-intl';
import colors from 'theme/colors';
import SectionWrapper from '../components/SectionWrapper';
import VideoPlayer, {
    videoParamsType,
} from 'components/dynamic-media-player/VideoPlayer';
import { useHistory } from 'react-router-dom';
import { Button } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        minHeight: 'calc(100vh - 100px)',
        color: colors.black,
        fontFamily: AVENIR_NEXT_LT_REGULAR,
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.up('md')]: {
            width: '98%',
            // paddingTop: '50px',
        },
        [theme.breakpoints.between(800, 1000)]: {
            minHeight: 'calc(100vh - 350px)',
        },
    },
    title: {
        color: colors.black,
        fontSize: pxToRem(25),
        lineHeight: '35px',
        textAlign: 'center',
        fontWeight: 600,
        [theme.breakpoints.up(768)]: {
            fontSize: pxToRem(35),
            lineHeight: '39px',
            textAlign: 'unset',
            marginBottom: 5,
        },
    },
    flexContainer: {
        display: 'flex',
        flexDirection: 'column',
        //border: 'solid 3px blue',
        width: '100%',
        [theme.breakpoints.up(768)]: {
            flexDirection: 'unset',
            alignItems: 'center',
            justifyContent: 'center',
        },
    },
    flextItem: {
        flex: 1,
        textAlign: 'left',
    },
    contentWrapper: {
        padding: 20,
        fontSize: pxToRem(20),
        lineHeight: '26px',
        weight: 400,
        marginTop: 'unset',
        textAlign: 'center',
        [theme.breakpoints.up(768)]: {
            //marginTop: -125,
            textAlign: 'unset',
        },
    },
    videoWrapper: {
        //height: 400,
    },
    calcLink: {
        color: '#364F6B',
        fontWeight: 'bold',
        display: 'contents',
        textDecoration: 'underline',
    },
}));

interface SectionProps {
    isMobile?: boolean;
    data: any;
}

const Section8: React.FC<SectionProps> = ({ data }) => {
    const classes = useStyles();
    const { companyId } = useSelector((state: RootState) => ({
        companyId: state.company.theme?.companyId,
    }));
    const history = useHistory();

    if (!data) {
        return <div>NO Data</div>;
    }

    return (
        <SectionWrapper sectionId="section_8">
            <div className={classes.root} data-qa="hispanic-section-8">
                <div className={classes.flexContainer}>
                    <div
                        className={`${classes.flextItem} ${classes.contentWrapper}`}
                    >
                        <h2 className={classes.title}>{data.title}</h2>
                        {data.content1}
                        <Button
                            className={classes.calcLink}
                            onClick={() =>
                                history.push(`/${companyId}${data.calcLink}`)
                            }
                        >
                            {data.calcLabel}
                        </Button>
                        {/* <Link
                            className={classes.calcLink}
                            to={`/${companyId}${data.calcLink}`}
                        >
                            {data.calcLabel}
                        </Link> */}
                        {data.content2}
                    </div>
                    <div className={classes.flextItem}>
                        <div className={classes.videoWrapper}>
                            <VideoPlayer
                                params={data.videoConfig as videoParamsType}
                                id="hispanic-section-8-video"
                                title="Calculator video"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </SectionWrapper>
    );
};

export default Section8;
