import React from 'react';
import loadable from '@loadable/component';

/* components */
import { Loader } from 'components';

export const asyncLoad = {
    /* async load by container foldername, can be any path in containers */
    container: (componentPath) =>
        loadable(() => import(`../../containers/${componentPath}`), {
            fallback: <Loader />,
        }),
    component: (componentPath, Loading = Loader) =>
        loadable(() => import(`../../components/${componentPath}`), {
            fallback: <Loading />,
        }),
};
