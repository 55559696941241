import { MutableRefObject, useEffect, useRef } from 'react';

type UseHtmlParser = (htmlString: string, onLinkClick: (event: MouseEvent) => void) => MutableRefObject<HTMLDivElement | null>;

const useHtmlParser: UseHtmlParser = (htmlString, onLinkClick) => {
  const containerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const container = containerRef.current;
		if (container) {
			const links = container.getElementsByTagName('a');
			Array.from(links).forEach((link) => {
				link.addEventListener('click', onLinkClick);
			});
		}

		return () => {
			if (container) {
				const links = container.getElementsByTagName('a');
				Array.from(links).forEach((link) => {
					link.removeEventListener('click', onLinkClick);
				});
			}
		};
  }, [htmlString, onLinkClick]);

  return containerRef;
};

export default useHtmlParser;