import React, { FC } from 'react';

import Box from '@material-ui/core/Box';

const PageWrapper: FC = ({ children, ...others }) => {
    return (
        <Box
            {...others}
            mx="auto"
            width={{ xs: '100%', lg: 1260 }}
            overflow="hidden"
        >
            {children}
        </Box>
    );
};

export default PageWrapper;
