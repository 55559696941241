/* interfaces */
import {
    ArticleSummaries,
    LikesPayload,
} from 'containers/article-landing/article.types';
import {
    GetVirtualEventArticlesSuccessAction,
    GetVirtualEventArticlesAction,
    GetVirtualEventArticlesErrorAction,
} from './virtual-event-articles';

/* constants */
import {
    CLEAR_READ_ARTICLES,
    GET_ARTICLE_SUCCESS,
    GET_ARTICLE_ERROR,
    GET_ARTICLE_LIKES_SUCCESS,
    GET_ARTICLE_SUMMARIES_ERROR,
    UPDATE_ARTICLE_LIKES_SUCCESS,
    GET_MONEY_MINUTE_ARTICLES,
    GET_MONEY_MINUTE_ARTICLES_SUCCESS,
    GET_MONEY_MINUTE_ARTICLES_ERROR,
    UPDATE_READ_INFLUENCER_ARTICLES,
    CLEAR_READ_INFLUENCER_ARTICLES,
} from 'constants/article';

export type CLEAR_READ_ARTICLES = typeof CLEAR_READ_ARTICLES;
export type GET_ARTICLE_ERROR = typeof GET_ARTICLE_ERROR;
export type GET_ARTICLE_SUCCESS = typeof GET_ARTICLE_SUCCESS;
export type GET_ARTICLE_LIKES_SUCCESS = typeof GET_ARTICLE_LIKES_SUCCESS;
export type GET_ARTICLE_SUMMARIES_ERROR = typeof GET_ARTICLE_SUMMARIES_ERROR;
export type UPDATE_ARTICLE_LIKES_SUCCESS = typeof UPDATE_ARTICLE_LIKES_SUCCESS;
export type GET_MONEY_MINUTE_ARTICLES = typeof GET_MONEY_MINUTE_ARTICLES;
export type GET_MONEY_MINUTE_ARTICLES_ERROR = typeof GET_MONEY_MINUTE_ARTICLES_ERROR;
export type GET_MONEY_MINUTE_ARTICLES_SUCCESS = typeof GET_MONEY_MINUTE_ARTICLES_SUCCESS;
export type UPDATE_READ_INFLUENCER_ARTICLES = typeof UPDATE_READ_INFLUENCER_ARTICLES;
export type CLEAR_READ_INFLUENCER_ARTICLES = typeof CLEAR_READ_INFLUENCER_ARTICLES;

/* types */
export type ClearReadArticlesAction = {
    type: CLEAR_READ_ARTICLES;
    articleId: string;
};

export type GetArticleSuccessAction = {
    type: GET_ARTICLE_SUCCESS;
    articleId: string;
};

export type GetArticleErrorAction = {
    type: GET_ARTICLE_ERROR;
    error: Error;
};

export type GetArticleSummariesErrorAction = {
    type: GET_ARTICLE_SUMMARIES_ERROR;
    error: Error;
};

export type GetLikesSuccessAction = {
    type: GET_ARTICLE_LIKES_SUCCESS;
    likes: number;
};

export type UpdateLikesSuccessAction = {
    type: UPDATE_ARTICLE_LIKES_SUCCESS;
    articleId: string;
};

export type GetMoneyMinuteArticlesAction = {
    type: GET_MONEY_MINUTE_ARTICLES;
};

export type GetMoneyMinuteArticlesSuccessAction = {
    type: GET_MONEY_MINUTE_ARTICLES_SUCCESS;
    articles: ArticleSummaries[];
};

export type GetMoneyMinuteArticlesErrorAction = {
    type: GET_MONEY_MINUTE_ARTICLES_ERROR;
    error: Error;
};

export type UpdateReadInfluencerArticlesAction = {
    type: UPDATE_READ_INFLUENCER_ARTICLES;
    articleId: string;
};

export type ClearReadInfluencerArticlesAction = {
    type: CLEAR_READ_INFLUENCER_ARTICLES;
    articleId: string | undefined;
};

/* action creator */
export const clearReadArticles = (
    articleId: string,
): ClearReadArticlesAction => ({
    type: CLEAR_READ_ARTICLES,
    articleId,
});

export const getArticleSuccess = (
    articleId: string,
): GetArticleSuccessAction => ({
    type: GET_ARTICLE_SUCCESS,
    articleId,
});

export const getArticleError = (error: Error): GetArticleErrorAction => ({
    type: GET_ARTICLE_ERROR,
    error,
});

export const getArticleSummariesError = (
    error: Error,
): GetArticleSummariesErrorAction => ({
    type: GET_ARTICLE_SUMMARIES_ERROR,
    error,
});

export const getLikesSuccess = (
    payload: LikesPayload,
): GetLikesSuccessAction => ({
    type: GET_ARTICLE_LIKES_SUCCESS,
    likes: payload.rating,
});

export const updateLikesSuccess = (
    articleId: string,
): UpdateLikesSuccessAction => ({
    type: UPDATE_ARTICLE_LIKES_SUCCESS,
    articleId,
});

export const getMoneyMinuteArticles = (): GetMoneyMinuteArticlesAction => ({
    type: GET_MONEY_MINUTE_ARTICLES,
});

export const getMoneyMinuteArticlesSuccess = (
    influencerArticles: ArticleSummaries[],
): GetMoneyMinuteArticlesSuccessAction => ({
    type: GET_MONEY_MINUTE_ARTICLES_SUCCESS,
    articles: influencerArticles,
});

export const getMoneyMinuteArticlesError = (
    error: Error,
): GetMoneyMinuteArticlesErrorAction => ({
    type: GET_MONEY_MINUTE_ARTICLES_ERROR,
    error,
});

export const updateReadInfluencerArticles = (
    articleId: string,
): UpdateReadInfluencerArticlesAction => ({
    type: UPDATE_READ_INFLUENCER_ARTICLES,
    articleId,
});

export const clearReadInfluencerArticles = (
    articleId?: string,
): ClearReadInfluencerArticlesAction => ({
    type: CLEAR_READ_INFLUENCER_ARTICLES,
    articleId,
});

export type ArticleActionTypes =
    | ClearReadArticlesAction
    | GetArticleErrorAction
    | GetArticleSuccessAction
    | GetArticleSummariesErrorAction
    | GetLikesSuccessAction
    | UpdateLikesSuccessAction
    | GetVirtualEventArticlesSuccessAction
    | GetVirtualEventArticlesAction
    | GetVirtualEventArticlesErrorAction
    | GetMoneyMinuteArticlesAction
    | GetMoneyMinuteArticlesErrorAction
    | GetMoneyMinuteArticlesSuccessAction
    | UpdateReadInfluencerArticlesAction
    | ClearReadInfluencerArticlesAction;
