import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';

/* material */
import { makeStyles } from '@material-ui/core/styles';

/* component */
import { Typography } from '@material-ui/core';
import TakeQuizCta from 'components/take-quiz/TakeQuizCta';

/* types */
import { Theme } from 'reducers/company';

/* utils */
import {
    eyebrowCard,
    AVENIR_NEXT_LT_REGULAR,
    AVENIR_NEXT_LT_BOLD,
} from 'theme/font';
import colors from 'theme/colors';
import { pxToRem } from 'theme/util';

const useStyles = makeStyles((theme) => ({
    contentWrapper: {
        backgroundColor: colors.turquoise5,
        borderTop: `solid 1px ${colors.turquoise30}`,
        padding: '24px 32px 32px',
        textAlign: 'center',
        width: '100%',

        [theme.breakpoints.up('sm')]: {
            padding: '34px 32px 48px',
        },
    },
    eyebrow: {
        ...eyebrowCard,
        textTransform: 'uppercase',

        [theme.breakpoints.up('sm')]: {
            fontFamily: AVENIR_NEXT_LT_BOLD,
            fontSize: pxToRem(14),
            letterSpacing: pxToRem(1.5),
        },
    },
    copy: {
        color: colors.darkSapphire,
        fontFamily: AVENIR_NEXT_LT_REGULAR,
        fontSize: pxToRem(22),
        lineHeight: pxToRem(29),
        margin: '16px 0 24px',

        [theme.breakpoints.up('sm')]: {
            margin: '24px 0 32px',
        },
    },
}));

type TakeQuizBannerProps = {
    company: Theme | null;
    domainName: string;
    size?: 'small' | 'medium' | 'large';
    dataAnalyticsPlacement: string;
};

const TakeQuizBanner: FC<TakeQuizBannerProps> = ({
    company,
    domainName,
    size,
    dataAnalyticsPlacement,
}) => {
    const classes = useStyles();

    return (
        <div data-qa="take-quiz-banner" className={classes.contentWrapper}>
            <Typography className={classes.eyebrow}>
                <FormattedMessage id="letsGetStarted" />
            </Typography>
            <div className={classes.copy}>
                <FormattedMessage id="changeContribution.thisJourney" />
            </div>
            <TakeQuizCta
                dataAnalyticsPlacement={dataAnalyticsPlacement}
                domainName={domainName}
                company={company}
                size={size}
            />
        </div>
    );
};

export default TakeQuizBanner;
