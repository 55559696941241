import React from 'react';
import { RenderType } from '../interfaces';

/* components */
import { FlashCardsContainer } from 'components';

const renderFlashCards: RenderType = (): JSX.Element | null => {
    return <FlashCardsContainer />;
};

export default renderFlashCards;
