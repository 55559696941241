import React, { FC } from 'react';

/* material */
import { makeStyles } from '@material-ui/core/styles';

/* utils */
import { AVENIR_NEXT_LT_DEMI, body1, contentLink, h4 } from 'theme/font';
import colors from 'theme/colors';
import { pxToRem } from 'theme/util';

const useStyles = makeStyles((theme) => ({
    list: {
        listStyle: 'none',
        margin: 0,
        marginBottom: pxToRem(40),
        padding: 0,
        [theme.breakpoints.up('sm')]: {
            marginLeft: pxToRem(21),
        },

        '& b': {
            ...h4,
            color: colors.darkSapphire,
            fontFamily: AVENIR_NEXT_LT_DEMI,
            display: 'block',
            marginBottom: pxToRem(11),
            [theme.breakpoints.up('sm')]: {
                fontSize: pxToRem(22),
                lineHeight: pxToRem(29),
                marginBottom: pxToRem(8),
            },
        },

        '& div': {
            alignItems: 'center',
            backgroundColor: colors.darkTurquoise,
            borderRadius: '50%',
            color: colors.white,
            display: 'flex',
            flexShrink: 0,
            fontFamily: AVENIR_NEXT_LT_DEMI,
            height: pxToRem(10),
            justifyContent: 'center',
            left: `-${pxToRem(21)}`,
            paddingTop: pxToRem(1),
            position: 'absolute',
            top: pxToRem(8),
            width: pxToRem(10),
            [theme.breakpoints.up('md')]: {
                left: `-${pxToRem(26)}`,
            },
        },

        '& li': {
            ...body1,
            margin: `0 0 ${pxToRem(40)} ${pxToRem(22)}`,
            position: 'relative',
            '&:last-child': {
                marginBottom: 0,
            },
            [theme.breakpoints.up('sm')]: {
                fontSize: pxToRem(18),
                lineHeight: pxToRem(28),
                marginLeft: pxToRem(26),
                marginBottom: pxToRem(8),
            },
        },
        '& a': { ...contentLink, textDecoration: 'none' },
    },
}));

const ArticleUnorderedList: FC = ({ children }) => {
    const classes = useStyles();
    return (
        <ul data-testid="article-unordered-list" className={classes.list}>
            {children}
        </ul>
    );
};

export default ArticleUnorderedList;
