import React, { FC } from 'react';

/* material */
import { makeStyles } from '@material-ui/core/styles';
import { Breakpoint } from '@material-ui/core/styles/createBreakpoints';
import { CreateCSSProperties } from '@material-ui/core/styles/withStyles';
import Box from '@material-ui/core/Box';

const fullWidthStyles: CreateCSSProperties = {
    display: 'block',
    left: '50%',
    marginLeft: '-50vw',
    marginRight: '-50vw',
    position: 'relative',
    right: '50%',
    width: '100vw',
};

const useStyles = makeStyles((theme) => ({
    hero: (props: Pick<HeroLayoutProps, 'fullWidth' | 'fullWidthUntil'>) => {
        const { fullWidth, fullWidthUntil } = props;

        if (fullWidthUntil) {
            return {
                ...fullWidthStyles,
                [theme.breakpoints.up(fullWidthUntil)]: {
                    left: 'inherit',
                    marginLeft: 'inherit',
                    marginRight: 'inherit',
                    right: 'inherit',
                    maxWidth: '100%',
                },
            };
        }

        if (fullWidth) {
            return fullWidthStyles;
        }

        return {
            display: 'block',
            maxWidth: '100%',
        };
    },
}));

type HeroLayoutProps = {
    children: React.ReactNode;
    fullWidth?: boolean;
    fullWidthUntil?: Breakpoint;
};

const HeroLayout: FC<HeroLayoutProps> = ({
    children,
    fullWidth,
    fullWidthUntil,
}) => {
    const classes = useStyles({ fullWidth, fullWidthUntil });
    return (
        <Box component="span" data-qa="HeroLayout" className={classes.hero}>
            {children}
        </Box>
    );
};

export default HeroLayout;
