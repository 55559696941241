import React, { FC, useState } from 'react';

/* components */
import {
    DynamicMediaPlayer,
    Accordion,
    AccordionDetails,
    AccordionSummary,
} from 'components';

/* material */
import { Box, Typography, makeStyles } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';

/* utils */
import { parseReplace } from 'utils/htmlParser';
import colors from 'theme/colors';
import { pxToSpacing, pxToRem } from 'theme/util';
import { AVENIR_NEXT_LT_DEMI } from 'theme/font';
import { customClickEvent } from 'analytics';

interface DynamicMediaPlayerWithTranscriptProps {
    params: { [key: string]: string } | undefined;
    title?: string;
    transcript?: string | undefined;
}

const useStyles = makeStyles((theme) => ({
    accordionTitle: {
        color: colors.darkSapphire,
        fontFamily: AVENIR_NEXT_LT_DEMI,
        fontSize: pxToRem(18),
        marginBottom: 11,

        [theme.breakpoints.up('sm')]: {
            fontSize: pxToRem(22),
        },
    },
}));

const DynamicMediaPlayerWithTranscript: FC<DynamicMediaPlayerWithTranscriptProps> = ({
    params,
    title,
    transcript,
}) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const classes = useStyles();
    const panelText = isExpanded ? 'Hide transcript' : 'Show transcript';

    const handleChange = (
        event: React.ChangeEvent<{}>,
        expanded: boolean,
    ): void => {
        event.preventDefault();
        setIsExpanded(expanded);

        customClickEvent({
            customLinkName:
                `${title}: ${expanded ? 'Show' : 'Hide'} transcript`,
        });

    };

    return (
        <>
            <DynamicMediaPlayer params={params} title={title} />
            {transcript && (
                <Accordion
                    data-qa="article-transcript"
                    square
                    onChange={handleChange}
                >
                    <AccordionSummary
                        id="transcript-header"
                        aria-controls="transcript-content"
                    >
                        <Typography
                            variant="h4"
                            component="p"
                            className={classes.accordionTitle}
                        >
                            {panelText}
                        </Typography>
                        <Box
                            color={colors.darkSapphire}
                            mr={`-${pxToSpacing(8)}`}
                        >
                            {isExpanded ? (
                                <RemoveIcon style={{ fontSize: 32 }} />
                            ) : (
                                <AddIcon style={{ fontSize: 32 }} />
                            )}
                        </Box>
                    </AccordionSummary>
                    <AccordionDetails>
                        {parseReplace(transcript)}
                    </AccordionDetails>
                </Accordion>
            )}
        </>
    );
};

export default DynamicMediaPlayerWithTranscript;
