export const CALCULATE_RETIREMENT_PROJECTION_UPDATE =
    'CALCULATE_RETIREMENT_PROJECTION_UPDATE';
export const CALCULATE_RETIREMENT_PROJECTION_UPDATE_SUCCESS =
    'CALCULATE_RETIREMENT_PROJECTION_UPDATE_SUCCESS';
export const CALCULATE_RETIREMENT_PROJECTION_UPDATE_ERROR =
    'CALCULATE_RETIREMENT_PROJECTION_ERROR';
export const CALCULATE_RETIREMENT_PROJECTIONS =
    'CALCULATE_RETIREMENT_PROJECTIONS';
export const CALCULATE_RETIREMENT_PROJECTIONS_SUCCESS =
    'CALCULATE_RETIREMENT_PROJECTIONS_SUCCESS';
export const CALCULATE_RETIREMENT_PROJECTIONS_ERROR =
    'CALCULATE_RETIREMENT_PROJECTIONS_ERROR';
export const CLEAR_RETIREMENT_PROJECTIONS = 'CLEAR_RETIREMENT_PROJECTIONS';
export const GET_RETIREMENT_CONTRIBUTION_CONSTANTS =
    'GET_RETIREMENT_CONTRIBUTION_CONSTANTS';
export const GET_RETIREMENT_CONTRIBUTION_CONSTANTS_SUCCESS =
    'GET_RETIREMENT_CONTRIBUTION_CONSTANTS_SUCCESS';
export const GET_RETIREMENT_CONTRIBUTION_CONSTANTS_ERROR =
    'GET_RETIREMENT_CONTRIBUTION_CONSTANTS_ERROR';

export const CONTRIBUTION_PERCENTAGE_KEY = 'contributionPercentage';
export const CURRENT_AGE_KEY = 'currentAge';
export const CURRENT_SAVINGS_KEY = 'currentSavings';
export const EMPLOYER_MATCHING_CONTRIBUTION_PERCENTAGE_KEY =
    'employerMatchingContributionPercentage';
export const EMPLOYER_MATCHING_UP_TO_CONTRIBUTION_PERCENTAGE_OF_SALARY_KEY =
    'employerMatchingUpToContributionPercentageOfSalary';
export const PRETAX_SALARY_KEY = 'pretaxSalary';
export const RETIREMENT_AGE_KEY = 'retirementAge';
export const AUTO_INCREASE_KEY = 'autoIncrease';

export const ANNUAL_NEED_AT_RETIREMENT_KEY = 'annualNeedAtRetirement';
export const GOAL_KEY = 'goal';
export const PREV_RETIREMENT_BALANCE_KEY = 'prevRetirementBalance';
export const RETIREMENT_BALANCE_KEY = 'retirementBalance';

export const MAX_AGE = 66;
export const MIN_AGE = 16;
export const MAX_CONTRIBUTION_PERCENTAGE = 99;
export const MIN_CONTRIBUTION_PERCENTAGE = 0;
export const MAX_PRETAX_SALARY = 10000000;
export const MIN_PRETAX_SALARY = 1000;
export const MAX_RETIREMENT_AGE = 87;
export const MIN_RETIREMENT_AGE = 35;
export const MAX_SAVINGS = 100000000;
export const MIN_SAVINGS = 0;

export const MAX_CONTRIBUTION_AGE_THRESHOLD = 50;
export const MAX_CONTRIBUTION_UNDER_50 = 19500;
export const MAX_CONTRIBUTION_OVER_EQUAL_50 = 26000;
