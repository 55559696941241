import * as React from 'react';
import { FC } from 'react';

import BaseEmoji from './BaseEmoji';
import { sentimentColors } from 'theme/colors';

const ConfidentEmoji: FC = () => {
    const mouthPath = (
        <path
            d="M24.7224121,57.7638473 C25.9931641,55.0800094 29.7041016,47.2440719 40.3514497,47.2440719 C50.9987978,47.2440719 55.473877,54.3424606 56.9733887,57.7638473 C58.4729004,61.185234 56.5419922,61.3334274 54.4978027,60.659111 C52.4536133,59.9847946 48.0378511,58.719902 40.3514497,58.719902 C32.6650483,58.719902 29.4812012,60.0756149 27.3334961,60.659111 C25.185791,61.2426071 23.4516602,60.4476852 24.7224121,57.7638473 Z"
            id="Rectangle"
            fill="#434343"
            transform="translate(40.898702, 54.111294) scale(1, -1) translate(-40.898702, -54.111294) "
        ></path>
    );
    return <BaseEmoji color={sentimentColors.confident} mouth={mouthPath} />;
};

export default ConfidentEmoji;
