import React from 'react';
import { domToReact } from 'html-react-parser';
/* interfaces */
import { RenderElement } from '../interfaces';

/* utils */
import { getCustomTagContent } from '../utils';

/* components */
import { ArticleReferences } from 'containers/article-landing';

const renderArticleReference: RenderElement = (props): JSX.Element => {
    const { domNode, options } = props;
    const { children } = domNode;
    const domElements = children && getCustomTagContent(children);

    return (
        <ArticleReferences>
            {domElements && domToReact(domElements, options)}
        </ArticleReferences>
    );
};

export default renderArticleReference;
