/* utils */
import { Theme } from 'reducers';

/**
 * Get article topic
 * @param tags
 *
 * @returns {string }
 */
export const getTopicTitle = (tags: string | string[]): string => {
    return getTopicTag(tags).replace(/-/g, ' ');
};

/**
 * Get article topic landing page url by topic
 * @param {string} topic
 * @param {Company} company
 *
 * @returns {string }
 */
export const getTopicUrl = (
    company: Theme,
    topics: string | string[],
): string => {
    const companyId = company.companyId;

    if (!companyId || !topics) return '';

    return `/${companyId}/get-the-facts/more/${getTopicTag(topics)}`;
};

/**
 * Get first article topic from a given AEM article fragment
 * @param {string[]} tags - article tags
 *
 * @returns { string }
 */
export const getTopicTag = (tags: string | string[] | undefined): string => {
    if (!tags) return '';
    const tag = typeof tags === 'string' ? tags : tags[0];
    const lastSlashIdx = tag.lastIndexOf('/') + 1;
    return tag.substring(lastSlashIdx);
};
