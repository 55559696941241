import React, { FC, useState, ChangeEvent } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { pxToRem } from 'theme/util';
import Option from './Option';
import Accordion from './Accordion';
import { RecipeOption } from './types';
import { customClickEvent } from 'analytics';

const useStyles = makeStyles((theme: Theme) => ({
    cardHeading: {
        fontWeight: 700,
        color: theme.colors.blackLight,
    },
    divider: {
        marginLeft: pxToRem(24),
        marginRight: pxToRem(24),
    },
}));

export interface RecipeCardProps {
    id: string;
    options: RecipeOption[];
}

const RecipeCard: FC<RecipeCardProps> = ({ id, options }: RecipeCardProps) => {
    const classes = useStyles();
    const [selectedOptionTitle, setSelectedOptionTitle] = useState<
        string | undefined | null
    >(undefined);
    const onOptionSelectionChange = (event: ChangeEvent<HTMLInputElement>) =>
        setSelectedOptionTitle(event.target.value);
    const onAccordionSelectionChange = (
        expanded: boolean,
        option: RecipeOption,
    ) => {
        if (expanded) {
            setSelectedOptionTitle(option.label.title);
        } else {
            setSelectedOptionTitle(null);
        }
    };

    return (
        <Card
            elevation={4}
            data-qa="recipe-card"
            onClick={() => {
                customClickEvent({
                    customLinkName: `recipe-card: recipe-card-${id}`,
                });
            }}
        >
            <CardContent style={{ padding: 0 }}>
                <Box pt={5} px={7} pb={3}>
                    <Box mb={5}>
                        <Typography
                            variant="h4"
                            className={classes.cardHeading}
                            gutterBottom
                        >
                            Choose your preference:
                        </Typography>
                    </Box>
                    {options.map((option: RecipeOption) => (
                        <Option
                            key={option.label.title}
                            name="recipe-option"
                            option={option}
                            checked={selectedOptionTitle === option.label.title}
                            onChange={onOptionSelectionChange}
                        />
                    ))}
                </Box>
                {selectedOptionTitle !== undefined && (
                    <Box>
                        {options.map((option: RecipeOption, index: number) => (
                            <Box key={option.label.title}>
                                {index !== 0 && (
                                    <Divider className={classes.divider} />
                                )}
                                <Accordion
                                    option={option}
                                    expanded={
                                        selectedOptionTitle ===
                                        option.label.title
                                    }
                                    onChange={(
                                        _: ChangeEvent<{}>,
                                        expanded: boolean,
                                    ) =>
                                        onAccordionSelectionChange(
                                            expanded,
                                            option,
                                        )
                                    }
                                />
                            </Box>
                        ))}
                    </Box>
                )}
            </CardContent>
        </Card>
    );
};

export default RecipeCard;
