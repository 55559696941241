import queryString from 'query-string';
import { availableLanguages, en, enUS, esUS } from './locales';

export const getPageViewLang = (userLang: string) => {
    const langRegex = /^([a-z]{2})-/;
    const langMatch = userLang.match(langRegex);
    let lang = 'en';
    if (langMatch && langMatch[1]) {
        lang = langMatch[1];
    }
    return lang;
};

export const isUsEn = (userLang: string) => {
    return userLang === enUS ? true : false;
};

export const getUrlLang = (userLang: string) => {
    const qs = queryString.parse(window.location.search);
    const langParam = qs.lang?.toString() || '';

    if (langParam === '') {
        return userLang;
    }

    if (availableLanguages.toString().includes(langParam)) {
        return langParam === en || langParam === enUS ? enUS : esUS;
    } else {
        return enUS;
    }
};
