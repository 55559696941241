import * as React from 'react';
import { FC } from 'react';

import BaseEmoji from './BaseEmoji';
import { sentimentColors } from 'theme/colors';

const SoSoEmoji: FC = () => {
    const mouthPath = (
        <line
            x1="32.0117188"
            y1="58.1357422"
            x2="58.8527832"
            y2="48.5827637"
            id="Line"
            stroke="#434343"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
        ></line>
    );
    return <BaseEmoji color={sentimentColors.soso} mouth={mouthPath} />;
};

export default SoSoEmoji;
