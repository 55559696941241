import React, { FC, ReactNode, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, MenuItem, Select } from '@material-ui/core';
import { parseReplace } from 'utils/htmlParser';
import { pxToRem } from 'theme/util';
import { AVENIR_NEXT_LT_REGULAR } from 'theme/font';
import { CenterSelectionOption } from './AuthorableSplitSelection';
import { customClickEvent } from 'analytics';

const minHeight = 500;
const useStyles = makeStyles((theme) => ({
    root: {
        fontFamily: AVENIR_NEXT_LT_REGULAR,
        flexGrow: 1,
        marginBottom: 50,
        marginTop: 50,
    },
    middleSection: {
        padding: theme.spacing(3),
        color: '#FFFFFF',
        minHeight: minHeight,
        borderRadius: 10,
    },
    middleSectionTitle: {
        fontSize: pxToRem(128),
        lineHeight: 1,
    },
    middleSectionTitleFirstDigit: {
        fontWeight: 400,
    },
    middleSectionTitleSecondDigit: {
        fontWeight: 800,
    },
    middleSectionContent: {
        marginTop: 30,
        fontSize: pxToRem(24),
        lineHeight: '32px',
    },
    middleSectionFooter: {
        marginTop: 20,
        fontSize: pxToRem(24),
        lineHeight: '32px',
    },
    menuImage: {
        width: 48,
        height: 48,
        marginRight: 10,
    },
    selectInput: {
        height: 72,
        border: '1px solid #364F6B',
        borderRadius: 10,
        width: '100%',
        marginBottom: 25,
        padding: 10,
    },
    optionWrapper: {
        display: 'flex',
        alignItems: 'center',
        fontSize: pxToRem(16),
    },
    selected: {
        border: '1px solid #364F6B',
    },
    selectRoot: {
        fontWeight: 400,
        '&:focus': {
            backgroundColor: 'unset',
        },
    },
    rootMenuItem: {
        backgroundColor: `#ffffff !important`,
    },
}));

interface MobileViewProps {
    leftOptions?: CenterSelectionOption[];
    rightOptions?: CenterSelectionOption[];
}

const MobileView: FC<MobileViewProps> = ({
    leftOptions = [],
    rightOptions = [],
}) => {
    const classes = useStyles();
    const [centerContentOption, setCenterContentOption] =
        useState<CenterSelectionOption | null>(null);
    const [selectedMenuItem, setSelectedMenuItem] = useState<ReactNode>();
    const [selectedOption, setSelectedOption] =
        useState<CenterSelectionOption>();

    const combinedOptions: CenterSelectionOption[] = [
        ...leftOptions,
        ...rightOptions,
    ].sort(
        (a: CenterSelectionOption, b: CenterSelectionOption) =>
            Number(a.number) - Number(b.number),
    );

    const handleOptionClick = (option: CenterSelectionOption) => {
        setCenterContentOption(option);
        setSelectedMenuItem(
            <div className={classes.optionWrapper}>
                <img
                    className={classes.menuImage}
                    src={option.imageOn}
                    alt={option.title}
                />
                {option.title}
            </div>,
        );
        setSelectedOption(option);

        customClickEvent({
            customLinkName: `selection-widget: ${option.title}`,
        });
    };

    const renderOption = (option: CenterSelectionOption) => (
        <MenuItem
            key={`split-selection-option-${option.number}`}
            classes={{
                selected: selectedOption === option ? classes.selected : '',
                root: classes.rootMenuItem,
            }}
            value=""
            onClick={() => handleOptionClick(option)}
        >
            <img
                className={classes.menuImage}
                src={option.imageOff}
                alt={option.title}
            />
            {option.title}
        </MenuItem>
    );

    if (!centerContentOption && combinedOptions.length > 0) {
        handleOptionClick(combinedOptions[0]);
    }

    return (
        <div className={classes.root} data-testid="split-selection-mobile">
            <Select
                className={classes.selectInput}
                classes={{ root: classes.selectRoot }}
                value={''}
                displayEmpty
                renderValue={() => <>{selectedMenuItem}</>}
            >
                {combinedOptions.map(renderOption)}
            </Select>

            <Grid>
                {/* center */}
                {centerContentOption && (
                    <div
                        className={classes.middleSection}
                        style={{
                            backgroundColor: `${centerContentOption.centerBGColor}`,
                            boxShadow: `10px 10px 0px 0px ${centerContentOption.centerBGColor}40`,
                        }}
                    >
                        <div className={classes.middleSectionTitle}>
                            <span
                                className={classes.middleSectionTitleFirstDigit}
                            >
                                {centerContentOption.number[0]}
                            </span>
                            <span
                                className={
                                    classes.middleSectionTitleSecondDigit
                                }
                            >
                                {centerContentOption.number[1]}
                            </span>
                        </div>
                        <div className={classes.middleSectionContent}>
                            {parseReplace(centerContentOption.content)}
                            <div className={classes.middleSectionFooter}>
                                {parseReplace(centerContentOption.footer)}
                            </div>
                        </div>
                    </div>
                )}
            </Grid>
        </div>
    );
};

export default MobileView;
