import { makeStyles } from '@material-ui/core/styles';
import { customClickEvent } from 'analytics/customClickEvent';
import React from 'react';
import { useHistory } from 'react-router-dom';
import colors from 'theme/colors';
import { pxToRem } from 'theme/util';
import { RootState } from 'store';
import { useSelector } from 'react-redux';
import { isUrlExternal } from 'utils/url';
import { AVENIR_NEXT_LT_BOLD } from 'theme/font';

export interface CardProps {
    linkTo: string;
    linkFromPathName?: string;
    isMobile?: boolean;
    footerLabel?: string;
    footerSubLabel?: string;
    mobileImgPath?: string;
    desktopImgPath?: string;
    show?: string;
    title?: string;
    newWindow?: boolean;
}

const useStyles = makeStyles((theme) => ({
    root: {
        width: '400px',
        height: '330px',
        flexShrink: 0,
        display: 'inline-flex',
        marginLeft: '50px',
        marginTop: '20px',
        cursor: 'pointer',
        [theme.breakpoints.down('md')]: {
            width: '332px',
            height: '275px',
            margin: '20px auto',
            display: 'table',
        },
    },
    link: {
        textDecoration: 'none',
    },
    featureImage: {
        height: '207px',
        width: '100%',
    },
    title: {
        fontFamily: AVENIR_NEXT_LT_BOLD,
        color: '#C40075',
        fontSize: pxToRem(11),
        fontWeight: 700,
        marginBottom: -15,
    },
    footer: {
        height: '123px',
        background: colors.white,
        display: 'flow-root',
        position: 'relative',
        top: '-7px',
        padding: '10px 20px',
    },
    footerLabel: {
        color: '#333',
        fontSize: '18px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '25px',
        [theme.breakpoints.down('md')]: {
            fontSize: '14px',
        },
    },
    footerSubLabel: {
        color: '#666',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: 'normal',
        [theme.breakpoints.down('md')]: {
            fontSize: '12px',
        },
    },
}));

const Card: React.FC<CardProps> = ({
    title = '',
    linkTo,
    linkFromPathName = '',
    isMobile = false,
    footerLabel = '',
    footerSubLabel = '',
    mobileImgPath = '',
    desktopImgPath = '',
    newWindow = false,
}) => {
    const classes = useStyles();
    const history = useHistory();

    const { rkUrl } = useSelector((state: RootState) => ({
        rkUrl: state.company.theme?.recordKeeperUrl,
    }));

    const getURL = () => {
        if (linkTo === 'company-rk') {
            return rkUrl || '\\';
        }
        return linkTo;
    };

    const handleClick = () => {
        const updatedURL = getURL();

        if (isUrlExternal(updatedURL)) {
            if (newWindow) {
                window.open(updatedURL);
            } else {
                window.location.replace(updatedURL);
            }
        } else {
            const newPathname = window.location.pathname.replace(
                linkFromPathName || window.location.pathname,
                updatedURL,
            );
            customClickEvent({
                customLinkName: `Content Card: ${footerLabel}`,
            });

            history.push(newPathname);
            window.location.reload();
        }
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
        const updatedURL = getURL();
        if (e.key === 'Enter') {
            if (isUrlExternal(updatedURL)) {
                window.location.replace(updatedURL);
            } else {
                const newPathname = window.location.pathname.replace(
                    linkFromPathName || window.location.pathname,
                    updatedURL,
                );
                history.push(newPathname);
                window.location.reload();
            }
        }
    };

    return (
        <div className={classes.root} data-qa="navigator-card">
            <div
                onClick={handleClick}
                onKeyDown={handleKeyDown}
                tabIndex={0}
                role="button"
            >
                <img
                    alt={`${linkTo}-img`}
                    className={classes.featureImage}
                    src={isMobile ? mobileImgPath : desktopImgPath}
                />
                <footer className={classes.footer}>
                    {title && <div className={classes.title}>{title}</div>}

                    <p className={classes.footerLabel}>{footerLabel}</p>
                    <p className={classes.footerSubLabel}>{footerSubLabel}</p>
                </footer>
            </div>
        </div>
    );
};

export default Card;
