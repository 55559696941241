import React, { useState, FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';
import dayjs from 'dayjs';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';

/* actions */
import { setLastQuizModalViewDate, setLastQuizTakeDate } from 'actions/user';

/* components */
import { SimpleModal } from 'components';

/* utils */
import { AVENIR_NEXT_LT_BOLD } from 'theme/font';
import colors from 'theme/colors';
import { pxToRem } from 'theme/util';

/* svg */
import womanQuizSvg from 'assets/woman-quiz.svg';
import { customClickEvent } from 'analytics';

const useStyles = makeStyles(() => ({
    header: {
        color: colors.symphonyBlue,
        fontFamily: AVENIR_NEXT_LT_BOLD,
        fontSize: pxToRem(30),
        lineHeight: pxToRem(39),
        textAlign: 'center',
    },
    image: {
        height: '300px',
    },
}));

interface QuizModalProps {
    visible?: boolean;
    onClose?: () => void;
    analyticId?: string;
}

const QuizModal: FC<QuizModalProps> = ({
    analyticId = 'quizModalPopUp',
    visible = false,
    onClose = () => null,
}: QuizModalProps) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const classes = useStyles();
    const [quizModalVisible, setQuizModalVisible] = useState<boolean>(visible);

    useEffect(() => {
        setQuizModalVisible(visible);
    }, [visible]);

    useEffect(() => {
        if (!quizModalVisible) return;

        customClickEvent({
            customLinkName: `quizModal: ${analyticId}`,
        });
    }, [analyticId, quizModalVisible]);

    const handleModalClose = () => {
        setQuizModalVisible(false);
        dispatch(setLastQuizModalViewDate(dayjs().utc().format()));
        if (onClose) {
            onClose();
        }
    };

    const handleTakeQuiz = () => {
        dispatch(setLastQuizTakeDate(dayjs().utc().format()));
        history.push('/generic/quiz');
    };

    if (!quizModalVisible) {
        return null;
    }

    return (
        <SimpleModal
            dataQA="quiz-modal"
            closeButton
            onClose={handleModalClose}
            isModalOpen={quizModalVisible}
        >
            <Box mt={3} mb={1} data-testid="quiz-modal-header">
                <Typography variant="h3" className={classes.header}>
                    <FormattedMessage id="quizModal.header" />
                </Typography>
            </Box>
            <Box display="flex" flexDirection="column" alignItems="center">
                <Box mb={1}>
                    <img
                        className={classes.image}
                        src={womanQuizSvg}
                        alt="center"
                    />
                </Box>
                <Box mb={5}>
                    <Button
                        variant="outlined"
                        size="medium"
                        onClick={handleTakeQuiz}
                    >
                        <FormattedMessage id="quizModal.takeQuiz" />
                    </Button>
                </Box>
            </Box>
        </SimpleModal>
    );
};

export default QuizModal;
