export const targetView = (path: string) => {
    let viewName = path;
    viewName = viewName || 'home';
    if (viewName.startsWith('#') || viewName.startsWith('/')) {
        viewName = viewName.substr(1);
    }
    if (
        typeof window.adobe != 'undefined' &&
        window.adobe.target &&
        typeof window.adobe.target.triggerView === 'function'
    ) {
        window.adobe.target.triggerView(viewName);
    }
};
