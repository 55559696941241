/* constants */
import {
    CALCULATE_RETIREMENT_PROJECTION_UPDATE,
    CALCULATE_RETIREMENT_PROJECTION_UPDATE_ERROR,
    CALCULATE_RETIREMENT_PROJECTION_UPDATE_SUCCESS,
    CALCULATE_RETIREMENT_PROJECTIONS,
    CALCULATE_RETIREMENT_PROJECTIONS_ERROR,
    CALCULATE_RETIREMENT_PROJECTIONS_SUCCESS,
    CLEAR_RETIREMENT_PROJECTIONS,
    GET_RETIREMENT_CONTRIBUTION_CONSTANTS,
    GET_RETIREMENT_CONTRIBUTION_CONSTANTS_ERROR,
    GET_RETIREMENT_CONTRIBUTION_CONSTANTS_SUCCESS,
} from 'constants/calculator';

/* types */
import {
    RetirementCalculatorValues,
    RetirementProjectionValues,
} from 'containers/calculator/calculator.type';
export type CALCULATE_RETIREMENT_PROJECTION_UPDATE =
    typeof CALCULATE_RETIREMENT_PROJECTION_UPDATE;
export type CALCULATE_RETIREMENT_PROJECTION_UPDATE_ERROR =
    typeof CALCULATE_RETIREMENT_PROJECTION_UPDATE_ERROR;
export type CALCULATE_RETIREMENT_PROJECTION_UPDATE_SUCCESS =
    typeof CALCULATE_RETIREMENT_PROJECTION_UPDATE_SUCCESS;
export type CALCULATE_RETIREMENT_PROJECTIONS =
    typeof CALCULATE_RETIREMENT_PROJECTIONS;
export type CALCULATE_RETIREMENT_PROJECTIONS_ERROR =
    typeof CALCULATE_RETIREMENT_PROJECTIONS_ERROR;
export type CALCULATE_RETIREMENT_PROJECTIONS_SUCCESS =
    typeof CALCULATE_RETIREMENT_PROJECTIONS_SUCCESS;
export type CLEAR_RETIREMENT_PROJECTIONS = typeof CLEAR_RETIREMENT_PROJECTIONS;
export type GET_RETIREMENT_CONTRIBUTION_CONSTANTS =
    typeof GET_RETIREMENT_CONTRIBUTION_CONSTANTS;
export type GET_RETIREMENT_CONTRIBUTION_CONSTANTS_ERROR =
    typeof GET_RETIREMENT_CONTRIBUTION_CONSTANTS_ERROR;
export type GET_RETIREMENT_CONTRIBUTION_CONSTANTS_SUCCESS =
    typeof GET_RETIREMENT_CONTRIBUTION_CONSTANTS_SUCCESS;

/* actions */
export type CalculateRetirementProjectionUpdateAction = {
    type: CALCULATE_RETIREMENT_PROJECTION_UPDATE;
    payload: RetirementCalculatorValues;
    contributionPercentage: number;
    autoIncreasePercentage: number;
};

export type CalculateRetirementProjectionUpdateSuccessAction = {
    type: CALCULATE_RETIREMENT_PROJECTION_UPDATE_SUCCESS;
    payload: {
        contributionPercentage: number;
        projection: RetirementProjectionValues;
    };
};

export type CalculateRetirementProjectionUpdateErrorAction = {
    type: CALCULATE_RETIREMENT_PROJECTION_UPDATE_ERROR;
    error: Error;
};

export type CalculateRetirementProjectionsAction = {
    type: CALCULATE_RETIREMENT_PROJECTIONS;
    payload: RetirementCalculatorValues;
    companyId: string;
    autoIncreasePercentage: number;
    redirectToResults: boolean;
};

export type CalculateRetirementProjectionsSuccessAction = {
    type: CALCULATE_RETIREMENT_PROJECTIONS_SUCCESS;
    payload: {
        contributionPercentageIncrease: number;
        projection: RetirementProjectionValues;
        projectionWithContributionPercentageIncrease: RetirementProjectionValues;
        values: RetirementCalculatorValues;
    };
};

export type CalculateRetirementProjectionsErrorAction = {
    type: CALCULATE_RETIREMENT_PROJECTIONS_ERROR;
    error: Error;
};

export type ClearRetirementProjectionsAction = {
    type: CLEAR_RETIREMENT_PROJECTIONS;
};

export type GetRetirementContributionConstantsAction = {
    type: GET_RETIREMENT_CONTRIBUTION_CONSTANTS;
};

export type GetRetirementContributionConstantsErrorAction = {
    type: GET_RETIREMENT_CONTRIBUTION_CONSTANTS_ERROR;
    error: Error;
};

export type GetRetirementContributionConstantsSuccessAction = {
    type: GET_RETIREMENT_CONTRIBUTION_CONSTANTS_SUCCESS;
    payload: {
        maxEmployeeContributionsWhenMeetingMinAgeForAdditionalContributionLimits: number;
        maxEmployeeContributionsWhenNotMeetingMinAgeForAdditionalContributionLimits: number;
        minAgeForAdditionalContributionLimits: number;
    };
};

/* action creators */
export const calculateRetirementProjectionUpdate = (
    payload: RetirementCalculatorValues,
    contributionPercentage: number,
    autoIncreasePercentage = 0,
): CalculateRetirementProjectionUpdateAction => ({
    type: CALCULATE_RETIREMENT_PROJECTION_UPDATE,
    payload,
    contributionPercentage,
    autoIncreasePercentage,
});

export const calculateRetirementProjectionUpdateSuccess = (payload: {
    contributionPercentage: number;
    projection: RetirementProjectionValues;
}): CalculateRetirementProjectionUpdateSuccessAction => ({
    type: CALCULATE_RETIREMENT_PROJECTION_UPDATE_SUCCESS,
    payload,
});

export const calculateRetirementProjectionUpdateError = (
    error: Error,
): CalculateRetirementProjectionUpdateErrorAction => ({
    type: CALCULATE_RETIREMENT_PROJECTION_UPDATE_ERROR,
    error,
});

export const calculateRetirementProjections = (
    payload: RetirementCalculatorValues,
    companyId: string,
    autoIncreasePercentage = 0,
    redirectToResults = false,
): CalculateRetirementProjectionsAction => ({
    type: CALCULATE_RETIREMENT_PROJECTIONS,
    payload,
    companyId,
    autoIncreasePercentage,
    redirectToResults,
});

export const calculateRetirementProjectionsSuccess = (payload: {
    contributionPercentageIncrease: number;
    projection: RetirementProjectionValues;
    projectionWithContributionPercentageIncrease: RetirementProjectionValues;
    values: RetirementCalculatorValues;
}): CalculateRetirementProjectionsSuccessAction => ({
    type: CALCULATE_RETIREMENT_PROJECTIONS_SUCCESS,
    payload,
});

export const calculateRetirementProjectionsError = (
    error: Error,
): CalculateRetirementProjectionsErrorAction => ({
    type: CALCULATE_RETIREMENT_PROJECTIONS_ERROR,
    error,
});

export const clearRetirementProjections =
    (): ClearRetirementProjectionsAction => ({
        type: CLEAR_RETIREMENT_PROJECTIONS,
    });

export const getRetirementContributionConstants =
    (): GetRetirementContributionConstantsAction => ({
        type: GET_RETIREMENT_CONTRIBUTION_CONSTANTS,
    });

export const getRetirementContributionConstantsError = (
    error: Error,
): GetRetirementContributionConstantsErrorAction => ({
    type: GET_RETIREMENT_CONTRIBUTION_CONSTANTS_ERROR,
    error,
});

export const getRetirementContributionConstantsSuccess = (payload: {
    maxEmployeeContributionsWhenMeetingMinAgeForAdditionalContributionLimits: number;
    maxEmployeeContributionsWhenNotMeetingMinAgeForAdditionalContributionLimits: number;
    minAgeForAdditionalContributionLimits: number;
}): GetRetirementContributionConstantsSuccessAction => ({
    type: GET_RETIREMENT_CONTRIBUTION_CONSTANTS_SUCCESS,
    payload,
});

export type CalculateRetirmentActionTypes =
    | CalculateRetirementProjectionUpdateAction
    | CalculateRetirementProjectionUpdateErrorAction
    | CalculateRetirementProjectionUpdateSuccessAction
    | CalculateRetirementProjectionsAction
    | CalculateRetirementProjectionsErrorAction
    | CalculateRetirementProjectionsSuccessAction
    | ClearRetirementProjectionsAction
    | GetRetirementContributionConstantsAction
    | GetRetirementContributionConstantsErrorAction
    | GetRetirementContributionConstantsSuccessAction;
