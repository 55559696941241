import React from 'react';
import { RenderType } from '../interfaces';

/* components */
import { StartingLate } from 'components';

const renderStartingLate: RenderType = (): JSX.Element | null => {
    return <StartingLate />;
};

export default renderStartingLate;
