import { Button, makeStyles } from '@material-ui/core';
import React from 'react';
import colors from 'theme/colors';
import { AVENIR_NEXT_LT_DEMI } from 'theme/font';
import { pxToRem } from 'theme/util';
import clsx from 'clsx';

interface ActionCtaProps {
    onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    label: string;
    dataQa: string;
    varient?: 'text' | 'outlined' | 'contained';
    className?: string;
    buttonTheme?:
        | 'button'
        | 'quiz'
        | 'correct'
        | 'wrong'
        | 'opposite'
        | 'back'
        | 'next';
}

const useStyles = makeStyles((theme) => ({
    button: {
        fontFamily: AVENIR_NEXT_LT_DEMI,
        // margin: '0 30px 0 30px',
        // width: 120,
        borderColor: theme.colors.white,
        '&:hover': {
            borderColor: theme.colors.white,
        },
        // [theme.breakpoints.up('sm')]: {
        //     margin: '0 40px 0 40px',
        // },
    },
    quiz: {
        background: theme.colors.white,
        color: colors.darkTurquoise,
        width: 146,
        padding: `${pxToRem(10)} ${pxToRem(10)} ${pxToRem(8)}`,
        boxShadow: '0px 10px 10px rgba(0, 0, 0, 0.25)',
        '&:hover': {
            borderColor: theme.colors.white,
            background: 'transparent',
            color: theme.colors.white,
        },
        [theme.breakpoints.up('sm')]: {
            width: 170,
        },
    },
    correct: {
        backgroundColor: `${colors.darkTurquoise} !important`,
        boxShadow: '0px 10px 10px rgba(0, 0, 0, 0.25) !important',
        border: 'none !important',
    },
    wrong: {
        backgroundColor: `${colors.raspberry} !important`,
        boxShadow: '0px 10px 10px rgba(0, 0, 0, 0.25) !important',
        border: 'none  !important',
    },
    opposite: {
        backgroundColor: `${colors.white} !important`,
        color: `${colors.gray80} !important`,
        boxShadow: 'none !important',
        border: 'none !important',
    },
    back: {
        background: 'transparent',
        color: theme.colors.white,
        borderColor: theme.colors.white,
        width: 146,
        '&:hover': {
            borderColor: theme.colors.white,
            background: 'transparent',
            color: theme.colors.white,
        },

        [theme.breakpoints.up('sm')]: {
            width: 170,
        },
    },
    next: {
        background: theme.colors.white,
        color: colors.darkTurquoise,
        borderColor: theme.colors.white,
        width: 146,
        padding: `${pxToRem(10)} ${pxToRem(10)} ${pxToRem(8)}`,
        '&:hover': {
            borderColor: theme.colors.white,
            background: 'transparent',
            color: theme.colors.white,
        },

        [theme.breakpoints.up('sm')]: {
            width: 170,
        },
    },
}));

const ActionCta = ({
    onClick,
    label,
    dataQa,
    varient,
    className,
    buttonTheme,
}: ActionCtaProps) => {
    const classes = useStyles();
    const getClassName = () => {
        switch (buttonTheme) {
            case 'quiz': {
                return classes.quiz;
            }
            case 'correct': {
                return classes.correct;
            }
            case 'wrong': {
                return classes.wrong;
            }
            case 'opposite': {
                return classes.opposite;
            }
            case 'back': {
                return classes.back;
            }
            case 'next': {
                return classes.next;
            }
        }
    };

    return (
        <Button
            className={clsx(classes.button, getClassName(), className)}
            variant={varient}
            data-qa={dataQa}
            onClick={(e) => onClick(e)}
        >
            {label}
        </Button>
    );
};

export default ActionCta;
