import React, { useState, FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import dayjs from 'dayjs';

/* actions */
import {
    setLastSentimentModalViewDate,
    setLastSentimentAnswerDate,
} from 'actions/user';

/* components */
import { SimpleModal } from 'components';
import SentimentSquare from 'components/sentimentTab/SentimentSquare';
import {
    AnxiousEmoji,
    ConfidentEmoji,
    DiscourageEmoji,
    HopefulEmoji,
    SoSoEmoji,
} from 'components/sentimentTab/Emojis';

/* material */
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

/* utils  */
import { AVENIR_NEXT_LT_BOLD } from 'theme/font';
import colors from 'theme/colors';
import { pxToRem } from 'theme/util';
import { modalOpenEvent } from 'analytics';

const useStyles = makeStyles((theme) => ({
    header: {
        color: colors.symphonyBlue,
        fontFamily: AVENIR_NEXT_LT_BOLD,
        fontSize: pxToRem(30),
        lineHeight: pxToRem(39),
        margin: '20px auto 16px',
        textAlign: 'center',
    },
    headerContainer: {
        marginBottom: 40,
        [theme.breakpoints.up('sm')]: {
            marginBottom: 48,
        },
    },
    subHeader: {
        fontSize: pxToRem(23),
        lineHeight: pxToRem(26),
        textAlign: 'center',
        [theme.breakpoints.up('sm')]: {
            fontSize: pxToRem(25),
            lineHeight: pxToRem(28),
        },
    },
    container: {
        display: 'flex',
        flexFlow: 'row wrap',
        justifyContent: 'center',
        marginBottom: 30,

        [theme.breakpoints.up('sm')]: {
            justifyContent: 'space-between',
            marginBottom: 60,
        },

        '& > button': {
            marginBottom: 18,
            marginRight: 10,

            '&:nth-of-type(3), &:nth-of-type(5)': {
                marginRight: 0,
            },

            [theme.breakpoints.up('sm')]: {
                marginRight: 0,
            },
        },
    },
}));

interface SentimentModalProps {
    isVisible?: boolean;
    onClose?: () => void;
    analyticId?: string;
    segment: string;
    isHomePage?: boolean;
}

const SentimentModal: FC<SentimentModalProps> = ({
    analyticId = 'contentModalPopUp',
    isVisible = false,
    onClose,
    segment,
    isHomePage = false,
}) => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const [isSentimentModalVisible, setIsSentimentModalVisible] =
        useState<boolean>(isVisible);
    const [sentiment, setSentiment] = useState<string>('');

    useEffect(() => {
        setIsSentimentModalVisible(isVisible);
    }, [isVisible]);

    useEffect(() => {
        if (!isSentimentModalVisible) return;
        modalOpenEvent('contentModalPopUp');
    }, [analyticId, isSentimentModalVisible]);

    const handleModalClose = () => {
        setIsSentimentModalVisible(false);
        setSentiment('');
        dispatch(setLastSentimentModalViewDate(dayjs().utc().format()));
        onClose && onClose();
    };

    const handleSelection = (label: string) => {
        setSentiment(label);
        dispatch(setLastSentimentAnswerDate(dayjs().utc().format()));
    };

    const renderCopy = () => {
        if (isHomePage) {
            return (
                <>
                    <Typography variant="h3" className={classes.header}>
                        <FormattedMessage id="sentimentPoll.emailHeader" />
                    </Typography>
                </>
            );
        } else {
            return sentiment ? (
                <Typography variant="h3" className={classes.header}>
                    <FormattedMessage id="sentimentPoll.thanksFeedback" />
                </Typography>
            ) : (
                <>
                    <Typography
                        variant="h3"
                        className={classes.header}
                        data-testid="sentiment-modal-header"
                    >
                        <FormattedMessage id="sentimentPoll.beforeYouGo" />
                    </Typography>
                    <Typography variant="h4" className={classes.subHeader}>
                        <FormattedMessage id="sentimentPoll.panelHeader" />
                    </Typography>
                </>
            );
        }
    };

    if (!isSentimentModalVisible) {
        return null;
    }

    return (
        <SimpleModal
            dataQA="sentiment-modal"
            closeButton
            onClose={handleModalClose}
            isModalOpen={isSentimentModalVisible}
        >
            <div className={classes.headerContainer}>{renderCopy()}</div>
            <div className={classes.container}>
                <SentimentSquare
                    sentiment={sentiment}
                    onClick={handleSelection}
                    label="confident"
                    segment={segment}
                    analyticComponent={analyticId}
                >
                    <ConfidentEmoji />
                </SentimentSquare>
                <SentimentSquare
                    sentiment={sentiment}
                    onClick={handleSelection}
                    label="hopeful"
                    segment={segment}
                    analyticComponent={analyticId}
                >
                    <HopefulEmoji />
                </SentimentSquare>
                <SentimentSquare
                    sentiment={sentiment}
                    onClick={handleSelection}
                    label="soso"
                    segment={segment}
                    analyticComponent={analyticId}
                >
                    <SoSoEmoji />
                </SentimentSquare>
                <SentimentSquare
                    sentiment={sentiment}
                    onClick={handleSelection}
                    label="anxious"
                    segment={segment}
                    analyticComponent={analyticId}
                >
                    <AnxiousEmoji />
                </SentimentSquare>
                <SentimentSquare
                    sentiment={sentiment}
                    onClick={handleSelection}
                    label="discourage"
                    segment={segment}
                    analyticComponent={analyticId}
                >
                    <DiscourageEmoji />
                </SentimentSquare>
            </div>
        </SimpleModal>
    );
};

export default SentimentModal;
