/* constants */
import {
    CLEAR_READ_ARTICLES,
    GET_ARTICLE_SUCCESS,
    GET_MONEY_MINUTE_ARTICLES_SUCCESS,
    UPDATE_ARTICLE_LIKES_SUCCESS,
    UPDATE_READ_INFLUENCER_ARTICLES,
    CLEAR_READ_INFLUENCER_ARTICLES,
} from 'constants/article';
import { GET_VIRTUAL_EVENT_ARTICLES_SUCCESS } from 'constants/virtual-articles';

/* types */
import { ArticleActionTypes } from 'actions';
import { VirtualEventArticles } from 'actions/virtual-event-articles';
import { ArticleSummaries } from 'containers/article-landing/article.types';

/* store state */
export interface Article {
    readArticles: string[];
    likedArticles: string[];
    virtualEventArticles: VirtualEventArticles[];
    loadedVirtualEventArticles: boolean;
    influencerArticles: ArticleSummaries[];
    influencerArticlesLoaded: boolean;
    influencerArticlesRead: string[];
}

export const initialArticleState: Article = {
    readArticles: [],
    likedArticles: [],
    virtualEventArticles: [],
    loadedVirtualEventArticles: false,
    influencerArticles: [],
    influencerArticlesLoaded: false,
    influencerArticlesRead: [],
};

export const articleReducer = (
    state = initialArticleState,
    action: ArticleActionTypes,
): Article => {
    switch (action.type) {
        case GET_ARTICLE_SUCCESS:
            const isReadArticle = state.readArticles.includes(action.articleId);

            return {
                ...state,
                readArticles: [
                    ...state.readArticles,
                    ...(isReadArticle ? [] : [action.articleId]),
                ],
                likedArticles: [...state.likedArticles],
            };
        case UPDATE_ARTICLE_LIKES_SUCCESS:
            const isLikedArticle = state.likedArticles.includes(
                action.articleId,
            );
            let newLikeArticles = [];
            if (isLikedArticle) {
                newLikeArticles = state.likedArticles.filter(
                    (id) => id !== action.articleId,
                );
            } else {
                newLikeArticles = [
                    ...state.likedArticles,
                    ...[action.articleId],
                ];
            }

            return {
                ...state,
                likedArticles: newLikeArticles,
            };
        case CLEAR_READ_ARTICLES:
            return {
                ...state,
                readArticles: [action.articleId],
            };
        case GET_VIRTUAL_EVENT_ARTICLES_SUCCESS:
            return {
                ...state,
                loadedVirtualEventArticles: true,
                virtualEventArticles: action.payload,
            };
        case GET_MONEY_MINUTE_ARTICLES_SUCCESS:
            return {
                ...state,
                influencerArticlesLoaded: true,
                influencerArticles: action.articles,
            };
        case CLEAR_READ_INFLUENCER_ARTICLES:
            return {
                ...state,
                influencerArticlesRead: action.articleId
                    ? [action.articleId]
                    : [],
            };
        case UPDATE_READ_INFLUENCER_ARTICLES: {
            const influencerArticlesRead = state.influencerArticlesRead || [];
            const isReadInfluencerArticle = influencerArticlesRead.includes(
                action.articleId,
            );
            return {
                ...state,
                influencerArticlesRead: [
                    ...influencerArticlesRead,
                    ...(isReadInfluencerArticle ? [] : [action.articleId]),
                ],
            };
        }
        default:
            return state;
    }
};
