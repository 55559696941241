import React, { FC, ReactElement, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

/* material */
import { Button, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

/* components */
import ContentCard from 'components/content-card/ContentCard';

/* types */
import { ArticleSummaries } from 'containers/article-landing/article.types';
import { Theme } from 'reducers';

/* utils */
import { AVENIR_NEXT_LT_DEMI } from 'theme/font';
import colors from 'theme/colors';
import { ArticleCategories } from 'utils/aem';
import { customClickEvent } from 'analytics';

type ContentCardContainerProps = {
    articles: ArticleSummaries[] | undefined;
    company: Theme | null;
    header?: ReactElement;
    isLoading: boolean;
    viewMoreLink?: string;
    variant?: 'contained';
    displayAll?: boolean;
    category?: ArticleCategories;
    align?: 'left' | 'center' | 'right';
};

const ContentCardContainer: FC<ContentCardContainerProps> = ({
    articles,
    company,
    header,
    isLoading,
    viewMoreLink,
    variant,
    displayAll,
    category,
    align = 'left',
}) => {
    const intl = useIntl();
    const [displayAllState, setDisplayAllState] = useState<boolean>(
        displayAll || false,
    );

    useEffect(() => {
        setDisplayAllState(displayAll || false);
    }, [displayAll]);

    const useStyles = makeStyles((theme) => ({
        root: (
            props: Pick<
                ContentCardContainerProps,
                'variant' | 'displayAll' | 'align'
            >,
        ) => {
            const { variant, align } = props;
            return {
                display: 'flex',
                flexDirection: variant === 'contained' ? 'column' : 'row',
                overflowY: 'hidden',
                overflowX: 'scroll',
                width: '100%',

                [theme.breakpoints.up('sm')]: {
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                    justifyContent: align,
                    overflowX: 'hidden',
                },
            };
        },
        breaker: {
            display: 'block',
            width: '100%',
        },
        articleSet: (
            props: Pick<ContentCardContainerProps, 'header' | 'displayAll'>,
        ) => {
            const { header } = props;
            return {
                marginTop: header ? '40px' : 0,
                padding: '0 20px',
                width: 'auto',

                [theme.breakpoints.up('sm')]: {
                    margin: header ? '80px 0 0' : '0',
                },
            };
        },
        articleSetHeader: {
            color: colors.darkSapphire,
            fontFamily: AVENIR_NEXT_LT_DEMI,
            marginBottom: '16px',

            [theme.breakpoints.up('sm')]: {
                textAlign: 'center',
                marginBottom: '32px',
            },
        },
        articleSetCta: {
            display: 'flex',
            justifyContent: 'center',
            marginTop: '48px',

            [theme.breakpoints.up('sm')]: {
                marginTop: '40px',
            },
        },
    }));

    const classes = useStyles({ header, variant, displayAll, align });

    if (!isLoading && (!articles || articles.length === 0)) return null;

    return (
        <div className={classes.articleSet} data-qa="content-card-container">
            {header && (
                <Typography
                    data-qa="content-card-container-header"
                    variant="h4"
                    className={classes.articleSetHeader}
                >
                    {header}
                </Typography>
            )}
            <div className={classes.root}>
                {articles &&
                    articles.map((article, index) => {
                        return index > 5 && !displayAllState ? null : (
                            <React.Fragment key={`article-${index}`}>
                                {index > 0 && index % 3 === 0 && (
                                    <span className={classes.breaker}></span>
                                )}
                                <ContentCard
                                    article={article}
                                    company={company}
                                    key={index}
                                    variant={variant}
                                    fullWidth
                                    category={category}
                                />
                            </React.Fragment>
                        );
                    })}
            </div>
            {viewMoreLink && (
                <div
                    data-qa="content-card-container-more-button"
                    className={classes.articleSetCta}
                >
                    <Button
                        onClick={() => {
                            customClickEvent({
                                customLinkName: `alert modal: ${intl.formatMessage(
                                    {
                                        id: 'viewMore',
                                    },
                                )}`,
                            });
                        }}
                        variant="outlined"
                    >
                        <FormattedMessage id={`viewMore`} />
                    </Button>
                </div>
            )}
        </div>
    );
};

export default ContentCardContainer;
