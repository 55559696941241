import * as React from 'react';
import { domToReact } from 'html-react-parser';

/* interfaces */
import { RenderType, RenderElementProps } from '../interfaces';

/* components */
import { ArticleList } from 'containers/article-landing';

/* utils */

const renderArticleOl: RenderType = (
    props: RenderElementProps,
): JSX.Element => {
    const { domNode, options } = props;
    const { children } = domNode;
    return (
        <ArticleList>{children && domToReact(children, options)}</ArticleList>
    );
};

export default renderArticleOl;
