/* interfaces */
import { Theme } from 'reducers';
import { resourceType } from 'containers/MakeAChange/types';

/* constants */
import {
    GET_COMPANY,
    GET_COMPANY_ERROR,
    GET_COMPANY_SUCCESS,
    GET_COMPANY_RESOURCES_SUCCESS,
} from 'constants/company';

export type GET_COMPANY = typeof GET_COMPANY;
export type GET_COMPANY_ERROR = typeof GET_COMPANY_ERROR;
export type GET_COMPANY_SUCCESS = typeof GET_COMPANY_SUCCESS;
export type GET_COMPANY_RESOURCES_SUCCESS =
    typeof GET_COMPANY_RESOURCES_SUCCESS;
/* actions */
export interface GetCompanyAction {
    type: GET_COMPANY;
    companyId: string;
    language: string;
}

export interface GetCompanySuccessAction {
    type: GET_COMPANY_SUCCESS;
    config: Theme;
}

export interface GetCompanyError {
    type: GET_COMPANY_ERROR;
    error: Error;
}
export interface GetCompanyResourcesSuccessAction {
    type: GET_COMPANY_RESOURCES_SUCCESS;
    resources: resourceType[];
}

/* action creator */
export const getCompany = (
    companyId: string,
    language: string,
): GetCompanyAction => ({
    type: GET_COMPANY,
    companyId,
    language,
});

export const getCompanySuccess = (payload: Theme): GetCompanySuccessAction => ({
    type: GET_COMPANY_SUCCESS,
    config: payload,
});

export const getCompanyError = (payload: Error): GetCompanyError => ({
    type: GET_COMPANY_ERROR,
    error: payload,
});

export const getCompanyResourcesSuccess = (
    payload: resourceType[],
): GetCompanyResourcesSuccessAction => ({
    type: GET_COMPANY_RESOURCES_SUCCESS,
    resources: payload,
});

export type CompanyActionTypes =
    | GetCompanyAction
    | GetCompanySuccessAction
    | GetCompanyError
    | GetCompanyResourcesSuccessAction;
