import React, { FC, useState } from 'react';
import Cart from './components/Cart';
import Card from './components/Card';
import { makeStyles } from '@material-ui/core';
// import { parseReplace } from 'utils/htmlParser';

const useStyles = makeStyles((theme) => ({
    container: {
        // maxWidth: '1170px',
        // margin: '0 auto',
        // padding: '10px'
    },
    shoppingTitle: {
        color: theme.colors.black,
        fontSize: '22px',
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: 0 /* 127.273% */,
    },
    shoppingContent: {
        color: theme.colors.black,
        fontSize: '18px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '28px' /* 127.273% */,
    },
    cartTitle: {
        color: theme.colors.black,
        textAlign: 'center',
        fontSize: '25px',
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: '28px',
        width: 'max-content',
        height: '20px',
        flexShrink: 0,
        margin: '10px auto',
        marginTop: '35px',
        [theme.breakpoints.down('sm')]: {
            lineHeight: '0px',
            width: 'max-content',
            fontSize: '22px',
            marginTop: '50px',
        },
    },
    cartSubTitle: {
        color: theme.colors.raspberry,
        textAlign: 'center',
        fontSize: '18px',
        fontStyle: 'normal',
        fontWeight: 700,
        lineHeight: '28px',
        width: 'max-content',
        height: '20px',
        flexShrink: 0,
        margin: '0 auto 20px',
        [theme.breakpoints.down('sm')]: {
            lineHeight: '0px',
            fontSize: '16px',
        },
    },
    cartCard: {
        display: 'flex',
        justifyContent: 'space-evenly',
        flexWrap: 'wrap',
        marginTop: '50px',
        [theme.breakpoints.down('sm')]: {
            marginTop: '0px',
        },
        [theme.breakpoints.between(760, 1000)]: {
            marginLeft: 105,
        },
    },
    footerSource: {
        color: theme.colors.black,
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '22px',
        '&& a': {
            color: theme.colors.black,
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '12px',
        },
    },
    footerDesc: {
        color: theme.colors.black,
        fontSize: '18px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '28px',
    },
    leftArrow: {
        border: `solid ${theme.colors.black}`,
        borderWidth: '0 3px 3px 0',
        [theme.breakpoints.down('xs')]: {
            left: 220,
            top: 220,
        },
        [theme.breakpoints.between(760, 1000)]: {
            left: 215,
            top: 215,
        },
    },
    rightArrow: {
        border: `solid ${theme.colors.black}`,
        borderWidth: '0 3px 3px 0',
        float: 'initial',
        [theme.breakpoints.down('xs')]: {
            right: -520,
            top: -130,
        },
        [theme.breakpoints.between(760, 1000)]: {
            right: -520,
        },
    },
    carouselWrapper: {
        margin: 'auto',
    },
}));

export type CartPieceList = {
    desc: string;
};

export type CartPiece = {
    card: string;
    header: string;
    list: CartPieceList[];
};

export interface WidgetShoppingCart {
    // mainTitle: string;
    // mainDescription: string;
    title: string;
    description: string;
    cartPieces: CartPiece[];
    // source: string;
    // disclaimer: string;
}

const WidgetShoppingCart: FC<WidgetShoppingCart> = ({
    // mainTitle = 'Price shopping',
    // mainDescription = 'Not Set',
    title = 'What’s in the inflation cart?',
    description = 'Not Set',
    cartPieces,
    // source = 'Not Set',
    // disclaimer = 'Not Set',
}) => {
    const [activeCard, setActiveCard] = useState<string | null>('Apparel');
    const classes = useStyles();

    return (
        <div
            className={classes.container}
            //tabIndex={0}
        >
            {/* <div>
                <h3 className={classes.shoppingTitle}>{mainTitle}</h3>
                <p className={classes.shoppingContent}>{mainDescription}</p>
            </div> */}
            <div>
                <h3 className={classes.cartTitle}>{title}</h3>
                <p className={classes.cartSubTitle}>{description}</p>
            </div>
            <div className={classes.cartCard}>
                <Cart onSelect={(card) => setActiveCard(card)} />
                <Card card={activeCard} cartPieces={cartPieces} />
            </div>
            {/* <div>
                <p className={classes.footerSource}>{parseReplace(source)}</p>
                <p className={classes.footerDesc}>{disclaimer}</p>
            </div> */}
        </div>
    );
};

export default WidgetShoppingCart;
