import React, { FC, useState } from 'react';
import Masonry from 'react-masonry-css';

/* material */
import { makeStyles, useTheme } from '@material-ui/core/styles';

/* Data */
import { FLASHCARDS } from './FlashCardData';

/* components */
import FlashCard from './FlashCard';

/* utils */
import { aemBaseURL } from 'constants/aem';
import { pxToSpacing } from 'theme/util';
import { parseReplace } from '../../utils/htmlParser';

const useStyles = makeStyles((theme) => ({
    root: {
        margin: theme.spacing(3, 0, 2, 0),
    },
    paper: {
        marginBottom: pxToSpacing(40),
    },
    masonryGrid: {
        display: 'flex',
        marginLeft: pxToSpacing(-40),
        width: 'inherit',
    },
    masonryColumn: {
        backgroundClip: 'padding-box',
        [theme.breakpoints.up('sm')]: {
            marginLeft: '40px',
            '&:first-child': {
                marginLeft: '0',
            },
        },
    },
}));

const FlashCardsContainer: FC = () => {
    const classes = useStyles();
    const theme = useTheme();
    const [expandedCards, setExpandedCards] = useState<number[]>([]);
    const breakpointCols = {
        default: 4,
        [theme.breakpoints.values.xl]: 2,
        [theme.breakpoints.values.lg]: 2,
        [theme.breakpoints.values.md]: 2,
        [theme.breakpoints.values.sm]: 1,
        [theme.breakpoints.values.xs]: 1,
    };

    const onCardExpanded = (index: number) => {
        setExpandedCards((prev) => [...prev, index]);
    };

    const onCardCollapsed = (index: number) => {
        setExpandedCards((prev) => prev.filter((x) => x !== index));
    };

    const lastCardExpanded = expandedCards.length
        ? expandedCards[expandedCards.length - 1]
        : null;

    const parsedFlashCards = FLASHCARDS.map((card, index) => {
        const description = parseReplace(card.description);
        return (
            <FlashCard
                forceStop={
                    lastCardExpanded === null
                        ? false
                        : lastCardExpanded !== index
                }
                onExpand={() => {
                    onCardExpanded(index);
                }}
                onCollapse={() => {
                    onCardCollapsed(index);
                }}
                key={`card-${index}`}
                title={card.title}
                animatedImage={`${aemBaseURL}${card.animatedImage}`}
                staticImage={`${aemBaseURL}${card.staticImage}`}
                description={description}
            />
        );
    });

    return (
        <Masonry
            breakpointCols={breakpointCols}
            className={classes.masonryGrid}
            columnClassName={classes.masonryColumn}
        >
            {parsedFlashCards}
        </Masonry>
    );
};

export default FlashCardsContainer;
