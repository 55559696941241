import createBreakpoints from '@material-ui/core/styles/createBreakpoints';
import colors from './colors';

import { AVENIR_NEXT_LT_BOLD, AVENIR_NEXT_LT_DEMI } from './font';
import { pxToRem } from './util';
import breakpointsSettings from './breakpoints';

const breakpoints = createBreakpoints({
    values: breakpointsSettings,
});

const button = {
    root: {
        fontSize: pxToRem(12),
        lineHeight: pxToRem(16),
        textTransform: 'inherit',
        borderRadius: 999,
    },
    contained: {
        backgroundColor: colors.prussianBlue,
        color: colors.white,
        fontFamily: AVENIR_NEXT_LT_BOLD,
        letterSpacing: pxToRem(1.5),
        lineHeight: pxToRem(16),
        outlineOffset: '2px',
        padding: `${pxToRem(10)} ${pxToRem(20)} ${pxToRem(8)}`,
        '&:hover': {
            backgroundColor: colors.prussianBlue70,
            transform: 'scale(0.98)',
            '@media (hover: none)': {
                backgroundColor: colors.prussianBlue70,
            },
        },
        textTransform: 'uppercase',
        '&.Mui-disabled': {
            backgroundColor: colors.prussianBlue,
            opacity: 0.5,
            color: colors.white,
        },

        [breakpoints.up('sm')]: {
            padding: `${pxToRem(12)} ${pxToRem(24)} ${pxToRem(11)}`,
        },
    },
    containedSecondary: {
        backgroundColor: colors.violetRed,
        color: colors.white,
        fontFamily: AVENIR_NEXT_LT_BOLD,
        letterSpacing: pxToRem(1.5),
        '&:hover': {
            backgroundColor: colors.violetRed70,
        },
        textTransform: 'uppercase',
        '&.Mui-disabled': {
            opacity: 0.5,
            backgroundColor: colors.violetRed,
        },
    },
    containedSizeSmall: {
        fontSize: pxToRem(10),
        outlineOffset: '2px',
        padding: `${pxToRem(10)} ${pxToRem(15)} ${pxToRem(8)}`,

        [breakpoints.up('sm')]: {
            fontSize: pxToRem(12),
            padding: `${pxToRem(10)} ${pxToRem(20)} ${pxToRem(8)}`,
        },
    },
    containedSizeLarge: {
        fontSize: pxToRem(12),
        padding: `${pxToRem(12)} ${pxToRem(24)} ${pxToRem(11)}`,

        [breakpoints.up('sm')]: {
            fontSize: pxToRem(16),
            padding: `${pxToRem(12)} ${pxToRem(24)} ${pxToRem(11)}`,
        },
    },
    text: {
        backgroundColor: 'transparent',
        color: colors.darkTurquoise,
        fontFamily: AVENIR_NEXT_LT_DEMI,
        fontSize: pxToRem(16),
        padding: 0,
        '&:hover': {
            backgroundColor: 'transparent',
            color: colors.pine,
        },
        [breakpoints.up('sm')]: {
            fontSize: pxToRem(18),
        },
    },
    textSizeSmall: {
        fontFamily: AVENIR_NEXT_LT_BOLD,
        fontSize: pxToRem(12),
        padding: 0,
    },
    outlined: {
        backgroundColor: colors.white,
        border: `solid 1.5px ${colors.paoloGreen}`,
        color: colors.paoloGreen,
        fontFamily: AVENIR_NEXT_LT_BOLD,
        fontSize: pxToRem(12),
        letterSpacing: pxToRem(1.5),
        padding: `${pxToRem(10)} ${pxToRem(20)} ${pxToRem(8)}`,
        textTransform: 'uppercase',

        [breakpoints.up('sm')]: {
            padding: `${pxToRem(12)} ${pxToRem(24)} ${pxToRem(11)}`,
        },

        '&:hover': {
            backgroundColor: colors.white,
            borderColor: colors.pine,
            color: colors.pine,
            transform: 'scale(0.98)',
        },

        '&.Mui-disabled': {
            borderColor: colors.paoloGreen,
            color: colors.paoloGreen,
            backgroundColor: colors.white,
            opacity: 0.5,
        },
    },
    outlinedSecondary: {
        backgroundColor: colors.white,
        border: `solid 1.5px ${colors.violetRed}`,
        color: colors.violetRed,
        '&:hover': {
            backgroundColor: colors.white,
            borderColor: colors.violetRed,
            color: colors.violetRed,
        },

        '&.Mui-disabled': {
            opacity: 0.5,
            backgroundColor: colors.white,
            color: colors.violetRed,
            borderColor: colors.violetRed,
        },
    },
    outlinedSizeSmall: {
        fontSize: pxToRem(10),
        outlineOffset: '2px',
        padding: `${pxToRem(10)} ${pxToRem(15)} ${pxToRem(8)}`,

        [breakpoints.up('sm')]: {
            fontSize: pxToRem(12),
            padding: `${pxToRem(10)} ${pxToRem(20)} ${pxToRem(8)}`,
        },
    },
    outlinedSizeLarge: {
        fontSize: pxToRem(12),
        padding: `${pxToRem(12)} ${pxToRem(24)} ${pxToRem(11)}`,

        [breakpoints.up('sm')]: {
            fontSize: pxToRem(16),
            padding: `${pxToRem(12)} ${pxToRem(24)} ${pxToRem(11)}`,
        },
    },
};

export default button;
