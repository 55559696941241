import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { History } from 'history';

/* reducers */
import {
    articleReducer,
    calculatorReducer,
    companyReducer,
    errorReducer,
    loaderAsyncReducer,
    personaQuizReducer,
    userReducer,
    dollarOnTheRoadReducer,
    analyticsReducer,
} from './index';

const createRootReducer = (history: History) =>
    combineReducers({
        router: connectRouter(history),
        article: articleReducer,
        company: companyReducer,
        error: errorReducer,
        loader: loaderAsyncReducer,
        personaQuiz: personaQuizReducer,
        calculator: calculatorReducer,
        user: userReducer,
        dollarOnTheRoad: dollarOnTheRoadReducer,
        analytics: analyticsReducer,
    });

export default createRootReducer;
