import { makeStyles } from '@material-ui/core';
import { CartPiece } from 'components/WidgetInflationCart/WidgetInflationCart';
import React from 'react';

interface TransportationCardProps {
    card: CartPiece;
}

const useStyles = makeStyles((theme) => ({
    transportationCard: {
        backgroundColor: '#A53470',
        width: '298px',
        height: '363px',
        flexShrink: 0,
        borderRadius: '10px',
        padding: '20px',
        [theme.breakpoints.down('sm')]: {
            height: '300px',
        },
    },
    svg: {
        width: '66px',
        height: '49.686px',
        flexShrink: 0,
    },
    h3: {
        color: theme.colors.white,
        textAlign: 'center',
        fontSize: '18px',
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: 'normal',
        width: '200px',
        height: '40px',
        flexShrink: 0,
        margin: '0px auto 18px',
    },
    li: {
        color: theme.colors.white,
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 400,
        // lineHeight: 'normal',
        lineHeight: '25px' /* 178.571% */,
        flexShrink: 0,
    },
    svgIcon: {
        textAlign: 'center',
    },
}));

const Transportation = ({ card }: TransportationCardProps) => {
    const classes = useStyles();
    return (
        <div className={classes.transportationCard}>
            <div className={classes.svgIcon}>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="66"
                    height="50"
                    viewBox="0 0 66 50"
                    fill="none"
                    className={classes.svg}
                    data-card={card.card}
                >
                    <path
                        d="M26.4811 29.8066L26.8154 28.4934H7.89874V9.35436H41.8899V15.9734C43.7262 15.7511 45.0923 15.7319 45.473 15.7319C45.916 15.7319 47.6916 15.7575 49.987 16.0998V13.1966C49.987 12.312 49.272 11.597 48.3874 11.597H45.988V8.39781C45.988 6.63187 39.3897 0 24.9935 0C10.5972 0 3.99896 6.63027 3.99896 8.39781V11.597H1.59958C0.718213 11.597 0 12.312 0 13.1966V20.3947C0 21.2777 0.718213 21.9943 1.59958 21.9943H3.99896V41.9891C3.99896 43.755 5.43218 45.1882 7.19812 45.1882H9.39755V47.9875C9.39755 48.8705 10.1158 49.5871 10.9971 49.5871H16.3957C17.2803 49.5871 17.9953 48.8705 17.9953 47.9875V45.1882H22.7125C22.6117 44.8027 22.5445 44.406 22.5445 43.9917V34.527C22.5445 32.1756 24.2433 30.2145 26.4811 29.8066ZM20.3947 3.89818H29.3923C30.2241 3.89818 30.8927 4.56841 30.8927 5.39859C30.8927 6.22718 30.2241 6.899 29.3923 6.899H20.3947C19.5661 6.899 18.8943 6.22718 18.8943 5.39859C18.8959 4.57001 19.5677 3.89818 20.3947 3.89818ZM12.6975 40.4886C10.8228 40.4886 9.29838 38.9674 9.29838 37.0895C9.29838 35.2116 10.8228 33.6904 12.6975 33.6904C14.5754 33.6904 16.0966 35.2116 16.0966 37.0895C16.0966 38.9674 14.5738 40.4886 12.6975 40.4886Z"
                        fill="white"
                    />

                    <path
                        d="M63.6021 32.1276H62.5799L60.3901 23.5106L60.1502 23.2275C55.8761 18.1904 45.8979 18.1296 45.4724 18.1296C45.0501 18.1296 35.0687 18.1888 30.7946 23.2275L30.5547 23.5106L28.3649 32.1276H27.3427C26.0183 32.1276 24.9434 33.2025 24.9434 34.527V43.9917C24.9434 45.3162 26.0183 46.3911 27.3427 46.3911H28.5424V48.2322C28.5424 49.0352 29.195 49.6862 29.998 49.6862H34.9056C35.7086 49.6862 36.358 49.0352 36.358 48.2322V46.3911H54.5852V48.2322C54.5852 49.0352 55.2379 49.6862 56.0409 49.6862H60.9484C61.7514 49.6862 62.4008 49.0352 62.4008 48.2322V46.3911H63.6005C64.9249 46.3911 65.9999 45.3162 65.9999 43.9917V34.527C66.0015 33.2025 64.9265 32.1276 63.6021 32.1276ZM31.4105 42.1266C29.8269 42.1266 28.5424 40.8421 28.5424 39.2601C28.5424 37.6765 29.8269 36.3937 31.4105 36.3937C32.9909 36.3937 34.2753 37.6765 34.2753 39.2601C34.2753 40.8421 32.9909 42.1266 31.4105 42.1266ZM52.0051 41.3252C52.0051 41.7667 51.6452 42.125 51.2053 42.125H39.7395C39.2996 42.125 38.9397 41.7667 38.9397 41.3252V37.1919C38.9397 36.7504 39.2996 36.3921 39.7395 36.3921H51.2053C51.6452 36.3921 52.0051 36.7504 52.0051 37.1919V41.3252ZM31.6664 32.1276L33.4659 25.0398C36.3628 22.0166 43.0698 21.3352 45.4724 21.3304C47.875 21.3352 54.582 22.0166 57.4789 25.0398L59.2784 32.1276H31.6664ZM59.5375 42.1266C57.954 42.1266 56.6695 40.8421 56.6695 39.2601C56.6695 37.6765 57.954 36.3937 59.5375 36.3937C61.1179 36.3937 62.4024 37.6765 62.4024 39.2601C62.4024 40.8421 61.1179 42.1266 59.5375 42.1266Z"
                        fill="white"
                    />
                </svg>
            </div>
            <hr />
            <h3 className={classes.h3}>{card.header}</h3>
            <ul>
                {card.list.map((item) => (
                    <li
                        className={classes.li}
                        key={`${card.card}-${Math.random()}`}
                    >
                        {item.desc}
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default Transportation;
