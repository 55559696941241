import React from 'react';
import Apparel from './Apparel';
import Education from './Education';
import Food from './Food';
import Housing from './Housing';
import Medical from './Medical';
import Other from './Other';
import Recreation from './Recreation';
import Transportation from './Transportation';
import { makeStyles } from '@material-ui/core';
import { CartPiece } from 'components/WidgetInflationCart/WidgetInflationCart';
// import Carousel from "components/carousel/Carousel";

interface Props {
    card: string | null;
    cartPieces: CartPiece[];
}

const useStyles = makeStyles((theme) => ({
    mainCard: {
        width: '400px',
        [theme.breakpoints.down('xs')]: {
            width: '-webkit-fill-available',
        },
    },
    leftArrow: {
        border: `solid ${theme.colors.black}`,
        borderWidth: '0 3px 3px 0',
        [theme.breakpoints.down('xs')]: {
            left: -20,
            top: 169,
        },
        [theme.breakpoints.between(760, 1000)]: {
            left: -35,
            top: 170,
        },
    },
    rightArrow: {
        border: `solid ${theme.colors.black}`,
        borderWidth: '0 3px 3px 0',
        float: 'initial',
        [theme.breakpoints.down('xs')]: {
            right: -297,
            top: -160,
        },
        [theme.breakpoints.between(760, 1000)]: {
            right: -310,
            top: -160,
        },
    },
    dotClassName: {
        [theme.breakpoints.between(760, 1000)]: {
            justifyContent: 'flex-start',
            paddingLeft: 100,
        },
    },
}));

const Card = ({ card, cartPieces }: Props) => {
    const classes = useStyles();

    return (
        <div className={classes.mainCard} id="inflation-info-card">
            {card === 'Apparel' && (
                <Apparel
                    card={
                        cartPieces.filter(
                            (piece) => piece.card === 'Apparel',
                        )[0]
                    }
                />
            )}
            {card === 'Education' && (
                <Education
                    card={
                        cartPieces.filter(
                            (piece) => piece.card === 'Education',
                        )[0]
                    }
                />
            )}
            {card === 'Food' && (
                <Food
                    card={
                        cartPieces.filter((piece) => piece.card === 'Food')[0]
                    }
                />
            )}
            {card === 'Housing' && (
                <Housing
                    card={
                        cartPieces.filter(
                            (piece) => piece.card === 'Housing',
                        )[0]
                    }
                />
            )}
            {card === 'Medical' && (
                <Medical
                    card={
                        cartPieces.filter(
                            (piece) => piece.card === 'Medical',
                        )[0]
                    }
                />
            )}
            {card === 'Other' && (
                <Other
                    card={
                        cartPieces.filter((piece) => piece.card === 'Other')[0]
                    }
                />
            )}
            {card === 'Recreation' && (
                <Recreation
                    card={
                        cartPieces.filter(
                            (piece) => piece.card === 'Recreation',
                        )[0]
                    }
                />
            )}
            {card === 'Transportation' && (
                <Transportation
                    card={
                        cartPieces.filter(
                            (piece) => piece.card === 'Transportation',
                        )[0]
                    }
                />
            )}
        </div>
    );
};

export default Card;
