import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { Box, Modal, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    root: {
        left: '50%',
        position: 'absolute',
        top: '50%',
        transform: 'translate(-50%, -50%)',
        outline: 'none',
    },
});

const LoaderAsync = () => {
    const classes = useStyles();
    const isLoading = useSelector((state: RootState) => state.loader.isVisible);

    return isLoading ? (
        <Modal open>
            <Box className={classes.root}>
                <CircularProgress thickness={2} size={75} />
            </Box>
        </Modal>
    ) : null;
};

export default LoaderAsync;
