import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { SpotifyPlaylist } from '../../../components';
import { AVENIR_NEXT_LT_REGULAR } from 'theme/font';
import { pxToRem } from 'theme/util';
import { FormattedMessage, useIntl } from 'react-intl';
import colors from 'theme/colors';

const useStyles = makeStyles((theme) => ({
    root: {
        minHeight: 'calc(100vh - 120px)',
        padding: 20,
        color: colors.white,
        fontFamily: AVENIR_NEXT_LT_REGULAR,
        [theme.breakpoints.up('md')]: {
            minHeight: 'calc(100vh - 100px)',
            padding: 'unset',
        },
    },
    header: {
        fontWeight: 600,
        fontSize: pxToRem(22),
        lineHeing: '36px',
        textAlign: 'center',
        [theme.breakpoints.up('md')]: {
            paddingTop: 25,
            fontSize: pxToRem(30),
            textAlign: 'unset',
        },
    },
    listenTitle: {
        color: 'white',
        padding: '25px 0px 25px 0px',
        textAlign: 'center',
        [theme.breakpoints.up('md')]: {
            textAlign: 'unset',
            maxWidth: 1024,
            fontSize: pxToRem(16),
        },
    },
}));

interface ListenSectionProps {
    isMobile?: boolean;
    isLatinGrammy?: boolean;
}

const ListenSection: React.FC<ListenSectionProps> = ({
    isMobile = false,
    isLatinGrammy = false,
}) => {
    const classes = useStyles();
    const intl = useIntl();

    return (
        <div id="Listen" className={classes.root}>
            <div className={classes.header}>
                <FormattedMessage
                    id={
                        isLatinGrammy
                            ? 'enLatinGrammyLanding.listen.title'
                            : 'enGrammyLanding.listen.title'
                    }
                    defaultMessage={
                        isLatinGrammy
                            ? 'enLatinGrammyLanding.listen.title'
                            : 'enGrammyLanding.listen.title'
                    }
                />
            </div>
            <div className={classes.listenTitle}>
                <FormattedMessage
                    id={
                        isLatinGrammy
                            ? 'enLatinGrammyLanding.listen.desc'
                            : 'enGrammyLanding.listen.desc'
                    }
                    defaultMessage={
                        isLatinGrammy
                            ? 'enLatinGrammyLanding.listen.desc'
                            : 'enGrammyLanding.listen.desc'
                    }
                />
            </div>
            <SpotifyPlaylist
                id={intl.formatMessage({
                    id: isLatinGrammy
                        ? 'enLatinGrammyLanding.listen.spotifyId'
                        : 'enGrammyLanding.listen.spotifyId',
                    defaultMessage: isLatinGrammy
                        ? 'enLatinGrammyLanding.listen.spotifyId'
                        : 'enGrammyLanding.listen.spotifyId',
                })}
                width={isMobile ? '100%' : '80%'}
                height={isMobile ? '500px' : '600px'}
            />
        </div>
    );
};

export default ListenSection;
