import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-scroll';
import { pxToRem } from '../../../theme/util';
import { AVENIR_NEXT_LT_REGULAR } from '../../../theme/font';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import CloseIcon from '@material-ui/icons//Close';
import { customClickEvent } from 'analytics';

type Props = {
    companyId: string;
    labels: { color: string; label: string }[];
};

const useStyles = makeStyles((theme) => ({
    dropdownMenu: {
        // marginTop: 80,
        position: 'fixed',
        zIndex: 10,
        fontFamily: AVENIR_NEXT_LT_REGULAR,
        [theme.breakpoints.up(768)]: {
            // marginTop: 100,
        },
    },
    selectedOption: {
        fontSize: pxToRem(20),
        width: '100vw',
        height: 50,
        backgroundColor: '#354F6C',
        color: 'white',
        paddingLeft: 20,
        paddingTop: 15,
        position: 'relative',
    },
    menuToggleWrapper: {
        position: 'absolute',
        right: 30,
        top: 8,
    },
    options: {
        paddingTop: 25,
        paddingBottom: 25,
        backgroundColor: '#FFFFFF',
        '&& ul': {
            margin: 0,
            listStyleType: 'none',
            paddingLeft: 25,
        },
        '&& li': {
            marginTop: 15,
            marginBottom: 15,
        },
    },
    navLink: {
        fontSize: pxToRem(16),
        cursor: 'pointer',
        '&:hover': {
            fontWeight: 'bold',
        },
    },
    closeButton: {
        color: 'white',
    },
    openButton: {
        color: 'white',
        transform: 'rotate(-90deg)',
    },
}));

const MobileNav: React.FC<Props> = ({ labels, companyId }) => {
    const classes = useStyles();
    const [selectedNavItem, setSelectedNavItem] = useState<string>(
        labels[0]?.label,
    );
    const [showMenu, setShowMenu] = useState<boolean>(false);
    const showMenuHandler = (isShow: boolean) => {
        setShowMenu(isShow);
    };

    useEffect(() => {
        setSelectedNavItem(labels[0]?.label);
    }, [labels]);

    if (!labels) {
        return null;
    }

    return (
        <div className={classes.dropdownMenu} data-qa="hispanic-mobile-nav">
            <div className={classes.selectedOption}>
                {selectedNavItem}
                <div className={classes.menuToggleWrapper}>
                    {showMenu ? (
                        <button
                            className={classes.closeButton}
                            onClick={() => {
                                showMenuHandler(false);
                            }}
                        >
                            <CloseIcon />
                        </button>
                    ) : (
                        <button
                            className={classes.openButton}
                            onClick={() => {
                                showMenuHandler(true);
                            }}
                        >
                            <ArrowBackIosIcon />
                        </button>
                    )}
                </div>
            </div>
            <div
                className={classes.options}
                style={{ display: showMenu ? 'block' : 'none' }}
            >
                <ul>
                    {labels.map((option, index) => {
                        if (companyId === 'seiu775srp' && index === 7) {
                            return null;
                        }
                        return (
                            <li key={`option-${index}`}>
                                <Link
                                    className={classes.navLink}
                                    offset={-113}
                                    smooth
                                    spy
                                    hashSpy
                                    to={`section_${index + 1}`}
                                    onSetActive={() => {
                                        setSelectedNavItem(option.label);
                                    }}
                                    onClick={() => {
                                        showMenuHandler(false);
                                        customClickEvent({
                                            customLinkName: `Hispanic Landing_${option.label}`,
                                        });
                                    }}
                                >
                                    {option.label}
                                </Link>
                            </li>
                        );
                    })}
                </ul>
            </div>
        </div>
    );
};

export default MobileNav;
