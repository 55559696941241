import React from 'react';
import { domToReact } from 'html-react-parser';

/* utils */
import { Typography } from '@material-ui/core';
import { getCustomTagContent } from '../utils';

/* interfaces */
import { RenderElement } from '../interfaces';

const renderStyle: RenderElement = (props): JSX.Element => {
    const { domNode, options, attributes } = props;
    const { children } = domNode;
    const domElements = children && getCustomTagContent(children);
    const style =
        attributes &&
        attributes.reduce(
            (
                accumulatedStyles: { [key: string]: string },
                currentStyle: string,
            ) => {
                if (currentStyle.indexOf('=')) {
                    const styleArr = currentStyle.split('=');
                    accumulatedStyles[styleArr[0]] = styleArr[1];
                }

                return accumulatedStyles;
            },
            {},
        );

    return (
        <Typography variant="body1" style={style} component="span">
            {domElements && domToReact(domElements, options)}
        </Typography>
    );
};

export default renderStyle;
