export const elipsis = function (text: string, length = 100) {
    if (!text) {
        return '';
    }
    if (text.length > length) {
        return `${text.substring(0, length)}...`;
    }

    return text;
};

export const kebabToCamelCase = (str: string) => {
    return str.replace(/-./g, (match) => match[1].toUpperCase());
};
