export const multiMediaReached50Event = (mediaName: string, type: string) => {
    window.appEventData = window.appEventData || [];
    window.appEventData.push({
        event: 'Multi-Media Reached 50%',
        MultiMedia: {
            MultiMediaName: mediaName,
            MultiMediaType: type,
        },
    });
};
