import React, { FC } from 'react';

/* material */
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { contentLink } from 'theme/font';

const useStyles = makeStyles(() => ({
    root: {
        '& a': { ...contentLink, textDecoration: 'none' },
    },
}));

const ArticleParagraph: FC = ({ children }) => {
    const classes = useStyles();
    return (
        <Box className={classes.root} mb={4}>
            <Typography data-qa="article-paragraph" variant="body1">
                {children}
            </Typography>
        </Box>
    );
};

export default ArticleParagraph;
