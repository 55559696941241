import { DomElement } from 'html-react-parser';

export const getCustomTagTextChildren = (
    children: DomElement[],
): DomElement[] => {
    return children.filter((child: DomElement) => {
        if (child.type === 'text' && child.data !== '\n') {
            child.data = child.data.replace(/\r?\n|\r/g, '');
            return child.data.length ? child : false;
        }

        return false;
    });
};

export const getCustomTagContent = (children: DomElement[]): DomElement[] => {
    const customTagRgx = /\[.*?\]/;
    return children.reduce((accumulator, child) => {
        const isCustomTag =
            child.type === 'text' && customTagRgx.test(child.data);
        const isLeadingBreakTag = child.name === 'br' && !accumulator.length;

        if (!isCustomTag && !isLeadingBreakTag) {
            accumulator.push(child);
        }

        return accumulator;
    }, [] as DomElement[]);
};
