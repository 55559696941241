import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import queryString from 'query-string';
import { v4 as uuidv4 } from 'uuid';

/* components */
import App from 'containers/App/App';

/* actions */
import { setDomainName, setIsEnroll, setLanguage, setUID } from 'actions/user';
import { setAnalyticPageViewReady } from 'actions/analytics';

/* utils */
import configureStore, { history } from './store';
import * as serviceWorker from './serviceWorker';
import { getDomain } from 'utils/domain';
import { getGlobalConfig } from 'utils';

import { getCompanyUrl } from 'utils/url';
import { enUS } from 'utils/locales';

const windowLocation = window.location;
const qs = queryString.parse(windowLocation.search);

const getUID = () => {
	return qs.uid ? (qs.uid as string) : null;
};

const isEnroll = () => {
	return qs.e ? true : false;
};

const store = configureStore();
const domain = getDomain();
const clientSideID = getGlobalConfig().LAUNCH_DARKLY_CLIENT_SIDE_ID;
const launchDarklyUsers = ['qa'];
const uid = getUID();

// set page title as suggested by cra
// https://create-react-app.dev/docs/title-and-meta-tags/
document.title = domain;

uid && store.dispatch(setUID(uid));
store.dispatch(setDomainName(domain));
store.dispatch(setIsEnroll(isEnroll()));
store.dispatch(setLanguage(enUS));
const companyID: string = getCompanyUrl();

// reset analytic page view ready state
store.dispatch(setAnalyticPageViewReady(false));
document.addEventListener('pageViewCompleted', () => {
	store.dispatch(setAnalyticPageViewReady(true));
});

// Redirect to po-web quiz if user hit old quiz
const redirectIfNeeded = () => {
	try {
		const currentUrl = window.location;
		const pathSegments = currentUrl.pathname.split('/').filter(Boolean);
		const queryParams = currentUrl.search;

		if (!pathSegments.includes('retirement') && (pathSegments.includes('quiz'))) {
			const newPathSegments = [...pathSegments];
			newPathSegments.splice(0, 0, 'retirement');
			const newUrl = `${currentUrl.protocol}//${currentUrl.host}/${newPathSegments.join('/')}${queryParams}`;
			window.location.href = newUrl;

			return;
		}
	} catch (error) {
		console.error('Error during redirection:', error);
	}
};

(async () => {
	const { user } = store.getState();
	let uuid: string | null = user.uid;
	if (!uuid) {
		const tmp = localStorage.getItem('LDUUID') as string;
		if (tmp) {
			uuid = tmp;
		} else {
			const created = uuidv4();
			localStorage.setItem('LDUUID', created);
			uuid = created;
		}
	}
	const shouldTrackUser = launchDarklyUsers.includes(user.uid as string);
	const LDProvider = await asyncWithLDProvider({
		clientSideID,
		user: {
			key: uuid,
			custom: {
				domain: domain,
				company: companyID,
				segment: user.segment,
				animal: user.animal,
				firstVisit: !user.isReturnVisit,
			},
			anonymous: !shouldTrackUser,
		},
	});
	// Call the redirect function
	redirectIfNeeded();
	ReactDOM.render(
		<LDProvider>
			<Provider store={store}>
				<App history={history} />
			</Provider>
		</LDProvider>,
		document.getElementById('root'),
	);
})();

serviceWorker.unregister();
