import React from 'react';

/* interfaces */
import { RenderElement } from '../interfaces';

/* components */
import { ArticleQuote } from 'containers/article-landing';

/* utils */
import { getCustomTagTextChildren } from '../utils';

const renderArticleQuote: RenderElement = (props): JSX.Element => {
    const { domNode } = props;
    const { children } = domNode;
    const textElements = children && getCustomTagTextChildren(children);
    const quote = textElements && textElements[1].data;
    return <ArticleQuote>{quote}</ArticleQuote>;
};

export default renderArticleQuote;
