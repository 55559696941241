import * as React from 'react';
import { FC } from 'react';

import BaseEmoji from './BaseEmoji';
import { sentimentColors } from 'theme/colors';

const DiscourageEmoji: FC = () => {
    const mouthPath = (
        <path
            d="M28.8608398,54.5332031 C30.6235352,52.5952148 34.8211127,49.2087402 41.4382324,49.2087402 C48.0553521,49.2087402 52.2593994,53.0258789 53.34021,54.5332031 C53.34021,54.5332031 53.34021,54.5332031 53.34021,54.5332031 C53.7830698,55.150826 53.6413963,56.0105172 53.0237734,56.453377 C52.7253056,56.6673902 52.3536712,56.7532122 51.9915855,56.6917416 C48.7881887,56.1478941 45.226377,55.8759766 41.3061523,55.8759766 C36.5665674,55.8759766 32.8011443,56.1229545 30.0098832,56.6169105 L30.0098939,56.616971 C29.3262223,56.737957 28.6739184,56.2818103 28.5529324,55.5981387 C28.4850646,55.2146291 28.5987828,54.8213207 28.8608398,54.5332031 C28.8608398,54.5332031 28.8608398,54.5332031 28.8608398,54.5332031 Z"
            id="Rectangle"
            fill="#434343"
        ></path>
    );
    return (
        <BaseEmoji
            // tearOnHover
            color={sentimentColors.discouraged}
            mouth={mouthPath}
        />
    );
};

export default DiscourageEmoji;
