//import React, { FC, useState } from 'react';
import React, { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import Button from '@material-ui/core/Button';

/* types */
import { Theme } from 'reducers/company';
import { customClickEvent } from 'analytics';

type TakeQuizProps = {
    company: Theme | null;
    domainName: string;
    size?: 'small' | 'medium' | 'large';
    dataAnalyticsPlacement: string;
    ctaType?: 'contribute' | 'quiz';
};

const ChangeContribution: FC<TakeQuizProps> = ({
    company,
    size = 'medium',
    dataAnalyticsPlacement,
}) => {
    const intl = useIntl();
    const history = useHistory();

    const handleClick = () => {
        history.push(`/${company?.companyId}/quiz/retirement-vacation`);
    };

    return (
        <>
            <Button
                data-qa="take-quiz"
                variant="contained"
                size={size}
                onClick={() => {
                    handleClick();

                    customClickEvent({
                        customLinkName: `${dataAnalyticsPlacement}: ${intl.formatMessage(
                            {
                                id: 'takeTheQuiz',
                            },
                        )}`,
                    });
                }}
            >
                <FormattedMessage id="takeTheQuiz" />
            </Button>
        </>
    );
};

export default ChangeContribution;
