import React, { FC } from 'react';
import { Typography, makeStyles } from '@material-ui/core';
import { AVENIR_NEXT_LT_REGULAR } from 'theme/font';
import { parseReplace } from 'utils/htmlParser';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
    '@keyframes stretchText': {
        '0%': {
            transform: 'scaleX(0.5)',
        },
        '100%': {
            transform: 'scaleX(1)',
        },
    },
    commonCard: {
        display: 'flex',
        justifyContent: 'space-between',
        border: '1px solid #076c6f',
        borderRadius: '15px',
        [theme.breakpoints.down('sm')]: {
            height: '302px',
            width: '335px',
        },
    },
    front: {
        boxShadow: '17px 17px 1px #c3e5e7',
        [theme.breakpoints.down('sm')]: {
            boxShadow: '7.5px 7.5px 1px #c3e5e7',
        },
    },
    frontContent: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignSelf: 'center',
        marginLeft: '80px',
        textAlign: 'left',
        height: '100%',
        width: '100%',
        [theme.breakpoints.down('sm')]: {
            marginLeft: '30px',
        },
    },
    frontTitle: {
        fontFamily: AVENIR_NEXT_LT_REGULAR,
        fontWeight: 400,
        fontSize: '48px',
        lineHeight: '60px',
        color: '#00294B',
        width: '253px',
        height: '166px',
        animation: `$stretchText 0.5s ease-out`,
        [theme.breakpoints.down('sm')]: {
            fontSize: '20px',
            lineHeight: '28px',
            width: '105px',
            height: '77px',
        },
    },
    frontCTA: {
        fontFamily: AVENIR_NEXT_LT_REGULAR,
        fontWeight: 600,
        fontSize: '18px',
        lineHeight: '28px',
        textDecoration: 'underline',
        marginTop: '20px',
        color: '#364F6B',
        [theme.breakpoints.down('sm')]: {
            fontSize: '16px',
            lineHeight: '24px',
        },
    },
    frontImage: {
        height: '504px',
        width: '500px',
        borderRadius: '15px',
        [theme.breakpoints.down('sm')]: {
            height: '198px',
            width: '193px',
            alignSelf: 'center',
        },
    },
    back: {
        alignItems: 'center',
        boxShadow: '-17px 17px 1px #c3e5e7',
        [theme.breakpoints.down('sm')]: {
            gap: '33px',
            boxShadow: '-7.5px 7.5px 1px #c3e5e7',
        },
    },
    backImage: {
        height: '504px',
        width: '482px',
        borderRadius: '15px',
        [theme.breakpoints.down('sm')]: {
            height: '173px',
            width: '157px',
            alignSelf: 'flex-start',
        },
    },
    backContent: {
        position: 'absolute',
        padding: '10px',
        lineHeight: '1.5',
        width: '394px',
        textAlign: 'left',
        right: '50px',
        [theme.breakpoints.down('sm')]: {
            position: 'relative',
        },
    },
    backTitle: {
        fontFamily: AVENIR_NEXT_LT_REGULAR,
        fontWeight: 400,
        fontSize: '30px',
        lineHeight: '36px',
        color: '#00294B',
        marginBottom: '10px',
        animation: `$stretchText 0.5s ease-out`,
        [theme.breakpoints.down('sm')]: {
            height: '96px',
            width: '132px',
            fontSize: '16px',
            lineHeight: '24px',
        },
    },
    backText: {
        fontFamily: AVENIR_NEXT_LT_REGULAR,
        fontWeight: 400,
        fontSize: '18px',
        lineHeight: '28px',
        color: '#00294B',
        height: '140px',
        width: '341px',
        [theme.breakpoints.down('sm')]: {
            height: '160px',
            width: '170px',
            fontSize: '14px',
            lineHeight: '20px',
        },
    },
    flipIcon: {
        height: '30px',
        width: '30px',
        top: '16px',
        right: '16px',
        position: 'absolute',
        [theme.breakpoints.down('sm')]: {
            height: '24px',
            width: '24px',
            top: '8px',
            right: '8px',
        },
    },
}));

export type CardSideProps = {
    title: string;
    frontCta?: string;
    backText?: string;
    imagePath: string;
    imageAlt: string;
    flipIconPath: string;
};

export type CardProps = {
    isFrontSide: boolean;
    cardSideProps: CardSideProps;
};

const Card: FC<CardProps> = ({ isFrontSide, cardSideProps }) => {
    const classes = useStyles();
    const { title, imagePath, imageAlt, flipIconPath, backText, frontCta } =
        cardSideProps;
    return (
        <div
            className={clsx(
                classes.commonCard,
                isFrontSide ? classes.front : classes.back,
            )}
            data-qa={isFrontSide ? 'front' : 'back'}
        >
            {isFrontSide ? (
                <>
                    <div
                        className={classes.frontContent}
                        data-qa="front-content"
                    >
                        <Typography variant="h1" className={classes.frontTitle}>
                            {title}
                        </Typography>
                        <Typography variant="h2" className={classes.frontCTA}>
                            {frontCta}
                        </Typography>
                    </div>
                    <img
                        src={imagePath}
                        alt={imageAlt}
                        className={classes.frontImage}
                        data-qa="front-image"
                    />
                </>
            ) : (
                <>
                    <img
                        src={imagePath}
                        alt={imageAlt}
                        className={classes.backImage}
                        data-qa="back-image"
                    />
                    <div className={classes.backContent} data-qa="back-content">
                        <Typography variant="h3" className={classes.backTitle}>
                            {parseReplace(title)}
                        </Typography>
                        <Typography
                            variant="body2"
                            className={classes.backText}
                        >
                            {backText}
                        </Typography>
                    </div>
                </>
            )}
            <img
                src={flipIconPath}
                alt="flip-icon"
                className={classes.flipIcon}
                data-qa="flip-icon"
            />
        </div>
    );
};
export default Card;
