import { DomElement, HTMLReactParserOptions } from 'html-react-parser';

/* interfaces */
import {
    FragmentDataType,
    RenderElement,
    HtmlRenderDictionary,
} from './interfaces';

interface GenerateOptionsProps {
    HtmlRenderMap: HtmlRenderDictionary;
    data?: FragmentDataType;
}

const generateOptions = (
    props: GenerateOptionsProps,
): HTMLReactParserOptions => {
    const { data, HtmlRenderMap } = props;
    return {
        replace: (domNode: DomElement) => {
            const { name } = domNode;

            if (name && name in HtmlRenderMap) {
                return (HtmlRenderMap[name] as RenderElement)({
                    domNode,
                    data,
                    HtmlRenderMap,
                    options: generateOptions(props),
                });
            }

            return null;
        },
    };
};

export default generateOptions;
