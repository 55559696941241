export const dollarOnTheRoadColors = [
    '#eb4e7c',
    '#e7b8c7',
    '#B42573',
    '#7bd1e1',
    '#05ada9',
];

export const defaultColor = dollarOnTheRoadColors[0];

export const SET_BASIC_DIR_INFO = 'SET_BASIC_DIR_INFO'; // DIR = Dollar in the road
