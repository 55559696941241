import { mount, shallow, ShallowWrapper, ReactWrapper } from 'enzyme';
import { IntlProvider } from 'react-intl';
import { createLocation, createMemoryHistory } from 'history';
import { match as routerMatch } from 'react-router';
import flat from 'flat';
import { getNavigatorLocale } from 'utils/locales';
import localesEn from 'locales/en-US.json';

export interface CustomGlobal extends NodeJS.Global {
    navigator: {
        clipboard: {};
    };
    document: {
        createRange: () => {};
    };
    MutationObserver: jest.Mock;
}

export const getWrapperByQaAttribute = (
    wrapper: ShallowWrapper | ReactWrapper,
    attribute: string,
) => wrapper.find(`[data-qa='${attribute}']`);

export const getHostNodesByQaAttribute = (
    wrapper: ReactWrapper,
    attribute: string,
) => getWrapperByQaAttribute(wrapper, attribute).hostNodes();

const locale = getNavigatorLocale();
const defaultLocale = locale;
const messages: Record<string, string> = flat(localesEn);

export function mountWithIntl(node: React.ReactElement) {
    return mount(node, {
        wrappingComponent: IntlProvider,
        wrappingComponentProps: {
            locale,
            defaultLocale,
            messages,
        },
    });
}

export function shallowWithIntl(node: React.ReactElement) {
    return shallow(node, {
        wrappingComponent: IntlProvider,
        wrappingComponentProps: {
            locale,
            defaultLocale,
            messages,
        },
    });
}

type MatchParameter<Params> = { [K in keyof Params]?: string };

export const routerTestProps = <Params extends MatchParameter<Params> = {}>(
    path: string,
    params: Params,
    extendMatch: Partial<routerMatch<any>> = {},
) => {
    const match: routerMatch<Params> = Object.assign(
        {},
        {
            isExact: false,
            path,
            url: generateUrl(path, params),
            params,
        },
        extendMatch,
    );
    const history = createMemoryHistory();
    const location = createLocation(match.url);

    return { history, location, match };
};

const generateUrl = <Params extends MatchParameter<Params>>(
    path: string,
    params: Params,
): string => {
    let tempPath = path;

    for (const param in params) {
        if (params.hasOwnProperty(param)) {
            const value = params[param];
            tempPath = tempPath.replace(
                `:${param}`,
                value as NonNullable<typeof value>,
            );
        }
    }

    return tempPath;
};
