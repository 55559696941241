import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { KeyboardArrowUp } from '@material-ui/icons';
import CloseIcon from '@material-ui/icons/Close';
import { AVENIR_NEXT_LT_DEMI, AVENIR_NEXT_LT_REGULAR } from 'theme/font';
import SectionWrapper from '../components/SectionWrapper';
import NavigatorCards from 'components/navigatorCards/NavigatorCards';
import colors from 'theme/colors';
import { pxToRem } from 'theme/util';
import Button from '@material-ui/core/Button';
import { customClickEvent, modalOpenEvent } from 'analytics';
import { Link as ScrollLink } from 'react-scroll';
import { Link } from 'react-router-dom';
import { GENERIC, SEIU } from 'constants/company';
import Modal from '@material-ui/core/Modal';
import { Accordion, AccordionDetails, AccordionSummary } from 'components';
import { Box, IconButton } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import { Family } from '../models/Family';
import { parseReplace } from 'utils/htmlParser';
// import pdf from 'assets/pdfs/ICR_Hispanic_Discussion_Guide.pdf';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        fontFamily: AVENIR_NEXT_LT_REGULAR,
        position: 'relative',
        // marginTop: '20px',
        [theme.breakpoints.up('md')]: {
            width: '98%',
            // paddingTop: '90px',
        },
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        background: colors.symphonyBlue,
        height: '475px',
        borderRadius: '10px',
        [theme.breakpoints.up('md')]: {
            height: '450px',
        },
    },
    heading: {
        color: colors.white,
        textAlign: 'center',
        fontSize: '25px',
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: '41px',
        width: '80%',
        [theme.breakpoints.up('md')]: {
            fontSize: '45px',
            lineHeight: '39px',
            width: 'fit-content',
        },
        [theme.breakpoints.down('md')]: {
            //width: '245px',
        },
    },
    mainPara: {
        color: colors.white,
        textAlign: 'center',
        fontSize: pxToRem(18),
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: pxToRem(26),
        width: '80%',
        fontFamily: AVENIR_NEXT_LT_DEMI,
        [theme.breakpoints.up('md')]: {
            fontSize: pxToRem(21),
            lineHeight: pxToRem(26),
            //width: '865px',
        },
    },
    title: {
        fontWeight: 600,
        fontSize: pxToRem(22),
        textAlign: 'center',
        marginBottom: 5,
        lineHeight: '25px',
    },
    subTitle: {
        fontWeight: 400,
        fontSize: pxToRem(16),
        textAlign: 'center',
        marginBottom: 40,
    },
    guideTitle: {
        fontWeight: 600,
        fontSize: pxToRem(18),
        marginBottom: 5,
    },
    guideContent: {
        fontWeight: 400,
        fontSize: '18px',
        marginBottom: 40,
        lineHeight: '26px',
    },
    answerTitle: {
        fontWeight: 600,
        fontSize: pxToRem(18),
        marginTop: 20,
    },
    answerSubHeader: {
        fontWeight: 600,
        fontSize: pxToRem(18),
        marginTop: 20,
        marginBottom: 20,
    },
    modal: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: theme.colors.symphonyBlue,
        maxHeight: '70%',
        width: '100%',
        overflowX: 'auto',
        padding: 30,
        color: colors.white,
        fontFamily: AVENIR_NEXT_LT_REGULAR,
        [theme.breakpoints.up('sm')]: {
            width: 'unset',
            maxHeight: '85%',
        },
    },
    questionAccordion: {
        borderBottom: '1px solid white',
        '&:first-child': {
            borderTop: '1px solid white',
        },
    },
    accordionSummary: {
        '& p': {
            color: colors.white,
        },
    },
    accordionDetails: {
        fontWeight: 400,
        fontSize: pxToRem(18),
        color: theme.colors.white,
        lineHeight: 1.2,
    },
    iconContainer: {
        color: colors.white,
        // marginBottom: 'auto',
    },
    addRemoveIcon: {
        fontSize: '22px',
        marginLeft: '20px',

        [theme.breakpoints.up('sm')]: {
            fontSize: '25px',
        },
    },
    modalLink: {
        cursor: 'pointer',
        color: theme.colors.brown,
        fontSize: 'inherit',
        textDecoration: 'underline',
        fontFamily: 'inherit',
        paddingRight: '0px',
    },
    closeButton: {
        color: theme.colors.white,
        display: 'flex',
        width: '100%',
        justifyContent: 'flex-end',
    },
    closeBtn: {
        border: `solid 1px white`,
        backgroundColor: 'unset',
        color: theme.colors.white,
        display: 'block',
        margin: '0px auto',
    },
    buttonWrapper: {
        padding: 25,
    },
    bold: {
        fontWeight: 600,
        fontSize: '22px',
        lineHeight: '29px',
    },
    ctaSection: {
        marginTop: 50,
        textAlign: 'center',
        fontSize: pxToRem(22),
        fontWeight: 400,
        color: colors.black,
    },
    backToTopWrapper: {
        //marginTop: 40,
        [theme.breakpoints.up(768)]: {
            position: 'absolute',
            right: 0,
            //bottom: -30,
        },
    },
    backToTop: {
        cursor: 'pointer',
        fontSize: pxToRem(16),
        fontWeight: 600,
        color: '#0E0E0E',
    },
    disclosure: {
        textAlign: 'left',
        marginTop: 125,
        marginBottom: 20,
        padding: 10,
        color: '#333333',
        fontWeight: 600,
        fontSize: pxToRem(14),
        lineHeight: '20px',
        fontFamily: AVENIR_NEXT_LT_REGULAR,
        '&& p': {
            marginTop: 20,
            padding: 10,
            color: '#333333',
            fontWeight: 600,
            fontSize: pxToRem(14),
            lineHeight: '20px',
            fontFamily: AVENIR_NEXT_LT_REGULAR,
        },
    },
}));

interface SectionProps {
    isMobile?: boolean;
    data: Family;
    companyId: string;
    disclosure?: string;
}
interface QuestionState {
    [id: string]: boolean;
}
const Section9: React.FC<SectionProps> = ({
    isMobile = false,
    data,
    companyId,
    disclosure,
}) => {
    const classes = useStyles();
    const isSEIU = companyId === SEIU;
    const isGeneric = companyId === GENERIC;
    const [showModal, setShowModal] = useState(false);
    const [expandedQuestions, setExpandedQuestions] = useState<QuestionState>(
        {},
    );

    const handleClick = (e: { stopPropagation: () => void }) => {
        e.stopPropagation();
        modalOpenEvent('Hispanic Landing Discussion Guide Modal');
        setShowModal(!showModal);
    };

    // const renderDownloadButton = () => {
    //     return (
    //         <Button
    //             className={classes.closeBtn}
    //             onClick={() => {
    //                 customClickEvent({
    //                     customLinkName: `hispanic-landing: Download Guide`,
    //                 });
    //                 const link = document.createElement('a');
    //                 link.href = pdf;
    //                 link.download = 'ICR_Hispanic_Discussion_Guide.pdf';
    //                 link.dispatchEvent(new MouseEvent('click'));
    //             }}
    //             variant={'outlined'}
    //         >
    //             {data.modal.dowloadNow}
    //         </Button>
    //     );
    // };

    const handleChange = (
        event: React.ChangeEvent<{}>,
        questionId: string,
        question: string,
    ): void => {
        event.preventDefault();
        const newExpandedQuestions = {
            [questionId]: !expandedQuestions[questionId],
        };
        customClickEvent({
            customLinkName: `How can I help my family_${question}_${questionId}`,
        });
        setExpandedQuestions(newExpandedQuestions);
    };

    if (!data) {
        return null;
    }

    return (
        <SectionWrapper sectionId="section_9">
            <div className={classes.root} data-qa="section_9">
                <div className={classes.container}>
                    <h2 className={classes.heading} data-qa="help-family">
                        {data.mainTitle}
                    </h2>
                    <p className={classes.mainPara}>
                        <span>{data.content1}</span>
                        <button
                            onKeyDown={() => {}}
                            className={classes.modalLink}
                            onClick={handleClick}
                            data-qa="discussion-guide-text-button"
                        >
                            {data.contentButton}
                        </button>
                        <span>{data.content2}</span>
                    </p>
                    {/* {renderDownloadButton()} */}
                </div>
                <NavigatorCards
                    cards={
                        isSEIU
                            ? data.SEIUCards
                            : isGeneric
                            ? data.nonSEIUGenericCards
                            : data.nonSEIUCards
                    }
                    note={data.title}
                    noteColor={colors.black}
                    isMobile={isMobile}
                    newWindow
                />
                <div className={classes.ctaSection}>
                    {data.CtaTitle}
                    <br />
                    <br />
                    <Button
                        data-qa="left-split-hero-cta"
                        variant="contained"
                        size="small"
                        to={`/${companyId}`}
                        component={Link}
                        onClick={() => {
                            customClickEvent({
                                customLinkName: `hispanic-landing: ${data.CtaLabel}`,
                            });
                        }}
                    >
                        {data.CtaLabel}
                    </Button>
                    <div className={classes.backToTopWrapper}>
                        <ScrollLink
                            onClick={() => {
                                customClickEvent({
                                    customLinkName: `hispanic-landing: back to top`,
                                });
                            }}
                            className={classes.backToTop}
                            offset={isMobile ? -130 : -100}
                            smooth
                            spy
                            to="section_1"
                        >
                            <KeyboardArrowUp />
                            <br />
                            {data.backToTop}
                        </ScrollLink>
                    </div>
                    <div className={classes.disclosure}>
                        {parseReplace(disclosure)}
                    </div>
                </div>
            </div>
            <Modal open={showModal} onClose={handleClick}>
                <div
                    className={classes.modal}
                    data-testid="help-family-modal"
                    onClick={(e) => {
                        e.stopPropagation();
                    }}
                    onKeyPress={(e) => {
                        e.stopPropagation();
                    }}
                    role="button"
                    tabIndex={0}
                >
                    <IconButton
                        aria-label="close"
                        data-qa="modal-close-btn"
                        data-testid="modal-close-btn"
                        className={classes.closeButton}
                        onClick={handleClick}
                    >
                        <CloseIcon />
                    </IconButton>
                    <h3 className={classes.title}>{data.modal.title}</h3>
                    <div className={classes.subTitle}>
                        {data.modal.subTitle}
                    </div>
                    <div className={classes.guideTitle}>
                        {data.modal.guideTitle}
                    </div>
                    <div className={classes.guideContent}>
                        {data.modal.guideContent}
                    </div>

                    <div data-qa="accordion-visible">
                        <Accordion
                            expanded={!!expandedQuestions['1']}
                            data-qa={`question-1`}
                            square
                            onChange={(event) => {
                                handleChange(
                                    event,
                                    '1',
                                    data.modal.questions.one.question,
                                );
                            }}
                            key={'1'}
                            className={classes.questionAccordion}
                        >
                            <AccordionSummary
                                id={`question-1-header`}
                                aria-controls={`question-1-content`}
                                className={classes.accordionSummary}
                            >
                                <p className={classes.bold}>
                                    {data.modal.questions.one.question}
                                </p>
                                <Box className={classes.iconContainer}>
                                    {expandedQuestions['1'] ? (
                                        <RemoveIcon
                                            className={classes.addRemoveIcon}
                                        />
                                    ) : (
                                        <AddIcon
                                            className={classes.addRemoveIcon}
                                        />
                                    )}
                                </Box>
                            </AccordionSummary>
                            <AccordionDetails>
                                <div className={classes.accordionDetails}>
                                    {data.modal.questions.one.content1}
                                    <div className={classes.answerTitle}>
                                        {data.modal.questions.one.content2}
                                    </div>
                                    <ul>
                                        <li>
                                            {data.modal.questions.one.content3}
                                        </li>
                                        <li>
                                            {data.modal.questions.one.content4}
                                        </li>
                                        <li>
                                            {data.modal.questions.one.content5}
                                        </li>
                                        <li>
                                            {data.modal.questions.one.content6}
                                        </li>
                                    </ul>
                                </div>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion
                            expanded={!!expandedQuestions['2']}
                            data-qa={`question-2`}
                            square
                            onChange={(event) => {
                                handleChange(
                                    event,
                                    '2',
                                    data.modal.questions.two.question,
                                );
                            }}
                            key={'2'}
                            className={classes.questionAccordion}
                        >
                            <AccordionSummary
                                id={`question-2-header`}
                                aria-controls={`question-2-content`}
                                className={classes.accordionSummary}
                            >
                                <p className={classes.bold}>
                                    {data.modal.questions.two.question}
                                </p>
                                <Box className={classes.iconContainer}>
                                    {expandedQuestions['2'] ? (
                                        <RemoveIcon
                                            className={classes.addRemoveIcon}
                                        />
                                    ) : (
                                        <AddIcon
                                            className={classes.addRemoveIcon}
                                        />
                                    )}
                                </Box>
                            </AccordionSummary>
                            <AccordionDetails>
                                <div className={classes.accordionDetails}>
                                    {data.modal.questions.two.content1}
                                    <div className={classes.answerTitle}>
                                        {data.modal.questions.two.content2}
                                    </div>
                                    <ul>
                                        <li>
                                            {data.modal.questions.two.content3}
                                        </li>
                                        <li>
                                            {data.modal.questions.two.content4}
                                        </li>
                                        <li>
                                            {data.modal.questions.two.content5}
                                        </li>
                                        <li>
                                            {data.modal.questions.two.content6}
                                        </li>
                                    </ul>
                                </div>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion
                            expanded={!!expandedQuestions['3']}
                            data-qa={`question-3`}
                            square
                            onChange={(event) => {
                                handleChange(
                                    event,
                                    '3',
                                    data.modal.questions.three.question,
                                );
                            }}
                            key={'3'}
                            className={classes.questionAccordion}
                        >
                            <AccordionSummary
                                id="question-3-header"
                                aria-controls="question-3-content"
                                className={classes.accordionSummary}
                            >
                                <p className={classes.bold}>
                                    {data.modal.questions.three.question}
                                </p>
                                <Box className={classes.iconContainer}>
                                    {expandedQuestions['3'] ? (
                                        <RemoveIcon
                                            className={classes.addRemoveIcon}
                                        />
                                    ) : (
                                        <AddIcon
                                            className={classes.addRemoveIcon}
                                        />
                                    )}
                                </Box>
                            </AccordionSummary>
                            <AccordionDetails>
                                <div className={classes.accordionDetails}>
                                    {data.modal.questions.three.content1}
                                    <div className={classes.answerTitle}>
                                        {data.modal.questions.three.content2}
                                    </div>
                                    <ul>
                                        <li>
                                            {
                                                data.modal.questions.three
                                                    .content3
                                            }
                                        </li>
                                        <li>
                                            {
                                                data.modal.questions.three
                                                    .content4
                                            }
                                        </li>
                                        <li>
                                            {
                                                data.modal.questions.three
                                                    .content5
                                            }
                                        </li>
                                        <li>
                                            {
                                                data.modal.questions.three
                                                    .content6
                                            }
                                        </li>
                                    </ul>
                                    <div className={classes.answerSubHeader}>
                                        {data.modal.questions.three.content7}
                                    </div>
                                    <div className={classes.answerTitle}>
                                        {data.modal.questions.three.content8}
                                    </div>
                                    <ul>
                                        <li>
                                            {
                                                data.modal.questions.three
                                                    .content9
                                            }
                                        </li>
                                        <li>
                                            {
                                                data.modal.questions.three
                                                    .content10
                                            }
                                        </li>
                                        <li>
                                            {
                                                data.modal.questions.three
                                                    .content11
                                            }
                                        </li>
                                        <li>
                                            {
                                                data.modal.questions.three
                                                    .content12
                                            }
                                        </li>
                                        <li>
                                            {
                                                data.modal.questions.three
                                                    .content13
                                            }
                                        </li>
                                        <li>
                                            {
                                                data.modal.questions.three
                                                    .content14
                                            }
                                        </li>
                                    </ul>
                                    <div className={classes.answerTitle}>
                                        {data.modal.questions.three.content15}
                                    </div>
                                    <ul>
                                        <li>
                                            {
                                                data.modal.questions.three
                                                    .content16
                                            }
                                        </li>
                                        <li>
                                            {
                                                data.modal.questions.three
                                                    .content17
                                            }
                                        </li>
                                        <li>
                                            {
                                                data.modal.questions.three
                                                    .content18
                                            }
                                        </li>
                                    </ul>
                                    <div className={classes.answerSubHeader}>
                                        {data.modal.questions.three.content19}
                                    </div>
                                    {data.modal.questions.three.content20}
                                    <div className={classes.answerTitle}>
                                        {data.modal.questions.three.content21}
                                    </div>
                                    <ul>
                                        <li>
                                            {
                                                data.modal.questions.three
                                                    .content22
                                            }
                                        </li>
                                        <li>
                                            {
                                                data.modal.questions.three
                                                    .content23
                                            }
                                        </li>
                                        <li>
                                            {
                                                data.modal.questions.three
                                                    .content24
                                            }
                                        </li>
                                    </ul>
                                    <div className={classes.answerTitle}>
                                        {data.modal.questions.three.content25}
                                    </div>
                                    <ul>
                                        <li>
                                            {
                                                data.modal.questions.three
                                                    .content26
                                            }
                                        </li>
                                    </ul>
                                </div>
                            </AccordionDetails>
                        </Accordion>
                    </div>
                    <div className={classes.buttonWrapper}>
                        {/* {renderDownloadButton()} */}
                    </div>
                </div>
            </Modal>
        </SectionWrapper>
    );
};

export default Section9;
