import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';

/* material */
import { makeStyles } from '@material-ui/core/styles';

/* utils */
import { label } from 'theme/font';

const useStyles = makeStyles({
    fontStyles: label,
});

type ArticleMinutesProps = {
    length: string;
    isVideo?: boolean;
};

const ArticleMinutes: FC<ArticleMinutesProps> = ({
    length,
    isVideo = false,
}) => {
    const classes = useStyles();

    if (!length) return null;

    return (
        <div data-qa="article-minute-view" className={classes.fontStyles}>
            {`${length} `}
            <FormattedMessage id={isVideo ? 'minuteView' : 'minuteRead'} />
        </div>
    );
};

export default ArticleMinutes;
