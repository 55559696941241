import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Scroll from 'react-scroll';
import { AVENIR_NEXT_LT_REGULAR } from 'theme/font';
import { pxToRem } from 'theme/util';
import { elipsis } from 'utils/text';
import { customClickEvent } from 'analytics';
import colors from 'theme/colors';
import clsx from 'clsx';

interface PlaylistItemProps {
    thumbnail: string;
    isMobile?: boolean;
    title: string;
    description: string;
    minutes: string;
    id: number;
    isSelected: boolean;
    handleThumbnailClick: (index: number) => void;
    detailStyle?: string;
}

const scroller = Scroll.scroller;

const useStyles = makeStyles((theme) => ({
    playlistItem: {
        display: 'flex',
        //alignItems: 'center',
        cursor: 'pointer',
        fontFamily: AVENIR_NEXT_LT_REGULAR,
        marginBottom: 5,
        alignItems: 'flex-start',
    },
    thumbnail: {
        width: 187,
        height: 120,
        borderRadius: 10,
        transition: 'filter 0.3s ease-in-out',
        filter: 'drop-shadow(5px 5px 10px rgba(0, 0, 0, 0.36))',
    },
    onState: {
        filter: 'brightness(100%)',
        transition: 'filter 0.3s ease-in-out',
    },
    offState: {
        filter: 'brightness(70%)',
        transition: 'filter 0.3s ease-in-out',
    },
    details: {
        flexGrow: 1,
        color: colors.white,
        marginLeft: 10,
    },
    title: {
        fontSize: pxToRem(14),
        fontWeight: 600,
        lineHeight: '16.8px',
        [theme.breakpoints.up('md')]: {
            fontSize: pxToRem(14),
            lineHeight: '18px',
        },
    },
    description: {
        width: 250,
        fontSize: pxToRem(14),
        fontWeight: 400,
        lineHeight: '16.8px',
        marginTop: 4,
    },
    minutes: {
        fontSize: pxToRem(12),
        lineHeight: '14.4px',
        fontWeight: 600,
        marginTop: 5,
        position: 'relative',
        [theme.breakpoints.up('md')]: {
            fontSize: pxToRem(14),
            lineHeight: '16.8px',
        },
    },
    thumbnailWrapper: {
        position: 'relative',
    },
    nowPlaying: {
        color: 'white',
        fontSize: pxToRem(10),
        padding: '3px 5px 3px 5px',
        fontWeight: 600,
        borderRadius: 5,
        backgroundColor: '#FC5185',
        position: 'absolute',
        top: 10,
        left: 10,
        zIndex: 10,
        filter: 'drop-shadow(5px 5px 10px rgba(0, 0, 0, 0.50))',
    },
    playBtn: {
        opacity: 0.6,
        zIndex: 10,
        left: 77,
        top: 42,
        position: 'absolute',
        width: 30,
        height: 30,
        borderRadius: '50%',
        backgroundColor: '#000',
        border: 0,
        outline: 'none',
        cursor: 'pointer',
        transition: 'filter 0.3s ease-in-out',
        '&::before': {
            content: '""',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-30%, -50%)',
            width: 0,
            height: 0,
            borderTop: '5px solid transparent',
            borderBottom: '5px solid transparent',
            borderLeft: '10px solid #fff',
        },
    },
}));

const PlaylistItem: React.FC<PlaylistItemProps> = ({
    thumbnail,
    isMobile = false,
    title,
    description,
    minutes,
    handleThumbnailClick,
    id,
    isSelected,
    detailStyle,
}) => {
    const classes = useStyles();
    const [isHover, setIsHover] = useState(false);
    return (
        <div
            role="button"
            tabIndex={0}
            className={classes.playlistItem}
            onKeyDown={() => {
                handleThumbnailClick(id);
            }}
            onMouseEnter={() => {
                setIsHover(true);
            }}
            onMouseLeave={() => {
                setIsHover(false);
            }}
            onClick={() => {
                customClickEvent({
                    customLinkName: `grammy-major-video: ${title}`,
                });

                handleThumbnailClick(id);
                scroller.scrollTo('watch', {
                    smooth: true,
                    offset: -100,
                });
            }}
        >
            <div className={classes.thumbnailWrapper}>
                {isSelected && (
                    <div className={classes.nowPlaying}>Now Playing</div>
                )}

                <button aria-label="play" className={classes.playBtn}></button>
                <img
                    className={
                        isHover
                            ? `${classes.thumbnail} ${classes.onState}`
                            : `${classes.thumbnail} ${classes.offState}`
                    }
                    src={thumbnail}
                    alt="Video thumbnail"
                />
            </div>
            <div className={clsx(detailStyle, classes.details)}>
                <div className={classes.title}>{title}</div>
                {!isMobile && (
                    <div className={classes.description}>
                        {elipsis(description, 100)}
                    </div>
                )}

                <div className={classes.minutes}>{minutes}</div>
            </div>
        </div>
    );
};

export default PlaylistItem;
