export const pollVoteEvent = (
    data = {
        pollAnswer: '',
    },
) => {
    window.appEventData = window.appEventData || [];
    window.appEventData.push({
        event: 'Poll Vote',
        tool: {
            pollAnswer: data.pollAnswer,
        },
    });
};
