import React, { FC } from 'react';

/* material */
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

/* utils */
import { AVENIR_NEXT_LT_REGULAR } from 'theme/font';
import colors from 'theme/colors';
import { pxToRem } from 'theme/util';

const useStyles = makeStyles((theme) => ({
    root: {
        borderBottom: `solid 1px ${colors.darkSapphire}`,
        borderTop: `solid 1px ${colors.darkSapphire}`,
        color: colors.darkTurquoise,
        fontFamily: AVENIR_NEXT_LT_REGULAR,
        lineHeight: pxToRem(36),
        marginBottom: pxToRem(32),
        padding: `${pxToRem(20)} 0`,
        [theme.breakpoints.up('sm')]: {
            fontSize: pxToRem(30),
            lineHeight: pxToRem(44),
            marginBottom: pxToRem(48),
        },
    },
}));

const ArticleQuote: FC = ({ children }) => {
    const classes = useStyles();
    return (
        <Typography
            data-qa="article-quote"
            className={classes.root}
            variant="h2"
            component="h6"
        >
            {children}
        </Typography>
    );
};

export default ArticleQuote;
