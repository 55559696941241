import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector, useStore } from 'react-redux';
//import { pxToRem } from '../../../theme/util';
import { setLanguage } from '../../actions';
import { enUS, esUS } from '../../utils/locales';
import { RootState } from 'store';
import { AVENIR_NEXT_LT_REGULAR } from '../../theme/font';
//import { customClickEvent } from '../../../analytics';
import {
    customClickEvent,
    pageViewCompleteEvent,
    pageViewEvent,
} from 'analytics';
import { getPageViewLang } from 'utils';
//import { useHistory } from 'react-router-dom';

interface StyleProps {
    hasMobileNav?: boolean;
}

const useStyles = makeStyles((theme) => ({
    root: {
        fontFamily: AVENIR_NEXT_LT_REGULAR,
        position: 'absolute',
        top: 20,
        right: 20,
        //marginLeft: '60%',
        color: 'white',
        '&& button': {
            color: 'inherit',
        },
        [theme.breakpoints.down(825)]: {
            top: (props: StyleProps) => (props.hasMobileNav ? 70 : 20),
        },
    },
}));

interface ButtonProps {
    style?: React.CSSProperties;
    location?: string;
    lang: string;
    hasMobileNav?: boolean;
}

const TranslationButton: React.FC<ButtonProps> = ({
    style,
    location = '',
    lang = enUS,
    hasMobileNav = false,
}) => {
    const classes = useStyles({ hasMobileNav });
    const store = useStore();
    //const history = useHistory();
    const { user } = useSelector((state: RootState) => ({
        user: state.user,
        company: state.company.theme,
    }));
    const [selectedLang, setSelectedLang] = useState<string>(lang);

    const handleLangClick = (language: string) => {
        setSelectedLang(language);
        store.dispatch(setLanguage(language));
        // if (getUrlLang()) {
        //     if (window.location.href.includes('hispanic')) {
        //         history.push(
        //             `/${company?.companyId}/hispanic?lang=${language}#${
        //                 window.location.href.split('#')[1]
        //             }`,
        //         );
        //     }
        //     if (window.location.href.includes('calculator')) {
        //         history.push(
        //             `/${company?.companyId}/calculator?lang=${language}`,
        //         );
        //     }
        // }
        customClickEvent({
            customLinkName: `Language Switch ${location}: ${language}`,
        });
        pageViewEvent({
            path: window.location.pathname,
            uid: user.uid || '',
            personaSegment: user.segment,
            language: getPageViewLang(language),
        });
        pageViewCompleteEvent();
    };

    return (
        <div
            className={classes.root}
            data-qa="translation-button"
            style={style}
        >
            <button
                style={selectedLang === enUS ? { fontWeight: 'bold' } : {}}
                onClick={() => {
                    handleLangClick(enUS);
                }}
            >
                English
            </button>{' '}
            |{' '}
            <button
                style={selectedLang === esUS ? { fontWeight: 'bold' } : {}}
                onClick={() => {
                    handleLangClick(esUS);
                }}
            >
                Español
            </button>
        </div>
    );
};

export default TranslationButton;
