import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import SectionWrapper from '../components/SectionWrapper';
import { KeyboardArrowDown } from '@material-ui/icons';
import { Link } from 'react-scroll';
import { pxToRem } from 'theme/util';
import {
    AVENIR_NEXT_LT_BOLD,
    AVENIR_NEXT_LT_DEMI,
    AVENIR_NEXT_LT_REGULAR,
} from 'theme/font';
import { customClickEvent } from 'analytics/customClickEvent';
import TranslationButton from 'components/translation-button/TranslationButton';
import { enUS } from 'utils/locales';

interface StyleProps {
    dpRatio: number;
    isMobile?: boolean;
}

const useStyles = makeStyles((theme) => ({
    root: {
        textAlign: 'center',
        width: '100%',
        display: 'grid',
        minHeight: (props: StyleProps) =>
            props.dpRatio === 1.5 ? '400px' : 'calc(100vh - 100px)',
        [theme.breakpoints.up('md')]: {
            width: '98%',
        },
    },
    stmtContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        columnGap: 20,
        minHeight: 200,
        // height: 500,
        // marginTop: 100,
        [theme.breakpoints.down(800)]: {
            flexDirection: 'column',
            height: '100%',
            marginTop: 100,
        },
    },
    stmtItem: {
        width: 400,
        [theme.breakpoints.down('sm')]: {
            width: '80%',
            maxWidth: 400,
        },
    },
    prompt: {
        fontSize: pxToRem(48),
        fontWeight: 600,
        fontFamily: AVENIR_NEXT_LT_BOLD,
        lineHeight: pxToRem(54),
        [theme.breakpoints.down('sm')]: {
            fontSize: pxToRem(40),
            lineHeight: pxToRem(44),
        },
    },
    stmtText: {
        fontSize: pxToRem(36),
        fontWeight: 400,
        fontFamily: AVENIR_NEXT_LT_REGULAR,
        lineHeight: pxToRem(40),
        fontStyle: 'italic',
        [theme.breakpoints.down('sm')]: {
            fontSize: pxToRem(28),
            lineHeight: pxToRem(32),
        },
    },
    finalStmt: {
        width: '80%',
        maxWidth: 800,
        fontSize: pxToRem(40),
        fontWeight: 600,
        fontFamily: AVENIR_NEXT_LT_BOLD,
        lineHeight: pxToRem(48),
        [theme.breakpoints.down('sm')]: {
            fontSize: pxToRem(30),
            lineHeight: pxToRem(34),
        },
    },
    nextSection: {
        textAlign: 'center',
        cursor: 'pointer',
        fontFamily: AVENIR_NEXT_LT_DEMI,
        opacity: '70%',
        transition: 'opacity 0.3s ease-in-out',
        alignSelf: 'initial',
        // paddingBottom: '10vh',
        '&& h2': {
            color: 'white',
            fontWeight: 600,
            fontSize: pxToRem(16),
            lineHeight: pxToRem(24),
            marginBottom: 5,
        },
        '&:hover': {
            opacity: '90%',
        },
    },
}));

interface SectionData {
    prompt: string;
    empathyStatements: string[];
    interval: number;
    nextSection: string;
}

interface SectionProps {
    isMobile?: boolean;
    data: SectionData;
    lang: string;
}

const Section1: React.FC<SectionProps> = ({
    isMobile = false,
    data,
    lang = enUS,
}) => {
    const classes = useStyles({ dpRatio: window.devicePixelRatio, isMobile });

    const [currentElements, setCurrentElements] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentElements((currentElements) =>
                currentElements === data.empathyStatements.length - 1
                    ? 0
                    : currentElements + 1,
            );
        }, data.interval);
        return () => clearInterval(interval);
    }, [data]);

    const renderStatements = () => {
        return (
            <>
                {currentElements === data.empathyStatements.length - 1 ? (
                    <h2 className={classes.finalStmt}>
                        {data.empathyStatements[currentElements]}
                    </h2>
                ) : (
                    <>
                        <div className={classes.stmtItem}>
                            <h1 className={classes.prompt}>{data.prompt}</h1>
                        </div>
                        <div className={classes.stmtItem}>
                            <h2 className={classes.stmtText}>
                                {data.empathyStatements[currentElements]}
                            </h2>
                        </div>
                    </>
                )}
            </>
        );
    };

    if (!data) {
        return null;
    }

    if (window.location.href.includes('section_1')) {
        window.scrollTo(0, 0);
    }

    return (
        <SectionWrapper sectionId="section_1">
            <TranslationButton
                location="hispanic landing"
                lang={lang}
                hasMobileNav
            />
            <div className={classes.root} data-qa="section 1">
                <div className={classes.stmtContainer} data-qa="empathy_stmts">
                    {renderStatements()}
                </div>
                <Link
                    className={classes.nextSection}
                    to={`section_2`}
                    offset={-100}
                    smooth
                    onClick={() => {
                        customClickEvent({
                            customLinkName: `Hispanic Landing : explore the experience`,
                        });
                    }}
                >
                    <h2>{data.nextSection}</h2>
                    <KeyboardArrowDown />
                </Link>
            </div>
        </SectionWrapper>
    );
};

export default Section1;
